import { FiltersType, ListingRequest } from '@/api/types';
import { client } from '@/client';
import { Course } from '@/client/courses';
import { PartialRequestMultiselect } from '@/components/form/generic/PartialRequestMultiselect';
import { MultiSelectTemplateOptions } from 'primereact/multiselect';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

type CoursesMultiselectInputProps = {
  onChange: (value: Course[]) => void;
  additionalFilters?: FiltersType;
  selectedOptions?: Course[];
  accountId?: string;
  isDisabled?: boolean;
  filterTemplate?:
    | React.ReactNode
    | ((options: MultiSelectTemplateOptions) => React.ReactNode);
  panelClassName?: string;
  placeholder?: string;
} & Omit<HTMLAttributes<HTMLSelectElement>, 'onChange'>;

export const CoursesMultiselectInput: React.FC<
  CoursesMultiselectInputProps
> = ({
  onChange,
  className,
  additionalFilters,
  selectedOptions,
  accountId,
  isDisabled,
  filterTemplate,
  panelClassName,
  placeholder,
}) => {
  const { t } = useTranslation();
  const fetchCourses = isDisabled
    ? undefined
    : accountId
    ? async (params: ListingRequest) =>
        await client.courses.getUnavailableCoursesToAccount(params, accountId)
    : async (params: ListingRequest) => await client.courses.getCourses(params);

  return (
    <PartialRequestMultiselect
      fetchService={fetchCourses}
      filter
      display="chip"
      optionLabel="name"
      dataKey="id"
      placeholder={placeholder ?? t('courses.select')}
      maxSelectedLabels={2}
      additionalFilters={additionalFilters}
      selectedOptions={selectedOptions}
      className={className}
      handleOnChange={onChange}
      disabled={isDisabled}
      filterTemplate={filterTemplate}
      panelClassName={panelClassName}
    />
  );
};
