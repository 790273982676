import { Branch } from '@/client/branches';
import { Group } from '@/client/groups';

export enum EfrontSyncType {
  GROUP = 'group',
  BRANCH = 'branch',
}

export type EfrontSyncRecommendationRequest = {
  type: EfrontSyncType;
  id: string;
};

export type EfrontRecommendation = {
  targetType: string;
  efrontType: EfrontSyncType;
  efrontId: string;
  name: string;
  efrontParentId?: string | null;
  children?: EfrontRecommendation[];
  isMapped?: boolean;
  assignedTo?: { name: string; id: string };
};

export type EfrontRecommendationResponse = {
  pairs: EfrontRecommendation[];
};

export type EfrontSyncRequest = {
  id: string;
  branch?: Branch;
  groups?: Group[];
  type: EfrontSyncType;
};

export type EfrontGroupSyncRequest = {
  id: string;
  groups: Group[];
  type: EfrontSyncType;
};

export type EfrontSyncRequestPair = {
  name: string;
  efrontId: string;
  efrontType: EfrontSyncType;
  targetType: 'account' | 'group' | 'branch';
  targetId?: string;
  children: EfrontSyncRequestPair[];
  skip?: boolean;
  isMapped?: boolean;
};

export type EfrontSyncRequestPairFlat = {
  name: string;
  efrontParentId?: string;
  efrontId: string;
  efrontType: EfrontSyncType;
  targetType: 'account' | 'group' | 'branch';
  targetId?: string;
  parentId?: string | null;
  skip: boolean;
};

export type EfrontSyncContextState = {
  pairs: EfrontSyncRequestPairFlat[];
  accountId?: string | null;
};
