import { equal, like, notEqual } from '@/api/helpers';
import { FiltersType, ListingRequest, ListingResponse } from '@/api/types';
import { ITEMS_PER_LAZY_REQUEST } from '@/utils/helpers';
import { debounce } from 'lodash';
import {
  Dropdown,
  DropdownChangeEvent,
  DropdownFilterEvent,
  DropdownProps,
} from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import {
  VirtualScroller,
  VirtualScrollerLazyEvent,
} from 'primereact/virtualscroller';
import React, {
  ChangeEvent,
  HTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

type LazyLoadingSelectProps = {
  fetchService?: (params: ListingRequest) => Promise<ListingResponse<any>>;
  handleOnChange: (value: any) => void;
  selectedValue: any;
  selectedValueFilterBy?: string;
  additionalFilters?: FiltersType;
  optionFilter?: (value: string) => FiltersType;
  refetchKey?: number;
  isDisabled?: boolean;
} & DropdownProps &
  Omit<HTMLAttributes<HTMLSelectElement>, 'onChange' | 'defaultValue'>;

export const LazyLoadingSelect: React.FC<LazyLoadingSelectProps> = ({
  fetchService,
  handleOnChange,
  selectedValue,
  selectedValueFilterBy = 'id',
  additionalFilters,
  optionFilter = (value) => [like('name', value)],
  refetchKey = 0,
  isDisabled,
  ...rest
}) => {
  const ref = useRef<Dropdown>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [visibleData, setVisibleData] = useState<any>([]);
  const [fetchedSelectedValue, setFetchedSelectedValue] = useState<any>();
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState<FiltersType>([]);
  const [filterValue, setFilterValue] = useState<string>('');

  const handleFetchItems = async (
    filter?: FiltersType,
    isFilterOrRefetch?: boolean,
  ) => {
    setIsLoading(true);

    if (isFilterOrRefetch) {
      setSkip(0);
    }

    const request = fetchService
      ? await fetchService({
          filters: [...(additionalFilters ?? []), ...(filter ?? [])],
          skip: isFilterOrRefetch ? 0 : skip,
          take: ITEMS_PER_LAZY_REQUEST,
        })
      : undefined;

    const fetchedData = request?.result ?? [];

    if (isFilterOrRefetch) {
      setData([]);
      setVisibleData([]);
    }

    setCount(request?.count ?? 0);
    setData((prevData: any) => [...prevData, ...fetchedData]);
    setVisibleData((prevData: any) => [...prevData, ...fetchedData]);

    setIsLoading(false);
  };

  const handleFetchSelectedValue = async () => {
    if (!selectedValue) return;
    if (!fetchService) return;

    const request = await fetchService({
      filters: [
        ...(additionalFilters ?? []),
        equal(selectedValueFilterBy, selectedValue[selectedValueFilterBy]),
      ],
      skip: 0,
      take: 1,
    });

    if (request?.result?.length !== 0) {
      setFetchedSelectedValue(request?.result?.pop());
    }
  };

  useEffect(() => {
    if (!refetchKey) {
      return;
    }

    const handleRefetch = async () => {
      await handleFetchItems(undefined, true);
    };

    handleRefetch();
  }, [refetchKey]);

  useEffect(() => {
    handleFetchItems();
    setSkip(skip + ITEMS_PER_LAZY_REQUEST);
  }, []);

  useEffect(() => {
    if (selectedValue) {
      handleFetchSelectedValue();
      clearSelectedValueDuplicates();
    } else {
      setVisibleData(data);
      setFetchedSelectedValue(null);
    }
  }, [selectedValue]);

  useEffect(() => {
    clearSelectedValueDuplicates();
  }, [data]);

  const onLazyLoad = (event: VirtualScrollerLazyEvent) => {
    if (!isLoading && event.last >= data.length && skip <= count) {
      setSkip(skip + ITEMS_PER_LAZY_REQUEST);
      handleFetchItems(filter);
    }
  };

  const onFilter = useMemo(
    () =>
      debounce((event) => {
        setSkip(0);
        setFilter(optionFilter(event?.target?.value));
        handleFetchItems(optionFilter(event?.target?.value), true);
        setSkip(skip + ITEMS_PER_LAZY_REQUEST);

        if (data?.length > ITEMS_PER_LAZY_REQUEST && ref.current) {
          const vs: VirtualScroller = ref.current.getVirtualScroller();
          vs?.scrollInView(1, 'to-end');
        }
      }, 500),
    [selectedValue],
  );

  const clearSelectedValueDuplicates = () => {
    if (selectedValue) {
      setVisibleData(
        data.filter(
          (value: any) =>
            value[selectedValueFilterBy] !==
            selectedValue[selectedValueFilterBy],
        ),
      );
    }
  };

  const loadingTemplate = () => (
    <div className="flex align-items-center p-2">
      <Skeleton height="32px" />
    </div>
  );

  const filterTemplate = (
    <div className="p-dropdown-filter-container w-full">
      <span className="p-input-icon-right w-full">
        <i className="pi pi-search" />
        <InputText
          className="p-dropdown-filter p-inputtext p-component"
          placeholder={rest.placeholder}
          autoFocus
          defaultValue={filterValue}
          onChange={onFilter}
          onBlur={(e) => setFilterValue(e.target.value)}
        />
      </span>
    </div>
  );

  const virtualScrollerOptions = () => {
    if (visibleData?.length > 8) {
      return {
        itemSize: 50,
        lazy: true,
        onScrollIndexChange: onLazyLoad, // Handle lazy load here as the native one does not trigger always.
        onLazyLoad: clearSelectedValueDuplicates,
        loadingTemplate,
        showLoader: true,
        loading: isLoading,
        step: ITEMS_PER_LAZY_REQUEST,
        autoSize: true,
      };
    }

    return undefined;
  };

  return (
    <Dropdown
      ref={ref}
      dataKey="id"
      value={!isDisabled ? fetchedSelectedValue : selectedValue}
      dropdownIcon="pi pi-chevron-down"
      options={
        !isDisabled
          ? [fetchedSelectedValue, ...visibleData].filter(Boolean)
          : selectedValue
          ? [selectedValue]
          : []
      }
      disabled={isDisabled}
      virtualScrollerOptions={virtualScrollerOptions()}
      filterTemplate={filterTemplate}
      onChange={(e: DropdownChangeEvent) => {
        handleOnChange(e.value);
      }}
      {...(rest as DropdownProps)}
    />
  );
};
