import { Actions, Subjects } from '@/client/users';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentUserAbility } from '@/store/features/users';

// Custom hook to determine permissions
export const usePermission = () => {
  const ability = useAppSelector(selectCurrentUserAbility);

  const can = (action: Actions, subject: Subjects) =>
    ability ? ability.can(action, subject) : false;

  const cannot = (action: Actions, subject: Subjects) =>
    ability ? ability.cannot(action, subject) : false;

  return { can, cannot };
};
