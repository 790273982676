import { FilePurpose } from '@/api/enums';
import { isKeycloakEnabled } from '@/auth';
import { client } from '@/client';
import { File } from '@/client/files';
import { AppButton } from '@/ui/buttons';
import {
  FileUpload,
  FileUploadProps,
  FileUploadUploadEvent,
} from 'primereact/fileupload';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import styled from 'styled-components';
import { FlexContainer } from '../styled-ui';

const StyledUploadWrapper = styled(FlexContainer)`
  background: var(--beige-main);
  border-radius: 4px;
  min-width: 200px;
  min-height: 90px;
  padding: var(--small-padding);
  word-break: break-word;
  .icon {
    color: var(--gray-darker);
    font-size: var(--body-font-size);
  }

  .p-progress-spinner {
    width: 24px;
    height: 24px;
  }
`;

const StyledFlexContainer = styled(FlexContainer)`
  border: 1px solid var(--gray-dark);
  border-radius: var(--xsmall-border-radius);
  padding: var(--default-padding);
  margin-top: 6px;
`;

const StyledError = styled.p`
  color: var(--red-main);
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  margin: 0;
  width: 100%;
`;

type AppFileUploadProps = {
  url?: string;
  purpose: FilePurpose;
  preselectedFile?: File | null;
  onFileUpload: (file: File | null) => void;
  isDisabled?: boolean;
};

export const AppFileUpload: React.FC<AppFileUploadProps & FileUploadProps> = ({
  url,
  purpose,
  preselectedFile,
  onFileUpload,
  isDisabled = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const isKeycloakAuth = isKeycloakEnabled();
  const [error, setError] = useState<string[]>([]);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    setSelectedFile(preselectedFile || null);
  }, [preselectedFile]);

  const onUpload = async (e: FileUploadUploadEvent) => {
    const response = JSON.parse(e?.xhr?.response);

    setSelectedFile(response);

    setError([]);

    onFileUpload(response);
    setIsUploading(false);
  };

  const clearUpload = () => {
    setSelectedFile(null);
    onFileUpload(null);
    setError([]);
  };

  return (
    <StyledFlexContainer gap={24} justify="space-between" wrap="wrap">
      <StyledUploadWrapper flex="1">
        {selectedFile?.id && !isUploading && (
          <FlexContainer justify="space-between">
            <p>{selectedFile?.fileName}</p>
            {!isDisabled && (
              <AppButton
                type="text"
                severity="secondary"
                icon="pi pi-times"
                onClick={clearUpload}
              />
            )}
          </FlexContainer>
        )}
        {!selectedFile?.id && !isUploading && (
          <i className="pi pi-upload icon"></i>
        )}
        {isUploading && <ProgressSpinner />}
      </StyledUploadWrapper>
      {!isDisabled && (
        <FileUpload
          mode="basic"
          name="file"
          url={url || client.files.getFileUploadUrl()}
          onUpload={onUpload}
          onError={(event) => {
            const response = JSON.parse(event?.xhr?.response);

            if (response?.message) {
              setError((state) => [response.message, ...state]);
            }
            setIsUploading(false);
          }}
          auto
          chooseLabel={t('generic.upload')}
          chooseOptions={{ icon: 'none' }}
          withCredentials
          onBeforeSend={(event) => {
            if (user && isKeycloakAuth) {
              event.xhr.setRequestHeader(
                'Authorization',
                `Bearer ${user?.access_token}`,
              );
            }
          }}
          onBeforeUpload={(event) => {
            clearUpload();
            setIsUploading(true);
            event.formData.append('purpose', purpose);
          }}
          disabled={isUploading}
          {...rest}
        />
      )}
      {!!error?.length && (
        <StyledError>
          {error.map((error, index) => (
            <span key={index}>
              {error}.<br />
            </span>
          ))}
        </StyledError>
      )}
    </StyledFlexContainer>
  );
};
