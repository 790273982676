import { client } from '@/client';
import {
  REFRESH_TOKEN_EXPIRES_KEY,
  TOKEN_EXPIRES_KEY,
} from '@/common/constants/user';
import { AppDispatch } from '@/store/store';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

// ***** SETUP *****
type AuthState = {
  loading: boolean;
  error?: string;
};

const initialState: AuthState = {
  loading: false,
  error: undefined,
};

const authSlice = createSlice({
  name: 'init',
  initialState,
  reducers: {
    setLoading: (state: Draft<AuthState>, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state: Draft<AuthState>, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

// ***** ACTIONS *****
export const doHandshakeAction =
  (token: string, isRefresh?: boolean) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
      const data = await client.auth[
        !isRefresh ? 'handshakeToken' : 'refreshToken'
      ](token);

      dispatch(setLoading(false));

      if (data) {
        sessionStorage.setItem(TOKEN_EXPIRES_KEY, data.payload.tokenExpiresOn);
        sessionStorage.setItem(
          REFRESH_TOKEN_EXPIRES_KEY,
          data.payload.refreshTokenExpiresOn,
        );
      }
    } catch (e: any) {
      dispatch(setError('Something went wrong :(, try again later!'));
    }
  };

// ***** EXPORTS *****
export const { setLoading, setError } = authSlice.actions;

export default authSlice.reducer;
