import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import {
  EfrontGroupSyncRequest,
  EfrontSyncRequest,
  EfrontSyncType,
} from '@/client/efront';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import { TranslationFunctionType } from '@/common/types';
import { EfrontTypeSelectFormContainer } from '@/components/efront-sync';
import { EfrontGroupsFormContainer } from '@/components/efront-sync/EfrontGroupsFormContainer';
import { EfrontMappingContainer } from '@/components/efront-sync/EfrontMappingContainer';
import { useSendSyncRequest } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import {
  useEFrontReindex,
  useEFrontSyncNotifications,
} from '@/hooks/sync/efront.hook';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import { Steps } from 'primereact/steps';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const pathItems = (account: Account, t: TranslationFunctionType) => [
  {
    label: account?.name,
    url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('sidebar.efront'),
    url: RedirectPaths[RedirectPathsEnum.EFRONT_SYNC](),
    template: AppBreadCrumbTemplate,
  },
];

const typeSelectInitialValues = (typeSelectValues?: EfrontSyncRequest) => ({
  id: typeSelectValues?.id || '',
  branch: typeSelectValues?.branch || undefined,
  type: EfrontSyncType.BRANCH,
});

const groupsSelectInitialValues = (
  groupsSelectValues?: EfrontGroupSyncRequest,
) => ({
  id: groupsSelectValues?.id || '',
  groups: groupsSelectValues?.groups || [],
  type: EfrontSyncType.GROUP,
});

export const EfrontSyncWizardPage: React.FC = () => {
  const { t } = useTranslation();

  const { setDialogData } = useContext(DialogContext);

  const account = useAppSelector(selectCurrentAccount);
  const toast = useToast();

  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [inProgress, setInProgress] = useState<LoadingStatuses>(
    LoadingStatuses.IDLE,
  );
  const [typeSelectFormInitialValues, setTypeSelectFormInitialValues] =
    useState<EfrontSyncRequest>(typeSelectInitialValues());
  const [typeSelectFormValues, setTypeSelectFormValues] =
    useState<EfrontSyncRequest>(typeSelectInitialValues());
  const [groupSelectFormValues, setGroupSelectFormValues] =
    useState<EfrontGroupSyncRequest>(groupsSelectInitialValues());
  const [disableSync, setDisableSync] = useState(false);
  const [enableThirdStep, setEnableThirdStep] = useState(false);
  const { create } = useSendSyncRequest();
  const messages = useEFrontSyncNotifications();
  const { isSuccess, loading, reindex } = useEFrontReindex();

  const steps = (t: TranslationFunctionType) => [
    {
      label: t('efront.select.branch.migrate'),
      disabled: inProgress === LoadingStatuses.LOADING,
    },
    {
      label: t('efront.preview'),
      disabled:
        !typeSelectFormInitialValues.branch ||
        inProgress === LoadingStatuses.LOADING,
    },
    {
      label: t('efront.groups'),
      disabled: !enableThirdStep || inProgress === LoadingStatuses.LOADING,
    },
  ];

  useEffect(() => {
    handleSyncNotification(messages);
  }, [JSON.stringify(messages)]);

  const handleSyncNotification = (messages: { data: any } | null) => {
    if (
      !messages?.data?.event ||
      !['eFront.account.sync.error', 'eFront.account.sync.finished'].includes(
        messages?.data?.event,
      ) ||
      !messages?.data?.eFrontId ||
      !typeSelectFormValues?.id ||
      messages.data.eFrontId !== typeSelectFormValues.id
    )
      return;

    if (messages.data.event === 'eFront.account.sync.error')
      toast?.error(
        t('toast.error'),
        t('sync.data.error', { error: messages.data?.error }),
      );

    if (messages.data.event === 'eFront.account.sync.finished') {
      toast?.success(t('toast.success'), t('efront.data.success'));
      setActiveIndex(activeIndex + 1);
      setEnableThirdStep(true);
    }

    setInProgress(LoadingStatuses.IDLE);
  };

  useEffect(() => {
    if (isSuccess === null) {
      return;
    }

    toast?.success(
      isSuccess ? t('toast.success') : t('toast.error'),
      isSuccess ? t('efront.queue') : t('efront.error.process'),
    );
  }, [isSuccess]);

  const onSyncTypeSubmit = () => {
    if (!typeSelectFormValues.id) return;

    setTypeSelectFormInitialValues(
      typeSelectInitialValues(typeSelectFormValues),
    );
    setActiveIndex(activeIndex + 1);
  };

  const onMappingContainerSubmit = async (
    efrontSyncRequest: EfrontSyncRequest,
  ) => {
    try {
      await create(efrontSyncRequest);
      setInProgress(LoadingStatuses.LOADING);

      if (activeIndex === 2) {
        navigate(RedirectPaths[RedirectPathsEnum.ACCOUNTS]());
      }
      toast?.success(t('toast.success'), t('sync.data.sent'));
    } catch (e) {
      handleAxiosError(e as AxiosError, toast);
    }
  };

  const onPrevPage = () => {
    setActiveIndex(activeIndex - 1);
  };

  const onNextPage = () => {
    setActiveIndex(activeIndex + 1);
  };

  const onReindex = async () => {
    await reindex();
  };

  return (
    <FlexContainer direction="column" align="flex-start" height="100%">
      <div className="w-full flex-1">
        <AppBreadCrumb model={pathItems(account as Account, t)} />
        <h1>{t('migration.wizard')}</h1>
        <Steps
          model={steps(t)}
          activeIndex={activeIndex}
          onSelect={(e) => setActiveIndex(e.index)}
          readOnly={!typeSelectFormValues.id || !typeSelectFormValues.branch}
        />

        <div>
          {activeIndex === 0 && (
            <EfrontTypeSelectFormContainer
              initialValues={typeSelectFormInitialValues}
              onValuesChange={(values) => setTypeSelectFormValues(values)}
            />
          )}
          {activeIndex === 1 && !!typeSelectFormValues && (
            <EfrontMappingContainer
              type={typeSelectFormValues.type}
              id={typeSelectFormValues.id}
              onTypeIdChange={(disabled) => setDisableSync(disabled)}
            />
          )}
          {activeIndex === 2 && typeSelectFormValues.branch && (
            <EfrontGroupsFormContainer
              initialValues={groupSelectFormValues}
              branch={typeSelectFormValues.branch}
              onValuesChange={(values) => setGroupSelectFormValues(values)}
            />
          )}
        </div>
      </div>
      <FlexContainer
        id="eFront-wizard-migration-footer-container"
        justify="space-between"
      >
        {activeIndex === 0 && (
          <>
            <AppButton
              severity="secondary"
              onClick={onReindex}
              isDisabled={loading}
              label={t('efront.reindex')}
            />
            <AppButton
              severity="secondary"
              isDisabled={
                !typeSelectFormValues.id || !typeSelectFormValues.branch
              }
              icon="pi pi-arrow-right"
              iconPos="right"
              label={t('button.next')}
              onClick={onSyncTypeSubmit}
            />
          </>
        )}
        {activeIndex === 1 && (
          <>
            <AppButton
              severity="secondary"
              onClick={onPrevPage}
              icon="pi pi-arrow-left"
              iconPos="left"
              label={t('button.back')}
              className="mr-3"
              state={inProgress}
            />
            <div>
              <AppButton
                severity="secondary"
                isDisabled={disableSync}
                onClick={() =>
                  setDialogData({
                    show: true,
                    type: 'confirmation',
                    header: t('efront.confirmMap'),
                    message: t('efront.map.confirm'),
                    onAccept: () =>
                      onMappingContainerSubmit({
                        ...typeSelectFormValues,
                        type: EfrontSyncType.BRANCH,
                      }),
                    onReject: () => onPrevPage(),
                  })
                }
                label={t('button.sync')}
                state={inProgress}
              />
              {enableThirdStep && (
                <AppButton
                  severity="secondary"
                  onClick={onNextPage}
                  icon="pi pi-arrow-right"
                  iconPos="left"
                  label={t('button.next')}
                  className="ml-3"
                />
              )}
            </div>
          </>
        )}
        {activeIndex === 2 && (
          <>
            <AppButton
              severity="secondary"
              onClick={onPrevPage}
              icon="pi pi-arrow-left"
              iconPos="left"
              label={t('button.back')}
              className="mr-3"
            />
            <div>
              <AppButton
                severity="info"
                isDisabled={!enableThirdStep}
                onClick={() =>
                  navigate(RedirectPaths[RedirectPathsEnum.ACCOUNTS]())
                }
                iconPos="left"
                label={t('button.skip')}
                className="mr-3"
              />
              <AppButton
                severity="secondary"
                isDisabled={
                  !enableThirdStep || !groupSelectFormValues?.groups?.length
                }
                onClick={() =>
                  setDialogData({
                    show: true,
                    type: 'confirmation',
                    header: t('efront.confirmMap'),
                    message: t('efront.map.confirm'),
                    onAccept: () =>
                      onMappingContainerSubmit({
                        ...groupSelectFormValues,
                        type: EfrontSyncType.GROUP,
                      }),
                    onReject: () => onPrevPage(),
                  })
                }
                label={t('button.sync')}
                state={inProgress}
              />
            </div>
          </>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};
