import { AuthTypes } from '@/auth';

export const isKeycloakEnabled = (): boolean => {
  return process.env.REACT_APP_AUTH_METHOD === AuthTypes.KEYCLOAK;
};

export const extractSubdomain = (location: Location) =>
  location.host.replace('www.', '').split('.').length >= 2
    ? location.host.split('.')[0]
    : null;
