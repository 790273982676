import React, { ReactNode } from 'react';

import styled from 'styled-components';

type AbsoluteCenterProps = {
  children: ReactNode;
};

const AbsoluteCenter: React.FC<AbsoluteCenterProps> = ({ children }) => {
  return <Centered>{children}</Centered>;
};

const Centered = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default AbsoluteCenter;
