import { FiltersType } from '@/api/types';
import { client } from '@/client';
import { MessageResponseModel } from '@/client/models';
import {
  ListSystemEmailTemplatesResponse,
  SaveSystemEmailTemplate,
  SystemEmailTemplate,
  UpdateSystemEmailTemplate,
} from '@/client/system-emails';
import {
  DeleteSystemEmailTemplateMutation,
  GenericForbiddenApiError,
  SaveSystemEmailTemplateMutation,
  UpdateSystemEmailTemplateMutation,
} from '@/hooks/query';
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

const queryKey = 'system-email-templates';
const singleQueryKey = 'system-email-template';

export const useSystemEmailsTemplates = (
  params: UseQueryOptions & {
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
): UseQueryResult<ListSystemEmailTemplatesResponse> & {
  systemEmailsTemplates: ListSystemEmailTemplatesResponse | undefined;
} => {
  const {
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(sort), JSON.stringify(filters)],
    () => client.systemEmailsTemplatesService.getSystemEmailsTemplates(params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    systemEmailsTemplates: data,
    ...(rest as UseQueryResult<ListSystemEmailTemplatesResponse>),
  };
};

export const useSystemEmailTemplate = (
  params: UseQueryOptions & {
    emailId?: string;
  },
):
  | UseQueryResult<SystemEmailTemplate> & {
      systemEmailTemplate: SystemEmailTemplate | undefined;
    } => {
  const {
    emailId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [singleQueryKey, emailId],
    () =>
      emailId
        ? client.systemEmailsTemplatesService.getSystemEmail(emailId)
        : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<SystemEmailTemplate>),
    },
  );

  return {
    systemEmailTemplate: data,
    ...(rest as UseQueryResult<SystemEmailTemplate>),
  };
};

export const useSaveSystemEmailTemplate = (): {
  create: (payload: SaveSystemEmailTemplate) => Promise<SystemEmailTemplate>;
} & SaveSystemEmailTemplateMutation => {
  const { mutateAsync, ...rest } = useMutation<
    SystemEmailTemplate,
    GenericForbiddenApiError,
    SaveSystemEmailTemplate
  >('createSystemEmailTemplate', (payload: SaveSystemEmailTemplate) =>
    client.systemEmailsTemplatesService.saveSystemEmailTemplate(payload),
  );

  return { create: mutateAsync, ...(rest as any) };
};

export const useUpdateSystemEmailTemplate = (): {
  update: (payload: UpdateSystemEmailTemplate) => Promise<SystemEmailTemplate>;
} & UpdateSystemEmailTemplateMutation => {
  const { mutateAsync, ...rest } = useMutation<
    SystemEmailTemplate,
    GenericForbiddenApiError,
    UpdateSystemEmailTemplate
  >('updateSystemEmailTemplate', (payload: UpdateSystemEmailTemplate) =>
    client.systemEmailsTemplatesService.updateSystemEmailTemplate(payload),
  );

  return { update: mutateAsync, ...(rest as any) };
};

export const useDeleteSystemEmailTemplate = (): {
  delete: (emailId: string) => Promise<MessageResponseModel>;
} & DeleteSystemEmailTemplateMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    string
  >('deleteSystemEmailTemplate', (emailId: string) =>
    client.systemEmailsTemplatesService.deleteSystemEmailTemplate(emailId),
  );

  return { delete: mutateAsync, ...(rest as any) };
};
