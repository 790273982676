import { processListingParams } from '@/api/helpers';
import {
  ListEfrontGroupsRequest,
  ListEfrontGroupsResponse,
} from '@/client/efront';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

export const efrontGroupService = (client: AxiosInstance) => {
  const getEfrontGroups = async (
    params: ListEfrontGroupsRequest,
  ): Promise<ListEfrontGroupsResponse> => {
    try {
      const result = await client.get<
        ListEfrontGroupsResponse,
        AxiosResponse<ListEfrontGroupsResponse>
      >('efront-integration/groups', {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  return {
    getEfrontGroups,
  };
};
