import { extractSubdomain } from '@/auth';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { CURRENT_ACCOUNT_KEY } from '@/common/constants';
import { AppDispatch, RootState } from '@/store/store';
import {
  createSelector,
  createSlice,
  Draft,
  PayloadAction,
} from '@reduxjs/toolkit';

type CurrentAccountState = {
  current: Account | null;
  error: string | null;
};

const initialState: CurrentAccountState = {
  current:
    sessionStorage.getItem(CURRENT_ACCOUNT_KEY) !== null
      ? JSON.parse(sessionStorage.getItem(CURRENT_ACCOUNT_KEY) as string)
      : null,
  error: null,
};

const currentAccountSlice = createSlice({
  name: 'current-account',
  initialState,
  reducers: {
    setCurrentAccount: (
      state: Draft<CurrentAccountState>,
      action: PayloadAction<Account>,
    ) => {
      state.current = action.payload;

      sessionStorage.setItem(
        CURRENT_ACCOUNT_KEY,
        JSON.stringify(action.payload),
      );
    },
    setCurrentAccountError: (
      state: Draft<CurrentAccountState>,
      action: PayloadAction<string>,
    ) => {
      state.error = action.payload;
    },
  },
});

export const getAccountBySubdomainAction =
  (location: Location) => async (dispatch: AppDispatch) => {
    const subdomain = extractSubdomain(location);

    if (!subdomain) {
      return;
    }

    try {
      let account: Account | undefined;
      const { id } = await client.accounts.getSubdomain(subdomain);

      if (id) {
        account = await client.accounts.getAccount(id);
      }

      account && dispatch(setCurrentAccount(account));
    } catch (e) {
      dispatch(setCurrentAccountError((e as Error).message));
    }
  };

export const selectCurrentAccount = createSelector(
  (state: RootState) => state.currentAccount,
  (currentAccountState) => currentAccountState.current,
);

export const selectCurrentAccountState = createSelector(
  (state: RootState) => state.currentAccount,
  (currentAccountState) => currentAccountState,
);

export const { setCurrentAccount, setCurrentAccountError } =
  currentAccountSlice.actions;

export default currentAccountSlice.reducer;
