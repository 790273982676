import { languageDisplayLabel, LanguagesEnum } from '@/api/enums';

export const availableLanguages = [
  LanguagesEnum.EN,
  LanguagesEnum.DK,
  LanguagesEnum.SE,
  LanguagesEnum.DE,
  LanguagesEnum.NL,
  LanguagesEnum.NO,
];

export const learnerOnlyLanguages = [
  LanguagesEnum.SE,
  LanguagesEnum.DE,
  LanguagesEnum.NL,
  LanguagesEnum.NO,
];

export const adminOnlyLanguages = availableLanguages.filter(
  (lang: LanguagesEnum) => !learnerOnlyLanguages.includes(lang),
);

// When redundant just clear the array, so it can be reused for future new languages.
export const courseOnlyLanguages: LanguagesEnum[] = [];

export const availableLanguagesDropdown = availableLanguages
  .map((language: LanguagesEnum) => ({
    label: languageDisplayLabel[language],
    value: language,
  }))
  /* 
    Exception for Kalaallisut (Greenland).
    Note: This should be used only in user/account/system email context and does not have UI translations.
  */
  .concat({
    label: languageDisplayLabel[LanguagesEnum.KL],
    value: LanguagesEnum.KL,
  });
