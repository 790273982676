import { ACCOUNT_DOMAIN_REGEX, DOMAIN_REGEX } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { array, boolean, date, number, object, string } from 'yup';

export const accountSchema = (t: TranslationFunctionType, isCreate?: boolean) =>
  object({
    name: string().required(t('error.requiredField')),
    subdomain: string()
      .matches(ACCOUNT_DOMAIN_REGEX, t('account.invalid.subdomain'))
      .nullable(),
    defaultLanguage: string().nullable(),
    hubspotId: string().when(['type'], ([type], schema) =>
      !type && isCreate
        ? schema.required(t('error.requiredField'))
        : schema.notRequired(),
    ),
    hubspotDomains: array().when(['type'], ([type], schema) => {
      if (!type && isCreate) {
        return schema
          .test('valid-domains', t('error.invalidDomain'), (domains) => {
            if (!domains || domains.length === 0) {
              return true;
            }

            const invalidDomains = domains.filter(
              (domain) => !DOMAIN_REGEX.test(domain),
            );

            return invalidDomains.length === 0;
          })
          .min(1, t('error.requiredField'));
      } else {
        return schema.nullable();
      }
    }),
    active: boolean().default(false),
    type: boolean(),
    eFrontSync: boolean(),
    autoCourseAvailability: boolean(),
    autoCourseEnrollment: boolean(),
    isSSO: boolean(),
    isPhishingOnly: boolean(),
    freeTrialCheck: boolean(),
    freeTrialEndsAt: date().when(
      ['freeTrialCheck'],
      ([freeTrialCheck], schema) =>
        freeTrialCheck === true ? schema.required() : schema.notRequired(),
    ),
    courseDueDaysCheck: boolean(),
    courseDueDays: number()
      .typeError(t('account.error.input.courseDueDays'))
      .when(['courseDueDaysCheck'], ([courseDueDaysCheck], schema) =>
        courseDueDaysCheck === true ? schema.required() : schema.notRequired(),
      ),
    notifyManagerForOverdueCourses: boolean(),
    showCampaignOpenEvents: boolean(),
  });

export const historicalDataSchema = (t: TranslationFunctionType) =>
  object({
    startDate: date(),
    endDate: date().required(t('error.requiredField')),
  });
