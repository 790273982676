import { StyledInputSwitch } from '@/components/form';
import { FlexContainer } from '@/ui/styled-ui';
import { InputSwitchChangeEvent } from 'primereact/inputswitch';
import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  font-weight: 500;
  margin-bottom: 0 !important;
  margin-left: var(--small-padding) !important;
`;

type CustomSwitchParams = {
  checked: boolean;
  onChange: (e: InputSwitchChangeEvent) => void;
  label: string;
};

export const CustomSwitch: React.FC<CustomSwitchParams> = ({
  checked,
  onChange,
  label,
}) => {
  return (
    <FlexContainer className="mb-4" justify="flex-start">
      <StyledInputSwitch
        id="useCustom"
        name="useCustom"
        checked={checked}
        onChange={onChange}
      />
      <StyledLabel htmlFor="useCustom">{label}</StyledLabel>
    </FlexContainer>
  );
};
