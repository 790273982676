import { client } from '@/client';
import { AccountAD, UpdateAccountADRequest } from '@/client/accounts/types';
import { MessageResponseModel } from '@/client/models';
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import {
  ForceAccountSyncMutation,
  GenericForbiddenApiError,
  SaveAccountADMutation,
} from './types';

const queryKey = 'accountAdConfig';

export const useADConfig = (
  params: UseQueryOptions & {
    accountId?: string;
  },
): UseQueryResult<AccountAD> & {
  accountAd: AccountAD | undefined;
} => {
  const {
    accountId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKey, accountId],
    () => (accountId ? client.adSync.getAccountADConfig(accountId) : undefined),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    accountAd: data,
    ...(rest as UseQueryResult<AccountAD>),
  };
};

export const useSaveADConfig = (): {
  create: (payload: UpdateAccountADRequest) => Promise<AccountAD>;
} & SaveAccountADMutation => {
  const { mutateAsync, ...rest } = useMutation<
    AccountAD,
    GenericForbiddenApiError,
    UpdateAccountADRequest
  >('addAccountADConfig', (payload: UpdateAccountADRequest) =>
    client.adSync.saveAccountADConfig(payload),
  );

  return { create: mutateAsync, ...(rest as any) };
};

export const useForceADSync = (): {
  sync: (accountId: string) => Promise<MessageResponseModel>;
} & ForceAccountSyncMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    string
  >('forceADSync', (accountId: string) => client.adSync.forceADSync(accountId));

  return { sync: mutateAsync, ...(rest as any) };
};
