import { DashboardType, LAST_SELECTED_DASHBOARD } from '@/common/constants';
import { RootState } from '@/store/store';
import {
  createSelector,
  createSlice,
  Draft,
  PayloadAction,
} from '@reduxjs/toolkit';

type CurrentDashboardState = {
  current: typeof DashboardType.ADMIN | typeof DashboardType.LEARNER | null;
};

const initialState: CurrentDashboardState = {
  current: localStorage.getItem(LAST_SELECTED_DASHBOARD) ?? null,
};

const currentDashboardSlice = createSlice({
  name: 'current-dashboard',
  initialState,
  reducers: {
    setCurrentDashboard: (
      state: Draft<CurrentDashboardState>,
      action: PayloadAction<string>,
    ) => {
      state.current = action.payload;
    },
  },
});

export const selectCurrentDashboard = createSelector(
  (state: RootState) => state.currentDashboard,
  (currentDashboardState) => currentDashboardState.current,
);

export const { setCurrentDashboard } = currentDashboardSlice.actions;

export default currentDashboardSlice.reducer;
