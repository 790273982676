import { FiltersType, ListingRequest, ListingResponse } from '@/api/types';
import classNames from 'classnames';
import { get } from 'lodash';
import { DropdownProps } from 'primereact/dropdown';
import React from 'react';
import { LazyLoadingSelect } from './generic/LazyLoadingSelect';

export type FormikLazyDropdownProps = {
  field: {
    name: string;
    value: object;
    onChange: () => void;
    onBlur: () => void;
  };
  fetchService: (params: ListingRequest) => Promise<ListingResponse<any>>;
  additionalFilters?: FiltersType;
  form: { touched: Record<string, any>; errors: Record<string, any> };
  label?: string;
};

export const FormikLazyDropdown: React.FC<
  FormikLazyDropdownProps & DropdownProps
> = ({
  field,
  form,
  label,
  required,
  fetchService,
  additionalFilters,
  className,
  ...rest
}) => {
  const fieldError = get(form.errors, field.name);
  const fieldTouched = get(form.touched, field.name);

  return (
    <>
      <label
        htmlFor={field.name}
        className={classNames({
          'p-error': fieldTouched && !!fieldError,
        })}
      >
        {label}
        {required ? <span className="red"> *</span> : ''}
      </label>
      <LazyLoadingSelect
        fetchService={fetchService}
        className={className}
        selectedValue={field.value ?? undefined}
        additionalFilters={additionalFilters}
        {...(rest as any)}
      />
      {fieldTouched && !!fieldError ? (
        <small className="p-error">{fieldError}</small>
      ) : null}
    </>
  );
};
