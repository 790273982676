import { URL_REGEX } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { boolean, object, string } from 'yup';

export const landingPageTemplateSchema = (t: TranslationFunctionType) =>
  object().shape(
    {
      name: string().required(t('error.requiredField')),
      captureCredentials: boolean(),
      redirectType: string().equals(['template', 'url']),
      redirectUrl: string()
        .nullable()
        .when(
          ['redirectType', 'captureCredentials'],
          ([redirectType, captureCredentials], scheme) =>
            redirectType === 'url' && captureCredentials
              ? string().matches(URL_REGEX || '' || null, t('error.invalidUrl'))
              : scheme,
        ),
      redirectPage: object()
        .nullable()
        .when(
          ['redirectType', 'captureCredentials'],
          ([redirectType, captureCredentials], scheme) =>
            redirectType === 'template' && captureCredentials
              ? object().nullable()
              : scheme,
        ),
    },
    [
      ['redirectUrl', 'redirectType'],
      ['redirectUrl', 'captureCredentials'],
      ['redirectPage', 'redirectType'],
      ['redirectPage', 'captureCredentials'],
    ],
  );
