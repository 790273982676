import { FiltersType, GroupedFilterConditions } from '@/api/types';
import { client } from '@/client';
import {
  EmailTemplate,
  ListEmailTemplatesResponse,
  SaveEmailTemplateRequest,
  UpdateEmailTemplateRequest,
} from '@/client/email-templates';
import { MessageResponseModel } from '@/client/models';
import {
  DeleteEmailTemplateMutation,
  GenericForbiddenApiError,
  SaveEmailTemplateMutation,
} from '@/hooks/query';
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

const queryKey = 'email-templates';
const singleQueryKey = 'email-template';

export const useEmailTemplates = (
  params: UseQueryOptions & {
    take?: number;
    skip?: number;
    filters?: FiltersType | GroupedFilterConditions;
  },
): UseQueryResult<ListEmailTemplatesResponse> & {
  emailTemplates: ListEmailTemplatesResponse | undefined;
} => {
  const {
    take = 200,
    skip = 0,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(filters)],
    () => client.emailTemplates.getEmailTemplates(params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    emailTemplates: data,
    ...(rest as UseQueryResult<ListEmailTemplatesResponse>),
  };
};

export const useEmailTemplate = (
  params: UseQueryOptions & {
    emailTemplateId?: string;
  },
): UseQueryResult<EmailTemplate> & {
  emailTemplate: EmailTemplate | undefined;
} => {
  const {
    emailTemplateId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [singleQueryKey, emailTemplateId],
    () =>
      emailTemplateId
        ? client.emailTemplates.getEmailTemplate(emailTemplateId)
        : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<EmailTemplate>),
    },
  );

  return {
    emailTemplate: data,
    ...(rest as UseQueryResult<EmailTemplate>),
  };
};

export const useSaveEmailTemplate = (): {
  create: (payload: SaveEmailTemplateRequest) => Promise<EmailTemplate>;
} & SaveEmailTemplateMutation => {
  const { mutateAsync, ...rest } = useMutation<
    EmailTemplate,
    GenericForbiddenApiError,
    SaveEmailTemplateRequest
  >('createEmailTemplate', (payload: SaveEmailTemplateRequest) =>
    client.emailTemplates.saveEmailTemplate(payload),
  );

  return { create: mutateAsync, ...(rest as any) };
};

export const useUpdateEmailTemplate = (): {
  update: (payload: UpdateEmailTemplateRequest) => Promise<EmailTemplate>;
} & SaveEmailTemplateMutation => {
  const { mutateAsync, ...rest } = useMutation<
    EmailTemplate,
    GenericForbiddenApiError,
    UpdateEmailTemplateRequest
  >('updateEmailTemplate', (payload: UpdateEmailTemplateRequest) =>
    client.emailTemplates.updateEmailTemplate(payload),
  );

  return { update: mutateAsync, ...(rest as any) };
};

export const useDeleteEmailTemplate = (): {
  delete: (emailTemplateId: string) => Promise<MessageResponseModel>;
} & DeleteEmailTemplateMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    string
  >('deleteEmailTemplate', (emailTemplateId: string) =>
    client.emailTemplates.deleteEmailTemplate(emailTemplateId),
  );

  return { delete: mutateAsync, ...(rest as any) };
};
