import { CampaignFormValues } from '@/client/campaigns';
import {
  availableLanguagesDropdown,
  courseOnlyLanguages,
} from '@/common/constants/languages';
import {
  FormikDropdown,
  FormikInput,
  FormikRichTextArea,
  FormikWrappedSwitch,
} from '@/components/form';
import { FormikInputNumber } from '@/components/form/FormikInputNumber';
import { AppButton } from '@/ui/buttons';
import { CheckboxesContainer } from '@/ui/checkboxes-container';
import { FlexContainer } from '@/ui/styled-ui';
import {
  getPhishingSignOptions,
  getPhishingTypeOptions,
} from '@/utils/helpers';
import { difficultyOptions, senderOptions } from '@/utils/helpers/ui.helper';
import { Field, FormikErrors } from 'formik';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { Tooltip } from 'primereact/tooltip';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledLabelBelow = styled.label`
  width: 100%;
  text-align: center;
  color: var(--gray-darker);
  padding-top: var(--xsmall-padding);
  padding-bottom: 0;
  margin: 0 !important;
`;

type InfoStepProps = {
  values: CampaignFormValues;
  errors: FormikErrors<CampaignFormValues>;
  valuesSetter: any;
};

export const InfoStep: React.FC<InfoStepProps> = ({
  values,
  errors,
  valuesSetter,
}) => {
  const { t } = useTranslation();
  const [canEditName, setCanEditName] = useState(false);

  return (
    <>
      <h2>{t('campaign.template.info')}</h2>
      <FlexContainer gap={24} className="mb-4 h-full" align="stretch">
        <div className="flex-1">
          <FlexContainer justify="flex-start" align="flex-end" className="mb-4">
            <div className="field w-full m-0 relative">
              <Field
                id="name"
                name="name"
                label={t('generic.name')}
                value={values.name}
                className="w-full"
                component={FormikInput}
                disabled={!canEditName}
                data-testid="campaign-form-info-step-name"
              />
            </div>
            <AppButton
              icon="pi pi-pencil"
              type="text"
              onClick={() => setCanEditName(true)}
              isDisabled={canEditName}
              data-testid="campaign-form-info-step-enable-edit-name"
            />
          </FlexContainer>
          <FlexContainer gap={24} align="flex-start">
            <div className="field w-full mb-4 flex-1">
              <Field
                id="language"
                label={t('generic.lang')}
                name="language"
                required
                filter
                className="w-full"
                component={FormikDropdown}
                placeholder={t('generic.select')}
                options={availableLanguagesDropdown.filter(
                  ({ value }) => !courseOnlyLanguages.includes(value),
                )}
              />
            </div>
            <div className="field w-full mb-4 flex-1">
              <Field
                id="difficulty"
                label={t('generic.difficulty')}
                name="difficulty"
                required
                className="w-full"
                component={FormikDropdown}
                placeholder={t('generic.select')}
                options={difficultyOptions}
              />
            </div>
          </FlexContainer>
          <div className="field w-full mb-4">
            <Field
              id="senderProfileType"
              label={t('generic.sender')}
              name="senderProfileType"
              required
              className="w-full"
              component={FormikDropdown}
              placeholder={t('generic.select')}
              options={senderOptions(t)}
            />
          </div>
          <div className="field w-full mb-4">
            <Field
              id="phishingSign"
              label={t('campaign.template.phishingSign')}
              name="phishingSign"
              className="w-full"
              component={FormikDropdown}
              placeholder={t('generic.select')}
              options={getPhishingSignOptions(t)}
            />
          </div>
          <div className="field w-full mb-4">
            <Field
              id="phishingType"
              label={t('campaign.template.phishingType')}
              name="phishingType"
              className="w-full"
              component={FormikDropdown}
              placeholder={t('generic.select')}
              options={getPhishingTypeOptions(t)}
            />
          </div>
          <label htmlFor="usersScore">{t('campaigns.userScore')} </label>
          <FlexContainer gap={24} className="mb-4 mt-2" align="stretch">
            <div className="field w-full flex-1 mb-0">
              <Field
                id="clickedLinksPercentage"
                name="clickedLinksPercentage"
                placeholder={t('campaign.clicked')}
                className="w-full"
                component={FormikInputNumber}
                onChange={(event: InputNumberChangeEvent) =>
                  valuesSetter('clickedLinksPercentage', event.value)
                }
                icon="pi pi-percentage"
              />
              <StyledLabelBelow htmlFor="clickedLinksPercentage">
                {t('campaign.clicked')}
              </StyledLabelBelow>
            </div>
            <div className="field w-full flex-1 mb-0">
              <Field
                id="submittedDataPercentage"
                name="submittedDataPercentage"
                placeholder={t('campaign.submitted')}
                className="w-full"
                component={FormikInputNumber}
                onChange={(event: InputNumberChangeEvent) =>
                  valuesSetter('submittedDataPercentage', event.value)
                }
                icon="pi pi-percentage"
              />
              <StyledLabelBelow htmlFor="submittedDataPercentage">
                {t('campaign.submitted')}
              </StyledLabelBelow>
            </div>
          </FlexContainer>
          <CheckboxesContainer
            className="toggle-container"
            id="isTemplate-active"
          >
            <Field
              disabled={
                !!Object.keys(errors).length && values?.isTemplate === false
              }
              inputId="isTemplate"
              name="isTemplate"
              label={t('campaign.template.isTemplate')}
              component={FormikWrappedSwitch}
            />
          </CheckboxesContainer>
          {!!Object.keys(errors).length && (
            <Tooltip
              position="mouse"
              target="#isTemplate-active"
              content={`${t('campaign.template.selectAll')}\n${Object.values(
                errors,
              ).join(', \n')}`}
            />
          )}
        </div>
        <div className="flex-1 h-auto field mb-0">
          <Field
            id="rich-text-editor"
            label={t('generic.description')}
            name="description"
            className="w-full"
            placeholder={t('generic.description')}
            component={FormikRichTextArea}
          />
        </div>
      </FlexContainer>
    </>
  );
};
