import { FilterNamesEnum } from '@/client/helpers';
import {
  CourseCompletionReportsType,
  userAwarenessReportsType,
} from '@/client/reports';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';

type GetCourseCompletionReportsPropsType = {
  courseCompletionReports: CourseCompletionReportsType;
  t: TranslationFunctionType;
};

export const getCourseCompletionReports = ({
  courseCompletionReports,
  t,
}: GetCourseCompletionReportsPropsType) => {
  return [
    {
      text: t('reports.courses.available'),
      count: courseCompletionReports.availableCourses,
      page: {
        to: RedirectPaths[RedirectPathsEnum.COURSES](),
      },
    },
    {
      text: t('reports.courses.enrolled'),
      count: courseCompletionReports.enrolledCourses,
      page: {
        to: RedirectPaths[RedirectPathsEnum.ACCOUNT_COURSE_SCHEDULE](),
        filters: {},
      },
    },
    {
      text: t('reports.courses.total.enrollments'),
      count: courseCompletionReports.totalEnrolments,
      page: {
        to: RedirectPaths[RedirectPathsEnum.REPORTS_SUMMARY](),
        filters: { [FilterNamesEnum.SUMMARY_HAVE_ENROLLED_COURSES]: 'yes' },
        isDashboardLink: true,
      },
    },
    {
      text: t('reports.courses.total.completions'),
      count: courseCompletionReports.totalCompletions,
      // TODO: Change by status `completed` and redirect to awareness when BE support this.
      page: {
        to: RedirectPaths[RedirectPathsEnum.REPORTS_SUMMARY](),
        filters: { [FilterNamesEnum.SUMMARY_HAVE_ENROLLED_COURSES]: 'yes' },
        isDashboardLink: true,
      },
    },
  ];
};

type getUserAwarenessReportsPropsType = {
  userAwarenessReports: userAwarenessReportsType;
  t: TranslationFunctionType;
};

export const getUserAwarenessReports = ({
  userAwarenessReports,
  t,
}: getUserAwarenessReportsPropsType) => {
  return [
    {
      text: t('reports.users.with.courses.enrolled'),
      count: userAwarenessReports.usersWithEnrolledCourses,
      page: {
        to: RedirectPaths[RedirectPathsEnum.REPORTS_SUMMARY](),
        filters: { [FilterNamesEnum.SUMMARY_HAVE_ENROLLED_COURSES]: 'yes' },
        isDashboardLink: true,
      },
    },
    {
      text: t('reports.users.without.courses'),
      count: userAwarenessReports.usersWithoutCourses,
      page: {
        to: RedirectPaths[RedirectPathsEnum.REPORTS_SUMMARY](),
        filters: { [FilterNamesEnum.SUMMARY_HAVE_ENROLLED_COURSES]: 'no' },
        isDashboardLink: true,
      },
    },
    {
      text: t('reports.users.completed.all.courses'),
      count: userAwarenessReports.usersWithCompletedCourses,
      page: {
        to: RedirectPaths[RedirectPathsEnum.REPORTS_SUMMARY](),
        filters: { [FilterNamesEnum.SUMMARY_COMPLETED_ALL_COURSES]: 'yes' },
        isDashboardLink: true,
      },
    },
    {
      text: t('reports.users.not.completed.all.courses'),
      count: userAwarenessReports.usersWithoutCompletedCourses,
      page: {
        to: RedirectPaths[RedirectPathsEnum.REPORTS_SUMMARY](),
        filters: { [FilterNamesEnum.SUMMARY_COMPLETED_ALL_COURSES]: 'no' },
        isDashboardLink: true,
      },
    },
  ];
};

export const scormColumnsWidth = {
  THREE: 1696,
  TWO: 1279,
  ONE: 865,
};

export const templatesColumnsWidth = {
  ONE: 1330,
};

export const postersColumnsWidth = {
  THREE: 1500,
  TWO: 1200,
  ONE: 820,
};
