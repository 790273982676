import React, { ReactNode } from 'react';

import styled from 'styled-components';

type FlexContainerProps = {
  children: ReactNode;
  direction?: string;
  justify?: string;
  align?: string;
  height?: string;
  width?: string;
  minHeight?: string;
  minWidth?: string;
  gap?: number;
  wrap?: string;
  flex?: string;
};

type StyledFlexProps = {
  direction?: string;
  justify?: string;
  align?: string;
  height?: string;
  width?: string;
  minHeight?: string;
  minWidth?: string;
  gap?: number;
  wrap?: string;
  flex?: string;
};

const FlexContainer: React.FC<
  FlexContainerProps & React.HTMLAttributes<any>
> = ({
  children,
  direction = 'row',
  justify = 'center',
  align = 'center',
  height,
  width,
  minHeight,
  minWidth,
  gap,
  wrap,
  flex,
  ...rest
}) => {
  return (
    <Flex
      direction={direction}
      justify={justify}
      align={align}
      height={height}
      width={width}
      minHeight={minHeight}
      minWidth={minWidth}
      gap={gap}
      wrap={wrap}
      flex={flex}
      {...rest}
    >
      {children}
    </Flex>
  );
};

const Flex = styled.div<StyledFlexProps>`
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : null)};
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}` : null)};
  min-height: ${(props) => (props.minHeight ? `${props.minHeight}` : null)};
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  gap: ${(props) => (props.gap ? `${props.gap}px` : null)};
  flex-wrap: ${(props) => props.wrap};
  flex: ${(props) => props.flex};
`;

export default FlexContainer;
