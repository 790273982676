import { AppSpinner } from '@/ui/app-spinner';
import { FlexContainer } from '@/ui/styled-ui';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledContainer = styled(FlexContainer)`
  border: 1px solid var(--gray-dark);
  border-radius: var(--medium-border-radius);
`;

const StyledTable = styled(FlexContainer)`
  background-color: var(--beige-main);
  border-radius: var(--medium-border-radius);
  padding: var(--medium-padding);
`;

const StyledToolbar = styled(FlexContainer)`
  overflow: hidden;
  padding: var(--medium-padding);
`;

const StyledEmptyMessage = styled(FlexContainer)`
  padding: var(--medium-padding 0);
  font-size: var(--medium-font-size);
  line-height: var(--medium-line-height);
  height: 82px;
  font-weight: 500;
`;

type GridTablePropsType = {
  take: number;
  skip: number;
  onPageChange: (value: number) => void;
  onRowsChange: (value: number) => void;
  isLoading: boolean;
  children: React.ReactNode;
  toolbarContent: JSX.Element;
  count?: number;
  rowsPerPage?: number[];
};

export const GridTable: React.FC<GridTablePropsType> = ({
  children,
  count,
  take,
  skip,
  onPageChange,
  onRowsChange,
  isLoading,
  toolbarContent,
  rowsPerPage,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (!rowsPerPage || rowsPerPage.length === 0) return;
    onRowsChange(rowsPerPage[0]);
    return () => {
      onRowsChange(take);
    };
  }, []);
  return (
    <>
      <StyledContainer direction="column">
        <StyledToolbar>{toolbarContent}</StyledToolbar>
        <StyledTable direction="column">
          {!isLoading ? (
            <>
              {children}
              {!count && (
                <StyledEmptyMessage justify="flex-start">
                  {t('filter.noItems')}
                </StyledEmptyMessage>
              )}
            </>
          ) : (
            <AppSpinner />
          )}
        </StyledTable>
      </StyledContainer>
      <Paginator
        className="justify-content-end pb-0"
        rows={take}
        first={skip}
        totalRecords={count}
        pageLinkSize={3}
        onPageChange={(event: PaginatorPageChangeEvent) => {
          onRowsChange(event.rows);
          onPageChange(event.first);
        }}
        template="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
        currentPageReportTemplate={`{first} ${t('filter.to')} {last} ${t(
          'filter.of',
        )} {totalRecords}`}
        rowsPerPageOptions={rowsPerPage ?? [12, 24]}
      />
    </>
  );
};
