import { LoadingStateType, LoadingStatuses } from '@/common/constants';

type QueryStateType = {
  isIdle: boolean;
  isLoading: boolean;
  isError: boolean;
};

export const queryStateConverter = (
  state: QueryStateType,
): LoadingStateType => {
  if (state.isIdle) {
    return LoadingStatuses.IDLE;
  } else if (state.isLoading) {
    return LoadingStatuses.LOADING;
  }

  return LoadingStatuses.IDLE;
};
