import { FiltersType } from '@/api/types';
import { client } from '@/client';
import { MessageResponseModel } from '@/client/models';
import {
  ListPageSystemEmailTypeRequest,
  ListPageSystemEmailTypeResponse,
  SaveSystemEmailTypeConfig,
  SystemEmailTypeConfig,
  UpdateSystemEmailTypeConfig,
} from '@/client/system-emails';
import {
  DeleteSystemEmailTemplateMutation,
  GenericForbiddenApiError,
  SaveSystemEmailTypesConfigsMutation,
  UpdateSystemEmailTemplateMutation,
} from '@/hooks/query';
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

const queryKey = 'system-emails-types-configs';
const singleQueryKey = 'system-email-type-config';

export const useSystemEmailsTypesConfigs = (
  params: UseQueryOptions & {
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
): UseQueryResult<ListPageSystemEmailTypeRequest> & {
  systemEmailsTypesConfigs: ListPageSystemEmailTypeResponse | undefined;
} => {
  const {
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(sort), JSON.stringify(filters)],
    () =>
      client.systemEmailsTypesConfigsService.getSystemEmailsTypesConfigs(
        params,
      ),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    systemEmailsTypesConfigs: data,
    ...(rest as UseQueryResult<ListPageSystemEmailTypeRequest>),
  };
};

export const useSystemEmailTypeConfig = (
  params: UseQueryOptions & {
    configId?: string;
  },
):
  | UseQueryResult<SystemEmailTypeConfig> & {
      systemEmailTypeConfig: SystemEmailTypeConfig | undefined;
    } => {
  const {
    configId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [singleQueryKey, configId],
    () =>
      configId
        ? client.systemEmailsTypesConfigsService.getSystemEmailTypeConfig(
            configId,
          )
        : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<SystemEmailTypeConfig>),
    },
  );

  return {
    systemEmailTypeConfig: data,
    ...(rest as UseQueryResult<SystemEmailTypeConfig>),
  };
};

export const useSaveSystemEmailTypeConfig = (): {
  create: (
    payload: SaveSystemEmailTypeConfig,
  ) => Promise<SystemEmailTypeConfig>;
} & SaveSystemEmailTypesConfigsMutation => {
  const { mutateAsync, ...rest } = useMutation<
    SystemEmailTypeConfig,
    GenericForbiddenApiError,
    SaveSystemEmailTypeConfig
  >('createSystemEmailTypeConfig', (payload: SaveSystemEmailTypeConfig) =>
    client.systemEmailsTypesConfigsService.saveSystemEmailTypeConfig(payload),
  );

  return { create: mutateAsync, ...(rest as any) };
};

export const useUpdateSystemEmailTypeConfig = (): {
  update: (
    payload: UpdateSystemEmailTypeConfig,
  ) => Promise<SystemEmailTypeConfig>;
} & UpdateSystemEmailTemplateMutation => {
  const { mutateAsync, ...rest } = useMutation<
    SystemEmailTypeConfig,
    GenericForbiddenApiError,
    UpdateSystemEmailTypeConfig
  >('updateSystemEmailTypeConfig', (payload: UpdateSystemEmailTypeConfig) =>
    client.systemEmailsTypesConfigsService.updateSystemEmailTypeConfig(payload),
  );

  return { update: mutateAsync, ...(rest as any) };
};

export const useDeleteSystemEmailTypeConfig = (): {
  delete: (configId: string) => Promise<MessageResponseModel>;
} & DeleteSystemEmailTemplateMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    string
  >('deleteSystemEmailTemplate', (configId: string) =>
    client.systemEmailsTypesConfigsService.deleteSystemEmailTypeConfig(
      configId,
    ),
  );

  return { delete: mutateAsync, ...(rest as any) };
};
