import { currentAccountReducer } from '@/store/features/account';
import { authReducer } from '@/store/features/auth';
import { currentDashboardReducer } from '@/store/features/dashboard';
import { currentUserReducer } from '@/store/features/users';
import { configureStore } from '@reduxjs/toolkit';
import { selectedColumnsReducer } from './features/columns';
import { openFiltersReducer } from './features/filters';

const store = configureStore({
  reducer: {
    auth: authReducer,
    currentUser: currentUserReducer,
    currentAccount: currentAccountReducer,
    currentDashboard: currentDashboardReducer,
    openFilters: openFiltersReducer,
    selectedColumns: selectedColumnsReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
