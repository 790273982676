import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { useNotifications } from '@/hooks/notifications.hook';
import { useAppSelector } from '@/hooks/store';
import { MultipleToastType, useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const pathItems = (account: Account, t: TranslationFunctionType) => [
  {
    label: account?.name,
    url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('hubspot.forceSync'),
    url: RedirectPaths[RedirectPathsEnum.HUBSPOT_SYNC](),
    template: AppBreadCrumbTemplate,
  },
];

export const HubspotSyncPage: React.FC = () => {
  const { t } = useTranslation();
  const account = useAppSelector(selectCurrentAccount);
  const toast = useToast();

  const [state, setState] = useState<LoadingStatuses>(LoadingStatuses.IDLE);

  const handleSubmit = async () => {
    try {
      if (!account) return;
      setState(LoadingStatuses.LOADING);
      setAccountId(account.id);
      await client.hubspot.sendSyncRequest();
    } catch (e) {
      setAccountId(undefined);
      setState(LoadingStatuses.IDLE);
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const {
    lastMessage,
    setNotificationParam: setAccountId,
    notificationParam: accountId,
  } = useNotifications(client.hubspot.hubspotNotifyUrl);

  useEffect(() => {
    if (!accountId) return;
    handleSyncNotification(lastMessage);
  }, [JSON.stringify(lastMessage)]);

  const handleSyncNotification = async (messages: { data: any } | null) => {
    if (
      !messages?.data?.event ||
      ![
        'hubspot.companies.link.finished',
        'hubspot.companies.link.error',
        'hubspot.companies.link.warning',
      ].includes(messages?.data?.event)
    )
      return;

    if (messages.data.event === 'hubspot.companies.link.error') {
      toast?.error(
        t('toast.error'),
        messages?.data?.payload?.error || t('accounts.hubspot.error'),
        10000,
      );
    }

    if (messages.data.event === 'hubspot.companies.link.finished') {
      toast?.success(
        t('toast.success'),
        t('accounts.hubspot.success', {
          total: messages?.data?.payload?.total || 0,
        }),
      );
    }

    if (
      messages.data.event === 'hubspot.companies.link.warning' &&
      messages?.data?.payload?.errors?.length
    ) {
      const errors = messages.data.payload.errors.map((error: string) => ({
        severity: 'info',
        summary: t('toast.info'),
        detail: error,
        life: 10000,
      })) as MultipleToastType[];

      toast?.multiple(errors);
    }

    setAccountId(undefined);
    setState(LoadingStatuses.IDLE);
  };

  return (
    <FlexContainer direction="column" align="flex-start">
      <div className="w-full">
        <AppBreadCrumb model={pathItems(account as Account, t)} />
        <h1>{t('accounts.hubspot.sync')}</h1>
        <AppButton
          state={state}
          label={t('button.sync')}
          className="mt-1"
          isDisabled={account?.meta?.isMigrationTriggered}
          onClick={handleSubmit}
        />
      </div>
    </FlexContainer>
  );
};
