import { equal, isIn } from '@/api/helpers';
import { client } from '@/client';
import { Branch } from '@/client/branches';
import { Group } from '@/client/groups';
import { User } from '@/client/users';
import {
  clearChildrenUsersFromParent,
  mapToArrayOfIds,
  partialRequests,
  removeById,
  updateChildrenUsersAfterUsersListChange,
} from '@/utils/helpers';
import { FormEvent, useEffect, useState } from 'react';

type SubjectUsers = {
  id: string;
  name: string;
  users: User[];
};

type LoadingType = { [key: string]: boolean };

export const useTargetGroups = () => {
  const [loading, setLoading] = useState<LoadingType>({});
  const [branches, setBranches] = useState<Branch[]>([]);
  const [branchesUsers, setBranchesUsers] = useState<SubjectUsers[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [groupsUsers, setGroupsUsers] = useState<SubjectUsers[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [searchedUsers, setSearchedUsers] = useState<User[]>([]);
  const [searchUserValue, setSearchUserValue] = useState<string>('');

  const isLoading = !!Object.values(loading).filter((l) => l).length;

  // Removes all branches and groups that do not have users in the users list
  useEffect(() => {
    if (isLoading) return;

    const branchesUsersIds = mapToArrayOfIds(branchesUsers);
    const groupsUsersIds = mapToArrayOfIds(groupsUsers);

    setBranches((state) =>
      state.filter((branch) => branchesUsersIds?.includes(branch.id)),
    );
    setGroups((state) =>
      state.filter((group) => groupsUsersIds?.includes(group.id)),
    );
  }, [usersList.length]);

  // add the current branches' users to the users list
  useEffect(() => {
    branches.forEach(handleFetchBranchUsers);
  }, [branches]);

  // add the current groups' users to the users list
  useEffect(() => {
    groups.forEach(handleFetchGroupUsers);
  }, [groups]);

  const handleSetLoading = (subjectId: string, loading: boolean) =>
    setLoading((state) => ({ ...state, [subjectId]: loading }));

  // apply the incoming changes from the dropdown of the branches
  const handleChangeBranches = (values: Branch[]) => {
    if (handleRemoveBranches(values)) return;
    setBranches(values);
  };

  // remove the branches that were selected but now are not
  const handleRemoveBranches = (values: Branch[]) => {
    const removedValues = branches.filter(
      (branch: Branch) => !mapToArrayOfIds(values).includes(branch.id),
    );

    if (!removedValues.length) return false;

    setBranches((state) => removeById(state, mapToArrayOfIds(removedValues)));
    setBranchesUsers((state) =>
      removeById(state, mapToArrayOfIds(removedValues)),
    );
    setUsersList((state) =>
      state.filter(
        (user) =>
          !clearChildrenUsersFromParent(
            branchesUsers,
            mapToArrayOfIds(removedValues),
          )?.includes(user.id),
      ),
    );

    return true;
  };

  const handleFetchBranchUsers = async (branch: Branch) => {
    if (branchesUsers.find((bu) => bu.id === branch.id)) return;

    handleSetLoading(branch.id, true);

    const users = await partialRequests(
      [isIn('branch', [branch.id]), equal('active', true)],
      client.users.getUsers,
    );

    setBranchesUsers((state) => [
      ...state,
      { id: branch.id, name: branch.name, users },
    ]);
    setUsersList((state) => [
      ...state,
      ...users.filter((user) => !mapToArrayOfIds(state)?.includes(user.id)),
    ]);

    handleSetLoading(branch.id, false);
  };

  const handleChangeGroups = (values: Group[]) => {
    if (handleRemoveGroups(values)) return;
    setGroups(values);
  };

  const handleRemoveGroups = (values: Group[]) => {
    const removedValues = groups.filter(
      (group: Group) => !mapToArrayOfIds(values).includes(group.id),
    );

    if (!removedValues.length) return false;

    setGroups((state) => removeById(state, mapToArrayOfIds(removedValues)));
    setGroupsUsers((state) =>
      removeById(state, mapToArrayOfIds(removedValues)),
    );
    setUsersList((state) =>
      state.filter(
        (user) =>
          !clearChildrenUsersFromParent(
            groupsUsers,
            mapToArrayOfIds(removedValues),
          )?.includes(user.id),
      ),
    );

    return true;
  };

  const handleFetchGroupUsers = async (group: Group) => {
    if (groupsUsers.find((gu) => gu.id === group.id)) return;

    handleSetLoading(group.id, true);

    const users = await partialRequests(
      [isIn('groups', [group.id]), equal('active', true)],
      client.users.getUsers,
    );

    setGroupsUsers((state) => [
      ...state,
      { id: group.id, name: group.name, users },
    ]);
    setUsersList((state) => [
      ...state,
      ...users.filter((user) => !mapToArrayOfIds(state)?.includes(user.id)),
    ]);

    handleSetLoading(group.id, false);
  };

  const handleChangeUsers = (values: User[]) => {
    const uniqueValues = Array.from(new Set(values));
    const existingUsersIds = new Set(mapToArrayOfIds(usersList));
    const usersToAdd = uniqueValues.filter(
      (user) => !existingUsersIds.has(user.id),
    );

    if (handleRemoveUsers(uniqueValues)) return;

    setUsers(values);
    setUsersList((state) => [...state, ...usersToAdd]);
  };

  const handleRemoveUsers = (values: User[]) => {
    const valuesSet = new Set(mapToArrayOfIds(values));
    const removedValues = users.filter((user: User) => !valuesSet.has(user.id));

    if (!removedValues.length) return false;

    setUsers(values);
    setUsersList((state) => removeById(state, mapToArrayOfIds(removedValues)));

    return true;
  };

  const handleChangeUsersList = (users: User[]) => setUsersList(users);

  const onSearchInput = (event: FormEvent<HTMLInputElement>) => {
    setSearchUserValue((event.target as HTMLInputElement).value);
    setSearchedUsers(
      usersList.filter((user: User) =>
        user.username.includes((event.target as HTMLInputElement).value),
      ),
    );
  };

  const handleClearDuplicatingUsers = () => {
    const usersIds: string[] = [];
    branchesUsers.forEach((bu: { id: string; name: string; users: User[] }) =>
      usersIds.push(...mapToArrayOfIds(bu.users)),
    );
    groupsUsers.forEach((gu: { id: string; name: string; users: User[] }) =>
      usersIds.push(...mapToArrayOfIds(gu.users)),
    );
    setUsers((state) => state.filter((user) => !usersIds.includes(user.id)));

    return usersIds;
  };

  const handleRemoveUserFromUsersList = (user: User) => {
    setSearchedUsers((state) =>
      state?.filter((u) => u.username !== user.username),
    );
    setUsersList((state) => removeById(state, [user.id]));
    setUsers((state) => removeById(state, [user.id]));
    setBranchesUsers((state) =>
      updateChildrenUsersAfterUsersListChange(state, user),
    );
    setGroupsUsers((state) =>
      updateChildrenUsersAfterUsersListChange(state, user),
    );
  };

  const handleClearAll = () => {
    setLoading({});
    setBranches([]);
    setBranchesUsers([]);
    setGroups([]);
    setGroupsUsers([]);
    setUsers([]);
    setUsersList([]);
    setSearchedUsers([]);
    setSearchUserValue('');
  };

  return {
    isLoading,
    branches,
    branchesUsers,
    groups,
    groupsUsers,
    users,
    usersList,
    searchUserValue,
    searchedUsers,
    handleClearDuplicatingUsers,
    handleChangeBranches,
    handleChangeGroups,
    handleChangeUsers,
    handleChangeUsersList,
    onSearchInput,
    handleRemoveUserFromUsersList,
    handleClearAll,
  };
};
