import { AppButton } from '@/ui/buttons';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import React, { createContext, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  width: 450px;
`;

type DialogState = {
  type?: 'info' | 'confirmation';
  show: boolean;
  message: string;
  customContent?: ReactNode;
  header: string;
  onAccept?: () => void;
  onReject?: () => void;
  closeImmediatelyAfterAccept?: boolean;
  confirmationMessage?: string;
};

export const DialogContext = createContext<{
  setDialogData: React.Dispatch<React.SetStateAction<DialogState>>;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDialogData: () => {},
});

export const ConfirmationDialog: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation();
  const [confirmation, setConfirmation] = useState<boolean>(false);

  const [dialogData, setDialogData] = useState<DialogState>({
    type: undefined,
    show: false,
    header: '',
    message: '',
    customContent: undefined,
    onAccept: undefined,
    onReject: undefined,
    closeImmediatelyAfterAccept: false,
    confirmationMessage: '',
  });

  const resetDialogData = () =>
    setDialogData({
      show: false,
      header: '',
      message: '',
      customContent: undefined,
      onAccept: undefined,
      type: undefined,
      onReject: undefined,
      closeImmediatelyAfterAccept: false,
      confirmationMessage: '',
    });

  const infoFooter = (
    <AppButton
      label={t('dialog.ok')}
      icon="pi pi-check"
      severity="secondary"
      onClick={resetDialogData}
    />
  );

  const confirmationFooter = (
    <>
      <AppButton
        label={t('dialog.yes')}
        icon="pi pi-check"
        severity="secondary"
        type="text"
        onClick={async () => {
          if (dialogData.closeImmediatelyAfterAccept) {
            resetDialogData();
          }

          if (dialogData.onAccept) {
            await dialogData.onAccept();
          }

          resetDialogData();
        }}
        isDisabled={dialogData.confirmationMessage ? !confirmation : undefined}
      />
      <AppButton
        label={t('dialog.no')}
        severity="secondary"
        icon="pi pi-times"
        onClick={() => {
          if (dialogData.onReject) dialogData.onReject();
          resetDialogData();
        }}
      />
    </>
  );

  const footer = () => {
    let result;

    switch (dialogData.type) {
      case 'confirmation':
        result = confirmationFooter;
        break;
      case 'info':
        result = infoFooter;
        break;
      default:
        result = infoFooter;
    }

    return result;
  };

  return (
    <DialogContext.Provider value={{ setDialogData }}>
      {children}
      <StyledDialog
        draggable={false}
        visible={dialogData.show}
        header={dialogData.header}
        modal
        footer={footer()}
        onHide={resetDialogData}
      >
        <>
          <span>{dialogData.message}</span>
          {dialogData.customContent}
          {dialogData.confirmationMessage && (
            <div className="flex align-items-center mt-4">
              <Checkbox
                checked={confirmation}
                name="dialogConfirmation"
                onChange={(e: CheckboxChangeEvent) =>
                  setConfirmation(e.checked ?? false)
                }
              />
              <label htmlFor="dialogConfirmation" className="ml-2">
                {dialogData.confirmationMessage}
              </label>
            </div>
          )}
        </>
      </StyledDialog>
    </DialogContext.Provider>
  );
};
