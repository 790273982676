import { Conditions } from '@/api/enums';
import { equal, handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { EmailTemplate } from '@/client/email-templates';
import { ThumbnailState } from '@/client/thumbnails/enums';
import { Actions, Subjects } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { DialogContext } from '@/common/context';

import { TemplatesFilters } from '@/components/templates';
import ThumbnailsGenerationNotify from '@/components/thumbnails/ThumbnailsGenerationNotify';
import {
  useDeleteEmailTemplate,
  useEmailTemplates,
  useUpdateEmailTemplate,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTemplatesFilters } from '@/hooks/templates';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { EmailTemplatesTabs } from '@/pages/email-templates';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { ImageCard } from '@/ui/image-card';
import { CardGridContainer, FlexContainer } from '@/ui/styled-ui';
import { displayCreatedBy } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const EmailTemplatesPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { setDialogData } = useContext(DialogContext);
  const account = useAppSelector(selectCurrentAccount);
  const deleteEmailTemplate = useDeleteEmailTemplate();
  const updateTemplate = useUpdateEmailTemplate();
  const { can } = usePermission();
  const {
    take,
    search,
    nameFilter,
    skip,
    handleSearch,
    handleChangeSkip,
    handleChangeDefaultRows,
  } = useTemplatesFilters();

  const breadCrumbItems = [
    {
      label: (account as Account)?.name,
      url: !account?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id as string)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('templates.predefined.email'),
      url: RedirectPaths[RedirectPathsEnum.EMAIL_TEMPLATES_PREDEFINED](),
      template: AppBreadCrumbTemplate,
    },
  ];

  const activeFilters = account?.isSystem ? [] : [equal('active', true)];

  // Templates filtered by account=null, will return only predefined templates
  const { isLoading, emailTemplates, refetch } = useEmailTemplates({
    take,
    skip,
    filters: [
      { field: 'account', condition: Conditions.EQUAL, value: null },
      ...nameFilter,
      ...activeFilters,
    ],
  });

  const handleToggleTemplateStatus = async (
    emailTemplateId: string,
    active: boolean,
  ) => {
    try {
      await updateTemplate.update({
        emailTemplateId: emailTemplateId,
        updates: { active: !active },
      });
      await refetch();
      toast?.success(
        t('toast.success'),
        active
          ? t('templates.deactivated.success')
          : t('templates.activated.success'),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleDeleteEmailTemplate = async (emailTemplateId: string) => {
    try {
      await deleteEmailTemplate.delete(emailTemplateId);
      setTimeout(() => {
        refetch();
      }, 100);
      toast?.success(
        t('toast.success'),
        t('templates.predefined.email.delete'),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const menuItems = (emailTemplate: EmailTemplate, account: Account) => {
    const actions: MenuItem[] = [];
    if (can(Actions.CREATE, Subjects.EMAIL_TEMPLATES)) {
      actions.push({
        label: t('generic.copyEdit'),
        icon: 'pi pi-copy',
        command: () =>
          navigate(RedirectPaths[RedirectPathsEnum.EMAIL_TEMPLATES_CREATE](), {
            state: { emailTemplate },
          }),
      });
    }

    if (can(Actions.UPDATE, Subjects.EMAIL_TEMPLATES) && account?.isSystem) {
      actions.push(
        ...[
          {
            label: t('generic.edit'),
            icon: 'pi pi-pencil',
            command: () =>
              navigate(
                RedirectPaths[RedirectPathsEnum.EMAIL_TEMPLATES_UPDATE](
                  emailTemplate.id,
                ),
              ),
          },
          {
            label: emailTemplate.active
              ? t('generic.deactivate')
              : t('generic.activate'),
            icon: emailTemplate.active ? 'pi pi-minus-circle' : 'pi pi-check',
            command: () =>
              setDialogData({
                type: 'confirmation',
                show: true,
                header: emailTemplate.active
                  ? t('dialog.deactivate.confirm')
                  : t('dialog.activate.confirm'),
                message: emailTemplate.active
                  ? t('templates.email.deactivate')
                  : t('templates.email.activate'),
                onAccept: async () =>
                  await handleToggleTemplateStatus(
                    emailTemplate.id,
                    emailTemplate.active,
                  ),
              }),
          },
        ],
      );
    }

    if (can(Actions.DELETE, Subjects.EMAIL_TEMPLATES) && account?.isSystem) {
      actions.push({
        label: t('generic.delete'),
        icon: 'pi pi-times',
        command: () =>
          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.delete.confirm'),
            message: t('dialog.delete.predefined.email'),
            onAccept: async () =>
              await handleDeleteEmailTemplate(emailTemplate.id),
          }),
      });
    }

    return actions;
  };

  return (
    <>
      <AppBreadCrumb model={breadCrumbItems} />
      <FlexContainer justify="space-between">
        <h1>{t('templates.predefined.email')}</h1>
        {can(Actions.CREATE, Subjects.EMAIL_TEMPLATES) && account?.isSystem && (
          <AppButton
            label={t('button.createNew')}
            severity="secondary"
            onClick={() => {
              navigate(
                RedirectPaths[RedirectPathsEnum.EMAIL_TEMPLATES_CREATE](),
              );
            }}
          />
        )}
      </FlexContainer>
      <EmailTemplatesTabs />

      <TemplatesFilters
        count={emailTemplates?.count ?? 0}
        take={take}
        skip={skip}
        onPageChange={handleChangeSkip}
        onRowsChange={handleChangeDefaultRows}
        onSearch={handleSearch}
        search={search}
      />
      {!isLoading && !emailTemplates?.result.length && (
        <FlexContainer direction="column" className="mt-5">
          <h3>{t('templates.email.empty')}</h3>
        </FlexContainer>
      )}
      {isLoading && !emailTemplates && (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
          <h3>{t('templates.email.loading')}</h3>
        </FlexContainer>
      )}
      {!isLoading && !!emailTemplates?.result.length && account && (
        <>
          <ThumbnailsGenerationNotify
            refetch={refetch}
            templates={emailTemplates.result}
          />
          <CardGridContainer>
            {emailTemplates.result.map((template) => (
              <ImageCard
                key={template.id}
                id={template.id}
                title={template.name}
                thumbnail={template.thumbnail?.signedUrl}
                isLoading={
                  template.thumbnailState === ThumbnailState.PROCESSING
                }
                author={displayCreatedBy(t, template.createdBy)}
                templateType="email"
                interactionType="withActions"
                showStatus={account.isSystem}
                activeStatus={template.active}
                actions={menuItems(template, account)}
              />
            ))}
          </CardGridContainer>
        </>
      )}
    </>
  );
};
