export const getTablePreferences = () => {
  let columnPreferences = localStorage.getItem(
    'tableColumnsVisibilityPreferences',
  );

  if (!columnPreferences) {
    columnPreferences = JSON.stringify({});
    localStorage.setItem(
      'tableColumnsVisibilityPreferences',
      columnPreferences,
    );
  }

  return columnPreferences;
};

export enum PreviewType {
  TABLE = 'table',
  GRID = 'grid',
}

export const previewOptions = [
  { icon: 'pi pi-list', value: PreviewType.TABLE },
  { icon: 'pi pi-th-large', value: PreviewType.GRID },
];
