import { GroupCondition } from '@/api/enums';
import {
  contains,
  equal,
  greater,
  group,
  isIn,
  like,
  lower,
  nested,
  notEqual,
} from '@/api/helpers';
import {
  FilterCondition,
  FilterCondtitionType,
  FilterFunctionType,
  FilterFunctionValuesType,
  FiltersType,
  FilterValue,
  GroupedFilterConditions,
} from '@/api/types';
import { DataTableColumnType, FilterType } from '@/components/tables/crud';
import { flatten, isArray, isEmpty, isObject } from 'lodash';
import { AccountTypeEnum } from '../accounts';
import { MaterialType, UserStatusEnum } from '../courses';
import { SurveyStatus } from '../security-culture/types';

export enum FilterNamesEnum {
  ID = 'id',
  NAME = 'name',
  TREE = 'tree',
  EMAIL = 'email',
  BRANCH = 'branch',
  ACTIVE = 'active',
  AUTO_ASSIGN = 'autoAssign',
  IS_FOR_PHISHING = 'isForPhishing',
  USER_BY_USERNAME = 'username',
  USER_BY_USERNAME_NESTED = 'usernameNested',
  USER_BY_EMAIL = 'userEmail',
  USER_BY_ROLE = 'role',
  CAMPAIGNS_BY_STATUS = 'campaignStatus',
  CAMPAIGNS_REPORT_BY_STATUS = 'campaignReportByStatus',
  CREATED_BY = 'createdBy',
  UPDATED_BY = 'updatedBy',
  EMAIL_TEMPLATE_BY_TYPE = 'type',
  EMAIL_TEMPLATE_BY_LANGUAGE = 'language',
  EMAIL_TYPE_BY_SEND_TIME = 'sendTime',
  AUTO_COURSE_AVAILABILITY = 'autoCourseAvailability',
  AUTO_COURSE_ENROLLMENT = 'autoCourseEnrollment',
  SCHEDULE_UPCOMING_DATE = 'scheduleExecutedAt',
  SCHEDULE_UNENROLLED = 'scheduleUnenrolledAt',
  COURSE_ACCOUNT_UPCOMING_DATE = 'accountExecutedAt',
  COURSE_ENTITY_UPCOMING_DATE = 'entityExecutedAt',
  COURSE_ENTITY_USER_HAS_DUE_COURSES = 'courseUserHasDueCourses',
  COURSE_ENTITY_AUTO_ENROLL = 'entityAutoEnroll',
  COURSE_ACCOUNT_AVAILABLE = 'available',
  COURSE_SCHEDULE_AUTO_ENROLL = 'scheduleAutoEnroll',
  COURSE_CUSTOM_CONTENT = 'customContent',
  COURSES_LIST_ENROLL_TYPE = 'coursesListEnrollType',
  COMPANY_NAME = 'companyName',
  DEPARTMENT = 'department',
  MANAGER = 'manager',
  MANAGER_EMAIL = 'managerEmail',
  COUNTRY = 'country',
  JOB = 'jobTitle',
  MOBILE_PHONE = 'mobilePhone',
  OFFICE_LOCATION = 'officeLocation',
  AWARENESS_REPORTS_BRANCH = 'awarenessBranch',
  GROUPS = 'groups',
  AWARENESS_REPORTS_COURSE_NAME = 'awarenessCourseName',
  AWARENESS_REPORTS_STATUS = 'awarenessStatus',
  AWARENESS_REPORTS_HAS_DUE_COURSES = 'awarenessHasDueCourses',
  USER_COMPANY_NAME = 'summaryCompanyName',
  USER_DEPARTMENT = 'summaryDepartment',
  USER_MANAGER = 'summaryManager',
  USER_COUNTRY = 'summaryCountry',
  USER_JOB = 'summaryJobTitle',
  USER_MOBILE_PHONE = 'summaryMobilePhone',
  USER_OFFICE_LOCATION = 'summaryOfficeLocation',
  USER_BRANCH = 'summaryBranch',
  USER_GROUPS = 'summaryGroup',
  SUMMARY_LAST_ACTION = 'lastAction',
  SUMMARY_HAVE_ENROLLED_COURSES = 'enrolledCourses',
  SUMMARY_HAVE_ENROLLED_MATERIALS = 'enrolledMaterials',
  SUMMARY_HAVE_OVERDUE_COURSES = 'overdueCourses',
  SUMMARY_COMPLETED_ALL_COURSES = 'completedAllCourses',
  SUMMARY_COMPLETED_ALL_MATERIALS = 'completedAllMaterials',
  ENTITY_TYPE = 'entityType',
  SCHEDULED_BY = 'scheduledBy',
  ENTITY_NAME = 'entityName',
  USER_COURSES_NAME = 'courseName',
  ENTITY_BY_STATUS = 'entityStatus',
  PHISHING_ONLY = 'isPhishingOnly',
  MIGRATED_OUTSIDE_OF_EFRONT = 'isMigratedOutsideOfEFront',
  IS_DEMO_ACCOUNT = 'isDemoAccount',
  FREE_TRIAL = 'freeTrialEndsAt',
  BASE_URL = 'baseUrl',
  THEME = 'theme',
  PHONE = 'phone',
  CAMPAIGN_TEMPLATE = 'template',
  CAMPAIGN_TEMPLATE_CLONEOF = 'cloneOf',
  CAMPAIGN_TEMPLATE_CLONEOF_NAME = 'cloneOfName',
  CAMPAIGN_DYNAMIC_TARGET = 'dynamicTarget',
  CAMPAIGN_PHISHING_TYPE = 'phishingType',
  CAMPAIGN_PHISHING_SIGN = 'phishingSign',
  HAS_HUBSPOT = 'hasHubspot',
  COURSE_DELIVERY = 'courseDelivery',
  DIFFICULTY = 'difficulty',
  SENDER = 'senderProfileType',
  SURVEY_PUBLISHED = 'surveyPublished',
  ACCOUNT_COMPANY_OWNER = 'companyOwner',
}

const FilterFunctions: Record<
  FilterNamesEnum,
  (v: FilterValue) => FilterCondtitionType
> = {
  [FilterNamesEnum.ID]: (v: FilterValue): FilterCondition => equal('id', v),

  [FilterNamesEnum.NAME]: (v: FilterValue): FilterCondition => like('name', v),

  [FilterNamesEnum.TREE]: (v: FilterValue): GroupedFilterConditions =>
    group(GroupCondition.OR, [like('tree', v), like('name', v)]),

  [FilterNamesEnum.EMAIL]: (v: FilterValue): FilterCondition =>
    like('email', v),

  [FilterNamesEnum.ACTIVE]: (v: FilterValue): FilterCondition =>
    equal('active', v),

  [FilterNamesEnum.AUTO_ASSIGN]: (v: FilterValue): FilterCondition => {
    if (v === 'yes') {
      return equal('autoAssign', true);
    } else {
      return equal('autoAssign', false);
    }
  },

  [FilterNamesEnum.IS_FOR_PHISHING]: (v: FilterValue): FilterCondition => {
    if (v === 'yes') {
      return equal('isForPhishing', true);
    } else {
      return equal('isForPhishing', false);
    }
  },

  [FilterNamesEnum.USER_BY_USERNAME]: (
    v: FilterValue,
  ): GroupedFilterConditions => group(GroupCondition.OR, [like('username', v)]),

  [FilterNamesEnum.USER_BY_USERNAME_NESTED]: (
    v: FilterValue,
  ): FilterCondition => nested('user', [like('username', v)]),

  [FilterNamesEnum.USER_BY_EMAIL]: (v: FilterValue): GroupedFilterConditions =>
    group(GroupCondition.OR, [like('email', v)]),

  [FilterNamesEnum.USER_BY_ROLE]: (v: FilterValue): FilterCondition =>
    nested('role', [equal('code', v)]),

  [FilterNamesEnum.CAMPAIGNS_BY_STATUS]: (v: FilterValue): FilterCondition =>
    isIn('status', v as string[]),

  [FilterNamesEnum.CAMPAIGNS_REPORT_BY_STATUS]: (
    v: FilterValue,
  ): FilterCondition => {
    return isIn('status', flatten(v as string[]));
  },

  [FilterNamesEnum.CREATED_BY]: (v: FilterValue): FilterCondition =>
    nested('createdBy', [like('name', v)]),

  [FilterNamesEnum.UPDATED_BY]: (v: FilterValue): FilterCondition =>
    nested('updatedBy', [like('name', v)]),

  [FilterNamesEnum.EMAIL_TEMPLATE_BY_TYPE]: (v: FilterValue) =>
    isIn('type', v as string[]),

  [FilterNamesEnum.EMAIL_TEMPLATE_BY_LANGUAGE]: (v: FilterValue) =>
    isIn('language', v as string[]),

  [FilterNamesEnum.EMAIL_TYPE_BY_SEND_TIME]: (v: FilterValue) =>
    contains('sendTime', v),

  [FilterNamesEnum.AUTO_COURSE_AVAILABILITY]: (
    v: FilterValue,
  ): FilterCondition => nested('meta', [equal('autoCourseAvailability', v)]),

  [FilterNamesEnum.AUTO_COURSE_ENROLLMENT]: (v: FilterValue): FilterCondition =>
    nested('meta', [equal('autoCourseEnrollment', v)]),

  [FilterNamesEnum.SCHEDULE_UPCOMING_DATE]: (
    v: FilterValue,
  ): FilterCondtitionType => {
    if (v === 'yes') {
      return equal('executedAt', null);
    } else {
      return group(GroupCondition.OR, [
        lower('date', new Date(), true),
        notEqual('executedAt', null),
      ]);
    }
  },

  [FilterNamesEnum.SCHEDULE_UNENROLLED]: (
    v: FilterValue,
  ): FilterCondtitionType => {
    if (v === 'yes') {
      return notEqual('unEnrolledAt', null);
    } else {
      return equal('unEnrolledAt', null);
    }
  },

  [FilterNamesEnum.COURSE_ACCOUNT_UPCOMING_DATE]: (
    v: FilterValue,
  ): FilterCondtitionType => {
    if (v === 'yes') {
      return group(GroupCondition.AND, [
        nested('schedule', [notEqual('date', null)]),
        nested('schedule', [equal('executedAt', null)]),
      ]);
    } else {
      return nested('schedule', [notEqual('executedAt', null)]);
    }
  },

  [FilterNamesEnum.COURSE_ENTITY_UPCOMING_DATE]: (
    v: FilterValue,
  ): FilterCondtitionType => {
    if (v === 'yes') {
      return greater('enrollDate', new Date());
    } else {
      return lower('enrollDate', new Date(), true);
    }
  },

  [FilterNamesEnum.COURSE_ENTITY_USER_HAS_DUE_COURSES]: (
    v: FilterValue,
  ): FilterCondtitionType => {
    if (v === 'yes') {
      return group(GroupCondition.AND, [
        group(GroupCondition.OR, [
          equal('status', UserStatusEnum.ENROLLED),
          equal('status', UserStatusEnum.IN_PROGRESS),
        ]),
        nested('enrollment', [lower('dueDate', new Date())]),
      ]);
    } else {
      return group(GroupCondition.OR, [
        group(GroupCondition.AND, [
          group(GroupCondition.OR, [
            equal('status', UserStatusEnum.ENROLLED),
            equal('status', UserStatusEnum.IN_PROGRESS),
          ]),
          nested('enrollment', [greater('dueDate', new Date())]),
        ]),
        group(GroupCondition.AND, [
          notEqual('status', UserStatusEnum.IN_PROGRESS),
          notEqual('status', UserStatusEnum.ENROLLED),
        ]),
      ]);
    }
  },

  [FilterNamesEnum.COURSE_ENTITY_AUTO_ENROLL]: (
    v: FilterValue,
  ): FilterCondition => nested('schedule', [equal('autoEnroll', v)]),

  [FilterNamesEnum.COURSE_ACCOUNT_AVAILABLE]: (
    v: FilterValue,
  ): FilterCondition => equal('available', v),

  [FilterNamesEnum.COURSE_SCHEDULE_AUTO_ENROLL]: (
    v: FilterValue,
  ): FilterCondition => equal('autoEnroll', v),

  [FilterNamesEnum.COURSE_CUSTOM_CONTENT]: (
    v: FilterValue,
  ): FilterCondition => {
    if (v === 'yes') {
      return notEqual('account', null);
    } else {
      return equal('account', null);
    }
  },

  [FilterNamesEnum.COURSES_LIST_ENROLL_TYPE]: (
    v: FilterValue,
  ): FilterCondition => {
    if (v === 'yes') {
      return equal('autoEnroll', true);
    } else {
      return equal('autoEnroll', false);
    }
  },
  [FilterNamesEnum.BRANCH]: (v: FilterValue): FilterCondition =>
    nested('branch', [equal('id', v)]),

  [FilterNamesEnum.COMPANY_NAME]: (v: FilterValue): FilterCondition =>
    nested('meta', [like('companyName', v)]),

  [FilterNamesEnum.DEPARTMENT]: (v: FilterValue): FilterCondition =>
    nested('meta', [like('department', v)]),

  [FilterNamesEnum.MANAGER]: (v: FilterValue): FilterCondition =>
    nested('meta', [like('manager', v)]),

  [FilterNamesEnum.MANAGER_EMAIL]: (v: FilterValue): FilterCondition =>
    nested('meta', [like('managerEmail', v)]),

  [FilterNamesEnum.COUNTRY]: (v: FilterValue): FilterCondition =>
    nested('meta', [like('country', v)]),

  [FilterNamesEnum.JOB]: (v: FilterValue): FilterCondition =>
    nested('meta', [like('jobTitle', v)]),

  [FilterNamesEnum.MOBILE_PHONE]: (v: FilterValue): FilterCondition =>
    nested('meta', [like('mobilePhone', v)]),

  [FilterNamesEnum.OFFICE_LOCATION]: (v: FilterValue): FilterCondition =>
    nested('meta', [like('officeLocation', v)]),

  [FilterNamesEnum.AWARENESS_REPORTS_COURSE_NAME]: (
    v: FilterValue,
  ): FilterCondtitionType => nested('course', [like('name', v)]),

  [FilterNamesEnum.AWARENESS_REPORTS_STATUS]: (
    v: FilterValue,
  ): FilterCondtitionType =>
    v === 'enrolled'
      ? equal('bestAttempt', null)
      : group(GroupCondition.AND, [
          nested('bestAttempt', [equal('status', v)]),
          group(GroupCondition.OR, [
            nested('course', [equal('type', MaterialType.COURSE)]),
            nested('course', [equal('type', MaterialType.CUSTOM_MATERIAL)]),
          ]),
        ]),

  [FilterNamesEnum.AWARENESS_REPORTS_HAS_DUE_COURSES]: (
    v: FilterValue,
  ): FilterCondtitionType => {
    if (v === 'yes') {
      return group(GroupCondition.AND, [
        nested('bestAttempt', [equal('completed', null)]),
        lower('dueDate', new Date()),
      ]);
    } else {
      return group(GroupCondition.OR, [
        group(GroupCondition.AND, [
          nested('bestAttempt', [equal('completed', null)]),
          greater('dueDate', new Date()),
        ]),
        nested('bestAttempt', [notEqual('completed', null)]),
      ]);
    }
  },

  [FilterNamesEnum.USER_COMPANY_NAME]: (v: FilterValue): FilterCondition =>
    nested('user', [nested('meta', [like('companyName', v)])]),

  [FilterNamesEnum.USER_DEPARTMENT]: (v: FilterValue): FilterCondition =>
    nested('user', [nested('meta', [like('department', v)])]),

  [FilterNamesEnum.USER_MANAGER]: (v: FilterValue): FilterCondition =>
    nested('user', [nested('meta', [like('manager', v)])]),

  [FilterNamesEnum.USER_COUNTRY]: (v: FilterValue): FilterCondition =>
    nested('user', [nested('meta', [like('country', v)])]),

  [FilterNamesEnum.USER_JOB]: (v: FilterValue): FilterCondition =>
    nested('user', [nested('meta', [like('jobTitle', v)])]),

  [FilterNamesEnum.USER_MOBILE_PHONE]: (v: FilterValue): FilterCondition =>
    nested('user', [nested('meta', [like('mobilePhone', v)])]),

  [FilterNamesEnum.USER_OFFICE_LOCATION]: (v: FilterValue): FilterCondition =>
    nested('user', [nested('meta', [like('officeLocation', v)])]),

  [FilterNamesEnum.USER_BRANCH]: (v: FilterValue): FilterCondition =>
    nested('user', [nested('branch', [equal('id', v)])]),

  [FilterNamesEnum.USER_GROUPS]: (v: FilterValue): FilterCondition =>
    nested('user', [nested('groups', [equal('id', v)])]),

  [FilterNamesEnum.SUMMARY_LAST_ACTION]: (v: FilterValue): FilterCondition => {
    if (Array.isArray(v)) {
      return isIn('lastAction', flatten(v as string[]));
    } else {
      return equal('lastAction', v);
    }
  },

  [FilterNamesEnum.SUMMARY_HAVE_ENROLLED_COURSES]: (
    v: FilterValue,
  ): FilterCondtitionType => {
    if (v === 'yes') {
      return notEqual('enrolledCourses', 0);
    } else {
      return equal('enrolledCourses', 0);
    }
  },

  [FilterNamesEnum.SUMMARY_HAVE_ENROLLED_MATERIALS]: (
    v: FilterValue,
  ): FilterCondtitionType => {
    if (v === 'yes') {
      return notEqual('enrolledMaterials', 0);
    } else {
      return equal('enrolledMaterials', 0);
    }
  },

  [FilterNamesEnum.SUMMARY_HAVE_OVERDUE_COURSES]: (
    v: FilterValue,
  ): FilterCondtitionType => {
    if (v === 'yes') {
      return group(GroupCondition.AND, [
        notEqual('overdueCourses', 0),
        notEqual('overdueCourses', null),
      ]);
    } else {
      return group(GroupCondition.OR, [
        equal('overdueCourses', 0),
        equal('overdueCourses', null),
      ]);
    }
  },

  [FilterNamesEnum.SUMMARY_COMPLETED_ALL_COURSES]: (
    v: FilterValue,
  ): FilterCondtitionType => {
    if (v === 'yes') {
      return group(GroupCondition.AND, [
        equal('notCompletedCourses', 0),
        notEqual('enrolledCourses', 0),
      ]);
    } else {
      return group(GroupCondition.AND, [
        notEqual('notCompletedCourses', 0),
        notEqual('enrolledCourses', 0),
      ]);
    }
  },

  [FilterNamesEnum.SUMMARY_COMPLETED_ALL_MATERIALS]: (
    v: FilterValue,
  ): FilterCondtitionType => {
    if (v === 'yes') {
      return group(GroupCondition.AND, [
        equal('notCompletedMaterials', 0),
        notEqual('enrolledMaterials', 0),
      ]);
    } else {
      return group(GroupCondition.AND, [
        notEqual('notCompletedMaterials', 0),
        notEqual('enrolledMaterials', 0),
      ]);
    }
  },

  [FilterNamesEnum.AWARENESS_REPORTS_BRANCH]: (
    v: FilterValue,
  ): FilterCondition => nested('branch', [equal('id', v)]),

  [FilterNamesEnum.ENTITY_NAME]: (v: FilterValue): FilterCondition =>
    nested('entity', [like('name', v)]),

  [FilterNamesEnum.ENTITY_TYPE]: (v: FilterValue): FilterCondition =>
    nested('entity', [equal('type', v)]),

  [FilterNamesEnum.SCHEDULED_BY]: (v: FilterValue): FilterCondition =>
    nested('scheduledBy', [like('name', v)]),

  [FilterNamesEnum.USER_COURSES_NAME]: (v: FilterValue): FilterCondition =>
    nested('course', [like('name', v)]),

  [FilterNamesEnum.ENTITY_BY_STATUS]: (v: FilterValue): FilterCondition =>
    equal('status', v),

  [FilterNamesEnum.PHISHING_ONLY]: (v: FilterValue): FilterCondition =>
    equal('isPhishingOnly', v),

  [FilterNamesEnum.MIGRATED_OUTSIDE_OF_EFRONT]: (
    v: FilterValue,
  ): FilterCondtitionType => {
    if (v === 'yes') {
      return nested('meta', [equal('isMigratedOutsideOfEFront', true)]);
    } else {
      return group(GroupCondition.OR, [
        nested('meta', [equal('isMigratedOutsideOfEFront', false)]),
        nested('meta', [equal('isMigratedOutsideOfEFront', null)]),
      ]);
    }
  },

  [FilterNamesEnum.IS_DEMO_ACCOUNT]: (v: FilterValue): FilterCondtitionType => {
    if (v === 'yes') {
      return equal('type', AccountTypeEnum.DEMO);
    } else {
      return equal('type', AccountTypeEnum.REAL);
    }
  },

  [FilterNamesEnum.FREE_TRIAL]: (v: FilterValue): FilterCondition => {
    if (v === 'yes') {
      return notEqual('freeTrialEndsAt', null);
    } else {
      return equal('freeTrialEndsAt', null);
    }
  },

  [FilterNamesEnum.BASE_URL]: (v: FilterValue): FilterCondition =>
    like('baseUrl', v),

  [FilterNamesEnum.THEME]: (v: FilterValue): FilterCondition =>
    equal('theme', v),

  [FilterNamesEnum.PHONE]: (v: FilterValue): FilterCondition =>
    like('phone', v),

  [FilterNamesEnum.GROUPS]: (v: FilterValue): FilterCondition =>
    isIn('groups', [v]),

  [FilterNamesEnum.CAMPAIGN_TEMPLATE]: (
    v: FilterValue,
  ): FilterCondtitionType => {
    if (v === 'yes') {
      return equal('isTemplate', true);
    } else {
      return equal('isTemplate', false);
    }
  },

  [FilterNamesEnum.CAMPAIGN_DYNAMIC_TARGET]: (
    v: FilterValue,
  ): FilterCondtitionType => {
    if (v === 'yes') {
      return equal('useDynamicTarget', true);
    } else {
      return group(GroupCondition.OR, [
        equal('useDynamicTarget', false),
        equal('useDynamicTarget', null),
      ]);
    }
  },

  [FilterNamesEnum.CAMPAIGN_TEMPLATE_CLONEOF]: (
    v: FilterValue,
  ): FilterCondition => nested('meta', [like('cloneOf', v)]),

  [FilterNamesEnum.CAMPAIGN_TEMPLATE_CLONEOF_NAME]: (
    v: FilterValue,
  ): FilterCondition => nested('meta', [like('cloneOfName', v)]),

  [FilterNamesEnum.CAMPAIGN_PHISHING_TYPE]: (v: FilterValue): FilterCondition =>
    nested('meta', [equal('phishingType', v)]),

  [FilterNamesEnum.CAMPAIGN_PHISHING_SIGN]: (v: FilterValue): FilterCondition =>
    nested('meta', [equal('phishingSign', v)]),

  [FilterNamesEnum.HAS_HUBSPOT]: (v: FilterValue): FilterCondtitionType => {
    if (v === 'yes') {
      return group(GroupCondition.AND, [
        nested('hubspot', [notEqual('id', null)]),
        nested('hubspot', [notEqual('id', '')]),
      ]);
    } else {
      return group(GroupCondition.OR, [
        nested('hubspot', [equal('id', null)]),
        nested('hubspot', [equal('id', '')]),
      ]);
    }
  },

  [FilterNamesEnum.COURSE_DELIVERY]: (v: FilterValue): FilterCondition =>
    equal('courseDelivery', v),

  [FilterNamesEnum.DIFFICULTY]: (v: FilterValue): FilterCondition => {
    return isIn('difficulty', v as number[]);
  },

  [FilterNamesEnum.SENDER]: (v: FilterValue): FilterCondition =>
    equal('senderProfileType', v),

  [FilterNamesEnum.SURVEY_PUBLISHED]: (v: FilterValue): FilterCondition => {
    if (v === 'yes') {
      return equal('status', SurveyStatus.PUBLISHED);
    } else {
      return equal('status', SurveyStatus.DRAFT);
    }
  },

  [FilterNamesEnum.ACCOUNT_COMPANY_OWNER]: (v: FilterValue): FilterCondition =>
    nested('hubspot', [nested('companyOwner', [like('firstName', v)])]),
};

export const getFilters: FilterFunctionType = (
  values: FilterFunctionValuesType,
): FiltersType => {
  return Object.entries(values)
    .filter(([, v]) =>
      isObject(v) && !isArray(v)
        ? Object.values(v).some((l) => !isEmpty(l))
        : !isEmpty(v),
    )
    .reduce(
      (a: FiltersType, [k, v]) =>
        Object.keys(FilterFunctions).includes(k)
          ? [...a, FilterFunctions[k as FilterNamesEnum](v as FilterValue)]
          : a,
      [],
    );
};

export const getFiltersFromColumns = (
  columns: DataTableColumnType[],
): FilterType[] => {
  // filtered all columns without filters
  const filteredColumns = columns.filter((c) => c.filterable);

  // takes field name from column field and label and placeholder from column properties if not specified in filters
  return filteredColumns.map(
    (column: DataTableColumnType): FilterType =>
      column.filterable
        ? {
            ...column.filters,
            field: column.filters?.field
              ? column.filters?.field
              : (column.field as FilterNamesEnum),
            label: column.filters?.label
              ? (column.filters.label as string)
              : typeof column.header === 'string'
              ? column.header
              : column.field,
            placeholder: column.filters?.placeholder
              ? (column.filters.placeholder as string | string[])
              : typeof column.header === 'string'
              ? column.header
              : column.field,
          }
        : ({} as FilterType),
  );
};
