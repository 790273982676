import { FiltersType, GroupedFilterConditions } from '@/api/types';
import { client } from '@/client';
import { MessageResponseModel } from '@/client/models';
import {
  ListPageTemplatesResponse,
  PageTemplate,
  SavePageTemplateRequest,
  UpdatePageTemplateRequest,
} from '@/client/page-templates';
import {
  DeleteLandingPageTemplateMutation,
  GenericForbiddenApiError,
  SaveLandingPageTemplateMutation,
} from '@/hooks/query';
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

const queryKey = 'landing-page-templates';
const singleQueryKey = 'landing-page-template';

export const usePageTemplates = (
  params: UseQueryOptions & {
    take?: number;
    skip?: number;
    filters?: FiltersType | GroupedFilterConditions;
  },
): UseQueryResult<ListPageTemplatesResponse> & {
  pageTemplates: ListPageTemplatesResponse | undefined;
} => {
  const {
    take = 200,
    skip = 0,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(filters)],
    () => client.pageTemplates.getPageTemplates(params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    pageTemplates: data,
    ...(rest as UseQueryResult<ListPageTemplatesResponse>),
  };
};

export const usePageTemplate = (
  params: UseQueryOptions & {
    pageTemplateId?: string;
  },
): UseQueryResult<PageTemplate> & {
  pageTemplate: PageTemplate | undefined;
} => {
  const {
    pageTemplateId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [singleQueryKey, pageTemplateId],
    () =>
      pageTemplateId
        ? client.pageTemplates.getPageTemplate(pageTemplateId)
        : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<PageTemplate>),
    },
  );

  return {
    pageTemplate: data,
    ...(rest as UseQueryResult<PageTemplate>),
  };
};

export const useSavePageTemplate = (): {
  create: (payload: SavePageTemplateRequest) => Promise<PageTemplate>;
} & SaveLandingPageTemplateMutation => {
  const { mutateAsync, ...rest } = useMutation<
    PageTemplate,
    GenericForbiddenApiError,
    SavePageTemplateRequest
  >('createLandingPageTemplate', (payload: SavePageTemplateRequest) =>
    client.pageTemplates.savePageTemplate(payload),
  );

  return { create: mutateAsync, ...(rest as any) };
};

export const useUpdatePageTemplate = (): {
  update: (payload: UpdatePageTemplateRequest) => Promise<PageTemplate>;
} & SaveLandingPageTemplateMutation => {
  const { mutateAsync, ...rest } = useMutation<
    PageTemplate,
    GenericForbiddenApiError,
    UpdatePageTemplateRequest
  >('updateLandingPageTemplate', (payload: UpdatePageTemplateRequest) =>
    client.pageTemplates.updatePageTemplate(payload),
  );

  return { update: mutateAsync, ...(rest as any) };
};

export const useDeletePageTemplate = (): {
  delete: (pageTemplateId: string) => Promise<MessageResponseModel>;
} & DeleteLandingPageTemplateMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    string
  >('deleteLandingPageTemplate', (landingPageTemplateId: string) =>
    client.pageTemplates.deletePageTemplate(landingPageTemplateId),
  );

  return { delete: mutateAsync, ...(rest as any) };
};
