import { FlexContainer } from '@/ui/styled-ui';
import classNames from 'classnames';
import { InputSwitchProps } from 'primereact/inputswitch';
import React from 'react';
import styled from 'styled-components';
import { FormikSwitchProps, StyledInputSwitch } from './FormikSwitch';

const StyledLabel = styled.label`
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  font-weight: 500;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
`;

export const FormikWrappedSwitch: React.FC<
  FormikSwitchProps & InputSwitchProps
> = ({ field, form, label, required, className, ...rest }) => (
  <FlexContainer justify="space-between" className="w-full">
    <StyledLabel
      htmlFor={field.name}
      className={classNames({
        'p-error': !!form.errors[field.name],
      })}
    >
      {label}
      {required ? <span className="red"> *</span> : ''}
    </StyledLabel>
    <StyledInputSwitch
      {...field}
      {...rest}
      checked={!!field.value}
      className={className}
    />
  </FlexContainer>
);
