import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { AccountSelectInput } from '@/components/form/selectors';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useEFrontSyncNotifications } from '@/hooks/sync/efront.hook';
import { useToast } from '@/hooks/useToast';
import {
  selectCurrentAccount,
  setCurrentAccount,
} from '@/store/features/account';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledDiv = styled.div`
  margin: 0 var(--medium-padding) var(--small-padding);
`;

export const SidebarAccountSelect: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const account = useAppSelector(selectCurrentAccount);
  const navigate = useNavigate();
  const messages = useEFrontSyncNotifications();
  const [refetch, setRefetch] = useState<boolean>(false);
  const toast = useToast();

  useEffect(() => {
    if (
      !messages ||
      !['eFront.account.sync.finished'].includes(messages?.data?.event)
    ) {
      return;
    }

    setRefetch(true);

    setTimeout(() => {
      setRefetch(false);
    }, 2000);
  }, [JSON.stringify(messages)]);

  const onSelect = async (account?: Account) => {
    if (account) {
      try {
        // Get the current account in case of data that is not send in the list of accounts
        const response = await client.accounts.getAccount(account.id);

        if (!response) {
          toast?.error(
            t('account.change.missing'),
            t('account.change.missing.message'),
          );
          return;
        }

        dispatch(setCurrentAccount(response));
        navigate(RedirectPaths[RedirectPathsEnum.ADMIN_DASHBOARD]());
      } catch (e) {
        handleAxiosError(e as AxiosError, toast);
      }
    }
  };

  return (
    <StyledDiv>
      {account && (
        <AccountSelectInput
          defaultValue={account}
          onSelect={onSelect}
          forceRefetch={refetch}
          isPurple
        />
      )}
    </StyledDiv>
  );
};
