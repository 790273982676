import { GroupCondition } from '@/api/enums';
import { equal, group } from '@/api/helpers';
import { client } from '@/client';
import { CampaignFormValues } from '@/client/campaigns';
import { EmailTemplate } from '@/client/email-templates';
import { ThumbnailState } from '@/client/thumbnails/enums';
import { Actions, Subjects } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { DialogContext } from '@/common/context';
import { FormikSwitch } from '@/components/form';
import { TemplatesFilters } from '@/components/templates';
import { ThumbnailsGenerationNotify } from '@/components/thumbnails';
import { useEmailTemplate, useEmailTemplates } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTemplatesFilters } from '@/hooks/templates';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { ImageCard } from '@/ui/image-card';
import { CardGridContainer, FlexContainer } from '@/ui/styled-ui';
import { displayCreatedBy } from '@/utils/helpers';
import { Field } from 'formik';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import { RadioButtonChangeEvent } from 'primereact/radiobutton';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

type EmailTemplateStepProps = {
  values: CampaignFormValues;
  valuesSetter: any;
  exitPrompt?: boolean;
  preselectedEmailTempId?: string;
};

// this is used in case of selected email ahead in pages and no visible for the current page
const initialSelectedTemplateLoad: (
  pageTemplateId?: string,
) => Promise<EmailTemplate | undefined> = async (pageTemplateId?: string) => {
  if (!pageTemplateId) return undefined;

  const response = await client.emailTemplates.getEmailTemplates({
    filters: [equal('id', pageTemplateId)],
  });

  return response?.result?.[0];
};

export const EmailTemplateStep: React.FC<EmailTemplateStepProps> = ({
  values,
  valuesSetter,
  exitPrompt,
  preselectedEmailTempId,
}) => {
  const { t } = useTranslation();
  const { can } = usePermission();
  const { id: campaignId } = useParams();
  const navigate = useNavigate();
  const { setDialogData } = useContext(DialogContext);
  const currentAccount = useAppSelector(selectCurrentAccount);

  const account = useAppSelector(selectCurrentAccount);
  const {
    take,
    nameFilter,
    search,
    skip,
    handleSearch,
    handleChangeSkip,
    handleChangeDefaultRows,
  } = useTemplatesFilters();

  const {
    emailTemplate: preselectedEmailTemp,
    isLoading: preselectedIsLoading,
  } = useEmailTemplate({
    emailTemplateId: preselectedEmailTempId,
  });

  const { isLoading, emailTemplates, refetch } = useEmailTemplates({
    take,
    skip,
    filters: [
      group(GroupCondition.OR, [
        equal('account', account?.id),
        equal('account', null),
      ]),
      ...nameFilter,
      equal('active', true),
    ],
  });

  const [selectedTemplateName, setSelectedTemplateName] = useState(
    `${t('generic.none')}`,
  );

  useEffect(() => {
    const loadTemplate = async () => {
      const template = await initialSelectedTemplateLoad(values.emailTemplate);

      if (template) setSelectedTemplateName(template.name);
    };

    loadTemplate();
  }, [values.emailTemplate]);

  const menuItems = (emailTemplate: EmailTemplate, campaignId?: string) => {
    const items: MenuItem[] = [];
    const createdInAccount = emailTemplate.account?.id === currentAccount?.id;

    if (
      can(Actions.CREATE, Subjects.EMAIL_TEMPLATES) &&
      !createdInAccount &&
      !currentAccount?.isSystem
    ) {
      items.push({
        label: t('generic.copyEdit'),
        icon: 'pi pi-copy',
        command: () => {
          if (exitPrompt) {
            return navigate(
              RedirectPaths[RedirectPathsEnum.EMAIL_TEMPLATES_CREATE](),
              {
                state: { emailTemplate, campaignId },
              },
            );
          }

          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.unsavedChanges'),
            message: t('dialog.unsavedChanges.confirm'),
            onAccept: () =>
              navigate(
                RedirectPaths[RedirectPathsEnum.EMAIL_TEMPLATES_CREATE](),
                {
                  state: { emailTemplate, campaignId },
                },
              ),
          });
        },
      });
    }

    if (
      can(Actions.UPDATE, Subjects.EMAIL_TEMPLATES) &&
      (createdInAccount || currentAccount?.isSystem)
    ) {
      items.push({
        label: t('generic.edit'),
        icon: 'pi pi-pencil',
        command: () => {
          if (exitPrompt) {
            return navigate(
              RedirectPaths[RedirectPathsEnum.EMAIL_TEMPLATES_UPDATE](
                emailTemplate.id,
              ),
              {
                state: { campaignId },
              },
            );
          }

          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.unsavedChanges'),
            message: t('dialog.unsavedChanges.confirm'),
            onAccept: () =>
              navigate(
                RedirectPaths[RedirectPathsEnum.EMAIL_TEMPLATES_UPDATE](
                  emailTemplate.id,
                ),
                {
                  state: { campaignId },
                },
              ),
          });
        },
      });
    }

    return items;
  };

  const setCampaignName = (event: RadioButtonChangeEvent) => {
    const selectedEmailTempName = emailTemplates?.result.find(
      (et) => et.id === event.value,
    )?.name;

    let campaignName = values.name;

    if (
      // when the camapign is created from template and the name is not yet changed -> prefix the name with the selected email template's name
      (selectedEmailTempName &&
        campaignName &&
        !campaignName?.includes(
          preselectedEmailTemp?.name ?? selectedEmailTempName,
        ) &&
        campaignId &&
        campaignName.includes(campaignId) &&
        values.meta?.cloneOf) ||
      // when the campaign is created from scratch and the name is not yet changed -> prefix the name with the selected email template's name
      (!preselectedEmailTemp?.name &&
        selectedEmailTempName &&
        campaignName &&
        !campaignName?.includes(selectedEmailTempName) &&
        !values.meta?.cloneOf)
    ) {
      campaignName =
        campaignName = `${selectedEmailTempName} - ${campaignName}`;
    }

    // when the campaign name is prefixed change it if the selected email template is changed
    if (
      selectedEmailTempName &&
      preselectedEmailTemp?.name &&
      campaignName &&
      campaignName.includes(preselectedEmailTemp.name)
    ) {
      campaignName = campaignName.replace(
        preselectedEmailTemp.name,
        selectedEmailTempName,
      );
    }

    valuesSetter('name', campaignName);
  };

  return (
    <>
      <h2>
        {t('campaign.select.emailTemplate')}{' '}
        <span id="selected-template-tag">{`(${selectedTemplateName})`}</span>
      </h2>
      <TemplatesFilters
        count={emailTemplates?.count ?? 0}
        take={take}
        skip={skip}
        onPageChange={handleChangeSkip}
        onRowsChange={handleChangeDefaultRows}
        onSearch={handleSearch}
        search={search}
      />
      <div className="field-checkbox mt-3">
        <Field
          inputId="encodeEmailTemplateImages"
          name="encodeEmailTemplateImages"
          label={t('campaign.email.template.encode.images')}
          component={FormikSwitch}
        />
      </div>
      {isLoading && !emailTemplates && (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
          <h3>{t('templates.email.loading')}</h3>
        </FlexContainer>
      )}
      {!emailTemplates?.result.length && !isLoading && (
        <FlexContainer className="mt-5">
          <h3>{t('templates.email.empty')}</h3>
        </FlexContainer>
      )}
      {!isLoading && !!emailTemplates?.result.length && (
        <>
          <ThumbnailsGenerationNotify
            refetch={refetch}
            templates={emailTemplates.result}
          />
          <CardGridContainer>
            {emailTemplates?.result.map((template) => (
              <ImageCard
                isLoading={
                  template.thumbnailState === ThumbnailState.PROCESSING
                }
                key={template.id}
                id={template.id}
                title={template.name}
                thumbnail={template.thumbnail?.signedUrl}
                actions={menuItems(template, campaignId)}
                author={displayCreatedBy(t, template.createdBy)}
                templateType="email"
                interactionType={
                  menuItems(template, campaignId).length
                    ? 'withSelectAndActions'
                    : 'withSelect'
                }
                selectName={template?.name}
                isSelected={values.emailTemplate === template.id}
                onChange={(event: RadioButtonChangeEvent) => {
                  if (preselectedIsLoading) return;
                  // Find the name of the template based on the selected id
                  valuesSetter('emailTemplate', event.value);
                  setCampaignName(event);
                }}
                data-testid={`campaign-form-email-template-step-template-${template.id}`}
              />
            ))}
          </CardGridContainer>
        </>
      )}
    </>
  );
};
