import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { GroupForm } from '@/components/groups/forms';
import { useAddGroup } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { queryStateConverter } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const CreateGroupPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const account = useAppSelector(selectCurrentAccount);

  const createGroup = useAddGroup();
  const initialValues = {
    name: '',
    autoAssign: false,
    active: true,
    account: '',
    eFrontSync: false,
  };

  const handleAccountAction = async (data: FormikValues) => {
    await createGroup.create({
      name: data.name,
      autoAssign: data.autoAssign,
      account: account?.id as string,
    });

    navigate(RedirectPaths[RedirectPathsEnum.GROUPS]());
  };

  const handleSubmit = async (data: FormikValues) => {
    try {
      await handleAccountAction(data);

      toast?.success(t('toast.success'), t('group.created'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const pathItems = [
    {
      label: account?.name,
      url: !account?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id as string)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('groups'),
      url: RedirectPaths[RedirectPathsEnum.GROUPS](),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('button.create'),
      url: RedirectPaths[RedirectPathsEnum.GROUPS_CREATE](),
      template: AppBreadCrumbTemplate,
    },
  ];

  return (
    <>
      <AppBreadCrumb model={pathItems} />
      <h1>{t('group.new')}</h1>
      <FlexContainer justify="flex-start">
        <GroupForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          state={queryStateConverter(createGroup)}
        />
      </FlexContainer>
    </>
  );
};
