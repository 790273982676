import { get } from 'lodash';
import { Skeleton } from 'primereact/skeleton';
import React from 'react';

type DataTableColumnProps = {
  field?: string | null;
  item: object;
  isLoading: boolean;
  render?: (item: object) => void;
};
export const DataTableColumn: React.FC<DataTableColumnProps> = ({
  field,
  item,
  isLoading,
  render,
}) => {
  if (isLoading) {
    return <Skeleton />;
  }

  if (!field) {
    return null;
  }

  return render ? render(item) : get(item, field);
};
