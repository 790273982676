import {
  DashboardType,
  LAST_SELECTED_DASHBOARD,
  LAST_SELECTED_LANGUAGE,
} from '@/common/constants';
import { learnerOnlyLanguages } from '@/common/constants/languages';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import { LanguagesEnum } from './api/enums';
import translationDEV from './locales/dev_translations.json';
import translationDK from './locales/localazy/da.json';
import translationDE from './locales/localazy/de.json';
import translationEN from './locales/localazy/en-US.json';
import translationNO from './locales/localazy/nb.json';
import translationNL from './locales/localazy/nl.json';
import translationSE from './locales/localazy/sv.json';
// TODO: Uncomment when more languages are available
// import translationIT from './locales/localazy/it.json';
// import translationPL from './locales/localazy/pl.json';

//Creat object with the imported translation files
export const resources = {
  [LanguagesEnum.EN]: {
    translation:
      process.env.REACT_APP_ENVIRONMENT === 'local'
        ? translationDEV // Use DEV translations on local env for easier development
        : translationEN,
  },
  [LanguagesEnum.DK]: {
    translation: translationDK,
  },
  [LanguagesEnum.SE]: {
    translation: translationSE,
  },
  [LanguagesEnum.DE]: {
    translation: translationDE,
  },
  [LanguagesEnum.NO]: {
    translation: translationNO,
  },
  [LanguagesEnum.NL]: {
    translation: translationNL,
  },
  // TODO: Add other resources when more languages are available
};

// Check if a language is available in localstorage
export const defaultLang = localStorage.getItem(LAST_SELECTED_LANGUAGE)
  ? localStorage.getItem(LAST_SELECTED_LANGUAGE)
  : LanguagesEnum.EN;

const isAdminDashboard =
  localStorage.getItem(LAST_SELECTED_DASHBOARD) === DashboardType.ADMIN;

//i18N Initialization
i18n.use(initReactI18next).init({
  resources,
  lng:
    learnerOnlyLanguages.includes(defaultLang) && isAdminDashboard
      ? LanguagesEnum.EN
      : defaultLang, //default language
  fallbackLng: LanguagesEnum.EN, //fallback language
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
