import { previewOptions, PreviewType } from '@/utils/helpers';
import { table } from 'console';
import { SelectButton, SelectButtonProps } from 'primereact/selectbutton';
import React, { useEffect } from 'react';
import styled from 'styled-components';

const StyledSelectButton = styled(SelectButton)`
  &.p-selectbutton {
    width: max-content;
    border-radius: var(--default-border-radius);
    background-color: var(--beige-main);
    padding: var(--xxsmall-padding);
    height: var(--default-input-height);

    .p-button {
      padding: 10px;
      height: 42px;
      width: 42px;
      border-radius: 50%;
      border: none;
      background-color: var(--beige-main);
      color: var(--black-main);
      i {
        margin: auto;
      }

      &.p-highlight {
        background-color: var(--white-main);
      }
      &:focus,
      &:hover {
        box-shadow: none;
        color: var(--red-main);
      }
    }
  }
`;

type AppSelectPreviewTypePropsType = {
  tableName: string;
  selectedPreviewType: PreviewType;
  setSelectedPreviewType: React.Dispatch<React.SetStateAction<PreviewType>>;
  userId?: string;
  accountId?: string;
} & SelectButtonProps;

export const AppSelectPreviewType: React.FC<AppSelectPreviewTypePropsType> = ({
  tableName,
  selectedPreviewType,
  setSelectedPreviewType,
  userId,
  accountId,
}) => {
  useEffect(() => {
    const typeFromLocalSt = localStorage.getItem(
      `${tableName}-selectedPreviewType-${userId}-${accountId}`,
    );
    const preselectedPreviewType =
      typeFromLocalSt &&
      [PreviewType.GRID as string, PreviewType.TABLE as string].includes(
        typeFromLocalSt,
      )
        ? (typeFromLocalSt as PreviewType)
        : selectedPreviewType;
    setSelectedPreviewType(preselectedPreviewType);
  }, []);

  const handleSelectedColumnsChange = (type: PreviewType) => {
    localStorage.setItem(
      `${tableName}-selectedPreviewType-${userId}-${accountId}`,
      type,
    );
    setSelectedPreviewType(type);
  };

  const justifyTemplate = (option: any) => {
    return <i className={option.icon}></i>;
  };
  return (
    <StyledSelectButton
      value={selectedPreviewType}
      onChange={(e) => handleSelectedColumnsChange(e.value)}
      options={previewOptions}
      optionLabel="value"
      itemTemplate={justifyTemplate}
    />
  );
};
