import AppRouter from '@/app/AppRouter';
import { REDIRECT_URL_KEY } from '@/common/constants/user';
import { AppContextComponent } from '@/common/context/AppContext';
import { LanguageContextComponent } from '@/common/context/LanguageContext';
import i18n from '@/i18n';
import { store } from '@/store';
import { isOutsideOfEfront, parseEnviourmentUrl } from '@/utils/helpers';
import { Toast } from 'primereact/toast';
import React, { createContext, useEffect, useRef } from 'react';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import {
  AuthProvider,
  AuthProviderNoUserManagerProps,
  useAuth,
} from 'react-oidc-context';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { AppHelmet } from './AppHelmet';
import { AppSessionExpiryListener } from './AppSessionExpiryListener';

const DEFAULT_KEYCLOAK_REALM = 'app';

const subdomain =
  window.location.host.replace('www.', '').split('.').length >= 2
    ? window.location.host.split('.')[0]
    : process.env?.REACT_APP_KEYCLOAK_REALM &&
      process.env?.REACT_APP_KEYCLOAK_REALM !== ''
    ? process.env?.REACT_APP_KEYCLOAK_REALM
    : DEFAULT_KEYCLOAK_REALM;

const keycloakUrl = parseEnviourmentUrl().keycloakUrl;

const oidcConfig: AuthProviderNoUserManagerProps = {
  authority: keycloakUrl ? `${keycloakUrl}realms/${subdomain}` : '',
  client_id: subdomain,
  redirect_uri:
    window.location.origin +
    (isOutsideOfEfront() ? '/complete-sign-in' : '!#/complete-sign-in'),
  automaticSilentRenew: true,
  staleStateAgeInSeconds: 60,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  },
});

export const GlobalToast = createContext({});

const App = () => {
  const toastRef = useRef(null);
  const Router = isOutsideOfEfront() ? BrowserRouter : HashRouter;

  const helmetContext = {};

  return (
    <Router>
      <HelmetProvider context={helmetContext}>
        <GlobalToast.Provider value={toastRef}>
          <CookiesProvider defaultSetOptions={{ path: '/' }}>
            <I18nextProvider i18n={i18n}>
              <AppHelmet />
              <AppSessionExpiryListener />
              <Toast
                ref={toastRef}
                baseZIndex={9999}
                appendTo={document.body}
              />
              <AppRouter />
            </I18nextProvider>
          </CookiesProvider>
        </GlobalToast.Provider>
      </HelmetProvider>
    </Router>
  );
};

const RedirectUrlTracker = () => {
  const { isAuthenticated, activeNavigator } = useAuth();

  useEffect(() => {
    if (
      !isAuthenticated &&
      activeNavigator !== 'signinRedirect' &&
      !window.location.href.match(/complete-sign-in/g)
    ) {
      sessionStorage.setItem(
        REDIRECT_URL_KEY,
        `${window.location.pathname}${window.location.search}`,
      );
    }
  }, [isAuthenticated, activeNavigator, window.location.href]);

  return null;
};

const AppWithStore = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <AppContextComponent>
        <LanguageContextComponent>
          <AuthProvider {...oidcConfig}>
            <RedirectUrlTracker />
            <App />
          </AuthProvider>
        </LanguageContextComponent>
      </AppContextComponent>
    </QueryClientProvider>
  </Provider>
);

export default AppWithStore;
