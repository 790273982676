import { ListingRequest, ListingResponse } from '@/api/types';
import { User } from '@/client/users';

export type ListPageSystemEmailTypeRequest = ListingRequest;
export type ListPageSystemEmailTypeResponse =
  ListingResponse<SystemEmailTypeConfig>;

export type SystemEmailsConfigFormValues = {
  type?: string;
  sendTime?: string[];
  active?: boolean;
};

export type SystemEmailsSendingConfigFormValues = {
  senderName?: string;
  fromAddress: string;
  host: string;
  username?: string;
  password?: string;
  ignoreCertErrors?: boolean;
  isRateLimited?: boolean | null;
};

export enum EmailSendTime {
  USER_REGISTRATION = 'user-registration',
  USER_REQUEST = 'user-request',
  USER_ENROLLMENT = 'user-enrollment',
}

export enum EmailType {
  WELCOME = 'welcome',
  PASSWORD_RESET = 'password-reset',
  REMINDER = 'reminder',
  COURSE_AVAILABILITY = 'course-availability',
  COURSE_ENROLLMENT = 'course-enrollment',
  INCOMPLETE_COURSE_REMINDER = 'incomplete-course-reminder',
  ACCOUNT_MIGRATION = 'account-migration',
  WELCOME_SSO = 'welcome-sso',
  ACCOUNT_MIGRATION_SSO = 'account-migration-sso',
  WELCOME_FREE_TRIAL = 'welcome-free-trial',
  SECURITY_CULTURE = 'security-culture',
}

export type SystemEmailTypeConfig = {
  id: string;
  type: EmailType;
  sendTime?: EmailSendTime[];
  active?: boolean;
  created?: Date;
  updated?: Date;
  createdBy?: User;
};

export type SaveSystemEmailTypeConfig = {
  type?: string;
  sendTime?: string[];
  active?: boolean;
  account: string | null;
};

export type UpdateSystemEmailTypeConfig = {
  configId: string;
} & Partial<SaveSystemEmailTypeConfig>;
