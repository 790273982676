import { equal, handleAxiosError, nested } from '@/api/helpers';
import { ListingRequest } from '@/api/types';
import { client } from '@/client';
import {
  Account,
  AccountCourseDelivery,
  AccountFormState,
} from '@/client/accounts';
import { LoadingStateType } from '@/common/constants';
import {
  availableLanguagesDropdown,
  courseOnlyLanguages,
} from '@/common/constants/languages';
import { DialogContext } from '@/common/context';
import { accountSchema } from '@/components/accounts/forms/validations/accounts';
import {
  FormikCheckbox,
  FormikDropdown,
  FormikWrappedSwitch,
} from '@/components/form';
import { FormikChips } from '@/components/form/FormikChips';
import { FormikInput } from '@/components/form/FormikInput';
import { FormikInputNumber } from '@/components/form/FormikInputNumber';
import { FormikLazyDropdown } from '@/components/form/FormikLazyDropdown';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { AppCalendar } from '@/ui/calendar';
import { CheckboxesContainer } from '@/ui/checkboxes-container';
import { FlexContainer, FormContainer } from '@/ui/styled-ui';
import {
  branchAdminCheck,
  courseDeliveryLmsOptions,
  isOutsideOfEfront,
} from '@/utils/helpers';
import { AxiosError } from 'axios';
import { Field, Form, Formik } from 'formik';
import { debounce } from 'lodash';
import moment from 'moment';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { FormEvent } from 'primereact/ts-helpers';
import React, {
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SendHisoricalDataModal } from '../modals';

type AccountFormProps = {
  isCreate: boolean;
  isSystem: boolean;
  initialValues: AccountFormState;
  state?: LoadingStateType;
  disabled?: boolean;
  locked?: boolean;
  accountId?: string;
  onSubmit: (data: AccountFormState) => void;
};

export const AccountForm: React.FC<AccountFormProps> = ({
  isCreate,
  isSystem,
  initialValues,
  state,
  disabled,
  locked,
  accountId,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const account = useAppSelector(selectCurrentAccount);
  const user = useAppSelector(selectCurrentUser);
  const { setDialogData } = useContext(DialogContext);
  const [showLms, setShowLms] = useState<boolean>(false);
  const [showSendHistoricalDataModal, setShowSendHistoricalDataModal] =
    useState(false);
  const [accountsWithSameHubspotId, setAccountsWithSameHubspotId] = useState<
    Account[]
  >([]);

  const isBranchAdmin = branchAdminCheck(user, account);

  useEffect(() => {
    setShowLms(initialValues.courseDelivery !== AccountCourseDelivery.CP_APP);
  }, [initialValues]);

  const fetchResellers = async (params: ListingRequest) =>
    await client.resellers.getResellers(params);

  const handleFetchAccounts = async (value: string) => {
    try {
      const request = await client.accounts.getAccounts({
        filters: [nested('hubspot', [equal('id', value)])],
      });
      setAccountsWithSameHubspotId(request?.result ?? []);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const checkForHubspotIdDuplicates = useMemo(
    () =>
      debounce((value: string) => {
        handleFetchAccounts(value);
      }, 500),
    [],
  );

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={accountSchema(t, isCreate)}
        onSubmit={onSubmit}
        validateOnMount
      >
        {({ values, errors, setFieldValue, setFieldTouched, validateForm }) => {
          return (
            <FormContainer width={100}>
              <Form className="w-full" autoComplete="off">
                <FlexContainer
                  gap={40}
                  width="100%"
                  justify="space-between"
                  align="flex-start"
                  wrap="wrap"
                >
                  <div className="flex-1">
                    <div className="field w-full mb-4">
                      <Field
                        id="name"
                        name="name"
                        label={t('generic.name')}
                        className="w-full"
                        component={FormikInput}
                        placeholder={t('account.accountName')}
                        required={!initialValues.name}
                        disabled={disabled || locked}
                      />
                    </div>
                    {isCreate && !disabled && (
                      <div className="field w-full mb-4">
                        <Field
                          id="hubspotId"
                          name="hubspotId"
                          label={t('hubspot.companyId')}
                          className="w-full"
                          component={FormikInput}
                          placeholder={t('hubspot.companyId')}
                          required={!initialValues.hubspotId && !values.type}
                          disabled={disabled || values.type || locked}
                          onInput={(
                            event: SyntheticEvent<HTMLInputElement>,
                          ) => {
                            const value = (event.target as HTMLInputElement)
                              .value;
                            setFieldValue('hubspotId', value);

                            if (value) {
                              checkForHubspotIdDuplicates(value);
                            } else {
                              setAccountsWithSameHubspotId([]);
                            }
                          }}
                        />

                        {!!accountsWithSameHubspotId?.length && (
                          <>
                            <span className="secondary-text">
                              {t('hubspot.companyId.duplicates.warning')}
                            </span>
                            <strong className="secondary-text">
                              {accountsWithSameHubspotId
                                ?.map(({ name }) => name)
                                .join(', ')}
                            </strong>
                          </>
                        )}
                      </div>
                    )}
                    {isCreate && !disabled && (
                      <div className="field w-full mb-4">
                        <Field
                          id="hubspotDomains"
                          name="hubspotDomains"
                          label={t('hubspot.domains')}
                          className="w-full"
                          component={FormikChips}
                          placeholder={t('hubspot.domains')}
                          required={
                            !initialValues.hubspotDomains?.length &&
                            !values.type &&
                            isCreate
                          }
                          disabled={disabled || values.type || !isCreate}
                        />
                      </div>
                    )}
                    <div className="field w-full mb-4">
                      <Field
                        id="subdomain"
                        name="subdomain"
                        label={t('account.subdomain')}
                        className="w-full"
                        component={FormikInput}
                        disabled={initialValues.subdomain || disabled || locked}
                      />
                    </div>
                    <div className="field w-full mb-4">
                      <Field
                        id="defaultLanguage"
                        label={t('account.defaultLanguage')}
                        name="defaultLanguage"
                        filter
                        onChange={(e: DropdownChangeEvent) =>
                          setFieldValue('defaultLanguage', e.value)
                        }
                        onBlur={() => setFieldTouched('defaultLanguage', true)}
                        className="w-full"
                        component={FormikDropdown}
                        placeholder={t('generic.select')}
                        options={availableLanguagesDropdown.filter(
                          ({ value }) => !courseOnlyLanguages.includes(value),
                        )}
                        disabled={locked}
                      />
                    </div>
                    {!disabled && (
                      <>
                        {!values?.mainAccountOfReseller && (
                          <div className="field w-full mb-4">
                            <Field
                              id="reseller"
                              label={t('reseller')}
                              name="reseller"
                              showClear
                              filter
                              onChange={(e: DropdownChangeEvent) =>
                                setFieldValue('reseller', e.value)
                              }
                              onBlur={() => setFieldTouched('reseller', true)}
                              optionLabel="name"
                              className="w-full"
                              component={FormikLazyDropdown}
                              placeholder={t('generic.select')}
                              fetchService={fetchResellers}
                              disabled={disabled || locked}
                            />
                          </div>
                        )}
                        {!!values?.mainAccountOfReseller && (
                          <div className="field w-full mb-4">
                            <label htmlFor="mainAccountOfReseller">
                              {t('reseller.mainAccount.forReseller')}
                            </label>
                            <InputText
                              value={
                                values.mainAccountOfReseller?.name ??
                                values.mainAccountOfReseller?.id
                              }
                              id="mainAccountOfReseller"
                              name="mainAccountOfReseller"
                              className="w-full"
                              disabled={true}
                            />
                          </div>
                        )}
                        <CheckboxesContainer
                          label={t('generic.status')}
                          className="toggle-container mb-4"
                        >
                          <Field
                            inputId="active"
                            name="active"
                            label={t('account.setActive')}
                            component={FormikWrappedSwitch}
                            disabled={disabled || locked}
                          />
                        </CheckboxesContainer>
                      </>
                    )}
                  </div>
                  <div className="flex-1">
                    {!disabled && isOutsideOfEfront() && (
                      <>
                        <CheckboxesContainer label={t('account.type')}>
                          <div className="field-checkbox mb-0">
                            <Field
                              inputId="type"
                              name="type"
                              label={t('account.mark.test')}
                              component={FormikCheckbox}
                              disabled={disabled || locked}
                            />
                          </div>
                          <div className="field-checkbox mb-0">
                            <Field
                              inputId="isPhishingOnly"
                              name="isPhishingOnly"
                              label={t('account.mark.phishingOnly')}
                              component={FormikCheckbox}
                              disabled={disabled || locked}
                              tooltip={t('account.mark.phishingOnly.tooltip')}
                              tooltipId="isPhishingOnly-info"
                            />
                          </div>
                          <div className="field-checkbox mb-0">
                            <Field
                              inputId="freeTrialCheck"
                              name="freeTrialCheck"
                              label={t('account.set.freeTrial')}
                              onChange={async (e: CheckboxChangeEvent) => {
                                setFieldValue('freeTrialCheck', e.checked);
                                if (!e.checked) {
                                  setFieldValue('freeTrialCheck', false);
                                  setFieldValue('freeTrialEndsAt', null);
                                }

                                setTimeout(async () => {
                                  await validateForm();
                                }, 0);
                              }}
                              component={FormikCheckbox}
                              disabled={disabled || locked}
                            />
                          </div>
                          {values.freeTrialCheck && (
                            <div className="field without-spacing">
                              <label htmlFor="freeTrialEndsAt">
                                {t('account.freeTrial.endDate')}
                              </label>
                              <AppCalendar
                                disabled={disabled || locked}
                                id="free-trial-ends-at"
                                name="freeTrialEndsAt"
                                className="w-full white-input"
                                showIcon
                                iconPos="left"
                                minDate={new Date()}
                                placeholder={t(
                                  'account.select.freeTrial.endDate',
                                )}
                                value={
                                  values.freeTrialEndsAt
                                    ? moment(values.freeTrialEndsAt?.toString())
                                        .utc(true)
                                        .toDate()
                                    : null
                                }
                                onChange={(event: FormEvent) =>
                                  setFieldValue(
                                    'freeTrialEndsAt',
                                    moment(event.value?.toString())
                                      .utc(true)
                                      .toDate(),
                                  )
                                }
                                showButtonBar
                                onClearButtonClick={() => {
                                  setFieldValue('freeTrialEndsAt', null);
                                }}
                              />
                              <br />
                              {!values.freeTrialEndsAt && (
                                <small className="p-error">
                                  {t('account.error.select.freeTrial.endDate')}
                                </small>
                              )}
                            </div>
                          )}
                          <div className="field-checkbox mb-0">
                            <Checkbox
                              checked={showLms}
                              name="showLms"
                              onChange={(e) => {
                                setShowLms(!!e.checked);
                                if (!e.checked) {
                                  setFieldValue(
                                    'courseDelivery',
                                    AccountCourseDelivery.CP_APP,
                                  );
                                }
                              }}
                              disabled={disabled || locked}
                            />
                            <label htmlFor="courseDelivery">
                              {t('account.set.courseDelivery')}
                            </label>
                          </div>
                          {!disabled && showLms && (
                            <div className="field w-8 mb-0">
                              <Field
                                id="courseDelivery"
                                label={t('account.courseDelivery')}
                                name="courseDelivery"
                                filter
                                onBlur={() =>
                                  setFieldTouched('courseDelivery', true)
                                }
                                className="w-full white"
                                component={FormikDropdown}
                                placeholder={t('generic.select')}
                                options={courseDeliveryLmsOptions(t)}
                                disabled={disabled || locked}
                              />
                            </div>
                          )}
                        </CheckboxesContainer>
                      </>
                    )}
                    <CheckboxesContainer
                      label={t('account.courseSettings')}
                      tooltip={
                        !disabled
                          ? t('account.courseSettings.tooltip')
                          : undefined
                      }
                      tooltipId="course-settings"
                    >
                      {!disabled && isOutsideOfEfront() && (
                        <>
                          <div className="field-checkbox mb-0">
                            <Field
                              inputId="autoCourseAvailability"
                              name="autoCourseAvailability"
                              label={t('account.autoCourseAvailability')}
                              component={FormikCheckbox}
                              disabled={disabled || locked}
                            />
                          </div>
                          <div className="field-checkbox mb-0">
                            <Field
                              inputId="autoCourseEnrollment"
                              name="autoCourseEnrollment"
                              label={t('account.autoCourseEnrollment')}
                              component={FormikCheckbox}
                              disabled={disabled || locked}
                            />
                          </div>
                        </>
                      )}
                      <div className="field-checkbox mb-0">
                        <Field
                          inputId="courseDueDaysCheck"
                          name="courseDueDaysCheck"
                          label={t('account.set.courseDueDays')}
                          onChange={async (e: CheckboxChangeEvent) => {
                            if (e.checked) {
                              setDialogData({
                                show: true,
                                type: 'confirmation',
                                header: t('account.set.courseDueDays'),
                                message: t(
                                  'dialog.course.dueDate.confirmation',
                                ),
                                onAccept: () => {
                                  setFieldValue(
                                    'courseDueDaysCheck',
                                    e.checked,
                                  );
                                  setFieldValue('courseDueDays', 14);
                                },
                              });
                            } else {
                              setFieldValue('courseDueDaysCheck', false);
                              setFieldValue('courseDueDays', null);
                            }

                            setTimeout(async () => {
                              await validateForm();
                            }, 0);
                          }}
                          component={FormikCheckbox}
                          disabled={isBranchAdmin || locked}
                        />
                      </div>
                      {values.courseDueDaysCheck && (
                        <>
                          <div className="field mb-0">
                            <Field
                              id="courseDueDays"
                              label={t('account.courseDueDays')}
                              name="courseDueDays"
                              className="w-full white"
                              component={FormikInputNumber}
                              required
                              placeholder={t('account.input.courseDueDays')}
                              min={1}
                              step={1}
                              allowEmpty={true}
                              onChange={(e: InputNumberChangeEvent) =>
                                setFieldValue('courseDueDays', e.value, true)
                              }
                              showButtons
                              disabled={isBranchAdmin || locked}
                            />
                          </div>
                          <div className="field-checkbox mb-0">
                            <Field
                              inputId="notifyManagerForOverdueCourses"
                              name="notifyManagerForOverdueCourses"
                              label={t(
                                'account.enable.notifyManagerForOverdueCourses',
                              )}
                              component={FormikCheckbox}
                              disabled={isBranchAdmin || locked}
                            />
                          </div>
                        </>
                      )}
                    </CheckboxesContainer>
                    {!disabled && isOutsideOfEfront() && (
                      <>
                        <CheckboxesContainer
                          label={t('account.other.settings')}
                        >
                          <div className="field-checkbox mb-0">
                            <Field
                              inputId="isTrackingEnabled"
                              name="isTrackingEnabled"
                              label={t('account.isTrackingEnabled')}
                              component={FormikCheckbox}
                              disabled={disabled || locked}
                            />
                            <AppButton
                              icon="pi pi-refresh"
                              size="sm"
                              type="text"
                              className="ml-1 p-0 h-auto"
                              ariaLabel={t('account.send.historicalData')}
                              tooltip={t('account.send.historicalData')}
                              tooltipOptions={{ position: 'top' }}
                              isDisabled={
                                isCreate ||
                                !initialValues.isTrackingEnabled ||
                                !values.isTrackingEnabled ||
                                locked
                              }
                              onClick={() =>
                                setShowSendHistoricalDataModal(true)
                              }
                            />
                          </div>
                          <div className="field-checkbox mb-0">
                            <Field
                              inputId="showCampaignOpenEvents"
                              name="showCampaignOpenEvents"
                              label={t('account.show.emailsOpened')}
                              component={FormikCheckbox}
                              disabled={disabled || locked}
                            />
                          </div>
                          <div className="field-checkbox mb-0">
                            <Field
                              inputId="enableCoursePlanner"
                              name="enableCoursePlanner"
                              label={t('account.enable.trainingPlanner')}
                              component={FormikCheckbox}
                              disabled={
                                disabled ||
                                locked ||
                                (!!initialValues?.enableCoursePlanner &&
                                  !isCreate)
                              }
                              tooltip={t(
                                'account.enabled.trainingPlanner.tooltip',
                              )}
                              tooltipId="trainingPlanner-info"
                            />
                          </div>
                          {!isSystem && (
                            <>
                              <div className="field-checkbox mb-0">
                                <Field
                                  inputId="enableMaterialsUpload"
                                  name="enableMaterialsUpload"
                                  label={t(
                                    'account.enable.enableMaterialsUpload',
                                  )}
                                  component={FormikCheckbox}
                                  disabled={disabled || locked}
                                />
                              </div>
                              <div className="field-checkbox mb-0">
                                <Field
                                  inputId="isSecurityCultureEnabled"
                                  name="isSecurityCultureEnabled"
                                  label={t('account.enable.securityCulture')}
                                  component={FormikCheckbox}
                                  disabled={disabled || locked}
                                />
                              </div>
                            </>
                          )}
                          <div className="field-checkbox mb-0">
                            <Field
                              inputId="isPhishingTaggingEnabled"
                              name="isPhishingTaggingEnabled"
                              label={t('account.enable.phishingTagging')}
                              component={FormikCheckbox}
                              disabled={disabled || locked}
                            />
                          </div>
                          <div className="field-checkbox mb-0">
                            <Field
                              inputId="isNewPhishingSelectorEnabled"
                              name="isNewPhishingSelectorEnabled"
                              label={t(
                                'account.enable.newPhishingSelectorEnabled',
                              )}
                              component={FormikCheckbox}
                              disabled={disabled || locked}
                            />
                          </div>
                        </CheckboxesContainer>
                      </>
                    )}
                  </div>
                </FlexContainer>
                {!locked && (
                  <AppButton
                    isSubmit
                    severity="secondary"
                    label={
                      initialValues.name ? t('button.save') : t('button.create')
                    }
                    className="w-2 min-w-min mt-2 mb-5"
                    state={state}
                    isDisabled={!!Object.keys(errors).length}
                  />
                )}
              </Form>
            </FormContainer>
          );
        }}
      </Formik>
      <SendHisoricalDataModal
        visible={showSendHistoricalDataModal}
        accountId={accountId}
        onHide={() => setShowSendHistoricalDataModal(false)}
      />
    </>
  );
};
