import { equal } from '@/api/helpers';
import { FiltersType, ListingRequest } from '@/api/types';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { AppContext } from '@/common/context/AppContext';
import { useAccounts } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentUser } from '@/store/features/users';
import React, { HTMLAttributes, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LazyLoadingSelect } from '../generic/LazyLoadingSelect';

const StyledSystemLink = styled.div`
  cursor: pointer;
  color: var(--red-main);
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  padding: var(--medium-padding) var(--small-padding);
  border-top: 1px solid var(--gray-pale);

  &:hover {
    color: var(--red-dark);
  }
`;

const StyledLazyLoadingSelect = styled(LazyLoadingSelect)`
  &.p-dropdown.is-purple {
    border: none;
    background-color: var(--purple-middle);
    border-radius: var(--default-border-radius);
    transition: none;

    &:not(.p-disabled).p-focus,
    &:not(.p-disabled):hover {
      background-color: var(--red-dark);
      box-shadow: 0 0 0 1px var(--red-dark);

      span,
      button {
        background-color: var(--red-dark);
      }
    }

    span,
    button {
      color: var(--white-main) !important;
      background-color: var(--purple-middle);
      transition: none;
    }

    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
      > .p-menuitem-content {
      background: transparent;
    }
  }
`;

type AccountSelectInputProps = {
  defaultValue: Account | null;
  onSelect: (value: Account | undefined) => void;
  forceRefetch?: boolean;
  additionalFilters?: FiltersType;
  isPurple?: boolean;
  isDisabled?: boolean;
  className?: string;
} & Omit<HTMLAttributes<HTMLInputElement>, 'onSelect' | 'defaultValue'>;

export const AccountSelectInput: React.FC<AccountSelectInputProps> = ({
  defaultValue,
  onSelect,
  forceRefetch,
  additionalFilters,
  isDisabled,
  isPurple,
  className,
}) => {
  const { t } = useTranslation();
  const currentUser = useAppSelector(selectCurrentUser);
  const { state, clearTrigger } = useContext(AppContext);
  const [refetchKey, setRefetchKey] = useState<number>(0);
  const [selectedAccount, setSelectedAccount] = useState<
    Account | null | undefined
  >(defaultValue);

  const fetchAccounts = isDisabled
    ? undefined
    : async (params: ListingRequest) =>
        await client.accounts.getAccounts({ ...params, sort: ['name,asc'] });

  useEffect(() => {
    if (!forceRefetch && !state.isAccountsLengthChanged) {
      return;
    }

    setRefetchKey(refetchKey + 1);

    if (state.isAccountsLengthChanged && clearTrigger) {
      clearTrigger();
    }
  }, [forceRefetch, state.isAccountsLengthChanged]);

  useEffect(() => {
    setSelectedAccount(defaultValue);
  }, [defaultValue]);

  const { accounts } = useAccounts({
    filters: [equal('isSystem', true)],
  });

  const hasGoToSystem =
    isPurple && currentUser?.account?.isSystem && !selectedAccount?.isSystem;

  const onJumpToSystemClick = () => {
    const systemAccount = accounts?.result[0];

    if (systemAccount) {
      onSelect(systemAccount);
      setSelectedAccount(systemAccount);
    }
  };

  const footerTemplate = (
    <StyledSystemLink onClick={onJumpToSystemClick}>
      {t('cp.jumpToSystem')}
    </StyledSystemLink>
  );

  return (
    <StyledLazyLoadingSelect
      fetchService={fetchAccounts}
      refetchKey={refetchKey}
      additionalFilters={additionalFilters}
      isDisabled={isDisabled}
      className={
        className ? className : isPurple ? 'w-full is-purple' : 'w-full'
      }
      selectedValue={selectedAccount}
      optionLabel="name"
      filter
      placeholder={t('account.select')}
      filterPlaceholder={t('accounts.search')}
      handleOnChange={(account: Account) => {
        onSelect(account);
        setSelectedAccount(account);
      }}
      panelFooterTemplate={hasGoToSystem ? footerTemplate : null}
    />
  );
};
