import { RedirectPageTemplateFormState } from '@/client/page-templates';
import { FormikInput, FormikSwitch } from '@/components/form';
import { redirectPageTemplateSchema } from '@/components/redirect-page-templates/forms/validations/redirect-page-template';
import { FlexContainer } from '@/ui/styled-ui';
import { Field, Form, Formik, FormikValues } from 'formik';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type RedirectPageTemplateInformationFormProps = {
  initialValues: RedirectPageTemplateFormState;
  onSubmit: (data: FormikValues) => void;
  onInputChange: (values: RedirectPageTemplateFormState) => void;
  onValidateForm: (isValid: boolean) => void;
  isSystem?: boolean;
};

export const RedirectPageTemplateInformationForm: React.FC<
  RedirectPageTemplateInformationFormProps
> = ({ initialValues, onSubmit, onInputChange, onValidateForm, isSystem }) => {
  const { t } = useTranslation();
  const [isValidState, setIsValidState] = useState(false);

  useEffect(() => {
    // Comunicate the validity state to the wrapper component
    onValidateForm(isValidState);
  }, [isValidState]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={redirectPageTemplateSchema(t)}
      onSubmit={onSubmit}
      validateOnMount
    >
      {({ values, isValid }) => {
        useEffect(() => {
          setIsValidState(isValid);
        }, [isValid]);

        useEffect(() => {
          onInputChange(values);
        }, [values]);

        return (
          <FlexContainer
            justify="flex-start"
            align="flex-start"
            direction="column"
          >
            <Form className="w-full">
              <div className="field w-6 mb-4">
                <Field
                  id="name"
                  name="name"
                  label={t('generic.name')}
                  placeholder={t('templates.nameOf')}
                  className="w-full"
                  component={FormikInput}
                  required
                />
              </div>
              {isSystem && (
                <div className="field-checkbox">
                  <Field
                    inputId="active"
                    name="active"
                    label={t('templates.set.active')}
                    component={FormikSwitch}
                  />
                </div>
              )}
              {values.meta?.cloneOf && (
                <>
                  <h3 className="mb-4">{t('generic.cloneOf')}</h3>

                  <div className="field w-6">
                    <label htmlFor="cloneOf">
                      {t('templates.redirectTemplate')}
                    </label>
                    <InputText
                      id="cloneOf"
                      name="cloneOf"
                      value={values.meta?.cloneOf}
                      className="w-full"
                      disabled
                    />
                  </div>

                  <div className="field w-6">
                    <label htmlFor="sourceCampaignId">{t('campaign')}</label>
                    <InputText
                      id="sourceCampaignId"
                      name="sourceCampaignId"
                      value={values.meta?.sourceCampaignId}
                      className="w-full"
                      disabled
                    />
                  </div>
                </>
              )}
            </Form>
          </FlexContainer>
        );
      }}
    </Formik>
  );
};
