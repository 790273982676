import { AccountSMTPSettings } from '@/client/accounts';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { SystemEmailTemplate } from '@/client/system-emails/types';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

export const systemEmailSendingConfigService = (client: AxiosInstance) => {
  const updateSystemSendingConfig = async (
    request: AccountSMTPSettings,
    accountId: string,
  ): Promise<AccountSMTPSettings> => {
    try {
      const result = await client.patch<
        SystemEmailTemplate,
        AxiosResponse<AccountSMTPSettings>
      >(`system-email-sending-config/${accountId}`, request, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  return { updateSystemSendingConfig };
};
