import { Account } from '@/client/accounts';
import {
  CourseAvailabilityOptionsEnum,
  CourseEnrollmentOptionsEnum,
} from '@/client/courses';
import React, { useEffect } from 'react';

type AccountsAutoSelectHelperProps = {
  accountsType: CourseAvailabilityOptionsEnum | CourseEnrollmentOptionsEnum;
  autoSelectedAccounts: Account[] | undefined;
  onSetAutoTriggeredSelection: (value: boolean) => void;
  onSetFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
};

export const AccountsAutoSelectHelper: React.FC<
  AccountsAutoSelectHelperProps
> = ({
  accountsType,
  autoSelectedAccounts,
  onSetAutoTriggeredSelection,
  onSetFieldValue,
}) => {
  useEffect(() => {
    if (
      accountsType === CourseAvailabilityOptionsEnum.AUTO ||
      accountsType === CourseEnrollmentOptionsEnum.AUTO
    ) {
      onSetAutoTriggeredSelection(true);
      onSetFieldValue('accounts', autoSelectedAccounts);
    }
  }, [accountsType]);

  return null;
};
