import { t } from 'i18next';

export class ConflictException extends Error {
  public readonly statusCode: number = 409;

  public constructor(
    public message: string = t('error.conflict'),
    public readonly errors?: string[],
  ) {
    super(message);
  }
}
