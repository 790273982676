import {
  BadRequestException,
  ConflictException,
  ForbiddenException,
  InternalServerErrorException,
  NotFoundException,
  UnauthorizedException,
  UnprocessableEntityException,
} from '@/api/exceptions';
import { AxiosError } from 'axios';

export const getExceptionFromAxiosError = (
  error: AxiosError<any, any>,
): Error => {
  switch (error.response?.status) {
    case 400:
      return new BadRequestException(
        error.response?.data.error,
        error.response?.data.message,
      );
    case 401:
      return new UnauthorizedException(error.response?.data.error);
    case 403:
      return new ForbiddenException(error.response?.data.error);
    case 404:
      return new NotFoundException(error.response?.data.error);
    case 409:
      return new ConflictException(error.response?.data.message);
    case 422:
      return new UnprocessableEntityException(
        error.response?.data.error,
        error.response?.data.message,
      );
    case 500:
      return new InternalServerErrorException(error.response?.data.error);
    default:
      return new Error(error.message);
  }
};
