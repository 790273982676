import { FlexContainer } from '@/ui/styled-ui';
import React from 'react';
import styled from 'styled-components';
import {
  CopyToClipboardButton,
  CopyToClipboardButtonProps,
} from './CopyToClipboardButton';

const StyledContainer = styled(FlexContainer)`
  white-space: nowrap;
  overflow-y: auto;
  padding-bottom: 2px;
  margin: var(--small-padding) 0;
`;

type ClipboardContainerProps = {
  clipboardItems: CopyToClipboardButtonProps[];
};

export const ClipboardContainer: React.FC<ClipboardContainerProps> = ({
  clipboardItems,
}) => (
  <StyledContainer gap={10} justify="flex-start">
    {clipboardItems.map((item) => (
      <CopyToClipboardButton
        key={item.clipboardText}
        clipboardText={item.clipboardText}
        clipboardValue={item.clipboardValue}
      />
    ))}
  </StyledContainer>
);
