// Font related
export let platformFontFamily: string;
export let iconFontFamily: string;
export let headingXLargeFontSize: string;
export let headingLargeFontSize: string;
export let headingLargeLineHeight: string;
export let headingMediumFontSize: string;
export let headingMediumLineHeight: string;
export let headingSmallFontSize: string;
export let headingSmallLineHeight: string;
export let bodyFontSize: string;
export let bodyLineHeight: string;
export let mediumFontSize: string;
export let mediumLineHeight: string;
export let smallFontSize: string;
export let smallLineHeight: string;
export let xSmallFontSize: string;
export let xSmallLineHeight: string;
export let xxSmallFontSize: string;

// Colors
export let colorBlackMain: string;
export let colorWhiteMain: string;
export let colorBeigeMain: string;
export let colorGrayPale: string;
export let colorGrayDark: string;
export let colorGrayOpacity: string;
export let colorGrayDarker: string;
export let colorPurpleLight: string;
export let colorPurpleMiddle: string;
export let colorPurpleDark: string;
export let colorPurpleHighlight: string;
export let colorRedPale: string;
export let colorRedLight: string;
export let colorRedMain: string;
export let colorRedMiddle: string;
export let colorRedDark: string;
export let colorRedOpacity: string;
export let colorGreenPale: string;
export let colorGreenMain: string;
export let colorGreenDark: string;
export let colorOrangePale: string;
export let colorOrangeMain: string;
export let avatarBackground: string;
export let avatarText: string;

// Box Shadows
export let boxShadowRed: string;
export let boxShadowRedInset: string;
export let boxShadowRedNoBlur: string;
export let boxShadowBlack: string;

// Paddings
export let bigPadding: string;
export let defaultPadding: string;
export let mediumPadding: string;
export let smallPadding: string;
export let xSmallPadding: string;
export let xxSmallPadding: string;

// Borders
export let bigBorderRadius: string;
export let defaultBorderRadius: string;
export let mediumBorderRadius: string;
export let smallBorderRadius: string;
export let xSmallBorderRadius: string;
export let xxSmallBorderRadius: string;
export let xxxSmallBorderRadius: string;

// Components
export let topNavHeight: string;

// Buttons
export let defaultInputHeight: string;
export let smallButtonHeight: string;
export let mediumButtonHeight: string;
export let defaultTextareaHeight: string;

// Widths
export let maxDashboardWidth: string;

// Initialize function to set values
export const initializeCssVariables = () => {
  const docStyle = getComputedStyle(document.documentElement);

  // Assign CSS variables to the exported variables
  platformFontFamily = docStyle.getPropertyValue('--font-family');
  iconFontFamily = docStyle.getPropertyValue('--icon-font-family');
  headingXLargeFontSize = docStyle.getPropertyValue(
    '--heading-xlarge-font-size',
  );
  headingLargeFontSize = docStyle.getPropertyValue('--heading-large-font-size');
  headingLargeLineHeight = docStyle.getPropertyValue(
    '--heading-large-line-height',
  );
  headingMediumFontSize = docStyle.getPropertyValue(
    '--heading-medium-font-size',
  );
  headingMediumLineHeight = docStyle.getPropertyValue(
    '--heading-medium-line-height',
  );
  headingSmallFontSize = docStyle.getPropertyValue('--heading-small-font-size');
  headingSmallLineHeight = docStyle.getPropertyValue(
    '--heading-small-line-height',
  );
  bodyFontSize = docStyle.getPropertyValue('--body-font-size');
  bodyLineHeight = docStyle.getPropertyValue('--body-line-height');
  mediumFontSize = docStyle.getPropertyValue('--medium-font-size');
  mediumLineHeight = docStyle.getPropertyValue('--medium-line-height');
  smallFontSize = docStyle.getPropertyValue('--small-font-size');
  smallLineHeight = docStyle.getPropertyValue('--small-line-height');
  xSmallFontSize = docStyle.getPropertyValue('--xsmall-font-size');
  xSmallLineHeight = docStyle.getPropertyValue('--xsmall-line-height');
  xxSmallFontSize = docStyle.getPropertyValue('--xxsmall-font-size');

  // Colors
  colorBlackMain = docStyle.getPropertyValue('--black-main');
  colorWhiteMain = docStyle.getPropertyValue('--white-main');
  colorBeigeMain = docStyle.getPropertyValue('--beige-main');
  colorGrayPale = docStyle.getPropertyValue('--gray-pale');
  colorGrayDark = docStyle.getPropertyValue('--gray-dark');
  colorGrayOpacity = docStyle.getPropertyValue('--gray-opacity');
  colorGrayDarker = docStyle.getPropertyValue('--gray-darker');
  colorPurpleLight = docStyle.getPropertyValue('--purple-light');
  colorPurpleMiddle = docStyle.getPropertyValue('--purple-middle');
  colorPurpleDark = docStyle.getPropertyValue('--purple-dark');
  colorPurpleHighlight = docStyle.getPropertyValue('--purple-highlight');
  colorRedPale = docStyle.getPropertyValue('--red-pale');
  colorRedLight = docStyle.getPropertyValue('--red-light');
  colorRedMain = docStyle.getPropertyValue('--red-main');
  colorRedMiddle = docStyle.getPropertyValue('--red-middle');
  colorRedDark = docStyle.getPropertyValue('--red-dark');
  colorRedOpacity = docStyle.getPropertyValue('--red-opacity');
  colorGreenPale = docStyle.getPropertyValue('--green-pale');
  colorGreenMain = docStyle.getPropertyValue('--green-main');
  colorGreenDark = docStyle.getPropertyValue('--green-dark');
  colorOrangePale = docStyle.getPropertyValue('--orange-pale');
  colorOrangeMain = docStyle.getPropertyValue('--orange-main');
  avatarBackground = docStyle.getPropertyValue('--avatar-background');
  avatarText = docStyle.getPropertyValue('--avatar-text');

  // Box Shadows
  boxShadowRed = docStyle.getPropertyValue('--box-shadow-red');
  boxShadowRedInset = docStyle.getPropertyValue('--box-shadow-red-inset');
  boxShadowRedNoBlur = docStyle.getPropertyValue('--box-shadow-red-no-blur');
  boxShadowBlack = docStyle.getPropertyValue('--box-shadow-black');

  // Paddings
  bigPadding = docStyle.getPropertyValue('--big-padding');
  defaultPadding = docStyle.getPropertyValue('--default-padding');
  mediumPadding = docStyle.getPropertyValue('--medium-padding');
  smallPadding = docStyle.getPropertyValue('--small-padding');
  xSmallPadding = docStyle.getPropertyValue('--xsmall-padding');
  xxSmallPadding = docStyle.getPropertyValue('--xxsmall-padding');

  // Borders
  bigBorderRadius = docStyle.getPropertyValue('--big-border-radius');
  defaultBorderRadius = docStyle.getPropertyValue('--default-border-radius');
  mediumBorderRadius = docStyle.getPropertyValue('--medium-border-radius');
  smallBorderRadius = docStyle.getPropertyValue('--small-border-radius');
  xSmallBorderRadius = docStyle.getPropertyValue('--xsmall-border-radius');
  xxSmallBorderRadius = docStyle.getPropertyValue('--xxsmall-border-radius');
  xxxSmallBorderRadius = docStyle.getPropertyValue('--xxxsmall-border-radius');

  // Components
  topNavHeight = docStyle.getPropertyValue('--top-nav-height');

  // Buttons
  defaultInputHeight = docStyle.getPropertyValue('--default-input-height');
  smallButtonHeight = docStyle.getPropertyValue('--small-button-height');
  mediumButtonHeight = docStyle.getPropertyValue('--medium-button-height');
  defaultTextareaHeight = docStyle.getPropertyValue(
    '--default-textarea-height',
  );

  // Widths
  maxDashboardWidth = docStyle.getPropertyValue('--max-dashboard-width');
};

// Initialize once the document is loaded
window.onload = initializeCssVariables;
