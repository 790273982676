import { SystemEmailsConfigFormValues } from '@/client/system-emails';
import {
  FormikDropdown,
  FormikMultiSelect,
  FormikSwitch,
} from '@/components/form';
import { systemEmailsConfigSchema } from '@/system-settings';
import { AppButton } from '@/ui/buttons';
import { FormContainer } from '@/ui/styled-ui';
import { emailSendTimeOptions, emailTypeOptions } from '@/utils/helpers';
import { Field, Form, Formik } from 'formik';
import { DropdownChangeEvent } from 'primereact/dropdown';
import React from 'react';
import { useTranslation } from 'react-i18next';

type SystemEmailsConfigureEmailFormProps = {
  initialValues: SystemEmailsConfigFormValues;
  onSubmit: (data: SystemEmailsConfigFormValues) => Promise<void>;
  onCancel: () => void;
};

export const SystemEmailsConfigureEmailForm: React.FC<
  SystemEmailsConfigureEmailFormProps
> = ({ initialValues, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={systemEmailsConfigSchema(t)}
      validateOnMount
    >
      {({ values, setFieldValue }) => (
        <FormContainer>
          <Form className="w-full" autoComplete="off">
            <div className="field">
              <Field
                id="type"
                name="type"
                label={t('generic.type')}
                className="w-full"
                component={FormikDropdown}
                placeholder={t('system.email.templates.emailType.placeholder')}
                onChange={(e: DropdownChangeEvent) =>
                  setFieldValue('type', e.value)
                }
                options={emailTypeOptions(t)}
                required
              />
            </div>
            <div className="field">
              <Field
                id="sendTime"
                name="sendTime"
                label={t('generic.sendTime')}
                className="w-full"
                display="chip"
                placeholder={t('system.email.templates.sendTime.placeholder')}
                value={values.sendTime}
                onChange={(event: DropdownChangeEvent) =>
                  setFieldValue('sendTime', event.value)
                }
                component={FormikMultiSelect}
                options={emailSendTimeOptions(t)}
                required
              />
            </div>
            <div className="field-checkbox mt-3">
              <Field
                inputId="active"
                name="active"
                label={t('generic.active')}
                component={FormikSwitch}
              />
            </div>
            <div className="mt-6 mb-5">
              <AppButton
                className="mr-3"
                label={t('button.cancel')}
                type="outlined"
                onClick={onCancel}
              />
              <AppButton
                isSubmit
                label={t('button.save')}
                severity="secondary"
              />
            </div>
          </Form>
        </FormContainer>
      )}
    </Formik>
  );
};
