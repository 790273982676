import { ForceCompleteFormValues } from '@/client/courses';
import { FormikInputNumber } from '@/components/form/FormikInputNumber';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { Field, Form, Formik } from 'formik';
import { Dialog, DialogProps } from 'primereact/dialog';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { forceCompleteCourseSchema } from '../forms/validations';

const StyledDialog = styled(Dialog)`
  width: 500px;
`;

type ForceCompleteModalProps = {
  onSubmit: (data: ForceCompleteFormValues) => void;
  userName?: string;
  courseName?: string;
} & DialogProps;

export const ForceCompleteModal: React.FC<ForceCompleteModalProps> = ({
  visible,
  onSubmit,
  onHide,
  courseName,
  userName,
}) => {
  const { t } = useTranslation();
  const [initialValues] = useState<ForceCompleteFormValues>({
    score: 0,
  });

  return (
    <StyledDialog
      blockScroll
      visible={visible}
      header={<h1>{t('course.forceComplete')}</h1>}
      onHide={onHide}
      draggable={false}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={forceCompleteCourseSchema(t)}
        validateOnMount
        onSubmit={onSubmit}
      >
        {({ errors, setFieldValue }) => {
          return (
            <Form>
              <div className="mb-4">
                {t('course.completeMessage.courseAndName', {
                  userName,
                  userCourse: courseName,
                })}
              </div>
              <div className="field mb-4">
                <Field
                  id="score"
                  label={t('reports.score')}
                  name="score"
                  className="w-full"
                  component={FormikInputNumber}
                  required
                  min={0}
                  max={100}
                  allowEmpty={false}
                  onChange={(e: InputNumberChangeEvent) =>
                    setFieldValue('score', e.value, true)
                  }
                  showButtons
                  step={10}
                />
              </div>
              <FlexContainer justify="flex-end" className="mt-5">
                <AppButton
                  label={t('button.cancel')}
                  severity="secondary"
                  type="outlined"
                  onClick={onHide}
                  className="mr-3"
                />
                <AppButton
                  label={t('generic.complete')}
                  isSubmit
                  isDisabled={!!Object.keys(errors).length}
                />
              </FlexContainer>
            </Form>
          );
        }}
      </Formik>
    </StyledDialog>
  );
};
