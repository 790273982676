import { CourseSchedulePeriodUnit } from '@/client/planner/types';
import { TranslationFunctionType } from '@/common/types';
import { t } from 'i18next';
import moment from 'moment';
import { singularOrPluralTranslation } from './string.helper';

export const checkIfNowOrAfter = (date?: Date) => {
  if (!date) return false;

  const now = moment(new Date()).seconds(0).milliseconds(0);
  const date1 = moment(date).seconds(0).milliseconds(0);

  return date1.isSameOrAfter(now);
};

export const checkIfDateIsPastDate = (date?: Date, dateToCompare?: Date) => {
  if (!date || !dateToCompare) return false;

  const date1 = moment(date).seconds(0).milliseconds(0);
  const date2 = moment(dateToCompare).seconds(0).milliseconds(0);

  return date1.isAfter(date2);
};

export const checkIfDatesAreEqual = (date?: Date, dateToCompare?: Date) => {
  if (!date || !dateToCompare) return false;

  const date1 = moment(date).seconds(0).milliseconds(0);
  const date2 = moment(dateToCompare).seconds(0).milliseconds(0);

  return date1.isSame(date2);
};

export const convertMinutesIntoHours = (
  minutes: number,
  t: TranslationFunctionType,
) => {
  // If num is less than 60, return as is with 'min'
  if (minutes < 60) {
    return `${minutes} ${t('generic.minutes')}`;
  }
  // If num is equal or more than 60 but less than 1440 (24 hours), convert to hours and minutes
  else if (minutes >= 60 && minutes < 1440) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours} ${t('generic.hours')} ${mins} ${t('generic.minutes')}`;
  }
  // If num is equal or more than 1440 (24 hours), convert to days, hours, and minutes
  else {
    const days = Math.floor(minutes / 1440);
    const remainder = minutes % 1440;
    const hours = Math.floor(remainder / 60);
    const mins = remainder % 60;
    return `${days} ${t('generic.days')} ${hours} ${t(
      'generic.hours',
    )} ${mins} ${t('generic.minutes')}`;
  }
};

export const calculateDayDifferenceFromToday = (date: Date) => {
  const scheduleDate = moment(date).utc(true);
  const currentDate = moment().utc(true).hours(0);

  return scheduleDate.diff(currentDate, 'days');
};

export const parsePeriodStringToUserReadable = (
  period = 0,
  type?: CourseSchedulePeriodUnit,
) => {
  switch (type) {
    case CourseSchedulePeriodUnit.DAYS:
      return `${period} ${singularOrPluralTranslation(
        period,
        t('day'),
        t('days'),
      )}`;
    case CourseSchedulePeriodUnit.WEEKS:
      return `${period} ${singularOrPluralTranslation(
        period,
        t('week'),
        t('weeks'),
      )}`;
    case CourseSchedulePeriodUnit.MONTHS:
      return `${period} ${singularOrPluralTranslation(
        period,
        t('month'),
        t('months'),
      )}`;
    default:
      return `0 ${t('days')}`;
  }
};

export const convertPeriodStringToDays = (
  period = 0,
  type?: CourseSchedulePeriodUnit,
): number => {
  switch (type) {
    case CourseSchedulePeriodUnit.DAYS:
      return period;
    case CourseSchedulePeriodUnit.WEEKS:
      return period * 7;
    case CourseSchedulePeriodUnit.MONTHS:
      return period * 30;
    default:
      return 0;
  }
};
