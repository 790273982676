import {
  Campaign,
  CreateCampaignFromTemplateFormState,
  ListCampaignsResponse,
} from '@/client/campaigns';
import { ThumbnailState } from '@/client/thumbnails/enums';
import { ImageCard } from '@/ui/image-card';
import { CardGridContainer } from '@/ui/styled-ui';
import { displaySenderProfile } from '@/utils/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { QueryObserverResult } from 'react-query';
import styled from 'styled-components';
import { ThumbnailsGenerationNotify } from '../thumbnails';

const StyledCardGridContainer = styled(CardGridContainer)`
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  margin: 0;

  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

type CampaignTemplatesListPropsType = {
  values: CreateCampaignFromTemplateFormState;
  setFieldValue: (field: string, value: any) => void;
  campaigns: ListCampaignsResponse;
  refetch: () => Promise<QueryObserverResult<ListCampaignsResponse, unknown>>;
};

export const CampaignTemplatesList: React.FC<
  CampaignTemplatesListPropsType
> = ({ values, setFieldValue, campaigns, refetch }) => {
  const { t } = useTranslation();
  return (
    <>
      <ThumbnailsGenerationNotify
        refetch={refetch}
        templates={campaigns.result.map(
          (campaign: Campaign) => campaign.emailTemplate,
        )}
      />
      <StyledCardGridContainer>
        {campaigns?.result.map((campaign: Campaign) => (
          <ImageCard
            isLoading={
              campaign.emailTemplate.thumbnailState ===
              ThumbnailState.PROCESSING
            }
            key={campaign.id}
            id={campaign.id}
            campaignValue={campaign}
            title={campaign.name}
            interactionType="withSelect"
            thumbnail={campaign.emailTemplate?.thumbnail?.signedUrl}
            author={displaySenderProfile(t, campaign.sendingProfile.name)}
            templateType="email"
            selectName={campaign.name}
            isSelected={values.selectedCampaign?.id === campaign.id}
            onChange={(event) => setFieldValue('selectedCampaign', event.value)}
            additionalClass="gray"
          />
        ))}
      </StyledCardGridContainer>
    </>
  );
};
