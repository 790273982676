import {
  EfrontRecommendationResponse,
  EfrontSyncRequest,
} from '@/client/efront';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { MessageResponse } from '@/client/types';
import { AxiosError, AxiosInstance } from 'axios';
import { EfrontSyncRecommendationRequest } from './types';

export const efrontSyncService = (client: AxiosInstance) => {
  const getSyncRecommendations = async (
    params: EfrontSyncRecommendationRequest,
  ): Promise<EfrontRecommendationResponse> => {
    try {
      return (
        await client.get(
          `efront-integration/sync/recommendation/${params.id}/${params.type}`,
          {
            withCredentials: true,
          },
        )
      ).data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const sendSyncRequest = async (
    request: EfrontSyncRequest,
  ): Promise<MessageResponse> => {
    try {
      return (
        await client.post('efront-integration/sync/sync-map', request, {
          withCredentials: true,
        })
      ).data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const forceEFrontBranchReindex = async (): Promise<MessageResponse> => {
    try {
      return (await client.post('efront-integration/sync/branches')).data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const forceEFrontGroupReindex = async (): Promise<MessageResponse> => {
    try {
      return (await client.post('efront-integration/sync/groups')).data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getNotificationsSSEUrl = () =>
    `${client.defaults.baseURL}efront-integration/sync/notify`;

  return {
    getSyncRecommendations,
    sendSyncRequest,
    forceEFrontBranchReindex,
    forceEFrontGroupReindex,
    getNotificationsSSEUrl,
  };
};
