import { equal } from '@/api/helpers';
import { AccountsAutocompleteInput } from '@/components/form/autocomplete';
import { CampaignsMultiselectInput } from '@/components/form/selectors';
import { oldCampaignsSchema } from '@/components/old-campaigns-sync/forms/validations';
import { AppButton } from '@/ui/buttons';
import { Form, Formik, FormikValues } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

type OldCampaignsSyncFormProps = {
  initialValues: FormikValues;
  onSubmit: (data: FormikValues) => void;
};

export const OldCampaignsSyncForm: React.FC<OldCampaignsSyncFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      enableReinitialize
      validationSchema={oldCampaignsSchema(t)}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, dirty, errors }) => (
        <>
          <h1>{t('campaign.sync')}</h1>
          <Form>
            <div className="field w-6">
              <label>
                {t('campaign.select.oldCampaing')}
                <span className="red"> *</span>
              </label>
              <CampaignsMultiselectInput
                className="w-full"
                onChange={(e) => setFieldValue('campaigns', e)}
                additionalFilters={[equal('account', null)]}
              />
            </div>
            <div className="field w-6">
              <label>
                {t('campaign.select.account')}
                <span className="red"> *</span>
              </label>
              <AccountsAutocompleteInput
                className="w-full"
                onSelect={(e) => setFieldValue('account', e)}
              />
            </div>

            <AppButton
              isSubmit
              className="mt-4 mb-5"
              label={t('button.sync')}
              severity="secondary"
              isDisabled={!dirty || !!Object.keys(errors).length}
            />
          </Form>
        </>
      )}
    </Formik>
  );
};
