export enum Conditions {
  EQUAL = 'eq',
  NOT_EQUAL = 'ne',
  IN = 'in',
  NOT_IN = 'nin',
  GREATER = 'gte',
  GREATER_OR_EQUAL = 'gte',
  LOWER = 'lt',
  LOWER_OR_EQUAL = 'lte',
  BETWEEN = 'between',
  LIKE = 'like',
  CONTAINS = 'contains',
  CONTAINED = 'contained',
  STARTS_WITH = 'sw',
  ENDS_WITH = 'ew',
  CONCAT = 'concat',
  NONE = 'none',
}

export enum GroupCondition {
  AND = 'and',
  OR = 'or',
}
