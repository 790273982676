import { useCampaign } from '@/hooks/query';
import PagePlaceholderImage from '@/images/placeholders/page-template.svg';
import { LoadingPage } from '@/pages';
import { FlexContainer } from '@/ui/styled-ui';
import { Divider } from 'primereact/divider';
import React, { MouseEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CampaignInfo } from './CampaignInfo';
import { EmailTemplatePreview } from './EmailTemplatePreview';

const StyledContainer = styled(FlexContainer)`
  height: 100%;
  width: 100%;
  max-height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
`;

const StyledEmailPreview = styled(FlexContainer)`
  flex: 1 0 60%;
  width: 90%;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
  gap: var(--default-padding);
  padding: 0;
  user-select: none;

  .thumbnail-preview {
    flex-shrink: 0;
    min-width: 100%;
    background-color: var(--beige-main);
    padding: var(--default-padding) var(--small-padding) var(--default-padding)
      var(--default-padding);
    display: flex;
    flex-direction: column;
    flex: 1 0 30%;
    font-size: var(--xsmall-font-size);
    height: 100%;
    overflow: scroll;

    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-corner {
      background-color: var(--beige-main);
    }
    ::-webkit-scrollbar-thumb {
      border-color: var(--beige-main);
    }

    .p-divider.p-divider-horizontal {
      margin: 0;
      &:before {
        border-color: var(--gray-dark);
      }
    }

    .thumbnail-type {
      margin-top: var(--xxsmall-padding);
      margin-bottom: var(--default-padding);
      font-weight: 500;
      text-align: center;
    }

    .p-divider-content {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--white-main);
      border-radius: 50%;
      border: 1px solid var(--gray-dark);
      width: 32px;
      height: 32px;
      font-weight: 700;
    }

    .img-container {
      background: var(--white-main);
      width: 100%;
      height: auto;
      img {
        display: block;
        pointer-events: none;
        height: 100%;
        width: 100%;
      }
    }
  }
`;

const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  cursor: grab;
  user-select: none;
`;

export type PreviewCampaignTemplatePropsType = {
  campaignId?: string;
};

export const PreviewCampaignTemplate: React.FC<
  PreviewCampaignTemplatePropsType
> = ({ campaignId }) => {
  const { t } = useTranslation();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const scrollContainer = scrollContainerRef.current;

  const { campaign, isLoading } = useCampaign({
    campaignId,
    enabled: !!campaignId,
  });

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    setIsDown(true);
    if (scrollContainer) {
      setStartX(e.pageX - scrollContainer.offsetLeft);
      setScrollLeft(scrollContainer.scrollLeft);
      scrollContainer.style.cursor = 'grabbing';
    }
  };

  const handleMouseEnter = (e: MouseEvent<HTMLDivElement>) => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.focus();
    }
  };

  const handleMouseLeaveOrUp = () => {
    setIsDown(false);
    if (scrollContainer) {
      scrollContainer.style.cursor = 'grab';
    }
  };

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (!isDown) return;
    e.preventDefault();
    if (scrollContainer) {
      const x = e.pageX - scrollContainer.offsetLeft;
      const walkX = (x - startX) * 3; // Adjust for faster/slower scrolling
      scrollContainer.scrollLeft = scrollLeft - walkX;
    }
  };

  return (
    <>
      {isLoading || !campaign ? (
        <LoadingPage message={t('generic.loading')} />
      ) : (
        <StyledContainer>
          <CampaignInfo campaign={campaign} className="preview" />
          <ScrollContainer
            ref={scrollContainerRef}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeaveOrUp}
            onMouseEnter={handleMouseEnter}
            onMouseUp={handleMouseLeaveOrUp}
            onMouseMove={handleMouseMove}
          >
            <StyledEmailPreview>
              <div className="thumbnail-preview">
                <Divider align="center">1</Divider>
                <h4 className="thumbnail-type">{t('templates.email')}</h4>
                <EmailTemplatePreview campaign={campaign} />
              </div>
              <div className="thumbnail-preview">
                <Divider align="center">2</Divider>
                <h4 className="thumbnail-type">
                  {t('templates.pageTemplate')}
                </h4>
                <div className="img-container">
                  <img
                    src={
                      campaign.landingPage?.preview?.signedUrl ||
                      campaign.landingPage?.thumbnail?.signedUrl ||
                      PagePlaceholderImage
                    }
                    alt={campaign.landingPage?.thumbnail?.fileName}
                  />
                </div>
              </div>
              {campaign.landingPage?.redirectPage && (
                <div className="thumbnail-preview">
                  <Divider align="center">3</Divider>
                  <h4 className="thumbnail-type">
                    {t('templates.redirectTemplate')}
                  </h4>
                  <div className="img-container">
                    <img
                      src={
                        campaign.landingPage?.redirectPage.preview?.signedUrl ||
                        campaign.landingPage?.redirectPage?.thumbnail
                          ?.signedUrl ||
                        PagePlaceholderImage
                      }
                      alt={
                        campaign.landingPage?.redirectPage?.thumbnail?.fileName
                      }
                    />
                  </div>
                </div>
              )}
            </StyledEmailPreview>
          </ScrollContainer>
        </StyledContainer>
      )}
    </>
  );
};
