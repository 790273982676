import { processListingParams } from '@/api/helpers';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { ListRolesRequest, ListRolesResponse } from '@/client/roles';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

export const rolesService = (client: AxiosInstance) => {
  const ROLES_BASE_URL = '/roles';

  const getRoles = async (
    params: ListRolesRequest,
  ): Promise<ListRolesResponse> => {
    try {
      const result = await client.get<
        ListRolesResponse,
        AxiosResponse<ListRolesResponse>
      >(ROLES_BASE_URL, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  return { getRoles };
};
