import { getExceptionFromAxiosError } from '@/client/helpers';
import { MessageResponseModel } from '@/client/models';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { SendHistoricalDataRequest } from './types';

export const trackingService = (client: AxiosInstance) => {
  const TRACKING_BASE_URL = '/tracking';

  const sendHistoricalData = async (
    request: SendHistoricalDataRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${TRACKING_BASE_URL}/send-historic-data`,
        { ...request },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const trackingNotifyUrl = (key: string) =>
    `${client.defaults.baseURL}tracking/${key}/notify`;

  return {
    sendHistoricalData,
    trackingNotifyUrl,
  };
};
