import { CampaignEmailingEnum, CampaignFormValues } from '@/client/campaigns';
import { TranslationFunctionType } from '@/common/types';
import { FormikInput } from '@/components/form';
import { AppButton } from '@/ui/buttons';
import { AppCalendar } from '@/ui/calendar';
import { FlexContainer } from '@/ui/styled-ui';
import { checkIfNowOrAfter, isWithinHourCheck } from '@/utils/helpers';
import { Field } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type PeriodStepProps = {
  values: CampaignFormValues;
  valuesSetter: any;
  campaignId?: string;
};

export const PeriodStep: React.FC<PeriodStepProps> = ({
  values,
  valuesSetter,
  campaignId,
}) => {
  const { t } = useTranslation();
  const [canEditName, setCanEditName] = useState(false);
  const [showDynamicTargetError, setShowDynamicTargetError] = useState(false);

  useEffect(() => {
    setShowDynamicTargetError(
      isWithinHourCheck(values.launchDate) && !!values.useDynamicTarget,
    );
  }, [values.launchDate, values.useDynamicTarget]);

  const emailingTypes = (t: TranslationFunctionType) => [
    {
      label: t('campaign.drip'),
      value: CampaignEmailingEnum.DRIP,
    },
    {
      label: t('campaign.blast'),
      value: CampaignEmailingEnum.BLAST,
    },
  ];

  return (
    <>
      <h2>{t('campaign.period')}</h2>

      <div className="field w-6">
        <label htmlFor="emailing-type">
          {t('campaign.emailType')}
          <span className="red"> *</span>
        </label>
        <Dropdown
          id="emailing-type"
          className="w-full"
          placeholder={t('generic.select')}
          value={values.periodType}
          onChange={(e) => valuesSetter('periodType', e.value)}
          options={emailingTypes(t)}
          data-testid="campaign-form-period-step-emailing-type"
        />
      </div>

      <div className="field w-6">
        <label htmlFor="launch-date">
          {t('campaign.launchDate')}
          <span className="red"> *</span>
        </label>
        <AppCalendar
          id="launch-date"
          name="launchDate"
          className="w-full"
          onFocus={() => {
            if (!values.launchDate || !checkIfNowOrAfter(values.launchDate))
              valuesSetter('launchDate', new Date());
          }}
          minDate={new Date()}
          value={values.launchDate}
          showTime
          onChange={(e) => valuesSetter('launchDate', e.value)}
          disabled={!values.periodType}
          readOnlyInput
          data-testid="campaign-form-period-step-launch-date"
        />
        {showDynamicTargetError && (
          <small className="p-error">
            {t('campaign.dynamicTarget.launchTime.warning')}
          </small>
        )}
      </div>

      {values.periodType === CampaignEmailingEnum.DRIP ? (
        <div className="field w-6">
          <label htmlFor="send-by">
            {t('campaign.sendDate')}
            <span className="red"> *</span>
          </label>
          <AppCalendar
            id="send-by-date"
            name="sendByDate"
            value={values.sendByDate}
            showTime
            onFocus={() => {
              if (!values.sendByDate || !checkIfNowOrAfter(values.sendByDate))
                valuesSetter('sendByDate', new Date());
            }}
            minDate={new Date()}
            className="w-full"
            onChange={(e) => valuesSetter('sendByDate', e.value)}
            readOnlyInput
            disabled={!values.launchDate}
            data-testid="campaign-form-period-step-send-by-date"
          />
        </div>
      ) : null}

      <FlexContainer justify="flex-start" align="flex-end" className="mb-4">
        <div className="field w-6 m-0 relative">
          <Field
            id="name"
            name="name"
            label={t('generic.name')}
            value={values.name}
            className="w-full"
            onChange={(event: any) => valuesSetter('name', event.target.value)}
            component={FormikInput}
            disabled={!canEditName}
            data-testid="campaign-form-period-step-name"
          />
        </div>
        {!canEditName ? (
          <AppButton
            icon="pi pi-pencil"
            type="text"
            onClick={() => setCanEditName(true)}
            className="ml-2"
            data-testid="campaign-form-period-step-enable-edit-name"
          />
        ) : null}
      </FlexContainer>

      {values.meta?.cloneOf && (
        <>
          <div className="field w-6">
            <label htmlFor="id">{t('generic.id')}</label>
            <InputText
              id="id"
              name="id"
              value={campaignId}
              className="w-full"
              disabled
              data-testid="campaign-form-period-step-cloneOf"
            />
          </div>

          <h3 className="mb-4">{t('generic.cloneOf')}</h3>
          <div className="field w-6">
            <label htmlFor="cloneOfName">{t('campaign.template.name')}</label>
            <InputText
              id="cloneOfName"
              name="cloneOfName"
              value={values.meta?.cloneOfName}
              className="w-full"
              disabled
              data-testid="campaign-form-period-step-cloneOfName"
            />
          </div>
        </>
      )}
    </>
  );
};
