import { CampaignFormValues } from '@/client/campaigns';
import { FormikErrors } from 'formik';
import { isObject } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledErrorsContainer = styled.div`
  background: var(--red-light);
  padding: 4px var(--default-padding) 2px 0;
  border-radius: 5px;
  margin-bottom: var(--xsmall-padding);

  ul > li {
    margin-bottom: var(--xsmall-padding);
  }
`;

type CampaignFormErrorsProps = {
  errors: FormikErrors<CampaignFormValues>;
  showErrors?: boolean;
};

const parseErrors = (errors: any) =>
  Object.keys(errors).map((key) => {
    if (isObject(errors[key]))
      return (errors[key] as { usersList: string }).usersList;

    return errors[key];
  });

export const CampaignFormErrors: React.FC<CampaignFormErrorsProps> = ({
  errors,
  showErrors,
}) => {
  const [formErrors, setFormErrors] = useState<string[]>([]);

  useEffect(() => {
    const result = parseErrors(errors);
    setFormErrors(result);
  }, [errors]);

  return showErrors && formErrors.length ? (
    <StyledErrorsContainer data-testid="campaign-form-errors-container">
      <ul data-testid="campaign-form-errors-list">
        {formErrors.map((error) => (
          <li key={error} data-testid={`campaign-form-errors-${error}`}>
            {error}
          </li>
        ))}
      </ul>
    </StyledErrorsContainer>
  ) : null;
};
