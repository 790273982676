import { AxiosError } from 'axios';

export const unauthorizedInterceptor = (
  callback: (url: string | undefined) => void,
) => {
  return (error: AxiosError) => {
    if (
      error.response?.status &&
      [401, 403].includes(error.response.status) &&
      callback
    ) {
      callback(error?.config?.url);
    }

    throw error;
  };
};
