import { TranslationFunctionType } from '@/common/types';
import { array, object, string } from 'yup';

export const courseAvailabilitySchema = (t: TranslationFunctionType) =>
  object().shape({
    accountsType: string(),
    accounts: array().min(1, t('error.requiredField')),
  });

export const accountToCoursesAvailabilitySchema = (
  t: TranslationFunctionType,
) =>
  object().shape({
    courses: array().min(1, t('error.requiredField')),
  });
