import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { SenderProfileForm } from '@/components/sender-profiles/forms';
import { useSaveSenderProfile } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { queryStateConverter } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import { MenuItem } from 'primereact/menuitem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const getBreadcrumbs = (
  account: Account,
  t: TranslationFunctionType,
): MenuItem[] => [
  {
    label: account?.name,
    url: !account?.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: account?.isSystem ? t('sender.predefined') : t('sender.custom'),
    url: account?.isSystem
      ? RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_PREDEFINED]()
      : RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_CUSTOM](),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('sender.create'),
    url: RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_CREATE](),
    template: AppBreadCrumbTemplate,
  },
];

export const CreateSenderProfilePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const campaignId = location?.state?.campaignId;
  const toast = useToast();
  const account = useAppSelector(selectCurrentAccount);

  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const [formInitialValues, setFormInitialValues] = useState({
    name: '',
    senderName: '',
    fromAddress: '',
    interfaceType: 'SMTP',
    host: '',
    username: '',
    password: '',
    ignoreCertErrors: false,
  });

  const createSenderProfile = useSaveSenderProfile();

  // If a state was provided through the location object, prefill the form data and template
  useEffect(() => {
    if (!location?.state?.senderProfile) return;

    setFormInitialValues({
      name: '',
      senderName: location.state.senderProfile.senderName,
      fromAddress: location.state.senderProfile.fromAddress,
      interfaceType: location.state.senderProfile.interfaceType,
      host: location.state.senderProfile.host,
      username: location.state.senderProfile.username,
      password: location.state.senderProfile.password,
      ignoreCertErrors: location.state.senderProfile.ignoreCertErrors,
    });
  }, [location?.state?.senderProfile]);

  const handleSubmit = async (data: FormikValues) => {
    try {
      if (!account) return;

      await createSenderProfile.create({
        name: data.name,
        senderName: data.senderName,
        fromAddress: data.fromAddress,
        interfaceType: data.interfaceType,
        host: data.host,
        username: data.username,
        password: data.password,
        ignoreCertErrors: data.ignoreCertErrors,
        account: account.isSystem ? null : account.id,
      });

      toast?.success(t('toast.success'), t('sender.created'));

      if (!campaignId) {
        if (account.isSystem) {
          navigate(
            RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_PREDEFINED](),
          );
        } else {
          navigate(RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_CUSTOM]());
        }
      } else {
        navigate(RedirectPaths[RedirectPathsEnum.CAMPAIGNS_EDIT](campaignId), {
          state: {
            activeStep: 2,
            isTemplate: !!account?.isSystem,
          },
        });
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  return (
    <>
      <AppBreadCrumb model={getBreadcrumbs(account as Account, t)} />
      <FlexContainer justify="flex-start">
        <SenderProfileForm
          initialValues={formInitialValues}
          onSubmit={handleSubmit}
          state={queryStateConverter(createSenderProfile)}
          isCreate
          passwordVisibility={passwordVisibility}
          onPasswordToggle={() => setPasswordVisibility(!passwordVisibility)}
          campaignId={campaignId}
        />
      </FlexContainer>
    </>
  );
};
