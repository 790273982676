import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FlexContainer } from '../styled-ui';

const StyledTabButton = styled.div`
  position: relative;
  border-radius: var(--xsmall-border-radius);
  padding: var(--small-padding) var(--medium-padding);
  background: var(--white-main);
  text-transform: capitalize;
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  cursor: pointer;

  &:not(.selected):hover {
    background: rgba(246, 246, 243, 0.5);
  }
  &.selected {
    background: var(--beige-main);
  }
  &.valid:before,
  &.required:before {
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 1;
    font-family: var(--icon-font-family);
    display: inline-block;
    font-size: 10px;
    line-height: 10px;
    font-weight: 900;
  }
  &.required::before {
    content: '\f00d';
    color: var(--red-main);
  }
  &.valid::before {
    content: '\f00c';
    color: var(--green-main);
  }
  &.disabled {
    pointer-events: none;
    opacity: 80%;
  }
`;

type TabSwitchButtonsProps = {
  options: string[];
  onToggle: (type?: string) => Promise<void> | void;
  defaultSelected: string;
  validOptions?: string[];
  requiredOptions?: string[];
  isDisabled?: boolean;
};

export const TabSwitchButtons: React.FC<TabSwitchButtonsProps> = ({
  options,
  onToggle,
  defaultSelected,
  validOptions = [],
  requiredOptions = [],
  isDisabled = false,
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(defaultSelected);

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  return (
    <FlexContainer justify="flex-start" gap={12} wrap="wrap">
      {options?.map((option) => (
        <StyledTabButton
          key={option}
          className={`${selected === option ? 'selected' : ''}
          ${requiredOptions.includes(option) ? 'required' : ''}
          ${validOptions.includes(option) ? 'valid' : ''}
          ${isDisabled ? 'disabled' : ''}`}
          onClick={async () => {
            if (selected !== option) {
              setSelected(option);
              await onToggle(option);
            }
          }}
        >
          {t(option)}
        </StyledTabButton>
      ))}
    </FlexContainer>
  );
};
