import { processListingParams } from '@/api/helpers';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { MessageResponseModel } from '@/client/models';
import {
  ListPageSystemEmailTypeRequest,
  ListPageSystemEmailTypeResponse,
  SaveSystemEmailTypeConfig,
  SystemEmailTypeConfig,
  UpdateSystemEmailTypeConfig,
} from '@/client/system-emails';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { omit } from 'lodash';

export const systemEmailsTypesConfigsService = (client: AxiosInstance) => {
  const BASE_SYSTEM_EMAILS_TYPES_URL = '/system-email-types';

  const getSystemEmailsTypesConfigs = async (
    params: ListPageSystemEmailTypeRequest,
  ): Promise<ListPageSystemEmailTypeResponse> => {
    try {
      const result = await client.get<
        ListPageSystemEmailTypeResponse,
        AxiosResponse<ListPageSystemEmailTypeResponse>
      >(BASE_SYSTEM_EMAILS_TYPES_URL, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getSystemEmailTypeConfig = async (
    configId: string,
  ): Promise<SystemEmailTypeConfig> => {
    try {
      const result = await client.get<
        SystemEmailTypeConfig,
        AxiosResponse<SystemEmailTypeConfig>
      >(`${BASE_SYSTEM_EMAILS_TYPES_URL}/${configId}`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveSystemEmailTypeConfig = async (
    request: SaveSystemEmailTypeConfig,
  ): Promise<SystemEmailTypeConfig> => {
    try {
      const result = await client.post<
        SystemEmailTypeConfig,
        AxiosResponse<SystemEmailTypeConfig>
      >(BASE_SYSTEM_EMAILS_TYPES_URL, request, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateSystemEmailTypeConfig = async (
    request: UpdateSystemEmailTypeConfig,
  ): Promise<SystemEmailTypeConfig> => {
    try {
      const result = await client.patch<
        SystemEmailTypeConfig,
        AxiosResponse<SystemEmailTypeConfig>
      >(
        `${BASE_SYSTEM_EMAILS_TYPES_URL}/${request.configId}`,
        omit(request, 'configId'),
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deleteSystemEmailTypeConfig = async (
    configId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${BASE_SYSTEM_EMAILS_TYPES_URL}/${configId}`, {
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  return {
    getSystemEmailsTypesConfigs,
    getSystemEmailTypeConfig,
    saveSystemEmailTypeConfig,
    updateSystemEmailTypeConfig,
    deleteSystemEmailTypeConfig,
  };
};
