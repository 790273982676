import { processUrl } from '@/utils/url/url.helper';

export const AppRoutesPaths = {
  BASE: '/',

  // ------------------- TOOLS --------------------------
  TOOLS_ROUTE: '/tools',
  OLD_CAMPAIGNS_WIZARD_ROUTE: '/old-campaigns-mapping',
  EFRONT_SYNC_ROUTE: '/efront-sync-mapping',
  HUBSPOT_SYNC_ROUTE: '/hubspot-sync',
  FIND_USER_ROUTE: '/find-user',

  // ------------------- ACCOUNTS --------------------------
  ACCOUNTS_ROUTE: '/accounts',
  ACCOUNT_ROUTE: '/account',
  ACCOUNTS_CREATE_ROUTE: '/accounts/create',
  ACCOUNTS_UPDATE_ROUTE: '/accounts/edit/:id',
  ACCOUNTS_INTEGRATIONS_ROUTE: '/accounts/integrations/:id',
  ACCOUNTS_INTEGRATIONS_GO_PHISH_ROUTE: '/accounts/integrations/:id/gophish',
  ACCOUNTS_INTEGRATIONS_HUBSPOT_ROUTE: '/accounts/integrations/:id/hubspot',
  ACCOUNT_AZURE_AD_ROUTE: '/accounts/azure-ad/:id',
  ACCOUNT_SAML_CONFIG_ROUTE: '/accounts/saml-config/:id',
  ACCOUNT_MIGRATION_ROUTE: '/accounts/migration/:id',
  ACCOUNT_SSO_ROUTE: '/accounts/sso/:id',
  ACCOUNT_COURSES_ROUTE: '/accounts/courses/:id',
  ACCOUNT_SECURITY_DOCUMENTS_ROUTE: '/accounts/documents/:id',

  // ------------------- RESELLERS --------------------------
  RESELLERS_ROUTE: '/resellers',
  RESELLERS_CREATE_ROUTE: '/resellers/create',
  RESELLERS_EDIT_ROUTE: '/resellers/edit/:id',
  RESELLERS_ACCOUNTS_ROUTE: '/resellers/:id/accounts',
  RESELLERS_SYSTEM_ROUTE: '/resellers/system',

  // ------------------- USERS --------------------------
  USERS_ROUTE: '/users',
  USERS_CREATE_ROUTE: '/users/create',
  USERS_EDIT_ROUTE: '/users/edit/:id',
  USERS_COURSES_ROUTE: '/users/courses/:id',
  USERS_DOCUMENTS_ROUTE: '/users/documents/:id',
  USERS_PROPERTIES_ROUTE: '/users/properties/:id',

  // ------------------- GROUPS --------------------------
  GROUPS_ROUTE: '/groups',
  GROUPS_CREATE_ROUTE: '/groups/create',
  GROUPS_EDIT_ROUTE: '/groups/edit/:id',
  GROUPS_USERS_ROUTE: '/groups/edit/:id/users',

  // ------------------- BRANCHES --------------------------
  BRANCHES_ROUTE: '/branches',
  BRANCHES_CREATE_ROUTE: '/branches/create',
  BRANCHES_EDIT_ROUTE: '/branches/edit/:id',
  BRANCHES_USERS_ROUTE: '/branches/edit/:id/users',
  BRANCHES_USERS_IMPORT_ROUTE: '/branches/edit/:id/import',

  // ------------------- CAMPAIGNS --------------------------
  CAMPAIGNS_ROUTE: '/campaigns',
  CAMPAIGNS_CREATE_ROUTE: '/campaigns/create',
  CAMPAIGNS_EDIT_ROUTE: '/campaigns/edit/:id',
  CAMPAIGNS_COPY_ROUTE: '/campaigns/copy/:id',
  CAMPAIGNS_REPORT_ROUTE: '/campaigns/report/:id',
  CAMPAIGNS_CATALOGUE_ROUTE: '/campaigns/catalogue',

  // ------------------- LANDING PAGE TEMPLATES --------------------------
  LANDING_PAGE_TEMPLATES_PREDEFINED_ROUTE: '/landing-page-templates/predefined',
  LANDING_PAGE_TEMPLATES_CUSTOM_ROUTE: '/landing-page-templates/custom',
  LANDING_PAGE_TEMPLATES_CREATE_ROUTE: '/landing-page-templates/create',
  LANDING_PAGE_TEMPLATES_UPDATE_ROUTE: '/landing-page-templates/edit/:id',

  // ------------------- REDIRECT PAGE TEMPLATES --------------------------
  REDIRECT_PAGE_TEMPLATES_PREDEFINED_ROUTE:
    '/redirect-page-templates/predefined',
  REDIRECT_PAGE_TEMPLATES_CUSTOM_ROUTE: '/redirect-page-templates/custom',
  REDIRECT_PAGE_TEMPLATES_CREATE_ROUTE: '/redirect-page-templates/create',
  REDIRECT_PAGE_TEMPLATES_UPDATE_ROUTE: '/redirect-page-templates/edit/:id',

  // ------------------- EMAIL TEMPLATES --------------------------
  EMAIL_TEMPLATES_PREDEFINED_ROUTE: '/email-templates/predefined',
  EMAIL_TEMPLATES_CUSTOM_ROUTE: '/email-templates/custom',
  EMAIL_TEMPLATES_CREATE_ROUTE: '/email-templates/create',
  EMAIL_TEMPLATES_UPDATE_ROUTE: '/email-templates/edit/:id',

  // ------------------- SENDER PROFILES --------------------------
  SENDER_PROFILES_PREDEFINED_ROUTE: '/sender-profiles/predefined',
  SENDER_PROFILES_CUSTOM_ROUTE: '/sender-profiles/custom',
  SENDER_PROFILES_CREATE_ROUTE: '/sender-profiles/create',
  SENDER_PROFILES_EDIT_ROUTE: '/sender-profiles/edit/:id',

  // ------------------- USER PROFILE -----------------------------
  PROFILE_SETTINGS_ROUTE: '/profile/settings',
  LEARNER_PROFILE_SETTINGS_ROUTE: '/learner/profile/settings',

  // ------------------- DASHBOARDS --------------------------
  ADMIN_DASHBOARD_ROUTE: '/dashboard/admin',
  LEARNER_DASHBOARD_ACTIVE_ROUTE: '/dashboard/learner/to-do',
  LEARNER_DASHBOARD_COMPLETED_ROUTE: '/dashboard/learner/completed',
  LEARNER_DASHBOARD_MATERIALS_RESOURCES_ROUTE:
    '/dashboard/learner/materials/resources',
  LEARNER_DASHBOARD_MATERIALS_TODO_ROUTE: '/dashboard/learner/materials/to-do',
  LEARNER_DASHBOARD_MATERIALS_COMPLETED_ROUTE:
    '/dashboard/learner/materials/completed',
  LEARNER_DASHBOARD_POSTERS_ALL_ROUTE: '/dashboard/learner/posters/all',
  LEARNER_DASHBOARD_POSTERS_COURSE_ROUTE: '/dashboard/learner/posters/course',
  LEARNER_DASHBOARD_POSTERS_CYBER_ROUTE:
    '/dashboard/learner/posters/cyber-security',
  LEARNER_DASHBOARD_POSTERS_GDPR_ROUTE: '/dashboard/learner/posters/gdpr',
  LEARNER_DASHBOARD_POSTERS_INFOSCREEN_ROUTE:
    '/dashboard/learner/posters/info-screen',
  LEARNER_DASHBOARD_POSTERS_INFOGRAPHICS_ROUTE:
    '/dashboard/learner/posters/infographics',

  // ------------------- NOT FOUND --------------------------
  NOT_FOUND_ROUTE: '/not-found',

  // ------------------- UNAUTHORIZED --------------------------
  UNAUTHORIZED_ROUTE: '/unauthorized',

  // ------------------- SYSTEM EMAILS --------------------------
  SYSTEM_EMAILS_TEMPLATES_ROUTE: '/system-emails/templates',
  SYSTEM_EMAILS_TEMPLATES_CREATE_ROUTE: '/system-emails/templates/create',
  SYSTEM_EMAILS_TEMPLATES_EDIT_ROUTE: '/system-emails/templates/edit/:id',
  SYSTEM_EMAILS_TYPES_CONFIGS_ROUTE: '/system-emails/configs',
  SYSTEM_EMAILS_TYPES_CONFIGS_CREATE_ROUTE: '/system-emails/configs/create',
  SYSTEM_EMAILS_TYPES_CONFIGS_EDIT_ROUTE: '/system-emails/configs/edit/:id',
  SYSTEM_EMAILS_SENDING_CONFIGS_ROUTE: '/system-emails/sending-configs',

  // ------------------- LOGIN/LOGOUT ---------------------------------
  COMPLETE_KEYCLOAK_SIGN_IN_ROUTE: '/complete-sign-in',
  LOGOUT: '/logout',

  // ------------------- COURSES --------------------------
  COURSES_ROUTE: '/courses',
  COURSES_CREATE_ROUTE: '/courses/create',
  COURSES_CONTENT_ROUTE: '/courses/:id/content',
  COURSES_SCHEDULE_ROUTE: '/courses/:id/schedule',
  COURSES_ACCOUNTS_ROUTE: '/courses/:id/accounts',
  COURSES_BRANCHES_ROUTE: '/courses/:id/branches',
  COURSES_GROUPS_ROUTE: '/courses/:id/groups',
  COURSES_USERS_ROUTE: '/courses/:id/users',
  COURSES_SCORM_PREVIEW_ROUTE: '/courses/:id/scorm-preview',
  COURSES_ANALYTICS_ROUTE: '/courses/:id/analytics',

  ACCOUNT_COURSE_EVENT_LOG: '/enrollment/event-log',
  ACCOUNT_COURSE_PLANNER_ROUTE: '/enrollment/training-planner',

  // ------------------- MATERIALS --------------------------
  RESOURCES_ROUTE: '/resources',
  RESOURCES_CREATE_ROUTE: '/resources/create',
  RESOURCES_EDIT_ROUTE: '/resources/edit/:id',
  RESOURCES_SCHEDULE_ROUTE: '/resources/:id/schedule',
  RESOURCES_ACCOUNTS_ROUTE: '/resources/:id/accounts',
  RESOURCES_USERS_ROUTE: '/resources/:id/users',
  CUSTOM_MATERIALS_ROUTE: '/custom-materials',
  CUSTOM_MATERIALS_CREATE_ROUTE: '/custom-materials/create',
  CUSTOM_MATERIALS_EDIT_ROUTE: '/custom-materials/edit/:id',
  CUSTOM_MATERIALS_SCHEDULE_ROUTE: '/custom-materials/:id/schedule',
  CUSTOM_MATERIALS_USERS_ROUTE: '/custom-materials/:id/users',
  MATERIALS_BRANCHES_ROUTE: '/custom-materials/:id/branches',
  MATERIALS_GROUPS_ROUTE: '/custom-materials/:id/groups',
  POSTERS_ROUTE: '/posters',
  POSTER_CREATE_ROUTE: '/posters/create',
  POSTER_EDIT_ROUTE: '/posters/edit/:id',
  DOCUMENT_VIEW_ROUTE: '/policies-and-documents/:id/view/:attemptId',

  ACCOUNT_DOCUMENTS_SCHEDULE_ROUTE: '/policies-and-documents-schedule',

  // ------------------- LEARNER COURSES ---------------------------------
  COURSE_INFO_ROUTE: '/courses/:id',
  COURSE_PLAY_ROUTE: '/courses/:id/play/:attemptId',

  // ------------------- SECURITY CULTURE ---------------------------------
  SECURITY_CULTURE_ROUTE: '/security-culture-surveys',
  SECURITY_CULTURE_UPLOAD_SURVEY_ROUTE:
    '/security-culture-surveys/survey/upload',
  SECURITY_CULTURE_EDIT_SURVEY_ROUTE:
    '/security-culture-surveys/survey/edit/:id',
  SECURITY_CULTURE_SEND_SURVEY_ROUTE: '/security-culture/invite',
  SECURITY_CULTURE_SURVEY_ROUTE: '/security-culture-survey',
  SECURITY_CULTURE_FEEDBACK_ROUTE: '/security-culture-survey/:id/feedback',
  SECURITY_CULTURE_CATEGORY_ROUTE:
    '/security-culture-survey/:id/category/:categoryName',

  // ------------------- REPORTS ---------------------------------
  REPORTS_SUMMARY_ROUTE: '/reports-summary',
  REPORTS_AWARENESS_ROUTE: '/reports-awareness',
};

export enum RedirectPathsEnum {
  BASE = 'BASE',
  // ------------------- TOOLS --------------------------
  OLD_CAMPAIGNS_WIZARD = 'OLD_CAMPAIGNS_WIZARD',
  EFRONT_SYNC = 'EFRONT_SYNC',
  HUBSPOT_SYNC = 'HUBSPOT_SYNC',
  FIND_USER = 'FIND_USER',

  // ------------------- ACCOUNTS --------------------------
  ACCOUNTS = 'ACCOUNTS',
  ACCOUNT = 'ACCOUNT',
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  EDIT_ACCOUNT = 'EDIT_ACCOUNT',
  ACCOUNT_INTEGRATIONS = 'ACCOUNT_INTEGRATIONS',
  ACCOUNTS_INTEGRATIONS_GO_PHISH = 'ACCOUNTS_INTEGRATIONS_GO_PHISH',
  ACCOUNTS_INTEGRATIONS_HUBSPOT = 'ACCOUNTS_INTEGRATIONS_HUBSPOT',
  ACCOUNT_AZURE_AD = 'ACCOUNT_AZURE_AD',
  ACCOUNT_MIGRATION = 'ACCOUNT_MIGRATION',
  ACCOUNT_SAML_CONFIG = 'ACCOUNT_SAML_CONFIG',
  ACCOUNT_SSO = 'ACCOUNT_SSO',
  ACCOUNT_COURSES = 'ACCOUNT_COURSES',
  ACCOUNT_SECURITY_DOCUMENTS = 'ACCOUNT_SECURITY_DOCUMENTS',

  // ------------------- RESELLERS --------------------------
  RESELLERS = 'RESELLERS',
  CREATE_RESELLERS = 'CREATE_RESELLERS',
  EDIT_RESELLERS = 'EDIT_RESELLERS',
  RESELLERS_ACCOUNTS = 'RESELLERS_ACCOUNTS',
  RESELLERS_SYSTEM = 'RESELLERS_SYSTEM',

  // ------------------- USERS --------------------------
  USERS = 'USERS',
  USERS_CREATE = 'USERS_CREATE',
  USERS_EDIT = 'USERS_EDIT',
  USERS_COURSES = 'USERS_COURSES',
  USERS_DOCUMENTS = 'USERS_DOCUMENTS',
  USERS_PROPERTIES = 'USERS_PROPERTIES',

  // ------------------- GROUPS --------------------------
  GROUPS = 'GROUPS',
  GROUPS_CREATE = 'GROUPS_CREATE',
  GROUPS_EDIT = 'GROUPS_EDIT',
  GROUPS_USERS = 'GROUPS_USERS',

  // ------------------- BRANCHES --------------------------
  BRANCHES = 'BRANCHES',
  BRANCHES_CREATE = 'BRANCHES_CREATE',
  BRANCHES_EDIT = 'BRANCHES_EDIT',
  BRANCHES_USERS = 'BRANCHES_USERS',
  BRANCHES_USERS_IMPORT = 'BRANCHES_USERS_IMPORT',

  // ------------------- CAMPAIGNS --------------------------
  CAMPAIGNS = 'CAMPAIGNS',
  CAMPAIGNS_CREATE = 'CAMPAIGNS_CREATE',
  CAMPAIGNS_EDIT = 'CAMPAIGNS_EDIT',
  CAMPAIGNS_COPY = 'CAMPAIGNS_COPY',
  CAMPAIGNS_REPORT = 'CAMPAIGNS_REPORT',
  CAMPAIGNS_CATALOGUE = 'CAMPAIGNS_CATALOGUE',

  // ------------------- PAGE TEMPLATES --------------------------
  LANDING_PAGE_TEMPLATES_PREDEFINED = 'LANDING_PAGE_TEMPLATES_PREDEFINED',
  LANDING_PAGE_TEMPLATES_CUSTOM = 'LANDING_PAGE_TEMPLATES_CUSTOM',
  LANDING_PAGE_TEMPLATES_CREATE = 'LANDING_PAGE_TEMPLATES_CREATE',
  LANDING_PAGE_TEMPLATES_UPDATE = 'LANDING_PAGE_TEMPLATES_UPDATE',

  // ------------------- REDIRECT PAGE TEMPLATES --------------------------
  REDIRECT_PAGE_TEMPLATES_PREDEFINED = 'REDIRECT_PAGE_TEMPLATES_PREDEFINED',
  REDIRECT_PAGE_TEMPLATES_CUSTOM = 'REDIRECT_PAGE_TEMPLATES_CUSTOM',
  REDIRECT_PAGE_TEMPLATES_CREATE = 'REDIRECT_PAGE_TEMPLATES_CREATE',
  REDIRECT_PAGE_TEMPLATES_UPDATE = 'REDIRECT_PAGE_TEMPLATES_UPDATE',

  // ------------------- EMAIL TEMPLATES --------------------------
  EMAIL_TEMPLATES_PREDEFINED = 'EMAIL_TEMPLATES_PREDEFINED',
  EMAIL_TEMPLATES_CUSTOM = 'EMAIL_TEMPLATES_CUSTOM',
  EMAIL_TEMPLATES_CREATE = 'EMAIL_TEMPLATES_CREATE',
  EMAIL_TEMPLATES_UPDATE = 'EMAIL_TEMPLATES_UPDATE',

  // ------------------- SENDER PROFILES --------------------------
  SENDER_PROFILES_PREDEFINED = 'SENDER_PROFILES_PREDEFINED',
  SENDER_PROFILES_CUSTOM = 'SENDER_PROFILES_CUSTOM',
  SENDER_PROFILES_CREATE = 'SENDER_PROFILES_CREATE',
  SENDER_PROFILES_EDIT = 'SENDER_PROFILES_EDIT',

  // ------------------- USER SETTINGS -----------------------------
  PROFILE_SETTINGS = 'PROFILE_SETTINGS',
  LEARNER_PROFILE_SETTINGS = 'LEARNER_PROFILE_SETTINGS',

  // ------------------- DASHBOARDS --------------------------
  ADMIN_DASHBOARD = 'ADMIN_DASHBOARD',
  LEARNER_DASHBOARD_ACTIVE = 'LEARNER_DASHBOARD_ACTIVE',
  LEARNER_DASHBOARD_COMPLETED = 'LEARNER_DASHBOARD_COMPLETED',
  LEARNER_DASHBOARD_MATERIALS_RESOURCES = 'LEARNER_DASHBOARD_MATERIALS_RESOURCES',
  LEARNER_DASHBOARD_MATERIALS_TODO = 'LEARNER_DASHBOARD_MATERIALS_TODO',
  LEARNER_DASHBOARD_MATERIALS_COMPLETED = 'LEARNER_DASHBOARD_MATERIALS_COMPLETED',
  LEARNER_DASHBOARD_POSTERS_ALL = 'LEARNER_DASHBOARD_POSTERS_ALL',
  LEARNER_DASHBOARD_POSTERS_COURSE = 'LEARNER_DASHBOARD_POSTERS_COURSE',
  LEARNER_DASHBOARD_POSTERS_CYBER = 'LEARNER_DASHBOARD_POSTERS_CYBER',
  LEARNER_DASHBOARD_POSTERS_GDPR = 'LEARNER_DASHBOARD_POSTERS_GDPR',
  LEARNER_DASHBOARD_POSTERS_INFOSCREEN = 'LEARNER_DASHBOARD_POSTERS_INFOSCREEN',
  LEARNER_DASHBOARD_POSTERS_INFOGRAPHICS = 'LEARNER_DASHBOARD_POSTERS_INFOGRAPHICS',

  // ------------------- NOT FOUND --------------------------
  NOT_FOUND = 'NOT_FOUND',

  // ------------------- UNAUTHORIZED --------------------------
  UNAUTHORIZED = 'UNAUTHORIZED',

  // ------------------- SYSTEM SETTINGS --------------------------
  SYSTEM_EMAILS_TEMPLATES = 'SYSTEM_EMAILS_TEMPLATES',
  SYSTEM_EMAILS_TEMPLATES_CREATE = 'SYSTEM_EMAILS_TEMPLATES_CREATE',
  SYSTEM_EMAILS_TEMPLATES_EDIT = 'SYSTEM_EMAILS_TEMPLATES_EDIT',
  SYSTEM_EMAILS_TYPES_CONFIGS = 'SYSTEM_EMAILS_TYPES_CONFIGS',
  SYSTEM_EMAILS_TYPES_CONFIGS_CREATE = 'SYSTEM_EMAILS_TYPES_CONFIGS_CREATE',
  SYSTEM_EMAILS_TYPES_CONFIGS_EDIT = 'SYSTEM_EMAILS_TYPES_CONFIGS_EDIT',
  SYSTEM_EMAILS_SENDING_CONFIGS = 'SYSTEM_EMAILS_SENDING_CONFIGS',

  // ------------------- LOGIN/LOGOUT ---------------------------------
  LOGOUT = 'LOGOUT',

  // ------------------- COURSES --------------------------
  COURSES = 'COURSES',
  COURSES_CREATE = 'COURSES_CREATE',
  COURSES_CONTENT = 'COURSES_CONTENT',
  COURSES_SCHEDULE = 'COURSES_SCHEDULE',
  COURSES_ACCOUNTS = 'COURSES_ACCOUNTS',
  COURSES_BRANCHES = 'COURSES_BRANCHES',
  COURSES_GROUPS = 'COURSES_GROUPS',
  COURSES_USERS = 'COURSES_USERS',
  COURSES_SCORM_PREVIEW = 'COURSES_SCORM_PREVIEW',
  COURSES_ANALYTICS = 'COURSES_ANALYTICS',

  ACCOUNT_COURSE_SCHEDULE = 'ACCOUNT_COURSE_SCHEDULE',
  ACCOUNT_COURSE_PLANNER = 'ACCOUNT_COURSE_PLANNER',

  // ------------------- MATERIALS --------------------------

  RESOURCES = 'RESOURCES',
  RESOURCES_CREATE = 'RESOURCES_CREATE',
  RESOURCES_EDIT = 'RESOURCES_EDIT',
  RESOURCES_SCHEDULE = 'RESOURCES_SCHEDULE',
  RESOURCES_ACCOUNTS = 'RESOURCES_ACCOUNTS',
  RESOURCES_USERS = 'RESOURCES_USERS',
  CUSTOM_MATERIALS = 'CUSTOM_MATERIALS',
  CUSTOM_MATERIALS_CREATE = 'CUSTOM_MATERIALS_CREATE',
  CUSTOM_MATERIALS_EDIT = 'CUSTOM_MATERIALS_EDIT',
  CUSTOM_MATERIALS_SCHEDULE = 'CUSTOM_MATERIALS_SCHEDULE',
  CUSTOM_MATERIALS_USERS = 'CUSTOM_MATERIALS_USERS',
  MATERIALS_BRANCHES = 'MATERIALS_BRANCHES',
  MATERIALS_GROUPS = 'MATERIALS_GROUPS',
  POSTERS = 'POSTERS',
  POSTER_CREATE = 'POSTER_CREATE',
  POSTER_EDIT = 'POSTER_EDIT',

  ACCOUNT_DOCUMENTS_SCHEDULE = 'ACCOUNT_DOCUMENTS_SCHEDULE',

  DOCUMENT_VIEW = 'DOCUMENT_VIEW',

  // ------------------- SCORM COURSES ---------------------------------
  COURSE_INFO = 'COURSE_INFO',
  COURSE_PLAY = 'COURSE_PLAY',

  // ------------------- SECURITY CULTURE ---------------------------------
  SECURITY_CULTURE = 'SECURITY_CULTURE',
  SECURITY_CULTURE_SEND_SURVEY = 'SECURITY_CULTURE_SEND_SURVEY',
  SECURITY_CULTURE_UPLOAD_SURVEY = 'SECURITY_CULTURE_UPLOAD_SURVEY',
  SECURITY_CULTURE_EDIT_SURVEY = 'SECURITY_CULTURE_EDIT_SURVEY',
  SECURITY_CULTURE_SURVEY = 'SECURITY_CULTURE_SURVEY',
  SECURITY_CULTURE_FEEDBACK = 'SECURITY_CULTURE_FEEDBACK',
  SECURITY_CULTURE_CATEGORY = 'SECURITY_CULTURE_CATEGORY',

  // ------------------- REPORTS ---------------------------------
  REPORTS_SUMMARY = 'REPORTS_SUMMARY',
  REPORTS_AWARENESS = 'REPORTS_AWARENESS',
}

export const RedirectPaths = {
  [RedirectPathsEnum.BASE]: AppRoutesPaths.BASE,

  // ------------------- TOOLS --------------------------
  [RedirectPathsEnum.OLD_CAMPAIGNS_WIZARD]: () =>
    AppRoutesPaths.OLD_CAMPAIGNS_WIZARD_ROUTE,
  [RedirectPathsEnum.EFRONT_SYNC]: () => AppRoutesPaths.EFRONT_SYNC_ROUTE,
  [RedirectPathsEnum.FIND_USER]: () => AppRoutesPaths.FIND_USER_ROUTE,

  // ------------------- ACCOUNTS --------------------------
  [RedirectPathsEnum.ACCOUNTS]: () => AppRoutesPaths.ACCOUNTS_ROUTE,
  [RedirectPathsEnum.ACCOUNT]: (id: string) =>
    processUrl(AppRoutesPaths.ACCOUNT_ROUTE, { id }),
  [RedirectPathsEnum.CREATE_ACCOUNT]: () =>
    AppRoutesPaths.ACCOUNTS_CREATE_ROUTE,
  [RedirectPathsEnum.EDIT_ACCOUNT]: (id: string) =>
    processUrl(AppRoutesPaths.ACCOUNTS_UPDATE_ROUTE, { id }),
  [RedirectPathsEnum.ACCOUNT_INTEGRATIONS]: (id: string) =>
    processUrl(AppRoutesPaths.ACCOUNTS_INTEGRATIONS_ROUTE, { id }),
  [RedirectPathsEnum.ACCOUNTS_INTEGRATIONS_GO_PHISH]: (id: string) =>
    processUrl(AppRoutesPaths.ACCOUNTS_INTEGRATIONS_GO_PHISH_ROUTE, { id }),
  [RedirectPathsEnum.ACCOUNTS_INTEGRATIONS_HUBSPOT]: (id: string) =>
    processUrl(AppRoutesPaths.ACCOUNTS_INTEGRATIONS_HUBSPOT_ROUTE, { id }),
  [RedirectPathsEnum.ACCOUNT_AZURE_AD]: (id: string) =>
    processUrl(AppRoutesPaths.ACCOUNT_AZURE_AD_ROUTE, { id }),
  [RedirectPathsEnum.ACCOUNT_SAML_CONFIG]: (id: string) =>
    processUrl(AppRoutesPaths.ACCOUNT_SAML_CONFIG_ROUTE, { id }),
  [RedirectPathsEnum.ACCOUNT_SSO]: (id: string) =>
    processUrl(AppRoutesPaths.ACCOUNT_SSO_ROUTE, { id }),
  [RedirectPathsEnum.ACCOUNT_MIGRATION]: (id: string) =>
    processUrl(AppRoutesPaths.ACCOUNT_MIGRATION_ROUTE, { id }),
  [RedirectPathsEnum.ACCOUNT_COURSES]: (id: string) =>
    processUrl(AppRoutesPaths.ACCOUNT_COURSES_ROUTE, { id }),
  [RedirectPathsEnum.ACCOUNT_SECURITY_DOCUMENTS]: (id: string) =>
    processUrl(AppRoutesPaths.ACCOUNT_SECURITY_DOCUMENTS_ROUTE, { id }),
  [RedirectPathsEnum.HUBSPOT_SYNC]: () => AppRoutesPaths.HUBSPOT_SYNC_ROUTE,

  //------------------- RESELLERS --------------------------
  [RedirectPathsEnum.RESELLERS]: () => AppRoutesPaths.RESELLERS_ROUTE,
  [RedirectPathsEnum.CREATE_RESELLERS]: () =>
    AppRoutesPaths.RESELLERS_CREATE_ROUTE,
  [RedirectPathsEnum.EDIT_RESELLERS]: (id: string) =>
    processUrl(AppRoutesPaths.RESELLERS_EDIT_ROUTE, { id }),
  [RedirectPathsEnum.RESELLERS_ACCOUNTS]: (id: string) =>
    processUrl(AppRoutesPaths.RESELLERS_ACCOUNTS_ROUTE, { id }),
  [RedirectPathsEnum.RESELLERS_SYSTEM]: () =>
    AppRoutesPaths.RESELLERS_SYSTEM_ROUTE,

  //------------------- USERS --------------------------
  [RedirectPathsEnum.USERS]: () => `${AppRoutesPaths.USERS_ROUTE}`,
  [RedirectPathsEnum.USERS_CREATE]: () =>
    `${AppRoutesPaths.USERS_CREATE_ROUTE}`,
  [RedirectPathsEnum.USERS_EDIT]: (id: string) =>
    processUrl(AppRoutesPaths.USERS_EDIT_ROUTE, { id }),
  [RedirectPathsEnum.USERS_COURSES]: (id: string) =>
    processUrl(AppRoutesPaths.USERS_COURSES_ROUTE, { id }),
  [RedirectPathsEnum.USERS_DOCUMENTS]: (id: string) =>
    processUrl(AppRoutesPaths.USERS_DOCUMENTS_ROUTE, { id }),
  [RedirectPathsEnum.USERS_PROPERTIES]: (id: string) =>
    processUrl(AppRoutesPaths.USERS_PROPERTIES_ROUTE, { id }),

  // ------------------- GROUPS --------------------------
  [RedirectPathsEnum.GROUPS]: () => AppRoutesPaths.GROUPS_ROUTE,
  [RedirectPathsEnum.GROUPS_CREATE]: () => AppRoutesPaths.GROUPS_CREATE_ROUTE,
  [RedirectPathsEnum.GROUPS_EDIT]: (groupId: string) =>
    processUrl(AppRoutesPaths.GROUPS_EDIT_ROUTE, { id: groupId }),
  [RedirectPathsEnum.GROUPS_USERS]: (groupId: string) =>
    processUrl(AppRoutesPaths.GROUPS_USERS_ROUTE, { id: groupId }),

  // ------------------- BRANCHES --------------------------
  [RedirectPathsEnum.BRANCHES]: () => AppRoutesPaths.BRANCHES_ROUTE,
  [RedirectPathsEnum.BRANCHES_CREATE]: () =>
    AppRoutesPaths.BRANCHES_CREATE_ROUTE,
  [RedirectPathsEnum.BRANCHES_EDIT]: (id: string) =>
    processUrl(AppRoutesPaths.BRANCHES_EDIT_ROUTE, { id }),
  [RedirectPathsEnum.BRANCHES_USERS]: (id: string) =>
    processUrl(AppRoutesPaths.BRANCHES_USERS_ROUTE, { id }),
  [RedirectPathsEnum.BRANCHES_USERS_IMPORT]: (id: string) =>
    processUrl(AppRoutesPaths.BRANCHES_USERS_IMPORT_ROUTE, { id }),

  // ------------------- CAMPAIGNS --------------------------
  [RedirectPathsEnum.CAMPAIGNS]: () => AppRoutesPaths.CAMPAIGNS_ROUTE,
  [RedirectPathsEnum.CAMPAIGNS_CREATE]: () =>
    AppRoutesPaths.CAMPAIGNS_CREATE_ROUTE,
  [RedirectPathsEnum.CAMPAIGNS_EDIT]: (id: string) =>
    processUrl(AppRoutesPaths.CAMPAIGNS_EDIT_ROUTE, { id }),
  [RedirectPathsEnum.CAMPAIGNS_COPY]: (id: string) =>
    processUrl(AppRoutesPaths.CAMPAIGNS_COPY_ROUTE, { id }),
  [RedirectPathsEnum.CAMPAIGNS_REPORT]: (id: string) =>
    processUrl(AppRoutesPaths.CAMPAIGNS_REPORT_ROUTE, { id }),
  [RedirectPathsEnum.CAMPAIGNS_CATALOGUE]: () =>
    AppRoutesPaths.CAMPAIGNS_CATALOGUE_ROUTE,

  // ------------------- PAGE TEMPLATES --------------------------
  [RedirectPathsEnum.LANDING_PAGE_TEMPLATES_PREDEFINED]: () =>
    AppRoutesPaths.LANDING_PAGE_TEMPLATES_PREDEFINED_ROUTE,
  [RedirectPathsEnum.LANDING_PAGE_TEMPLATES_CUSTOM]: () =>
    AppRoutesPaths.LANDING_PAGE_TEMPLATES_CUSTOM_ROUTE,
  [RedirectPathsEnum.LANDING_PAGE_TEMPLATES_CREATE]: () =>
    AppRoutesPaths.LANDING_PAGE_TEMPLATES_CREATE_ROUTE,
  [RedirectPathsEnum.LANDING_PAGE_TEMPLATES_UPDATE]: (id: string) =>
    processUrl(AppRoutesPaths.LANDING_PAGE_TEMPLATES_UPDATE_ROUTE, { id }),

  // ------------------- REDIRECT TEMPLATES --------------------------
  [RedirectPathsEnum.REDIRECT_PAGE_TEMPLATES_PREDEFINED]: () =>
    AppRoutesPaths.REDIRECT_PAGE_TEMPLATES_PREDEFINED_ROUTE,
  [RedirectPathsEnum.REDIRECT_PAGE_TEMPLATES_CUSTOM]: () =>
    AppRoutesPaths.REDIRECT_PAGE_TEMPLATES_CUSTOM_ROUTE,
  [RedirectPathsEnum.REDIRECT_PAGE_TEMPLATES_CREATE]: () =>
    AppRoutesPaths.REDIRECT_PAGE_TEMPLATES_CREATE_ROUTE,
  [RedirectPathsEnum.REDIRECT_PAGE_TEMPLATES_UPDATE]: (id: string) =>
    processUrl(AppRoutesPaths.REDIRECT_PAGE_TEMPLATES_UPDATE_ROUTE, { id }),

  // ------------------- EMAIL TEMPLATES --------------------------
  [RedirectPathsEnum.EMAIL_TEMPLATES_PREDEFINED]: () =>
    AppRoutesPaths.EMAIL_TEMPLATES_PREDEFINED_ROUTE,
  [RedirectPathsEnum.EMAIL_TEMPLATES_CUSTOM]: () =>
    AppRoutesPaths.EMAIL_TEMPLATES_CUSTOM_ROUTE,
  [RedirectPathsEnum.EMAIL_TEMPLATES_CREATE]: () =>
    AppRoutesPaths.EMAIL_TEMPLATES_CREATE_ROUTE,
  [RedirectPathsEnum.EMAIL_TEMPLATES_UPDATE]: (id: string) =>
    processUrl(AppRoutesPaths.EMAIL_TEMPLATES_UPDATE_ROUTE, { id }),

  //------------------- SENDER PROFILES --------------------------
  [RedirectPathsEnum.SENDER_PROFILES_PREDEFINED]: () =>
    `${AppRoutesPaths.SENDER_PROFILES_PREDEFINED_ROUTE}`,
  [RedirectPathsEnum.SENDER_PROFILES_CUSTOM]: () =>
    `${AppRoutesPaths.SENDER_PROFILES_CUSTOM_ROUTE}`,
  [RedirectPathsEnum.SENDER_PROFILES_CREATE]: () =>
    `${AppRoutesPaths.SENDER_PROFILES_CREATE_ROUTE}`,
  [RedirectPathsEnum.SENDER_PROFILES_EDIT]: (id: string) =>
    processUrl(AppRoutesPaths.SENDER_PROFILES_EDIT_ROUTE, { id }),

  //------------------- USER SETTINGS --------------------------
  [RedirectPathsEnum.PROFILE_SETTINGS]: () =>
    `${AppRoutesPaths.PROFILE_SETTINGS_ROUTE}`,
  [RedirectPathsEnum.LEARNER_PROFILE_SETTINGS]: () =>
    `${AppRoutesPaths.LEARNER_PROFILE_SETTINGS_ROUTE}`,

  //------------------- DASHBOARDS --------------------------
  [RedirectPathsEnum.ADMIN_DASHBOARD]: () =>
    `${AppRoutesPaths.ADMIN_DASHBOARD_ROUTE}`,
  [RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE]: () =>
    `${AppRoutesPaths.LEARNER_DASHBOARD_ACTIVE_ROUTE}`,
  [RedirectPathsEnum.LEARNER_DASHBOARD_COMPLETED]: () =>
    `${AppRoutesPaths.LEARNER_DASHBOARD_COMPLETED_ROUTE}`,
  [RedirectPathsEnum.LEARNER_DASHBOARD_MATERIALS_RESOURCES]: () =>
    `${AppRoutesPaths.LEARNER_DASHBOARD_MATERIALS_RESOURCES_ROUTE}`,
  [RedirectPathsEnum.LEARNER_DASHBOARD_MATERIALS_TODO]: () =>
    `${AppRoutesPaths.LEARNER_DASHBOARD_MATERIALS_TODO_ROUTE}`,
  [RedirectPathsEnum.LEARNER_DASHBOARD_MATERIALS_COMPLETED]: () =>
    `${AppRoutesPaths.LEARNER_DASHBOARD_MATERIALS_COMPLETED_ROUTE}`,
  [RedirectPathsEnum.LEARNER_DASHBOARD_POSTERS_ALL]: () =>
    `${AppRoutesPaths.LEARNER_DASHBOARD_POSTERS_ALL_ROUTE}`,
  [RedirectPathsEnum.LEARNER_DASHBOARD_POSTERS_COURSE]: () =>
    `${AppRoutesPaths.LEARNER_DASHBOARD_POSTERS_COURSE_ROUTE}`,
  [RedirectPathsEnum.LEARNER_DASHBOARD_POSTERS_CYBER]: () =>
    `${AppRoutesPaths.LEARNER_DASHBOARD_POSTERS_CYBER_ROUTE}`,
  [RedirectPathsEnum.LEARNER_DASHBOARD_POSTERS_GDPR]: () =>
    `${AppRoutesPaths.LEARNER_DASHBOARD_POSTERS_GDPR_ROUTE}`,
  [RedirectPathsEnum.LEARNER_DASHBOARD_POSTERS_INFOSCREEN]: () =>
    `${AppRoutesPaths.LEARNER_DASHBOARD_POSTERS_INFOSCREEN_ROUTE}`,
  [RedirectPathsEnum.LEARNER_DASHBOARD_POSTERS_INFOGRAPHICS]: () =>
    `${AppRoutesPaths.LEARNER_DASHBOARD_POSTERS_INFOGRAPHICS_ROUTE}`,

  //------------------- NOT FOUND --------------------------
  [RedirectPathsEnum.NOT_FOUND]: () => `${AppRoutesPaths.NOT_FOUND_ROUTE}`,

  // ------------------- UNAUTHORIZED --------------------------
  [RedirectPathsEnum.UNAUTHORIZED]: () =>
    `${AppRoutesPaths.UNAUTHORIZED_ROUTE}`,

  //------------------- SYSTEM EMAILS --------------------------
  [RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES]: () =>
    `${AppRoutesPaths.SYSTEM_EMAILS_TEMPLATES_ROUTE}`,
  [RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES_CREATE]: () =>
    `${AppRoutesPaths.SYSTEM_EMAILS_TEMPLATES_CREATE_ROUTE}`,
  [RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES_EDIT]: (id: string) =>
    processUrl(AppRoutesPaths.SYSTEM_EMAILS_TEMPLATES_EDIT_ROUTE, { id }),
  [RedirectPathsEnum.SYSTEM_EMAILS_TYPES_CONFIGS]: () =>
    `${AppRoutesPaths.SYSTEM_EMAILS_TYPES_CONFIGS_ROUTE}`,
  [RedirectPathsEnum.SYSTEM_EMAILS_SENDING_CONFIGS]: () =>
    `${AppRoutesPaths.SYSTEM_EMAILS_SENDING_CONFIGS_ROUTE}`,
  [RedirectPathsEnum.SYSTEM_EMAILS_TYPES_CONFIGS_CREATE]: () =>
    `${AppRoutesPaths.SYSTEM_EMAILS_TYPES_CONFIGS_CREATE_ROUTE}`,
  [RedirectPathsEnum.SYSTEM_EMAILS_TYPES_CONFIGS_EDIT]: (id: string) =>
    processUrl(AppRoutesPaths.SYSTEM_EMAILS_TYPES_CONFIGS_EDIT_ROUTE, { id }),

  // ------------------- COURSES --------------------------
  [RedirectPathsEnum.COURSES]: () => `${AppRoutesPaths.COURSES_ROUTE}`,
  [RedirectPathsEnum.COURSES_CREATE]: () =>
    `${AppRoutesPaths.COURSES_CREATE_ROUTE}`,
  [RedirectPathsEnum.COURSES_CONTENT]: (id: string) =>
    processUrl(AppRoutesPaths.COURSES_CONTENT_ROUTE, { id }),
  [RedirectPathsEnum.COURSES_SCHEDULE]: (id: string) =>
    processUrl(AppRoutesPaths.COURSES_SCHEDULE_ROUTE, { id }),
  [RedirectPathsEnum.COURSES_ACCOUNTS]: (id: string) =>
    processUrl(AppRoutesPaths.COURSES_ACCOUNTS_ROUTE, { id }),
  [RedirectPathsEnum.COURSES_BRANCHES]: (id: string) =>
    processUrl(AppRoutesPaths.COURSES_BRANCHES_ROUTE, { id }),
  [RedirectPathsEnum.COURSES_GROUPS]: (id: string) =>
    processUrl(AppRoutesPaths.COURSES_GROUPS_ROUTE, { id }),
  [RedirectPathsEnum.COURSES_USERS]: (id: string) =>
    processUrl(AppRoutesPaths.COURSES_USERS_ROUTE, { id }),
  [RedirectPathsEnum.COURSES_SCORM_PREVIEW]: (id: string) =>
    processUrl(AppRoutesPaths.COURSES_SCORM_PREVIEW_ROUTE, { id }),
  [RedirectPathsEnum.COURSES_ANALYTICS]: (id: string) =>
    processUrl(AppRoutesPaths.COURSES_ANALYTICS_ROUTE, { id }),

  [RedirectPathsEnum.ACCOUNT_COURSE_SCHEDULE]: () =>
    `${AppRoutesPaths.ACCOUNT_COURSE_EVENT_LOG}`,
  [RedirectPathsEnum.ACCOUNT_COURSE_PLANNER]: () =>
    `${AppRoutesPaths.ACCOUNT_COURSE_PLANNER_ROUTE}`,

  // ------------------- MATERIALS --------------------------
  [RedirectPathsEnum.RESOURCES]: () => `${AppRoutesPaths.RESOURCES_ROUTE}`,
  [RedirectPathsEnum.RESOURCES_CREATE]: () =>
    `${AppRoutesPaths.RESOURCES_CREATE_ROUTE}`,
  [RedirectPathsEnum.RESOURCES_EDIT]: (id: string) =>
    processUrl(AppRoutesPaths.RESOURCES_EDIT_ROUTE, { id }),
  [RedirectPathsEnum.RESOURCES_SCHEDULE]: (id: string) =>
    processUrl(AppRoutesPaths.RESOURCES_SCHEDULE_ROUTE, { id }),
  [RedirectPathsEnum.RESOURCES_ACCOUNTS]: (id: string) =>
    processUrl(AppRoutesPaths.RESOURCES_ACCOUNTS_ROUTE, { id }),
  [RedirectPathsEnum.RESOURCES_USERS]: (id: string) =>
    processUrl(AppRoutesPaths.RESOURCES_USERS_ROUTE, { id }),
  [RedirectPathsEnum.CUSTOM_MATERIALS]: () =>
    `${AppRoutesPaths.CUSTOM_MATERIALS_ROUTE}`,
  [RedirectPathsEnum.CUSTOM_MATERIALS_CREATE]: () =>
    `${AppRoutesPaths.CUSTOM_MATERIALS_CREATE_ROUTE}`,
  [RedirectPathsEnum.CUSTOM_MATERIALS_EDIT]: (id: string) =>
    processUrl(AppRoutesPaths.CUSTOM_MATERIALS_EDIT_ROUTE, { id }),
  [RedirectPathsEnum.CUSTOM_MATERIALS_SCHEDULE]: (id: string) =>
    processUrl(AppRoutesPaths.CUSTOM_MATERIALS_SCHEDULE_ROUTE, { id }),
  [RedirectPathsEnum.CUSTOM_MATERIALS_USERS]: (id: string) =>
    processUrl(AppRoutesPaths.CUSTOM_MATERIALS_USERS_ROUTE, { id }),
  [RedirectPathsEnum.MATERIALS_BRANCHES]: (id: string) =>
    processUrl(AppRoutesPaths.MATERIALS_BRANCHES_ROUTE, { id }),
  [RedirectPathsEnum.MATERIALS_GROUPS]: (id: string) =>
    processUrl(AppRoutesPaths.MATERIALS_GROUPS_ROUTE, { id }),
  [RedirectPathsEnum.POSTERS]: () => `${AppRoutesPaths.POSTERS_ROUTE}`,
  [RedirectPathsEnum.POSTER_CREATE]: () =>
    `${AppRoutesPaths.POSTER_CREATE_ROUTE}`,
  [RedirectPathsEnum.POSTER_EDIT]: (id: string) =>
    processUrl(AppRoutesPaths.POSTER_EDIT_ROUTE, { id }),

  [RedirectPathsEnum.ACCOUNT_DOCUMENTS_SCHEDULE]: () =>
    `${AppRoutesPaths.ACCOUNT_DOCUMENTS_SCHEDULE_ROUTE}`,

  [RedirectPathsEnum.DOCUMENT_VIEW]: (id: string, attemptId: string) =>
    processUrl(AppRoutesPaths.DOCUMENT_VIEW_ROUTE, { id, attemptId }),

  // ------------------- SCORM COURSES ---------------------------------
  [RedirectPathsEnum.COURSE_INFO]: (id: string) =>
    processUrl(AppRoutesPaths.COURSE_INFO_ROUTE, { id }),
  [RedirectPathsEnum.COURSE_PLAY]: (id: string, attemptId: string) =>
    processUrl(AppRoutesPaths.COURSE_PLAY_ROUTE, { id, attemptId }),

  // ------------------- SECURITY CULTURE ---------------------------------
  [RedirectPathsEnum.SECURITY_CULTURE]: () =>
    AppRoutesPaths.SECURITY_CULTURE_ROUTE,
  [RedirectPathsEnum.SECURITY_CULTURE_SEND_SURVEY]: () =>
    AppRoutesPaths.SECURITY_CULTURE_SEND_SURVEY_ROUTE,
  [RedirectPathsEnum.SECURITY_CULTURE_UPLOAD_SURVEY]: () =>
    AppRoutesPaths.SECURITY_CULTURE_UPLOAD_SURVEY_ROUTE,
  [RedirectPathsEnum.SECURITY_CULTURE_EDIT_SURVEY]: (id: string) =>
    processUrl(AppRoutesPaths.SECURITY_CULTURE_EDIT_SURVEY_ROUTE, { id }),
  [RedirectPathsEnum.SECURITY_CULTURE_SURVEY]: () =>
    AppRoutesPaths.SECURITY_CULTURE_SURVEY_ROUTE,
  [RedirectPathsEnum.SECURITY_CULTURE_FEEDBACK]: (id: string) =>
    processUrl(AppRoutesPaths.SECURITY_CULTURE_FEEDBACK_ROUTE, {
      id,
    }),
  [RedirectPathsEnum.SECURITY_CULTURE_CATEGORY]: (
    id: string,
    categoryName: string,
  ) =>
    processUrl(AppRoutesPaths.SECURITY_CULTURE_CATEGORY_ROUTE, {
      id,
      categoryName,
    }),

  // ------------------- REPORTS ---------------------------------
  [RedirectPathsEnum.REPORTS_SUMMARY]: () =>
    AppRoutesPaths.REPORTS_SUMMARY_ROUTE,
  [RedirectPathsEnum.REPORTS_AWARENESS]: () =>
    AppRoutesPaths.REPORTS_AWARENESS_ROUTE,

  // ------------------- LOGIN/LOGOUT ---------------------------------
  [RedirectPathsEnum.LOGOUT]: () => `${AppRoutesPaths.LOGOUT}`,
};
