import { Actions, Subjects } from '@/client/users';
import {
  AppRoutesPaths,
  DashboardType,
  LAST_SELECTED_DASHBOARD,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { CYBERPEDIA_URL } from '@/common/constants/external-urls';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentDashboard } from '@/store/features/dashboard';
import { selectCurrentUser } from '@/store/features/users';
import { FlexContainer } from '@/ui/styled-ui';
import { CyberPediaIconPath } from '@/utils/helpers';
import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledContainer = styled(FlexContainer)`
  height: 100%;
`;

const StyledAvatar = styled(Avatar)`
  border-radius: 50%;
  background: var(--white-main);
  cursor: pointer;
  &:hover {
    background: var(--gray-dark);
  }
  .p-avatar-text {
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);
  }
`;

const StyledMenu = styled(Menu)`
  margin: 4px 0;
  .separator .p-menuitem-link {
    padding: 0;
    border-bottom: 1px solid var(--gray-dark);
  }
  &.p-menu.p-menu-overlay .p-menuitem {
    border: 0;
  }
`;

const StyledImg = styled.img`
  cursor: pointer;
  max-width: 140px;
  max-height: 40px;
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  svg {
    fill: var(--white-main);
    &:hover {
      fill: var(--purple-light);
    }
  }
`;

export const TopbarMenu: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector(selectCurrentUser);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const selectedDashboard = useAppSelector(selectCurrentDashboard);

  const { can } = usePermission();

  const itemActionMenu = useRef<any>(null);

  const toggleItemActionMenu = (event: any) => {
    if (!itemActionMenu.current) {
      return;
    }

    itemActionMenu.current.toggle(event);
  };

  const logoRedirect = () => {
    if (localStorage.getItem(LAST_SELECTED_DASHBOARD) === DashboardType.ADMIN) {
      navigate(RedirectPaths[RedirectPathsEnum.ADMIN_DASHBOARD]());
    } else {
      navigate(RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE]());
    }
  };

  const menuItems: MenuItem[] = [
    {
      label: t('generic.profile.settings'),
      icon: 'pi pi-user',
      command: () => {
        if (
          localStorage.getItem(LAST_SELECTED_DASHBOARD) === DashboardType.ADMIN
        ) {
          navigate(RedirectPaths[RedirectPathsEnum.PROFILE_SETTINGS]());
        } else {
          navigate(
            RedirectPaths[RedirectPathsEnum.LEARNER_PROFILE_SETTINGS](),
            {
              state: { from: location.pathname },
            },
          );
        }
      },
    },
  ];

  if (
    can(Actions.READ, Subjects.ADMIN_DASHBOARD) &&
    localStorage.getItem(LAST_SELECTED_DASHBOARD) === DashboardType.LEARNER
  ) {
    menuItems.push(
      ...[
        {
          items: [
            {
              label: t('profile.goAdmin'),
              icon: 'pi pi-home',
              command: () => {
                navigate(RedirectPaths[RedirectPathsEnum.ADMIN_DASHBOARD]());
              },
            },
          ],
        },
      ],
    );
  } else if (
    can(Actions.READ, Subjects.ADMIN_DASHBOARD) &&
    localStorage.getItem(LAST_SELECTED_DASHBOARD) === DashboardType.ADMIN
  ) {
    menuItems.push(
      ...[
        {
          items: [
            {
              label: t('profile.goLearner'),
              icon: 'pi pi-home',
              command: () => {
                navigate(
                  RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE](),
                );
              },
            },
          ],
        },
      ],
    );
  }

  menuItems.push(
    ...[
      {
        items: [
          { separator: true, className: 'separator' },
          {
            label: t('generic.logout'),
            icon: 'pi pi-sign-out',
            command: () => {
              navigate(AppRoutesPaths.LOGOUT);
            },
          },
        ],
      },
    ],
  );

  return (
    <>
      <StyledMenu
        model={menuItems}
        popup
        ref={itemActionMenu}
        id="overlay_menu"
      />
      <StyledContainer justify="space-between" className="px-4">
        <StyledImg
          src={currentAccount?.platformSettings?.logo}
          alt={currentAccount?.platformSettings?.name}
          onClick={logoRedirect}
        />
        <FlexContainer justify="flex-end" gap={16}>
          {selectedDashboard === DashboardType.ADMIN &&
            !currentAccount?.isSystem && (
              <StyledLink
                href={CYBERPEDIA_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  width="115"
                  height="32"
                  viewBox="0 0 115 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <CyberPediaIconPath />
                </svg>
              </StyledLink>
            )}
          <StyledAvatar
            label={user?.firstName?.slice(0, 1).toUpperCase()}
            shape="circle"
            onClick={toggleItemActionMenu}
          />
        </FlexContainer>
      </StyledContainer>
    </>
  );
};
