import { TargetEntities, TargetEntity } from '@/common/types/targets.type';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import React from 'react';
import styled from 'styled-components';

type PlannerTargetsProps = {
  targets?: TargetEntities;
  isInclude?: boolean;
};

const StyledDiv = styled.div`
  display: flex;
  gap: var(--medium-padding);
  flex-wrap: wrap;

  span {
    white-space: nowrap;
  }
`;

export const PlannerTargets: React.FC<
  PlannerTargetsProps & React.HTMLAttributes<any>
> = ({ targets, isInclude, ...rest }) => {
  const account = useAppSelector(selectCurrentAccount);

  return (
    <>
      {targets && Object.values(targets)?.length ? (
        Object.values(targets).map((items, index) => (
          <StyledDiv key={index} {...rest}>
            {items?.map((enrolled: TargetEntity, index: number) => (
              <span key={index}>{enrolled.name}</span>
            ))}
          </StyledDiv>
        ))
      ) : isInclude ? (
        <span>{account?.name}</span>
      ) : null}
    </>
  );
};
