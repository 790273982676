import { TranslationFunctionType } from '@/common/types';
import { boolean, object, string } from 'yup';

export const branchesSchema = (
  t: TranslationFunctionType,
  isBranchAdmin: boolean,
) =>
  object({
    name: string().required(t('error.requiredField')),
    parent: isBranchAdmin
      ? object().required(t('error.requiredField'))
      : object().nullable(),
    status: boolean().default(false),
  });
