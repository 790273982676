export const USERNAME_REGEX = /^[A-Za-z0-9,_@.#-]*$/;
export const KEYCLOAK_USERNAME_REGEX = /^[a-z0-9_\-@.æøå]+$/;
export const EMAIL_REGEX =
  /^(([^æøå<>()[\]\\.,;:\s@"]+(\.[^æøå<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const URL_REGEX =
  /^(https?:\/\/)?((([a-zA-Z0-9$_.+!*'(),;?&=-]+):([a-zA-Z0-9$_.+!*'(),;?&=-]+)@)?((([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})|((\d{1,3}\.){3}\d{1,3})|(\[[0-9a-fA-F:]+\]))(:\d{2,5})?)(\/[a-zA-Z0-9$_.+!*'(),;?&=-]*)*(\?[a-zA-Z0-9$_.+!*'(),;?&=-]+)?(#[a-zA-Z0-9$_.+!*'(),;?&=-]+)?$/;

export const ACCOUNT_DOMAIN_REGEX = /^([a-z0-9]+(-[a-z0-9]+)*)?$/;

export const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*\d).+$/;

export const DOMAIN_REGEX =
  /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
