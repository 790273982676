import { equal, handleAxiosError, isIn, nested } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import {
  Actions,
  Subjects,
  SystemRoles,
  User,
  UsersBulk,
} from '@/client/users';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import {
  DataTableActions,
  DataTableColumnType,
} from '@/components/tables/crud';
import { UserDatatable } from '@/components/users/datatables';
import { BulkUsersModalForm } from '@/components/users/forms';
import { useAddUser, useBranch, useRemoveUser } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { BranchesTabs } from '@/pages/branches/BranchesTabs';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { branchAdminCheck, queryStateConverter } from '@/utils/helpers';
import { AxiosError } from 'axios/index';
import { FormikHelpers, FormikValues } from 'formik';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LoadingPage } from '../LoadingPage';

export const BranchUsers: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { can } = usePermission();
  const navigate = useNavigate();
  const location = useLocation();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const isBranchAdmin = branchAdminCheck(currentUser, currentAccount);

  const [bulkModalType, setBulkModalType] = useState<UsersBulk | undefined>(
    undefined,
  );
  const [deletingUserId, setDeletingUserId] = useState<string | null>(null);

  const { branch, isLoading } = useBranch({
    branchId: id as string,
    accountId: currentAccount?.id,
  });
  const account = useAppSelector(selectCurrentAccount);

  const filters = [
    equal('account', (account as Account)?.id),
    equal('branch', branch?.id),
  ];

  const bulkAddFilters = [
    equal('active', true),
    nested('role', [isIn('code', ['learner', 'admin'])]),
    equal('account', (account as Account)?.id),
    equal('branch', null),
  ];

  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);

  const onCreateOrDelete = () => {
    setShouldRefetch(true);

    setTimeout(() => {
      setShouldRefetch(false);
    }, 500);
  };

  const removeUser = useRemoveUser('branches');

  const pathItems = [
    {
      label: (account as Account)?.name,
      url: !account?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id as string)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('branches'),
      url: RedirectPaths[RedirectPathsEnum.BRANCHES](),
      template: AppBreadCrumbTemplate,
    },
    {
      label: branch?.name,
      url: RedirectPaths[RedirectPathsEnum.BRANCHES_EDIT](id as string),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('users'),
      url: RedirectPaths[RedirectPathsEnum.BRANCHES_USERS](id as string),
      template: AppBreadCrumbTemplate,
    },
  ];

  const { setDialogData } = useContext(DialogContext);

  const handleRemoveManager = async (userId: string) => {
    try {
      setDeletingUserId(userId);
      await removeUser.remove({
        accountId: (account as Account)?.id,
        typeId: branch?.id as string,
        userIds: [userId],
      });

      toast?.success(t('toast.success'), t('user.removed'));
      onCreateOrDelete();
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    } finally {
      setDeletingUserId(null);
    }
  };

  const menuItems = (user: User) => {
    const showActions =
      !currentAccount?.isSystem ||
      currentUser?.role.code === SystemRoles.DEVELOPER;

    if (!showActions) {
      return [];
    }

    const items = [
      {
        label: t('generic.edit'),
        icon: 'pi pi-pencil',
        command: () =>
          navigate(RedirectPaths[RedirectPathsEnum.USERS_EDIT](user.id), {
            state: {
              pathname: location.pathname,
              search: location.search,
            },
          }),
      },
    ];

    if (!isBranchAdmin && can(Actions.REMOVE_USER, Subjects.BRANCHES)) {
      items.push({
        label: t('generic.remove'),
        icon: 'pi pi-times',
        command: () =>
          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.remove'),
            message: t('user.remove'),
            closeImmediatelyAfterAccept: true,
            onAccept: async () => handleRemoveManager(user.id),
          }),
      });
    }

    return [{ label: t('generic.actions'), items }];
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'actions',
      header: '',
      sortable: false,
      filterable: false,
      style: {
        width: '80px',
        textAlign: 'center',
      },
      render: (row: User) => {
        if (deletingUserId === row.id) {
          return (
            <FlexContainer>
              <ProgressSpinner style={{ width: '24px', height: '24px' }} />
            </FlexContainer>
          );
        }
        return (
          <DataTableActions
            disabled={menuItems(row).length < 1}
            menuItems={menuItems(row)}
          />
        );
      },
    },
  ];

  const addUser = useAddUser('branches');
  const toast = useToast();

  const handleBulkAddUsers = async (
    data: FormikValues,
    helpers?: FormikHelpers<{ users?: User[] }>,
  ) => {
    try {
      await addUser.add({
        accountId: (account as Account)?.id,
        typeId: branch?.id as string,
        userIds: data.users.map((user: User) => user.id),
      });

      toast?.success(
        t('toast.success'),
        data?.users && data.users?.length > 1
          ? t('users.added')
          : t('user.added'),
      );
      setBulkModalType(undefined);
      helpers?.resetForm();
      onCreateOrDelete();
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleBulkRemoveUsers = async (
    data: FormikValues,
    helpers?: FormikHelpers<{ users?: User[] }>,
  ) => {
    try {
      await removeUser.remove({
        accountId: (account as Account)?.id,
        typeId: branch?.id as string,
        userIds: data.users.map((user: User) => user.id),
      });

      toast?.success(
        t('toast.success'),
        data?.users && data.users?.length > 1
          ? t('users.removed')
          : t('user.removed'),
      );
      setBulkModalType(undefined);
      helpers?.resetForm();
      onCreateOrDelete();
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  if (isLoading) {
    return <LoadingPage message={t('generic.loading')} />;
  }

  return (
    <>
      <AppBreadCrumb model={pathItems} />
      {branch && (
        <>
          <h1>{branch.name}</h1>
          <BranchesTabs branch={branch} />
        </>
      )}

      {can(Actions.ADD_USER, Subjects.BRANCHES) && (
        <BulkUsersModalForm
          visible={
            bulkModalType === UsersBulk.ADD ||
            bulkModalType === UsersBulk.REMOVE
          }
          onHide={() => setBulkModalType(undefined)}
          entityName={branch?.name}
          initialValues={{ users: [] }}
          additionalFilters={
            bulkModalType === UsersBulk.ADD ? bulkAddFilters : filters
          }
          onSubmit={
            bulkModalType === UsersBulk.ADD
              ? handleBulkAddUsers
              : handleBulkRemoveUsers
          }
          state={addUser ? queryStateConverter(addUser) : LoadingStatuses.IDLE}
          isAdd={bulkModalType === UsersBulk.ADD}
        />
      )}

      <UserDatatable
        withToolbar
        withImport
        withBranches
        withGroups={1}
        defaultFilters={filters}
        defaultTableFilters={{ status: 'yes' }}
        additionalColumns={columns}
        shouldRefetch={shouldRefetch}
        branchId={id}
        setBulkModalType={setBulkModalType}
      />
    </>
  );
};
