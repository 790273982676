import { Account } from '@/client/accounts';
import { Branch } from '@/client/branches';
import { CourseEntityEnrollEnum } from '@/client/courses';
import { TargetEntities, TargetEntity } from '@/common/types/targets.type';

export const getTargetEntityIdsByType = (
  entities: any[],
  type: CourseEntityEnrollEnum,
): any[] => {
  return entities
    .filter((entity: any) => entity.type === type)
    .map(({ id }) => id);
};

export const formatTargetEntities = (
  items: TargetEntity[],
  prefix: 'A' | 'B' | 'G' | 'U' | null,
  type: CourseEntityEnrollEnum,
) => {
  return (
    items?.map((item: TargetEntity) => ({
      id: item.id,
      name: prefix ? `${prefix} ${item.name}` : item.name,
      type,
      descendants: item?.descendants || [],
      parent: item?.parent,
    })) || []
  );
};

export const normalizeTargetsEntitiesFromRawObject = (
  targets: TargetEntities,
  currentAccount: Account,
  isExclude?: boolean,
): {
  accounts: TargetEntity[];
  branches: TargetEntity[];
  groups: TargetEntity[];
  users: TargetEntity[];
} => {
  let accounts = undefined;

  if (!isExclude) {
    accounts = formatTargetEntities(
      !targets?.branches?.length &&
        !targets?.groups?.length &&
        !targets?.users?.length
        ? [currentAccount]
        : [],
      'A',
      CourseEntityEnrollEnum.ACCOUNT,
    );
  }
  const branches = formatTargetEntities(
    targets?.branches || [],
    'B',
    CourseEntityEnrollEnum.BRANCHES,
  );
  const groups = formatTargetEntities(
    targets?.groups || [],
    'G',
    CourseEntityEnrollEnum.GROUPS,
  );
  const users = formatTargetEntities(
    targets?.users || [],
    'U',
    CourseEntityEnrollEnum.USERS,
  );

  return { accounts: accounts || [], branches, groups, users };
};

export const processIncludeExcludeTargetEntities = ({
  target,
  excludes,
  account,
}: {
  target: TargetEntities;
  excludes: TargetEntities;
  account: Account;
}) => {
  const {
    accounts: includedAccount,
    branches: includedBranches,
    groups: includedGroups,
    users: includedUsers,
  } = normalizeTargetsEntitiesFromRawObject(target, account);

  const {
    branches: excludedBranches,
    groups: excludedGroups,
    users: excludedUsers,
  } = normalizeTargetsEntitiesFromRawObject(excludes, account);

  const includedEntities = [
    ...includedAccount,
    ...includedBranches,
    ...includedGroups,
    ...includedUsers,
  ];

  const excludedEntities = [
    ...excludedBranches,
    ...excludedGroups,
    ...excludedUsers,
  ];

  return {
    includedEntities,
    excludedEntities,
  };
};

export const extractAllEntitiesDescendants = (
  items: any[],
  branches: Branch[] | undefined,
) => {
  return items.flatMap((item: Branch) => {
    const itemMapped = branches?.find(({ id }) => id === item.id);

    return formatTargetEntities(
      branches?.filter((b) =>
        itemMapped?.descendants?.some(({ id }: Branch) => id === b.id),
      ) || [],
      'B',
      CourseEntityEnrollEnum.BRANCHES,
    );
  });
};
