import { Conditions } from '@/api/enums';
import { equal, handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { PageTemplate, PageTemplatesEnum } from '@/client/page-templates';
import { ThumbnailState } from '@/client/thumbnails/enums';
import { Actions, Subjects } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { DialogContext } from '@/common/context';
import { TemplatesFilters } from '@/components/templates';
import ThumbnailsGenerationNotify from '@/components/thumbnails/ThumbnailsGenerationNotify';
import { useDeletePageTemplate, usePageTemplates } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTemplatesFilters } from '@/hooks/templates';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { RedirectPageTemplatesTabs } from '@/pages/redirect-page-templates';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { ImageCard } from '@/ui/image-card';
import { CardGridContainer, FlexContainer } from '@/ui/styled-ui';
import { displayCreatedBy } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const RedirectPageTemplatesCustomPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { setDialogData } = useContext(DialogContext);
  const account = useAppSelector(selectCurrentAccount);
  const deleteRedirectPageTemplate = useDeletePageTemplate();
  const { can } = usePermission();
  const {
    take,
    search,
    skip,
    handleSearch,
    handleChangeSkip,
    nameFilter,
    handleChangeDefaultRows,
  } = useTemplatesFilters();

  const breadCrumbItems = [
    {
      label: (account as Account)?.name,
      url: !account?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id as string)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('templates.custom.redirect'),
      url: RedirectPaths[RedirectPathsEnum.REDIRECT_PAGE_TEMPLATES_CUSTOM](),
      template: AppBreadCrumbTemplate,
    },
  ];

  // Templates filtered by account, will return only custom templates
  const { isLoading, pageTemplates, refetch } = usePageTemplates({
    take,
    skip,
    filters: [
      {
        field: 'account',
        condition: Conditions.EQUAL,
        value: (account as Account)?.id,
      },
      ...nameFilter,
      equal('type', PageTemplatesEnum.REDIRECT),
    ],
  });

  const handleDeleteRedirectPageTemplate = async (
    redirectPageTemplateId: string,
  ) => {
    try {
      await deleteRedirectPageTemplate.delete(redirectPageTemplateId);
      setTimeout(() => {
        refetch();
      }, 100);
      toast?.success(t('toast.success'), t('templates.custom.redirect.delete'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const menuItems = (redirectPageTemplate: PageTemplate) => {
    const actions: MenuItem[] = [];

    if (can(Actions.CREATE, Subjects.PAGES)) {
      actions.push({
        label: t('generic.copyEdit'),
        icon: 'pi pi-copy',
        command: () =>
          navigate(
            RedirectPaths[RedirectPathsEnum.REDIRECT_PAGE_TEMPLATES_CREATE](),
            {
              state: redirectPageTemplate,
            },
          ),
      });
    }

    if (can(Actions.UPDATE, Subjects.PAGES)) {
      actions.push({
        label: t('generic.edit'),
        icon: 'pi pi-pencil',
        command: () =>
          navigate(
            RedirectPaths[RedirectPathsEnum.REDIRECT_PAGE_TEMPLATES_UPDATE](
              redirectPageTemplate.id,
            ),
          ),
      });
    }

    if (can(Actions.DELETE, Subjects.PAGES)) {
      actions.push({
        label: t('generic.delete'),
        icon: 'pi pi-times',
        command: () =>
          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.delete.confirm'),
            message: t('dialog.delete.custom.redirect'),
            onAccept: async () =>
              await handleDeleteRedirectPageTemplate(redirectPageTemplate.id),
          }),
      });
    }

    return actions;
  };

  return (
    <>
      <AppBreadCrumb model={breadCrumbItems} />
      <FlexContainer justify="space-between">
        <h1>{t('templates.custom.redirect')}</h1>
        {can(Actions.CREATE, Subjects.PAGES) && (
          <AppButton
            label={t('button.createNew')}
            severity="secondary"
            onClick={() => {
              navigate(
                RedirectPaths[
                  RedirectPathsEnum.REDIRECT_PAGE_TEMPLATES_CREATE
                ](),
              );
            }}
          />
        )}
      </FlexContainer>
      <RedirectPageTemplatesTabs />

      <TemplatesFilters
        count={pageTemplates?.count ?? 0}
        take={take}
        skip={skip}
        onPageChange={handleChangeSkip}
        onRowsChange={handleChangeDefaultRows}
        onSearch={handleSearch}
        search={search}
      />
      {!isLoading && !pageTemplates?.result.length && (
        <FlexContainer direction="column" className="mt-5">
          <h3>{t('templates.redirect.empty')}</h3>
        </FlexContainer>
      )}
      {isLoading && !pageTemplates && (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
          <h3>{t('templates.redirect.loading')}</h3>
        </FlexContainer>
      )}
      {!isLoading && (!!pageTemplates?.result.length || search) && (
        <>
          <ThumbnailsGenerationNotify
            refetch={refetch}
            templates={pageTemplates?.result}
          />
          <CardGridContainer>
            {pageTemplates?.result.map((template) => (
              <ImageCard
                key={template.id}
                id={template.id}
                title={template.name}
                thumbnail={template.thumbnail?.signedUrl}
                isLoading={
                  template.thumbnailState === ThumbnailState.PROCESSING
                }
                author={displayCreatedBy(t, template.createdBy)}
                templateType="page"
                interactionType="withActions"
                actions={menuItems(template)}
              />
            ))}
          </CardGridContainer>
        </>
      )}
    </>
  );
};
