import { equal } from '@/api/helpers';
import {
  Account,
  AccountADFormValues,
  ADFormIndexValueMapping,
  ADFormMapping,
} from '@/client/accounts';
import { Actions, Subjects } from '@/client/users';
import {
  availableLanguagesDropdown,
  courseOnlyLanguages,
} from '@/common/constants/languages';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppButton, AppDropdownButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { branchAdminCheck } from '@/utils/helpers';
import { Field, FormikTouched } from 'formik';
import { Chip } from 'primereact/chip';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MenuItem } from 'primereact/menuitem';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FormikDropdown } from '../form';
import { BranchesSelectInput, GroupsMultiselectInput } from '../form/selectors';

const StyledFlexContainer = styled(FlexContainer)`
  position: relative;
  flex-direction: column;
  border-radius: var(--xsmall-border-radius);
  border: 2px solid var(--beige-main);
  padding: var(--default-padding);

  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    &.p-button-secondary:enabled:focus {
      box-shadow: none !important;
    }
  }
`;

const StyledChip = styled(Chip)`
  height: var(--default-input-height);
  border-radius: var(--default-border-radius);
  padding: 10px var(--default-padding);
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  background-color: var(--white-main);
  color: var(--black-main);
`;

type AzureADGroupSelectionPropsType = {
  index: string;
  values: ADFormIndexValueMapping;
  adGroup: ADFormMapping;
  account: Account;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors: any;
  touched: FormikTouched<AccountADFormValues>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => void;
  disabled?: boolean;
};

export const AzureADGroupSelection: React.FC<
  AzureADGroupSelectionPropsType
> = ({
  index,
  values,
  adGroup,
  account,
  setFieldValue,
  errors,
  touched,
  setFieldTouched,
  disabled,
}) => {
  const { can } = usePermission();
  const { t } = useTranslation();
  const [showAccount, setShowAccount] = useState(!adGroup.branches.length);
  const [showBranches, setShowBranches] = useState(!!adGroup.branches.length);
  const [showGroups, setShowGroups] = useState(!!adGroup.groups.length);
  const currentUser = useAppSelector(selectCurrentUser);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const isBranchAdmin = branchAdminCheck(currentUser, currentAccount);

  const setCurrentMapping = (key: string, value: any) => {
    if (!(touched.mapping && touched.mapping[index])) {
      setFieldTouched(`mapping.${index}`, true);
    }
    setFieldValue('mapping', {
      ...values,
      [index]: { ...adGroup, [key]: value },
    });
  };

  const removeCurrentMapping = () => {
    delete values[index];
    setFieldValue('mapping', values, true);
  };

  const dropdownButtonOptions: MenuItem[] = [
    {
      label: t('account'),
      command: () => {
        setShowBranches(false);
        setCurrentMapping('branches', []);
        setCurrentMapping('account', account);
        setShowAccount(true);
      },
      disabled: !!showAccount,
    },
    {
      label: t('branch'),
      command: () => {
        setShowAccount(false);
        setShowBranches(true);
        setCurrentMapping('account', undefined);
      },
      disabled: !!showBranches,
    },
    ...(can(Actions.READ, Subjects.GROUPS) && !isBranchAdmin
      ? [
          {
            label: t('group'),
            command: () => {
              setShowGroups(true);
              setFieldTouched(`mapping.${index}`, true);
            },
            disabled: !!showGroups,
          },
        ]
      : []),
  ];

  const hasError =
    !!errors.mapping &&
    errors.mapping[index] &&
    touched.mapping &&
    touched.mapping[index];

  return (
    <>
      <label htmlFor="adGroup" className={hasError ? 'p-error mt-4' : 'mt-4'}>
        {t('azure.mapGroup', { platformName: account?.platformSettings?.name })}
        <span className="red"> *</span>
      </label>
      <StyledFlexContainer className="mt-2">
        <AppButton
          className="close-icon"
          icon="pi pi-times"
          severity="secondary"
          type="text"
          rounded
          onClick={removeCurrentMapping}
          isDisabled={Object.keys(values).length === 1 || disabled}
        />
        <FlexContainer justify="flex-start" align="flex-end">
          <div className="field w-full">
            <label htmlFor="groupId">
              {t('azure.groupId')}
              <span className="red"> *</span>
            </label>
            <InputText
              defaultValue={adGroup?.groupId}
              onBlur={(event: any) =>
                setCurrentMapping('groupId', event.target.value)
              }
              className="w-full white"
              disabled={disabled}
            />
          </div>
          <AppDropdownButton
            menuId="add-to-azure-group"
            className="white-background ml-2 mb-3"
            label={t('generic.addTo')}
            severity="secondary"
            items={dropdownButtonOptions}
            isDisabled={disabled}
          />
        </FlexContainer>

        <div className="field w-full mb-4">
          <Field
            value={adGroup.language}
            id="language"
            label={t('azure.defaultLanguage')}
            name={`mapping.${index}.language`}
            filter
            onChange={(e: DropdownChangeEvent) =>
              setCurrentMapping('language', e.value)
            }
            onBlur={() => setFieldTouched(`mapping.${index}`, true)}
            className="w-full white"
            component={FormikDropdown}
            placeholder={t('generic.select')}
            options={availableLanguagesDropdown.filter(
              ({ value }) => !courseOnlyLanguages.includes(value),
            )}
            required
            disabled={disabled}
          />
        </div>

        {showAccount && (
          <FlexContainer justify="flex-start" align="flex-end">
            <div className="field w-full">
              <label htmlFor="account">{`${account?.platformSettings?.name} ${t(
                'account',
              )}`}</label>
              <StyledChip
                className={disabled ? 'w-full p-disabled' : 'w-full'}
                label={account?.name}
              />
            </div>
            <AppButton
              icon="pi pi-times"
              severity="secondary"
              className="white-background ml-2 mb-3"
              rounded
              onClick={() => {
                setShowBranches(true);
                setShowAccount(false);
                setCurrentMapping('account', undefined);
              }}
              isDisabled={disabled}
            />
          </FlexContainer>
        )}

        {showBranches && (
          <FlexContainer justify="flex-start" align="flex-end">
            <div className="field w-full">
              <label htmlFor="branches">{`${
                account?.platformSettings?.name
              } ${t('branch')}`}</label>
              <BranchesSelectInput
                className="w-full white"
                onChange={(branch) => setCurrentMapping('branches', [branch])}
                additionalFilters={[
                  equal('account', account?.id),
                  equal('active', true),
                ]}
                selectedValue={adGroup.branches[0]}
                isDisabled={disabled}
              />
            </div>
            <AppButton
              icon="pi pi-times"
              severity="secondary"
              className="white-background ml-2 mb-3"
              rounded
              onClick={() => {
                setShowAccount(true);
                setCurrentMapping('branches', []);
                setShowBranches(false);
                setCurrentMapping('account', account);
              }}
              isDisabled={disabled}
            />
          </FlexContainer>
        )}

        {showGroups && can(Actions.READ, Subjects.GROUPS) && !isBranchAdmin && (
          <FlexContainer justify="flex-start" align="flex-end">
            <div className="field w-full">
              <label htmlFor="groups">{`${account?.platformSettings?.name} ${t(
                'group',
              )}`}</label>
              <GroupsMultiselectInput
                className="w-full white"
                onChange={(groups) => setCurrentMapping('groups', groups)}
                additionalFilters={[
                  equal('account', account?.id),
                  equal('active', true),
                ]}
                selectedOptions={adGroup.groups}
                isDisabled={disabled}
                accountId={account?.id}
              />
            </div>
            <AppButton
              icon="pi pi-times"
              severity="secondary"
              className="white-background ml-2 mb-3"
              rounded
              onClick={() => {
                setShowGroups(false);
                setCurrentMapping('groups', []);
              }}
              isDisabled={disabled}
            />
          </FlexContainer>
        )}

        {hasError && (
          <small className="p-error align-self-start">
            {Object.values(errors.mapping[index]).join(', ')}
          </small>
        )}
      </StyledFlexContainer>
    </>
  );
};
