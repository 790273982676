import { GroupCondition } from '@/api/enums';
import { equal, group, isIn, isNotIn, nested } from '@/api/helpers';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { Branch, BranchDescendant } from '@/client/branches';
import { CourseEntityEnrollEnum } from '@/client/courses';
import { Group } from '@/client/groups';
import { User } from '@/client/users';
import { GroupedEntitiesType } from '@/common/types';
import {
  useAccountUsersPartialRequest,
  useBranchesPartialRequest,
  useGroupsPartialRequest,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { branchAdminCheck, RemoveIconPath } from '@/utils/helpers';
import { getAllParentIds } from '@/utils/helpers/branches.helper';
import {
  extractAllEntitiesDescendants,
  formatTargetEntities,
  getTargetEntityIdsByType,
} from '@/utils/targets';
import { uniqBy } from 'lodash';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FlexContainer } from '../styled-ui';

const StyledMultiSelect = styled(MultiSelect)`
  .p-multiselect-label {
    flex-wrap: nowrap;
  }
  .p-multiselect-token-label {
    &::first-letter {
      color: var(--gray-darker);
      margin-right: 4px;
    }
  }
`;

const StyledLink = styled.div`
  cursor: pointer;
  color: var(--red-main);
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);

  &:hover {
    color: var(--red-dark);
  }
`;

const StyledSpan = styled.span`
  font-size: var(--xsmall-font-size);
  color: var(--black-main);
`;

const StyledTopDiv = styled.div`
  width: 100%;
  color: var(--black-main);

  &.planner {
    padding: var(--medium-padding) var(--default-padding) 0
      var(--default-padding);
  }

  &.phishing {
    padding: 0;
  }
`;

type TargetSelectorPropsType = {
  includedEntities: (Account | Branch | Group)[];
  setIncludedEntities: any;
  excludedEntities: (Branch | Group)[];
  setExcludedEntities: any;
  title?: string;
  required?: boolean;
  className?: string;
};

export const TargetEntitiesSelector: React.FC<TargetSelectorPropsType> = ({
  includedEntities,
  setIncludedEntities,
  excludedEntities,
  setExcludedEntities,
  title,
  required,
  className,
}) => {
  const { t } = useTranslation();
  const account = useAppSelector(selectCurrentAccount);
  const user = useAppSelector(selectCurrentUser);
  const isBranchAdmin = branchAdminCheck(user, account);

  const [groupedEntitiesInclude, setGroupedEntitiesInclude] =
    useState<GroupedEntitiesType>([]);
  const [groupedEntitiesExclude, setGroupedEntitiesExclude] =
    useState<GroupedEntitiesType>([]);
  const [groupedUsers, setGroupedUsers] = useState<GroupedEntitiesType>([]);

  const [includedEntitiesDescendants, setIncludedEntitiesDescendants] =
    useState<(Branch | Group)[]>([]);
  const [excludedEntitiesDescendants, setExcludedEntitiesDescendants] =
    useState<(Branch | Group)[]>([]);

  const [shouldRefetchUsers, setShouldRefetchUsers] = useState(true);
  const [isExcludeUsers, setIsExcludeUsers] = useState(false);
  const [isDropdownLoading, setIsDropdownLoading] = useState(false);
  const [userBranches, setUserBranches] = useState<string[]>([]);

  const {
    branches,
    isLoading: isBranchesLoading,
    refetch: refetchBranches,
  } = useBranchesPartialRequest({
    accountId: account?.id,
    filters: [equal('active', true), isIn('id', userBranches)],
    sort: ['name,asc'],
    withDescendants: true,
    take: 20,
    enabled: !isBranchAdmin,
  });

  const { groups, isLoading: isGroupsLoading } = useGroupsPartialRequest({
    accountId: account?.id,
    sort: ['name,asc'],
    enabled: !isBranchAdmin,
  });

  const { isLoading: isUsersLoading, refetch: refetchUsers } =
    useAccountUsersPartialRequest({
      accountId: account?.id,
      filters: [
        equal('active', true),
        group(GroupCondition.OR, [
          group(GroupCondition.AND, [
            nested('branch', [
              isIn(
                'id',
                getTargetEntityIdsByType(
                  [...includedEntities, ...includedEntitiesDescendants],
                  CourseEntityEnrollEnum.BRANCHES,
                ),
              ),
            ]),
            nested('branch', [
              isNotIn(
                'id',
                getTargetEntityIdsByType(
                  [...excludedEntities, ...excludedEntitiesDescendants],
                  CourseEntityEnrollEnum.BRANCHES,
                ),
              ),
            ]),
          ]),
          nested('groups', [
            isIn(
              'id',
              getTargetEntityIdsByType(
                includedEntities,
                CourseEntityEnrollEnum.GROUPS,
              ),
            ),
          ]),
        ]),
      ],
      sort: ['name,asc'],
      enabled: false,
    });

  useEffect(() => {
    if (!account || isBranchesLoading || isGroupsLoading) return;
    const entities = [];

    if (branches?.length) {
      entities.push({
        label: t('branches'),
        items: formatTargetEntities(
          branches,
          'B',
          CourseEntityEnrollEnum.BRANCHES,
        ),
      });
    }

    if (groups?.length) {
      entities.push({
        label: t('groups'),
        items: formatTargetEntities(groups, 'G', CourseEntityEnrollEnum.GROUPS),
      });
    }

    setGroupedEntitiesExclude(entities);
    setGroupedEntitiesInclude([
      ...(isBranchAdmin
        ? []
        : [
            {
              label: t('account'),
              items: formatTargetEntities(
                [account],
                'A',
                CourseEntityEnrollEnum.ACCOUNT,
              ),
            },
          ]),
      ...entities,
    ]);

    if (isBranchesLoading) return;
    setIncludedEntitiesDescendants(
      extractAllEntitiesDescendants(includedEntities, branches),
    );
    setExcludedEntitiesDescendants(
      extractAllEntitiesDescendants(excludedEntities, branches),
    );
  }, [isBranchesLoading, isGroupsLoading]);

  useEffect(() => {
    if (!isBranchAdmin) return;
    const fetchBranchAdminBranches = async () => {
      const response = await client.branches.getBranches(
        {
          filters: [equal('active', true), equal('id', user?.branch?.id)],
          take: 1,
          withDescendants: true,
        },
        account?.id,
      );

      const branch = response.result[0];

      if (branch) {
        setUserBranches([
          branch.id,
          ...(branch?.descendants?.map(({ id }) => id) || []),
        ]);
      }

      setTimeout(() => {
        refetchBranches();
      });
    };

    fetchBranchAdminBranches();
  }, [user]);

  useEffect(() => {
    setShouldRefetchUsers(true);
  }, [includedEntities?.length, excludedEntities?.length]);

  const handleIncludeExcludeChange = (
    event: MultiSelectChangeEvent,
    isInclude: boolean,
  ) => {
    const items = event.value;

    const descendantsFormatted = extractAllEntitiesDescendants(items, branches);
    const filteredDescendatEntities = items.filter(
      ({ id }: Branch | Group) =>
        !descendantsFormatted.some((des) => des.id === id),
    );

    if (isInclude) {
      const accountSelected = items.find(
        (item: any) => item.id === account?.id,
      );

      // if account is selected, disselect all other entities
      if (accountSelected) {
        setIncludedEntities([accountSelected]);
      } else {
        setIncludedEntities(filteredDescendatEntities);
        setIncludedEntitiesDescendants(descendantsFormatted);
      }
    } else {
      setExcludedEntities(filteredDescendatEntities);
      setExcludedEntitiesDescendants(descendantsFormatted);
    }
  };

  const checkIsIncludeOptionDisabled = (option: Account | Branch | Group) => {
    if (
      includedEntities.find((entity) => entity.id === account?.id) &&
      option.id !== account?.id
    )
      return true;
    return (
      excludedEntities.some((excluded) => excluded.id === option.id) ||
      getAllParentIds(branches?.find(({ id }) => id === option.id)).some(
        (parentId) =>
          [
            ...includedEntities,
            ...excludedEntities,
            ...excludedEntitiesDescendants,
          ]
            .map(({ id }) => id)
            .includes(parentId),
      )
    );
  };

  const checkIsExcludeOptionDisabled = (option: any) => {
    return (
      includedEntities.some((included) => included.id === option.id) ||
      getAllParentIds(branches?.find(({ id }) => id === option.id)).some(
        (parentId) =>
          [...excludedEntities, ...excludedEntitiesDescendants]
            .map(({ id }) => id)
            .includes(parentId),
      ) ||
      option?.descendants?.some((descendant: BranchDescendant) =>
        includedEntities.map(({ id }) => id).includes(descendant.id),
      )
    );
  };

  const onChangeExcludeDropdownMode = async () => {
    setIsDropdownLoading(true);

    if (
      shouldRefetchUsers &&
      !isExcludeUsers &&
      ((isBranchAdmin && includedEntities?.length) || !isBranchAdmin)
    ) {
      const users = await refetchUsers();

      setGroupedUsers([
        {
          label: t('users'),
          items: formatTargetEntities(
            users.data || [],
            'U',
            CourseEntityEnrollEnum.USERS,
          ),
        },
      ]);

      setShouldRefetchUsers(false);
    }

    setIsExcludeUsers(!isExcludeUsers);

    setTimeout(() => {
      setIsDropdownLoading(false);
    }, 250);
  };

  const selectedItemTemplate = (
    option: Account | Branch | Group | User,
    isInclude: boolean,
  ) => {
    return option ? (
      <div
        className={
          (isInclude && checkIsIncludeOptionDisabled(option)) ||
          (!isInclude && checkIsExcludeOptionDisabled(option))
            ? 'p-multiselect-token p-disabled'
            : 'p-multiselect-token'
        }
        data-pc-section="token"
      >
        <span
          className="p-multiselect-token-label"
          data-pc-section="tokenlabel"
        >
          {option?.name}
        </span>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="p-icon p-multiselect-token-icon"
          aria-hidden="true"
          onClick={() =>
            isInclude
              ? setIncludedEntities(
                  includedEntities.filter(({ id }) => id !== option.id),
                )
              : setExcludedEntities(
                  excludedEntities.filter(({ id }) => id !== option.id),
                )
          }
        >
          <RemoveIconPath />
        </svg>
      </div>
    ) : null;
  };

  const footerTemplate = (
    <StyledLink onClick={onChangeExcludeDropdownMode}>
      {isExcludeUsers
        ? t('planner.excludeBranchesGroups')
        : t('planner.excludeUsers')}
    </StyledLink>
  );

  const loadingTemplate = () =>
    Array.from({ length: 5 }).map((_, index) => (
      <div key={index} className="flex align-items-center p-2">
        <Skeleton key={index} width="100%" height="32px" />
      </div>
    ));

  return (
    <StyledTopDiv className={className}>
      {title && (
        <StyledSpan>
          {title}
          {required && <span className="red"> *</span>}
        </StyledSpan>
      )}

      <FlexContainer justify="flex-start" gap={12} className="mt-1">
        <StyledMultiSelect
          dropdownIcon={
            isBranchesLoading || isGroupsLoading
              ? 'pi pi-spinner pi-spin'
              : 'pi pi-chevron-down'
          }
          dataKey="id"
          value={includedEntities}
          options={groupedEntitiesInclude}
          onChange={(event) => handleIncludeExcludeChange(event, true)}
          filter
          optionLabel="name"
          optionGroupLabel="label"
          optionGroupChildren="items"
          placeholder={isBranchAdmin ? t('branch.select') : t('generic.select')}
          display="chip"
          className="flex-1"
          disabled={isBranchesLoading || isGroupsLoading}
          optionDisabled={checkIsIncludeOptionDisabled}
          panelClassName="hide-first-letter"
          style={{ width: '200px' }}
          panelStyle={{ width: '200px' }}
          selectedItemTemplate={(item) => selectedItemTemplate(item, true)}
        />
        {t('generic.excluding')}
        <StyledMultiSelect
          dropdownIcon={
            isBranchesLoading || isGroupsLoading || isUsersLoading
              ? 'pi pi-spinner pi-spin'
              : 'pi pi-chevron-down'
          }
          dataKey="id"
          value={excludedEntities}
          options={isExcludeUsers ? groupedUsers : groupedEntitiesExclude}
          onChange={(event) => handleIncludeExcludeChange(event, false)}
          filter
          optionLabel="name"
          optionGroupLabel="label"
          optionGroupChildren="items"
          placeholder={t('generic.none')}
          display="chip"
          className="flex-1"
          disabled={isBranchesLoading || isGroupsLoading || isUsersLoading}
          optionDisabled={checkIsExcludeOptionDisabled}
          panelClassName="hide-first-letter"
          style={{ width: '200px' }}
          panelStyle={{ width: '200px' }}
          virtualScrollerOptions={
            isDropdownLoading
              ? {
                  loadingTemplate,
                  showLoader: true,
                  loading: isDropdownLoading,
                }
              : undefined
          }
          panelFooterTemplate={footerTemplate}
          selectedItemTemplate={(item) => selectedItemTemplate(item, false)}
          onHide={() => setIsExcludeUsers(false)}
        />
      </FlexContainer>
    </StyledTopDiv>
  );
};
