import { languageDisplayCode } from '@/api/enums';
import { equal, handleAxiosError } from '@/api/helpers';
import { FiltersType } from '@/api/types';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { getFiltersFromColumns } from '@/client/helpers';
import { SystemEmailTemplate } from '@/client/system-emails';
import { Actions, Subjects } from '@/client/users';
import {
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { availableLanguagesDropdown } from '@/common/constants/languages';
import { DialogContext } from '@/common/context';
import { TranslationFunctionType } from '@/common/types';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import {
  useDeleteSystemEmailTemplate,
  useSystemEmailsTemplates,
  useUpdateSystemEmailTemplate,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { CustomSwitch, SystemEmailsTabs } from '@/system-settings';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import { emailTypeOptions, getEmailTypesLabels } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { MenuItem } from 'primereact/menuitem';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const pathItems = (account: Account, t: TranslationFunctionType) => [
  {
    label: account?.name,
    url: !account?.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('generic.system.emails'),
    url: RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES](),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('templates'),
    url: RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES](),
    template: AppBreadCrumbTemplate,
  },
];

export const SystemEmailTemplatesPage = () => {
  const { can } = usePermission();
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const account = useAppSelector(selectCurrentAccount);
  const { setDialogData } = useContext(DialogContext);
  const { take, skip, sort, apiFilters, onPage, onSort, onFilter } = useTable();

  const [showCustom, setShowCustom] = useState<boolean>(!account?.isSystem);

  const [predefinedTemplatesFilter, setPredefinedTemplatesFilter] =
    useState<FiltersType>([
      equal('account', account?.isSystem ? null : account?.id),
    ]);

  const { systemEmailsTemplates, refetch, isLoading } =
    useSystemEmailsTemplates({
      skip,
      take,
      filters: [...apiFilters, ...predefinedTemplatesFilter],
      sort: sort && sort.length > 0 ? [sort.join(',')] : [],
    });
  const deleteSystemEmailTemplate = useDeleteSystemEmailTemplate();
  const updateSystemEmailTemplate = useUpdateSystemEmailTemplate();

  const menuItems = (emailTemplate: SystemEmailTemplate) => {
    const actions: MenuItem[] = [];

    if (can(Actions.CREATE, Subjects.SYSTEM_SETTINGS)) {
      actions.push({
        label: t('generic.copyEdit'),
        icon: 'pi pi-copy',
        command: () =>
          navigate(
            RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES_CREATE](),
            {
              state: emailTemplate,
            },
          ),
      });
    }

    if (
      can(Actions.UPDATE, Subjects.SYSTEM_SETTINGS) &&
      (account?.isSystem || showCustom)
    ) {
      actions.push(
        ...[
          {
            label: t('generic.edit'),
            icon: 'pi pi-pencil',
            command: () =>
              navigate(
                RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES_EDIT](
                  emailTemplate?.id,
                ),
              ),
          },
          {
            label: emailTemplate.active
              ? t('generic.deactivate')
              : t('generic.activate'),
            icon: emailTemplate.active ? 'pi pi-minus-circle' : 'pi pi-check',
            command: () => {
              const deactivateMsg = account?.isSystem
                ? t('system.email.templates.predefined.deactivate')
                : t('system.email.templates.custom.deactivate');
              const activateMsg = account?.isSystem
                ? t('system.email.templates.predefined.activate')
                : t('system.email.templates.custom.activate');

              setDialogData({
                type: 'confirmation',
                show: true,
                header: emailTemplate.active
                  ? t('dialog.deactivate.confirm')
                  : t('dialog.activate.confirm'),
                message: emailTemplate.active ? deactivateMsg : activateMsg,
                onAccept: async () =>
                  await handleToggleTemplateStatus(
                    emailTemplate.id,
                    emailTemplate.active as boolean,
                  ),
              });
            },
          },
        ],
      );
    }

    if (
      can(Actions.DELETE, Subjects.SYSTEM_SETTINGS) &&
      (account?.isSystem || showCustom)
    ) {
      actions.push({
        label: t('generic.delete'),
        icon: 'pi pi-times',
        command: () =>
          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.delete.confirm'),
            message: account?.isSystem
              ? t('system.email.templates.delete.predefined')
              : t('system.email.templates.delete.custom'),
            onAccept: async () =>
              await handleDeleteEmailTemplate(emailTemplate.id),
          }),
      });
    }

    return actions;
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'type',
      header: t('system.email.templates.emailType'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.MULTI_SELECT,
        placeholder: t('generic.type.search'),
        options: emailTypeOptions(t),
      },
      render: (row: SystemEmailTemplate) => getEmailTypesLabels(t)[row.type],
    },
    {
      field: 'language',
      header: t('generic.lang'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.MULTI_SELECT,
        placeholder: t('generic.lang.search'),
        options: availableLanguagesDropdown,
      },
      render: (row: SystemEmailTemplate) => languageDisplayCode[row.language],
    },
    {
      field: 'updated',
      header: t('generic.updated'),
      sortable: false,
      filterable: false,
      render: (row: SystemEmailTemplate) =>
        row.updated ? <FormatDate date={row.updated} /> : t('generic.never'),
    },
    {
      field: 'createdBy',
      header: t('generic.createdBy'),
      sortable: false,
      filterable: true,
      filters: { type: FilterTypeEnum.TEXT },
      render: (row: SystemEmailTemplate) =>
        row.createdBy
          ? `${row.createdBy?.firstName} ${row.createdBy?.lastName}`
          : t('generic.none'),
    },
    {
      field: 'updatedBy',
      header: t('generic.updatedBy'),
      sortable: false,
      filterable: true,
      filters: { type: FilterTypeEnum.TEXT },
      render: (row: SystemEmailTemplate) =>
        row.updatedBy
          ? `${row.updatedBy?.firstName} ${row.updatedBy?.lastName}`
          : t('generic.none'),
    },
    {
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      style: {
        width: '80px',
        textAlign: 'center' as const,
      },
      render: (row: SystemEmailTemplate) => (
        <DataTableActions
          disabled={menuItems(row).length < 1}
          menuItems={menuItems(row)}
        />
      ),
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['type', 'actions'];
  //

  const handleToggleTemplateStatus = async (
    emailTemplateId: string,
    active: boolean,
  ) => {
    try {
      await updateSystemEmailTemplate.update({
        emailId: emailTemplateId,
        active: !active,
      });
      await refetch();
      toast?.success(
        t('toast.success'),
        active
          ? t('templates.deactivated.success')
          : t('templates.activated.success'),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleDeleteEmailTemplate = async (emailTemplateId: string) => {
    try {
      await deleteSystemEmailTemplate.delete(emailTemplateId);
      setTimeout(() => {
        refetch();
      }, 100);
      toast?.success(
        t('toast.success'),
        account?.isSystem
          ? t('system.email.templates.delete.predefined.success')
          : t('system.email.templates.delete.custom.success'),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleToggle = () => {
    setShowCustom((state) => !state);
    setPredefinedTemplatesFilter([
      equal('account', showCustom ? null : account?.id),
    ]);
  };

  const toolbar = (
    <DataTableToolbar justify="space-between">
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
          tableName={TableNamesEnum.EMAIL_TEMPLATES_LIST}
        />
        {can(Actions.CREATE, Subjects.SYSTEM_SETTINGS) &&
          (showCustom || account?.isSystem) && (
            <AppButton
              label={t('button.createNew')}
              severity="secondary"
              onClick={() => {
                navigate(
                  RedirectPaths[
                    RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES_CREATE
                  ](),
                );
              }}
            />
          )}
      </FlexContainer>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={TableNamesEnum.EMAIL_TEMPLATES_LIST}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  return (
    <>
      <AppBreadCrumb model={pathItems(account as Account, t)} />
      <SystemEmailsTabs className="mb-4" />
      {!account?.isSystem && (
        <CustomSwitch
          checked={!!showCustom}
          label={t('campaign.testEmail.custom')}
          onChange={handleToggle}
        />
      )}
      <DataTable
        data={systemEmailsTemplates?.result}
        count={systemEmailsTemplates?.count}
        isLoading={isLoading}
        toolbar={toolbar}
        columns={columns}
        visibleColumns={visibleColumns}
        rows={take}
        skip={skip}
        onPage={onPage}
        onSort={onSort}
        sort={sort}
      />
    </>
  );
};
