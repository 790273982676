import { client } from '@/client';
import { ListCampaignsResponse } from '@/client/campaigns';
import {
  EmailTemplate,
  ListEmailTemplatesResponse,
} from '@/client/email-templates';
import {
  ListPageTemplatesResponse,
  PageTemplate,
} from '@/client/page-templates';
import { ThumbnailState } from '@/client/thumbnails/enums';
import { useNotifications } from '@/hooks/notifications.hook';
import { useToast } from '@/hooks/useToast';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import { QueryObserverResult } from 'react-query';
import { v4 } from 'uuid';

type ThumbnailsGenerationNotifyProps = {
  refetch: () =>
    | Promise<
        QueryObserverResult<
          ListEmailTemplatesResponse | ListPageTemplatesResponse
        >
      >
    | Promise<QueryObserverResult<ListCampaignsResponse>>;
  templates?: EmailTemplate[] | PageTemplate[];
};

export const ThumbnailsGenerationNotify: React.FC<
  ThumbnailsGenerationNotifyProps
> = ({ refetch, templates }) => {
  const toast = useToast();
  const id = v4();
  const {
    lastMessage: notifications,
    setNotificationParam: setNotifyId,
    notificationParam: notifyId,
  } = useNotifications(client.thumbnails.thumbnailsNotifyUrl);

  useEffect(() => {
    if (!notifyId) return;
    handleThumbnailsNotifications(notifications);
  }, [JSON.stringify(notifications)]);

  const handleThumbnailsNotifications = async (messages: any) => {
    if (
      !messages?.data?.event ||
      !['thumbnail.generation'].includes(messages?.data?.event)
    )
      return;

    if (messages?.data?.payload?.state === ThumbnailState.FAILED) {
      toast?.error(
        t('toast.error'),
        t('thumbnails.generation.somethingWentWrong'),
        5000,
      );

      setNotifyId(undefined);
      return;
    }

    await refetch();
    setNotifyId(undefined);
  };

  useEffect(() => {
    if (
      templates?.length &&
      templates?.some(
        (template) => template?.thumbnailState === ThumbnailState.PROCESSING,
      )
    ) {
      setNotifyId(id);
    }
  }, [templates]);

  return null;
};

export default ThumbnailsGenerationNotify;
