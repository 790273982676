import {
  EMAIL_REGEX,
  PASSWORD_REGEX,
  USERNAME_REGEX,
} from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { boolean, object, string } from 'yup';

export const createSenderProfileSchema = (t: TranslationFunctionType) =>
  object({
    name: string().required(t('error.requiredField')),
    senderName: string().nullable(),
    fromAddress: string()
      .required(t('error.requiredField'))
      .matches(EMAIL_REGEX, t('error.invalidEmail')),
    interfaceType: string().required(t('error.requiredField')),
    host: string().required(t('error.requiredField')),
    username: string().matches(USERNAME_REGEX, t('error.onlyLetters')),
    password: string()
      .matches(PASSWORD_REGEX, t('error.password.complexity'))
      .min(8, t('error.password.length', { length: 8 })),
    ignoreCertErrors: boolean(),
  });

export const updateSenderProfileSchema = (t: TranslationFunctionType) =>
  object({
    name: string().required(t('error.requiredField')),
    senderName: string().nullable(),
    fromAddress: string()
      .required(t('error.requiredField'))
      .matches(EMAIL_REGEX, t('error.invalidEmail')),
    interfaceType: string().required(t('error.requiredField')),
    host: string().required(t('error.requiredField')),
    username: string().matches(USERNAME_REGEX, t('error.onlyLetters')),
    password: string().min(8, t('error.password.length', { length: 8 })),
    ignoreCertErrors: boolean(),
  });
