export const randomString = (length: number): string => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$^&*()';
  return [...Array(length)]
    .map(() => characters[Math.floor(Math.random() * characters.length)])
    .join('');
};

export const singularOrPluralTranslation = (
  num: number,
  singularTranslation: string,
  pluralTranslation: string,
) => {
  if (Number(num) === 1) {
    return singularTranslation;
  } else {
    return pluralTranslation;
  }
};
