import { getQueryTransformation } from '@/api/helpers';
import { FiltersType, FilterValue } from '@/api/types';
import { FilterNamesEnum, getFilters } from '@/client/helpers';
import { useQueryParams } from '@/hooks/query.hook';
import { DataTablePageEvent } from 'primereact/datatable';
import { useEffect, useState } from 'react';

export const useTable = () => {
  const [skip, setSkip] = useState<number>(0);
  const [take, setTake] = useState<number>(10);

  const { params, setSearchParams } = useQueryParams(
    getQueryTransformation<{ name: string }>(),
  );
  const [apiFilters, setFilters] = useState<FiltersType>(
    params.filters ? getFilters(params.filters) : [],
  );
  const [filterValues, setFilterValues] = useState<Record<string, any>>(
    params.filters ? params.filters : {},
  );
  const [sort, setSort] = useState<[string, 'asc' | 'desc'] | null>(
    params['sort[]'] ? params['sort[]'] : ['created', 'desc'],
  );

  useEffect(() => {
    setSearchParams({
      filters: filterValues,
      'sort[]': sort,
    });
  }, [JSON.stringify(filterValues), JSON.stringify(sort)]);

  const onPage = (event: DataTablePageEvent) => {
    setSkip(event.first);
    setTake(event.rows);
  };

  const onGridRowChange = (value: number) => setTake(value);
  const onGridPageChange = (value: number) => setSkip(value);

  const onSort = (event: DataTablePageEvent) => {
    setSkip(0);
    setSort([event.sortField, event.sortOrder === -1 ? 'desc' : 'asc']);
  };

  const onFilter = (values: Record<FilterNamesEnum, FilterValue>) => {
    setSkip(0);
    setFilterValues(values);
    setFilters(getFilters(values));
  };

  return {
    skip,
    take,
    sort,
    apiFilters,
    onPage,
    onSort,
    setSkip,
    onFilter,
    onGridPageChange,
    onGridRowChange,
  };
};
