import { FlexContainer } from '@/ui/styled-ui';
import React, { ReactNode } from 'react';

type FormContainerProps = {
  width?: number;
  children?: ReactNode;
};

const FormContainer: React.FC<FormContainerProps> = ({ width, children }) => (
  <FlexContainer
    width={width ? `${width}%` : '50%'}
    direction="column"
    align="flex-start"
  >
    {children}
  </FlexContainer>
);

export default FormContainer;
