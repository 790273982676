import { TableNamesEnum, TableNamesKeys } from '@/common/constants';
import { DataTableFilter } from '@/components/tables/crud/filters/DataTableFilter';
import { FilterType } from '@/components/tables/crud/types';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import { selectOpenFilters, setOpenFilters } from '@/store/features/filters';
import { selectCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import React, {
  SyntheticEvent,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

type FiltersProps = {
  filters: FilterType[];
  onFilter: (filters: Record<string, any>) => void;
  tableName?: TableNamesEnum;
  defaultValues?: Record<string, any>;
  forcedFilter?: Record<string, any>;
  setOpenedFilters?: React.Dispatch<React.SetStateAction<string[] | undefined>>;
} & React.HTMLAttributes<HTMLElement>;

const filtersReducer = (
  state: Record<string, { value: any; filter: FilterType }>,
  action: {
    type: 'updateValue' | 'clear' | 'remove' | 'add';
    key: string;
    value?: any;
    filter?: FilterType;
  },
): Record<string, { value: any; filter: FilterType }> => {
  const newState: typeof state = {};

  switch (action.type) {
    case 'clear':
      return {};
    case 'remove':
      Object.keys(state)
        .filter((x) => x !== action.key)
        .map((x) => (newState[x] = state[x]));

      return { ...newState };
    case 'add':
      return {
        ...state,
        [action.key]: {
          value: action.filter?.defaultValue ?? null,
          filter: action.filter as FilterType,
        },
      };
    case 'updateValue':
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          value: action.value,
        },
      };
    default:
      return state;
  }
};

const buildFiltersFromValues = (
  filters: FilterType[],
  values: Record<string, any> | undefined,
): Record<string, { value: any; filter: FilterType }> => {
  if (!values) {
    return {};
  }

  return Object.keys(values).reduce((a, k) => {
    if (!values[k]) {
      return a;
    }

    const filter = filters.find((f) => f.field === k);

    if (!filter) {
      return a;
    }

    return {
      ...a,
      [k]: {
        value: typeof values[k] === 'string' ? values[k].trim() : values[k],
        filter,
      },
    };
  }, {});
};

export const DataTableFilters: React.FC<FiltersProps> = ({
  filters,
  onFilter,
  tableName,
  defaultValues,
  forcedFilter,
  setOpenedFilters,
  ...rest
}) => {
  const allOpenFilters = useAppSelector(selectOpenFilters);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const user = useAppSelector(selectCurrentUser);
  const { t } = useTranslation();
  const [values, dispatch] = useReducer(
    filtersReducer,
    !forcedFilter
      ? {
          ...buildFiltersFromValues(filters, defaultValues),
          ...(tableName && allOpenFilters
            ? allOpenFilters[
                `${TableNamesKeys[tableName]}-${currentAccount?.id}-${user?.id}`
              ]
            : {}),
        }
      : buildFiltersFromValues(filters, forcedFilter),
  );
  const appDispatcher = useAppDispatch();

  const menuRef = useRef<Menu>(null);
  const items: MenuItem[] = useMemo(
    () =>
      filters.map(
        (f): MenuItem => ({
          label: f.label,
          disabled: !!values[f.field],
          command: () => dispatch({ type: 'add', filter: f, key: f.field }),
        }),
      ),
    [JSON.stringify(filters), JSON.stringify(values)],
  );

  const onChange = (value: any, field: string) => {
    dispatch({
      type: 'updateValue',
      value,
      key: field,
    });
  };

  const onRemove = (field: string) => {
    dispatch({
      type: 'remove',
      key: field,
    });
  };

  useEffect(() => {
    onFilter(
      Object.values(values).reduce(
        (a, v) => ({ ...a, [v.filter.field]: v.value }),
        {},
      ),
    );
    if (setOpenedFilters) {
      setOpenedFilters(Object.keys(Object(values)));
    }

    if (tableName && !forcedFilter) {
      appDispatcher(
        setOpenFilters({
          ...allOpenFilters,
          [`${TableNamesKeys[tableName]}-${currentAccount?.id}-${user?.id}`]:
            values,
        }),
      );
    }
  }, [JSON.stringify(values)]);

  return (
    <FlexContainer
      width="auto"
      flex="1"
      wrap="wrap"
      gap={12}
      justify="flex-start"
      align="flex-start"
      {...rest}
    >
      <Menu model={items} popup ref={menuRef} />
      <AppButton
        label={t('filter.add')}
        icon="pi pi-filter"
        type="outlined"
        onClick={(event: SyntheticEvent<HTMLButtonElement>) =>
          menuRef.current?.toggle(event)
        }
      />
      {Object.keys(values).length ? (
        <>
          {Object.keys(values).map((f: string) => (
            <DataTableFilter
              {...values[f]}
              onChange={onChange}
              key={f}
              onRemove={onRemove}
            />
          ))}
        </>
      ) : (
        <></>
      )}
    </FlexContainer>
  );
};
