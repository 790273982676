import { Accordion, AccordionProps, AccordionTab } from 'primereact/accordion';
import React from 'react';
import styled from 'styled-components';

const StyledAccordion = styled(Accordion)`
  &.p-accordion {
    .p-accordion-header {
      display: inline-block;
      .p-accordion-header-link {
        color: var(--gray-darker);
        font-weight: 500;
        background-color: transparent;
        border: none;
        text-transform: uppercase;
        padding: var(--default-padding) 0;
        .accordion-header {
          color: var(--gray-darker);
        }

        &:not(.p-disabled):hover {
          color: var(--gray-darker);
          background-color: transparent;
        }
      }

      .p-accordion-toggle-icon {
        order: 1;
        padding-left: var(--small-padding);
      }

      &:not(.p-disabled).p-highlight .p-accordion-header-link {
        background-color: transparent;
        color: var(--gray-darker);
      }
      &:not(.p-disabled) .p-accordion-header-link:focus {
        box-shadow: none;
      }
      &:not(.p-disabled).p-highlight:hover {
        .p-accordion-header-link {
          background-color: transparent;
          border: none;
        }
      }

      &:not(p-disabled):hover {
        background-color: transparent;
      }
    }

    .p-accordion-content {
      border: none;
      padding: 0;
      color: var(--black-main);
      .p-calendar .p-inputtext:not(.p-dropdown-label) {
        padding-left: var(--xsmall-padding);
      }
    }
    &.p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
      background-color: transparent;
    }
  }
`;

type AppFormAccordionPropsType = {
  label: string;
  children: React.ReactNode;
} & AccordionProps;

export const AppFormAccordion: React.FC<AppFormAccordionPropsType> = ({
  label,
  children,
  ...rest
}) => {
  return (
    <StyledAccordion
      collapseIcon="pi pi-chevron-up"
      expandIcon="pi pi-chevron-down"
      {...rest}
    >
      <AccordionTab
        headerTemplate={<div className="accordion-header">{label}</div>}
      >
        {children}
      </AccordionTab>
    </StyledAccordion>
  );
};
