import { Conditions, GroupCondition } from '@/api/enums';
import { equal, group } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { MaterialType } from '@/client/courses';
import { Actions, Subjects } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { GridTable } from '@/components/grid-table';
import { DataTableToolbar } from '@/components/tables/crud';
import { useCourses } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { DocumentCard } from '@/ui/document-card';
import { CardGridContainer, FlexContainer } from '@/ui/styled-ui';
import { branchAdminCheck, templatesColumnsWidth } from '@/utils/helpers';
import { debounce } from 'lodash';
import { InputText } from 'primereact/inputtext';
import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledCardGridContainer = styled(CardGridContainer)`
  margin: 0;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: ${templatesColumnsWidth.ONE}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const MaterialsPage: React.FC<{ type: MaterialType }> = ({ type }) => {
  const { t } = useTranslation();
  const { can } = usePermission();
  const navigate = useNavigate();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const user = useAppSelector(selectCurrentUser);
  const isCustomMaterial = type === MaterialType.CUSTOM_MATERIAL;
  const isBranchAdmin = branchAdminCheck(user, currentAccount);

  const {
    skip,
    take,
    apiFilters,
    onFilter,
    onGridPageChange,
    onGridRowChange,
    setSkip,
  } = useTable();

  const [showValue, setShowValue] = useState('');
  const [multiSearchValue, setMultiSearchValue] = useState('');
  const debouncedSetMultiSearchValue = useMemo(
    () =>
      debounce((event: FormEvent) => {
        setSkip(0);
        setMultiSearchValue((event.target as HTMLInputElement).value);
      }, 500),
    [],
  );

  const handleChange = (e: FormEvent) => {
    setShowValue((e.target as HTMLInputElement).value);
    debouncedSetMultiSearchValue(e);
  };

  useEffect(() => {
    setShowValue('');
    setMultiSearchValue('');
  }, [type]);

  const groupFilters = [
    group(GroupCondition.AND, [
      ...(!currentAccount?.isSystem
        ? [
            {
              field: 'availableToAccounts',
              condition: Conditions.EQUAL,
              value: currentAccount?.id,
            },
          ]
        : []),
      equal('type', type),
    ]),
  ];

  if (currentAccount?.meta?.enableMaterialsUpload) {
    groupFilters.push(
      group(GroupCondition.AND, [
        ...(!currentAccount?.isSystem
          ? [
              {
                field: 'trial',
                condition: Conditions.EQUAL,
                value: true,
              },
            ]
          : []),
        equal('type', type),
      ]),
    );
  }

  const { courses: templates, isLoading } = useCourses({
    take: take,
    skip: skip,
    search: multiSearchValue,
    filters: [...apiFilters, group(GroupCondition.OR, groupFilters)],
  });

  const pathItems = [
    {
      label: currentAccount?.name,
      url: !currentAccount?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](currentAccount?.id as string)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: isCustomMaterial ? t('custom.materials') : t('resources'),
      url: RedirectPaths[
        isCustomMaterial
          ? RedirectPathsEnum.CUSTOM_MATERIALS
          : RedirectPathsEnum.RESOURCES
      ](),
      template: AppBreadCrumbTemplate,
    },
  ];

  const toolbar = (
    <DataTableToolbar justify="space-between">
      {/* <DataTableFilters
        className="flex-initial"
        filters={getFilters(t)}
        onFilter={onFilter}
        tableName={
          isCustomMaterial
            ? TableNamesEnum.CUSTOM_MATERIALS_LIST
            : TableNamesEnum.RESOURCES_LIST
        }
      /> */}

      <div className="p-input-icon-left flex-auto min-w-300">
        <InputText
          className="w-full"
          value={showValue}
          onChange={handleChange}
          placeholder={t('generic.name.search')}
          autoComplete="off"
        />
        <i className="pi pi-search" />
      </div>
    </DataTableToolbar>
  );

  return (
    <>
      <AppBreadCrumb model={pathItems} />
      <FlexContainer justify="space-between" className="mb-4">
        <h1 className="mb-0">
          {isCustomMaterial ? t('custom.materials') : t('resources')}
        </h1>
        {((can(Actions.CREATE, Subjects.COURSES) && currentAccount?.isSystem) ||
          (isCustomMaterial && !isBranchAdmin)) && (
          <AppButton
            label={t('button.createNew')}
            severity="secondary"
            onClick={() => {
              navigate(
                RedirectPaths[
                  isCustomMaterial && !isBranchAdmin
                    ? RedirectPathsEnum.CUSTOM_MATERIALS_CREATE
                    : RedirectPathsEnum.RESOURCES_CREATE
                ](),
              );
            }}
          />
        )}
      </FlexContainer>
      <GridTable
        isLoading={isLoading}
        count={templates?.count}
        take={take}
        skip={skip}
        onPageChange={onGridPageChange}
        onRowsChange={onGridRowChange}
        toolbarContent={toolbar}
        rowsPerPage={[6, 12]}
      >
        <FlexContainer direction="column" align="flex-start">
          {!!templates?.count && (
            <StyledCardGridContainer>
              {templates?.result.map((template) => (
                <DocumentCard
                  key={template.id}
                  template={template}
                  isSystem={currentAccount?.isSystem}
                  withActions
                />
              ))}
            </StyledCardGridContainer>
          )}
        </FlexContainer>
      </GridTable>
    </>
  );
};
