import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import styled from 'styled-components';

const StyledLoaderContainer = styled.div<{ fullScreen?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: var(--default-padding);
  justify-content: center;
  align-items: center;
  width: ${(props) =>
    props.fullScreen ? '100%' : 'calc(100% + (var(--default-padding) * 2))'};
  height: ${(props) =>
    props.fullScreen ? '100%' : 'calc(100% + (var(--default-padding) * 2))'};
  top: ${(props) =>
    props.fullScreen ? 0 : 'calc(-1 * var(--default-padding))'};
  left: ${(props) =>
    props.fullScreen ? 0 : 'calc(-1 * var(--default-padding))'};
  z-index: 1000;
  pointer-events: all;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
`;

type AbsoluteLoaderProps = {
  message?: string;
  fullScreen?: boolean;
};

const AbsoluteLoader: React.FC<AbsoluteLoaderProps> = ({
  message,
  fullScreen,
}) => (
  <StyledLoaderContainer fullScreen={fullScreen}>
    <ProgressSpinner />
    {message && <h2>{message}</h2>}
  </StyledLoaderContainer>
);

export default AbsoluteLoader;
