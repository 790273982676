import { processListingParams } from '@/api/helpers';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { MessageResponseModel } from '@/client/models';
import {
  ListPageTemplatesRequest,
  ListPageTemplatesResponse,
  PageTemplate,
  SavePageTemplateRequest,
  UpdatePageTemplateRequest,
} from '@/client/page-templates';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

export const pageTemplatesService = (client: AxiosInstance) => {
  const LANDING_PAGE_TEMPLATES_BASE_URL = '/gophish/pages';

  const getPageTemplates = async (
    params: ListPageTemplatesRequest,
  ): Promise<ListPageTemplatesResponse> => {
    try {
      const result = await client.get<
        ListPageTemplatesResponse,
        AxiosResponse<ListPageTemplatesResponse>
      >(LANDING_PAGE_TEMPLATES_BASE_URL, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getPageTemplate = async (
    landingPageTemplateId?: string,
  ): Promise<PageTemplate> => {
    try {
      const result = await client.get<
        PageTemplate,
        AxiosResponse<PageTemplate>
      >(`${LANDING_PAGE_TEMPLATES_BASE_URL}/${landingPageTemplateId}`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const savePageTemplate = async (
    request: SavePageTemplateRequest,
  ): Promise<PageTemplate> => {
    try {
      const result = await client.post(
        LANDING_PAGE_TEMPLATES_BASE_URL,
        request,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updatePageTemplate = async (
    request: UpdatePageTemplateRequest,
  ): Promise<PageTemplate> => {
    try {
      const result = await client.patch<
        PageTemplate,
        AxiosResponse<PageTemplate>
      >(
        `${LANDING_PAGE_TEMPLATES_BASE_URL}/${request.pageTemplateId}`,
        request.updates,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deletePageTemplate = async (
    landingPageTemplateId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${LANDING_PAGE_TEMPLATES_BASE_URL}/${landingPageTemplateId}`, {
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  return {
    getPageTemplates,
    getPageTemplate,
    savePageTemplate,
    updatePageTemplate,
    deletePageTemplate,
  };
};
