import { languageDisplayCode, LanguagesEnum } from '@/api/enums';
import { CourseFormState, MaterialType } from '@/client/courses';
import { File } from '@/client/files';
import { CourseFileUpload } from '@/ui/course-file-upload';
import { FlexContainer } from '@/ui/styled-ui';
import { TabSwitchButtons } from '@/ui/tab-switch-buttons';
import { sortLanguagesWithPriority } from '@/utils/helpers';
import { FormikValues } from 'formik';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledFlexContainer = styled(FlexContainer)`
  border: 1px solid var(--gray-dark);
  border-radius: var(--xsmall-border-radius);
  padding: var(--default-padding);
  margin-top: 6px;
`;

const StyledLabel = styled.span`
  display: block;
  font-size: var(--xsmall-font-size);
  line-height: var(--xsmall-line-height);
  font-weight: 500;
`;

type CourseFilesProps = {
  values: CourseFormState;
  label: string;
  selfHosted?: boolean;
  isCreate?: boolean;
  isDisabled: boolean;
  setFieldValue: (field: string, value: any) => void;
  setIsSubmitDisabled: (isSubmitDisabled: boolean) => void;
};

export const CourseFiles: React.FC<CourseFilesProps> = ({
  values,
  label,
  selfHosted,
  isCreate,
  isDisabled,
  setFieldValue,
  setIsSubmitDisabled,
}) => {
  const [selectedFileTab, setSelectedFileTab] = useState<
    keyof typeof LanguagesEnum
  >(languageDisplayCode[LanguagesEnum.EN] as keyof typeof LanguagesEnum);

  const [requiredTranslations, setRequiredTranslations] = useState([
    languageDisplayCode[LanguagesEnum.EN],
  ]);

  const [preselectedFile, setPreselectedFile] = useState<File | null>(null);

  const [isTabsDisabled, setIsTabsDisabled] = useState<boolean>(false);

  const [validOptions, setValidOptions] = useState([] as string[]);
  const checkValidOptions = (values: FormikValues) => {
    return values.courseLanguages
      .filter((languageCode: string) => {
        if (values.courseFiles[languageCode]) {
          return !!values.courseFiles[languageCode].file;
        }
      })
      .map((languageCode: string) => languageDisplayCode[languageCode]);
  };

  useEffect(() => {
    if (values?.courseLanguages) {
      setRequiredTranslations(
        values.courseLanguages.map(
          (languageCode: string) => languageDisplayCode[languageCode],
        ),
      );
    }
    setValidOptions(checkValidOptions(values));
    if (values?.courseFiles) {
      const language =
        isCreate || selfHosted
          ? LanguagesEnum[selectedFileTab]
          : LanguagesEnum.EN;

      setPreselectedFile(values.courseFiles[language]?.file);
    }
  }, [values, selectedFileTab]);

  useEffect(() => {
    setFieldValue('courseFiles', {
      [LanguagesEnum.EN]: {
        file: null,
      },
    });
  }, [selfHosted]);

  useEffect(() => {
    const fileTab = values.courseLanguages?.length
      ? languageDisplayCode[values.courseLanguages[0]]
      : languageDisplayCode[LanguagesEnum.EN];
    setSelectedFileTab(fileTab as keyof typeof LanguagesEnum);
  }, [values.courseLanguages]);

  useEffect(() => {
    setIsSubmitDisabled(isTabsDisabled);
  }, [isTabsDisabled]);

  const handleFileUpload = (file: File | null) => {
    if (file?.id) {
      setFieldValue(`courseFiles.${LanguagesEnum[selectedFileTab]}.file`, file);
    } else {
      setFieldValue(`courseFiles.${LanguagesEnum[selectedFileTab]}.file`, null);
    }
  };

  return (
    <div>
      <StyledLabel className="mt-4">
        {label} {!values.releaseDate && <span className="red"> *</span>}
      </StyledLabel>

      {isCreate || selfHosted ? (
        <StyledFlexContainer justify="flex-start" direction="column">
          <TabSwitchButtons
            options={sortLanguagesWithPriority(
              values?.courseLanguages?.map(
                (languageCode: string) => languageDisplayCode[languageCode],
              ) ?? [LanguagesEnum.EN],
            )}
            requiredOptions={requiredTranslations}
            validOptions={validOptions}
            isDisabled={isDisabled || isTabsDisabled}
            onToggle={(option) =>
              setSelectedFileTab(option as keyof typeof LanguagesEnum)
            }
            defaultSelected={selectedFileTab}
          />
          <div
            className={
              values?.courseLanguages?.length
                ? 'field w-full mt-4 mb-0'
                : 'field w-full mb-0'
            }
          >
            <CourseFileUpload
              key={selectedFileTab}
              onFileUpload={handleFileUpload}
              preselectedFile={preselectedFile}
              type={values.type}
              isDisabled={
                isDisabled || (selfHosted && !values?.courseLanguages?.length)
              }
              setIsTabsDisabled={setIsTabsDisabled}
            />
          </div>
        </StyledFlexContainer>
      ) : (
        <StyledFlexContainer justify="flex-start" direction="column">
          <div className="field w-full mt-0 mb-0">
            <CourseFileUpload
              key={selectedFileTab}
              onFileUpload={(file: File | null) => {
                setFieldValue(`courseFiles.${LanguagesEnum.EN}.file`, file);
              }}
              setIsTabsDisabled={setIsTabsDisabled}
              preselectedFile={preselectedFile}
              type={values.type}
              isDisabled={isDisabled}
            />
          </div>
        </StyledFlexContainer>
      )}
    </div>
  );
};
