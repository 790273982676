import { AuthKeycloakRedirectIfNotLogged } from '@/auth/components/AuthKeycloakRedirectIfNotLogged';
import React from 'react';

type AuthKeycloakProtectedProps = {
  children: React.ReactNode;
};

export const AuthKeycloakProtected: React.FC<AuthKeycloakProtectedProps> = ({
  children,
}) => {
  return (
    <AuthKeycloakRedirectIfNotLogged>
      {children}
    </AuthKeycloakRedirectIfNotLogged>
  );
};
