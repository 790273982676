import { like } from '@/api/helpers';
import { FilterCondition } from '@/api/types';
import { useEffect, useState } from 'react';

export const useTemplatesFilters = () => {
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(12);
  const [search, setSearch] = useState<string>('');
  const [nameFilter, setNameFilter] = useState<FilterCondition[]>([]);

  useEffect(() => {
    setNameFilter(search ? [like('name', search)] : []);
  }, [search]);

  const handleChangeDefaultRows = (value: number) => setTake(value);
  const handleChangeSkip = (value: number) => setSkip(value);
  const handleSearch = (value: string) => {
    setSkip(0);
    setSearch(value);
  };

  return {
    skip,
    take,
    search,
    nameFilter,
    handleSearch,
    handleChangeSkip,
    handleChangeDefaultRows,
  };
};
