import classNames from 'classnames';
import { InputSwitch, InputSwitchProps } from 'primereact/inputswitch';
import React from 'react';
import styled from 'styled-components';

export type FormikSwitchProps = {
  field: {
    name: string;
    value: string;
    onChange: () => void;
    onBlur: () => void;
  };
  form: { touched: Record<string, any>; errors: Record<string, any> };
  label?: string;
};

export const StyledInputSwitch = styled(InputSwitch)`
  &.p-inputswitch {
    &.p-focus .p-inputswitch-slider {
      box-shadow: var(--box-shadow-red) !important;
    }

    &.p-inputswitch-checked[aria-checked='true'] {
      .p-inputswitch-slider,
      &:not(.p-disabled):hover .p-inputswitch-slider {
        background-color: var(--red-main);
      }
    }

    &[aria-checked='false'] {
      .p-inputswitch-slider,
      &:not(.p-disabled):hover .p-inputswitch-slider {
        background-color: var(--purple-dark);
      }
    }
  }
`;

const StyledLabel = styled.label`
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  font-weight: 500;
  margin-bottom: 0 !important;
  margin-left: var(--small-padding) !important;
`;

export const FormikSwitch: React.FC<FormikSwitchProps & InputSwitchProps> = ({
  field,
  form,
  label,
  prefix,
  required,
  className,
  ...rest
}) => (
  <>
    {prefix && (
      <StyledLabel
        htmlFor={field.name}
        className={classNames(
          {
            'p-error': !!form.errors[field.name],
          },
          'mr-2',
        )}
      >
        {prefix}
      </StyledLabel>
    )}
    <StyledInputSwitch
      {...field}
      {...rest}
      checked={!!field.value}
      className={className}
    />
    <StyledLabel
      htmlFor={field.name}
      className={classNames({
        'p-error': !!form.errors[field.name],
      })}
    >
      {label}
      {required ? <span className="red"> *</span> : ''}
    </StyledLabel>
  </>
);
