import { getExceptionFromAxiosError } from '@/client/helpers';
import { MessageResponseModel } from '@/client/models';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import {
  ListDuplicatedUsersResponse,
  MigrateDuplicatedUsersRequest,
} from './types';

export const accountMigrationService = (client: AxiosInstance) => {
  const ACCOUNTS_BASE_URL = '/accounts';

  const migrateDuplicatedUsers = async (
    request: MigrateDuplicatedUsersRequest,
  ): Promise<ListDuplicatedUsersResponse> => {
    try {
      const result = await client.post<
        ListDuplicatedUsersResponse,
        AxiosResponse<ListDuplicatedUsersResponse>
      >(
        `${ACCOUNTS_BASE_URL}/${request.accountId}/users/migration`,
        request.payload,
        {
          withCredentials: true,
        },
      );
      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const sendEmailsToMigratedUsers = async (
    accountId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${ACCOUNTS_BASE_URL}/${accountId}/migration/emails`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deactivateEfrontUsers = async (
    accountId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${ACCOUNTS_BASE_URL}/${accountId}/migration/efront-users`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const reSyncEfrontCourses = async (
    accountId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${ACCOUNTS_BASE_URL}/${accountId}/migration/efront-courses`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  return {
    migrateDuplicatedUsers,
    sendEmailsToMigratedUsers,
    deactivateEfrontUsers,
    reSyncEfrontCourses,
  };
};
