import { User } from '@/client/users';
import { AppSpinner } from '@/ui/app-spinner';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { VirtualScroller } from 'primereact/virtualscroller';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledContainer = styled.div`
  width: 100%;
  padding-top: var(--xsmall-padding);
  border: 1px solid var(--gray-dark);
  border-radius: var(--small-border-radius);
`;

const StyledHeader = styled.h1`
  margin: 0;
  align-self: center;

  span {
    font-size: var(--medium-font-size);
    line-height: var(--medium-line-height);
    font-weight: 400;
  }
`;

const StyledHeaderContainer = styled.div`
  padding: 0 var(--xsmall-padding) var(--xsmall-padding) var(--xsmall-padding);
`;

const StyledDataContainer = styled.div`
  padding: 0;
  height: 250px;
  overflow-y: auto;
`;

const StyledData = styled.div`
  padding: var(--xsmall-padding);
  border-top: 1px solid var(--gray-dark);
`;

type ShowDataProps = {
  data: User[];
  onRemoveAll?: () => void;
  onRemove: (row: User) => void;
  loading?: boolean;
};

export const ShowData: React.FC<ShowDataProps> = ({
  data,
  onRemoveAll,
  onRemove,
  loading,
}) => {
  const { t } = useTranslation();
  const renderItem = (value: User) => (
    <StyledData>
      <FlexContainer justify="space-between">
        <span>
          {value.username} ({value.email})
        </span>
        <AppButton
          icon="pi pi-times"
          type="text"
          severity="secondary"
          onClick={() => onRemove(value)}
        />
      </FlexContainer>
    </StyledData>
  );

  return (
    <StyledContainer>
      <StyledHeaderContainer>
        <FlexContainer justify="space-between">
          <StyledHeader>
            {t('users')}
            <span> ({data.length})</span>
          </StyledHeader>
          <AppButton
            icon="pi pi-times"
            type="text"
            severity="secondary"
            onClick={onRemoveAll}
          />
        </FlexContainer>
      </StyledHeaderContainer>

      <StyledDataContainer>
        {loading ? (
          <FlexContainer height="100%">
            <AppSpinner />
          </FlexContainer>
        ) : (
          <VirtualScroller
            items={data}
            itemTemplate={renderItem}
            itemSize={50}
            style={{ width: '100%', height: '100%' }}
          />
        )}
      </StyledDataContainer>
    </StyledContainer>
  );
};
