import { handleAxiosError } from '@/api/helpers';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import BeePlugin from '@mailupinc/bee-plugin';
import { IEntityContentJson } from '@mailupinc/bee-plugin/dist/types/bee';
import { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
const beeEmailEditor = new BeePlugin();

type BeePluginEmailContainerProps = {
  template: any;
  handleChange: (page: IEntityContentJson) => void;
  handleSave: (page: object, html: string) => void;
  handleSendTest?: (html: string) => void;
};

export const BeePluginEmailContainer: React.FC<
  BeePluginEmailContainerProps
> = ({ template, handleChange, handleSave, handleSendTest }) => {
  const account = useAppSelector(selectCurrentAccount);
  const toast = useToast();
  const ref = useRef(null);
  const [beeToken, setBeeToken] = useState<string | undefined>();
  const clientId = process.env.REACT_APP_BEE_PLUGIN_EMAIL_CLIENT_ID || '';
  const secret = process.env.REACT_APP_BEE_PLUGIN_EMAIL_SECRET || '';

  // If the plugin was already initialized and the token is present, don't get it
  useEffect(() => {
    const getToken = async () => {
      if (beeEmailEditor.token) {
        setBeeToken(beeEmailEditor.token);
      } else {
        const token = await beeEmailEditor.getToken(clientId, secret);
        setBeeToken(token.access_token);
      }
    };
    getToken();
  }, []);

  useEffect(() => {
    if (!beeToken) {
      return;
    }

    const loadEditor = async () => {
      try {
        if (!account) {
          return;
        }

        beeEmailEditor.start(
          {
            container: 'beeplugin-editor',
            uid: account.id,
            trackChanges: true,
            preventClose: true,
            onChange: (page) => {
              handleChange(JSON.parse(page));
            },
            onSend: (html) => {
              if (!handleSendTest) return;
              handleSendTest(html);
            },
            onSave: (page, html) => {
              // if (!handleSave) return;
              handleSave(JSON.parse(page), html);
            },
            commenting: true,
          },
          template,
        );
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    };

    loadEditor();
  }, [beeToken]);

  return (
    <div>
      <div id="beeplugin-editor" ref={ref} style={{ height: '100%' }}></div>
    </div>
  );
};
