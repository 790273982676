import { client } from '@/client';
import {
  EfrontRecommendationResponse,
  EfrontSyncRequest,
  EfrontSyncType,
} from '@/client/efront';
import { MessageResponse } from '@/client/types';
import { EfrontSyncMutation, GenericForbiddenApiError } from '@/hooks/query';
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

export const useEfrontRecommendations = (
  params: UseQueryOptions & {
    token?: string;
    id: string;
    type: EfrontSyncType;
  },
): UseQueryResult<EfrontRecommendationResponse> & {
  recommendations: EfrontRecommendationResponse;
} => {
  const {
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;
  const { data, ...rest } = useQuery(
    ['efront-recommendations', params.id, params.type],
    () => client.efrontSync.getSyncRecommendations(params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any), // eslint-disable-line
    },
  );

  return {
    recommendations: data as EfrontRecommendationResponse,
    ...(rest as UseQueryResult<EfrontRecommendationResponse>),
  };
};

export const useSendSyncRequest = (): {
  create: (payload: EfrontSyncRequest) => Promise<MessageResponse>;
} & EfrontSyncMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponse,
    GenericForbiddenApiError,
    EfrontSyncRequest
  >('syncRequest', (payload: EfrontSyncRequest) =>
    client.efrontSync.sendSyncRequest(payload),
  );

  return { create: mutateAsync, ...(rest as any) };
};
