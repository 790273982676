import { AbsoluteLoader, FlexContainer } from '@/ui/styled-ui';
import React from 'react';

type StateProviderProps = {
  children: React.ReactNode;
  isLoading?: boolean;
  state: any;
  loadingMessage: string;
  errorMessage: string;
};

export const StateProvider: React.FC<StateProviderProps> = ({
  children,
  isLoading,
  state,
  loadingMessage,
  errorMessage,
}) => {
  if (isLoading) return <AbsoluteLoader fullScreen message={loadingMessage} />;

  if (!state && !isLoading)
    return (
      <FlexContainer height="100%">
        <p>{errorMessage}</p>
      </FlexContainer>
    );

  return <>{children}</>;
};
