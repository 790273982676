import { apiClientFactory } from '@/client/api-client.factory';
import { parseEnviourmentUrl } from '@/utils/helpers';

export * from './api-client.factory';
export * from './auth';
export * from './axios-client.factory';

const apiUrl = parseEnviourmentUrl().apiUrl;

export const client = apiClientFactory({
  apiUrl,
  onUnauthorized: () => {
    console.log('on unauthorized action!');
  },
});
