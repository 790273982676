import { Conditions, GroupCondition } from '@/api/enums';
import {
  FilterCondition,
  FilterValue,
  GroupedFilterConditions,
} from '@/api/types';

const condition = (
  field: string,
  condition: Conditions,
  value: FilterValue | FilterValue[],
): FilterCondition => ({
  field,
  condition,
  value,
});

export const contains = (
  field: string,
  value: FilterValue | FilterValue[],
): FilterCondition => condition(field, Conditions.CONTAINS, value);

export const equal = (field: string, value: FilterValue): FilterCondition =>
  condition(field, Conditions.EQUAL, value);

export const notEqual = (field: string, value: FilterValue): FilterCondition =>
  condition(field, Conditions.NOT_EQUAL, value);

export const none = (field: string, value: FilterValue): FilterCondition =>
  condition(field, Conditions.NONE, value);

export const lower = (
  field: string,
  value: FilterValue,
  orEqual?: boolean,
): FilterCondition =>
  condition(
    field,
    orEqual ? Conditions.LOWER_OR_EQUAL : Conditions.LOWER,
    value,
  );

export const greater = (
  field: string,
  value: FilterValue,
  orEqual?: boolean,
): FilterCondition =>
  condition(
    field,
    orEqual ? Conditions.GREATER : Conditions.GREATER_OR_EQUAL,
    value,
  );

export const like = (field: string, value: FilterValue): FilterCondition =>
  condition(field, Conditions.LIKE, value);

export const isIn = (field: string, value: FilterValue[]): FilterCondition =>
  condition(field, Conditions.IN, value);

export const isNotIn = (field: string, value: FilterValue[]): FilterCondition =>
  condition(field, Conditions.NOT_IN, value);

export const between = (
  field: string,
  value: [FilterValue, FilterValue],
): FilterCondition => condition(field, Conditions.BETWEEN, value);

export const group = (
  type: GroupCondition,
  conditions: (GroupedFilterConditions | FilterCondition)[],
): GroupedFilterConditions => ({ type, conditions });

export const nested = (
  field: string,
  conditions: (GroupedFilterConditions | FilterCondition)[],
): FilterCondition => ({ field, value: conditions });

export const startsWith = (
  field: string,
  value: FilterValue,
): FilterCondition => condition(field, Conditions.STARTS_WITH, value);

export const endsWith = (field: string, value: FilterValue): FilterCondition =>
  condition(field, Conditions.ENDS_WITH, value);

export const concatFilter = (
  field: string,
  value: FilterValue,
): FilterCondition => condition(field, Conditions.CONCAT, value);

export const getQueryTransformation = <T>() => ({
  filters: {
    encode: (value: T): string => JSON.stringify(value),
    decode: (value: string): T => JSON.parse(value),
  },
  'sort[]': {
    encode: (value: string[]) => value.join(','),
    decode: (value: string) => value.split(','),
  },
});
