import { unauthorizedInterceptor } from '@/client/interceptors';
import { ApiClientConfigType } from '@/client/types';
import i18n from '@/i18n';
import axios, { AxiosInstance } from 'axios';
import momentTimezone from 'moment-timezone';

const axiosClientFactory = (config: ApiClientConfigType): AxiosInstance => {
  const instance = axios.create({
    baseURL: config.apiUrl,
    withCredentials: true,
  });

  instance.defaults.timeout = 180000;

  if (config.onUnauthorized) {
    instance.interceptors.response.use(
      undefined,
      unauthorizedInterceptor(config.onUnauthorized),
    );
  }

  instance.interceptors.request.use((request) => {
    request.headers['x-accept-language'] = i18n.language;
    request.headers['x-accept-timezone'] = momentTimezone.tz.guess();

    return request;
  });

  return instance;
};

export default axiosClientFactory;
