export const processUrl = (url: string, params: Record<string, any>) => {
  if (Object.values(params).length === 0) {
    return url;
  }

  return Object.entries(params).reduce(
    (a, [key, value]) => a.replace(`:${key}`, value),
    url,
  );
};
