import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';

type AppSpinnerProps = {
  width?: number;
  height?: number;
  strokeWidth?: number;
  duration?: number;
};

export const AppSpinner: React.FC<AppSpinnerProps> = ({
  width = 50,
  height = 50,
  strokeWidth = 8,
  duration = 1.5,
}) => {
  return (
    <ProgressSpinner
      style={{ width: `${width}px`, height: `${height}px` }}
      strokeWidth={`${strokeWidth}`}
      animationDuration={`${duration}s`}
    />
  );
};
