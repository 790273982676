import { Branch } from '@/client/branches';

export const getBranchName = (branch: Branch) => {
  let names = [branch.name];

  if (branch?.parent) {
    names = [...names, ...getBranchName(branch?.parent)];
  }

  return names;
};

export const getAllParentIds = (branch?: Branch): string[] => {
  if (!branch || !branch.parent) {
    return [];
  }

  return [branch.parent.id, ...getAllParentIds(branch.parent)];
};
