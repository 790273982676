import { FiltersType, ListingRequest } from '@/api/types';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { PartialRequestMultiselect } from '@/components/form/generic/PartialRequestMultiselect';
import React, { HTMLAttributes, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type AccountsMultiselectInputProps = {
  onChange: (value: Account[], manualInteractionChange: boolean) => void;
  additionalFilters?: FiltersType;
  selectedOptions?: Account[];
  courseId?: string;
  isUnenroll?: boolean;
  getOnlyUnavailableAccounts?: boolean;
  isDisabled?: boolean;
} & Omit<HTMLAttributes<HTMLSelectElement>, 'onChange'>;

export const AccountsMultiselectInput: React.FC<
  AccountsMultiselectInputProps
> = ({
  onChange,
  className,
  additionalFilters,
  selectedOptions,
  getOnlyUnavailableAccounts,
  courseId,
  isUnenroll,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const fetchUnavailableAccounts = isDisabled
    ? undefined
    : async (params: ListingRequest) =>
        await client.courses.getUnavailableAccounts(params, courseId);

  const fetchAccounts = isDisabled
    ? undefined
    : async (params: ListingRequest) =>
        isUnenroll
          ? await client.courses.getCourseAccounts(params, courseId)
          : await client.accounts.getAccounts(params);

  useEffect(() => {
    onChange(selectedOptions as Account[], false);
  }, [selectedOptions]);

  const handleMultiselectChange = (accounts: Account[]) => {
    onChange(accounts, true);
  };

  return (
    <PartialRequestMultiselect
      fetchService={
        getOnlyUnavailableAccounts ? fetchUnavailableAccounts : fetchAccounts
      }
      filter
      display="chip"
      optionLabel="name"
      optionValue="value"
      placeholder={t('accounts.select')}
      maxSelectedLabels={2}
      additionalFilters={additionalFilters}
      selectedOptions={selectedOptions}
      className={className}
      handleOnChange={handleMultiselectChange}
    />
  );
};
