import { equal, notEqual } from '@/api/helpers';
import { client } from '@/client';

export const checkIfSystemEmailExists = async (payload: {
  type: string;
  account?: { id: string; isSystem: boolean };
  id?: string;
}): Promise<boolean> => {
  let filters = [equal('type', payload.type)];

  if (payload?.account) {
    filters = [
      ...filters,
      equal('account', payload.account?.isSystem ? null : payload.account?.id),
    ];
  }

  if (payload?.id) {
    filters = [...filters, notEqual('id', payload.id)];
  }

  const result =
    await client.systemEmailsTypesConfigsService.getSystemEmailsTypesConfigs({
      filters: filters,
    });

  return result.count > 0;
};
