import {
  REFRESH_TOKEN_EXPIRES_KEY,
  TOKEN_EXPIRES_KEY,
} from '@/common/constants/user';
import { useHandshake } from '@/hooks/query/auth.hooks';
import { useEffect, useState } from 'react';

export const useAuth = (handshakeToken: string) => {
  const { isLoading, request, error } = useHandshake();
  const [isDone, setIsDone] = useState<boolean>(false);

  useEffect(() => {
    const token =
      handshakeToken || process.env.REACT_APP_HANDSHAKE_TOKEN || undefined;

    const doAuth = async () => {
      if (!token) {
        return;
      }

      try {
        const response = await request({
          token: token,
          isRefresh: false,
        });

        if (response.payload) {
          sessionStorage.setItem(
            TOKEN_EXPIRES_KEY,
            response.payload.tokenExpiresOn,
          );
          sessionStorage.setItem(
            REFRESH_TOKEN_EXPIRES_KEY,
            response.payload.refreshTokenExpiresOn,
          );
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsDone(true);
      }
    };

    doAuth();
  }, [handshakeToken]);

  return { isLoading, error, isDone };
};
