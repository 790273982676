import { FiltersType, ListingRequest } from '@/api/types';
import { client } from '@/client';
import { Campaign } from '@/client/campaigns';
import { PartialRequestMultiselect } from '@/components/form/generic/PartialRequestMultiselect';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

type OldCampaignsMultiselectInputProps = {
  onChange: (value: Campaign[]) => void;
  additionalFilters?: FiltersType;
  selectedOptions?: Campaign[];
} & Omit<HTMLAttributes<HTMLSelectElement>, 'onChange'>;

export const CampaignsMultiselectInput: React.FC<
  OldCampaignsMultiselectInputProps
> = ({ onChange, className, additionalFilters, selectedOptions }) => {
  const { t } = useTranslation();

  const fetchCampaigns = async (params: ListingRequest) =>
    await client.campaigns.getCampaigns(params);

  return (
    <PartialRequestMultiselect
      fetchService={fetchCampaigns}
      filter
      display="chip"
      optionLabel="name"
      optionValue="value"
      placeholder={t('campaign.select')}
      maxSelectedLabels={2}
      additionalFilters={additionalFilters}
      selectedOptions={selectedOptions}
      className={className}
      handleOnChange={onChange}
    />
  );
};
