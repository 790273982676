import classNames from 'classnames';
import React from 'react';

import { RadioButton, RadioButtonProps } from 'primereact/radiobutton';
import styled from 'styled-components';

const StyledLabel = styled.label`
  &:hover {
    cursor: pointer;
  }
`;

const StyledRadioInput = styled(RadioButton)`
  &.p-radiobutton {
    width: var(--default-padding);
    height: var(--default-padding);
    border-radius: 2px;
    background: transparent;
    padding: 3px;
    margin-right: 4px;

    .p-radiobutton-box {
      width: 18px;
      height: 18px;
      border-radius: 2px;
      border: 1px solid var(--gray-pale);

      &:not(.p-disabled):not(.p-highlight):hover {
        border-color: var(--red-main);
      }

      &.p-highlight {
        border-color: var(--red-main);
        background: var(--red-main);
        box-shadow: none;

        &:not(.p-disabled):hover {
          border-color: var(--red-main);
          background: var(--red-main);
          color: var(--white-main);
        }
      }

      .p-radiobutton-icon {
        width: 18px;
        height: 18px;
        background: transparent;
        &::before {
          font-family: var(--icon-font-family);
          content: '\f00c';
          display: inline-block;
          color: var(--white-main);
          vertical-align: middle;
          font-weight: 900;
          font-size: var(--small-padding);
          padding-left: 2px;
        }
      }
    }
  }
  &.p-circle {
    .p-radiobutton-box {
      border-color: var(--gray-darker);
      border-radius: 50%;
      .p-radiobutton-icon {
        width: 10px;
        height: 10px;
        background: transparent;
        background: var(--white-main);
        border-radius: 50%;
        &::before {
          display: none;
        }
      }
    }
  }
`;

export type FormikRadioProps = {
  field: {
    name: string;
    value: boolean;
    onChange: () => void;
    onBlur: () => void;
  };
  form: { touched: Record<string, any>; errors: Record<string, any> };
  label?: string;
  shapeType: 'square' | 'circle';
};

export const FormikRadio: React.FC<FormikRadioProps & RadioButtonProps> = ({
  field,
  form,
  label,
  required,
  className,
  shapeType = 'square',
  ...rest
}) => {
  return (
    <StyledLabel
      html-for={field.name}
      className={classNames({
        'p-error': !!form.errors[field.name],
      })}
    >
      <StyledRadioInput
        {...field}
        {...rest}
        className={classNames(className, {
          'p-circle': shapeType === 'circle',
        })}
      />
      {label}
      {required ? <span className="red"> *</span> : ''}
    </StyledLabel>
  );
};
