import classNames from 'classnames';
import { Checkbox, CheckboxProps } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import styled from 'styled-components';

const StyledIcon = styled.i`
  font-size: var(--xsmall-font-size);
  color: var(--gray-darker);
  cursor: pointer;
`;

export type FormikCheckboxProps = {
  field: {
    name: string;
    value: string;
    onChange: () => void;
    onBlur: () => void;
  };
  form: { touched: Record<string, any>; errors: Record<string, any> };
  label?: string;
  tooltip?: string;
  tooltipId?: string;
};

export const FormikCheckbox: React.FC<FormikCheckboxProps & CheckboxProps> = ({
  field,
  form,
  label,
  required,
  className,
  tooltip,
  tooltipId,
  ...rest
}) => (
  <>
    <Checkbox
      {...field}
      {...rest}
      checked={!!field.value}
      className={className}
    />
    <label
      htmlFor={field.name}
      className={classNames({
        'p-error': !!form.errors[field.name],
      })}
    >
      {label}
      {required ? <span className="red"> *</span> : ''}
    </label>
    {tooltip && tooltipId && (
      <>
        <Tooltip
          target={`#${tooltipId}`}
          position="top"
          content={tooltip}
          my="center bottom-10"
        />
        <StyledIcon className="pi pi-info-circle ml-1" id={tooltipId} />
      </>
    )}
  </>
);
