import { languageDisplayLabel } from '@/api/enums';
import { Campaign } from '@/client/campaigns';
import { DoughnutChart } from '@/ui/charts/doughnut-chart';
import { FlexContainer } from '@/ui/styled-ui';
import { displayDifficulty } from '@/utils/helpers/ui.helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledInfo = styled(FlexContainer)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: var(--small-padding) var(--default-padding);
  color: var(--black-main);
  flex: 1 0 40%;
  height: 100%;
  z-index: 1;

  .red-text {
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    font-weight: 500;
    color: var(--red-main);
    margin-bottom: var(--medium-padding);
  }

  &.preview {
    padding: var(--default-padding) calc(2 * var(--default-padding));
    border-right: 1px solid var(--gray-dark);
    box-shadow: 5px 0 5px -5px var(--gray-dark) !important;
    overflow-y: scroll;
  }

  &.catalogue {
    flex: 1 1 50%;
  }

  h1 {
    font-size: var(--heading-medium-font-size);
    line-height: var(--heading-medium-line-height);
    font-weight: 700;
  }

  ul {
    margin: 0;
    padding-inline: var(--default-padding);
  }

  h3 {
    margin-top: var(--medium-padding);
    margin-bottom: 0;
  }

  h3,
  div {
    font-size: var(--medium-font-size);
    line-height: var(--medium-line-height);
  }

  .languages {
    margin-block: var(--medium-padding);
    color: var(--gray-darker);
    font-weight: 500;
    font-size: var(--xsmall-font-size);
    line-height: var(--xsmall-line-height);
  }

  .ql-editor {
    height: auto;
    overflow-y: unset;
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;

const StyledUsersScore = styled(FlexContainer)`
  padding-inline: var(--medium-padding);
  justify-content: flex-start;

  .charts {
    width: max-content;
    flex-direction: column;
  }

  label {
    text-align: center;
    width: 100%;
    padding-top: var(--default-padding);
    padding-bottom: 0;
    margin: 0 !important;
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
  }
`;

export type CampaignInfoPropsType = {
  campaign: Campaign;
  className?: string;
};

export const CampaignInfo: React.FC<CampaignInfoPropsType> = ({
  campaign,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <StyledInfo className={className}>
      {campaign?.meta?.phishingSign && (
        <div className="red-text">
          {t(`campaign.template.${campaign.meta.phishingSign}`)}
        </div>
      )}
      <h1 className="m-0">{campaign.name}</h1>
      {campaign?.language && (
        <div className="languages">
          {languageDisplayLabel[campaign.language]}
        </div>
      )}
      <div
        className="ql-editor"
        dangerouslySetInnerHTML={{
          __html: campaign?.description ?? '',
        }}
      />
      {!!campaign?.difficulty && (
        <h3>
          {`${t('generic.difficulty')}: `}
          {displayDifficulty(campaign.difficulty)}
        </h3>
      )}

      {!!campaign?.clickedLinksPercentage &&
        !!campaign?.submittedDataPercentage && (
          <>
            <h3>{t('campaign.template.usersScore')}</h3>
            <StyledUsersScore className="mt-4" gap={40}>
              <FlexContainer className="charts">
                <DoughnutChart
                  allCount={100}
                  singleCount={campaign.clickedLinksPercentage ?? 0}
                  id="1"
                  label={t('campaign.clicked')}
                  cutout="70%"
                  width="120px"
                  isPercentCount
                  isDashboard
                />
                <label htmlFor="clickedLinksPercentage">
                  {t('campaign.clicked')}
                </label>
              </FlexContainer>
              <FlexContainer className="charts">
                <DoughnutChart
                  allCount={100}
                  singleCount={campaign?.submittedDataPercentage ?? 0}
                  id="1"
                  label={t('campaign.submitted')}
                  cutout="70%"
                  width="120px"
                  isPercentCount
                  isDashboard
                />
                <label htmlFor="submittedDataPercentage">
                  {t('campaign.submitted')}
                </label>
              </FlexContainer>
            </StyledUsersScore>
          </>
        )}
    </StyledInfo>
  );
};
