import { processListingParams } from '@/api/helpers';
import {
  EmailTemplate,
  ListEmailTemplatesRequest,
  ListEmailTemplatesResponse,
  SaveEmailTemplateRequest,
  UpdateEmailTemplateRequest,
} from '@/client/email-templates';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { MessageResponseModel } from '@/client/models';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

export const emailTemplatesService = (client: AxiosInstance) => {
  const EMAIL_TEMPLATES_BASE_URL = '/gophish/email-templates';

  const getEmailTemplates = async (
    params: ListEmailTemplatesRequest,
  ): Promise<ListEmailTemplatesResponse> => {
    try {
      const result = await client.get<
        ListEmailTemplatesResponse,
        AxiosResponse<ListEmailTemplatesResponse>
      >(EMAIL_TEMPLATES_BASE_URL, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getEmailTemplate = async (
    emailTemplateId?: string,
  ): Promise<EmailTemplate> => {
    try {
      const result = await client.get<
        EmailTemplate,
        AxiosResponse<EmailTemplate>
      >(`${EMAIL_TEMPLATES_BASE_URL}/${emailTemplateId}`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveEmailTemplate = async (
    request: SaveEmailTemplateRequest,
  ): Promise<EmailTemplate> => {
    try {
      const result = await client.post(EMAIL_TEMPLATES_BASE_URL, request, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateEmailTemplate = async (
    request: UpdateEmailTemplateRequest,
  ): Promise<EmailTemplate> => {
    try {
      const result = await client.patch<
        EmailTemplate,
        AxiosResponse<EmailTemplate>
      >(
        `${EMAIL_TEMPLATES_BASE_URL}/${request.emailTemplateId}`,
        request.updates,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deleteEmailTemplate = async (
    emailTemplateId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${EMAIL_TEMPLATES_BASE_URL}/${emailTemplateId}`, {
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  return {
    getEmailTemplates,
    getEmailTemplate,
    saveEmailTemplate,
    updateEmailTemplate,
    deleteEmailTemplate,
  };
};
