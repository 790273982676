import { like } from '@/api/helpers';
import { client } from '@/client';
import { Branch } from '@/client/branches';
import { EfrontBranch } from '@/client/efront';
import {
  AutoComplete,
  AutoCompleteCompleteEvent,
} from 'primereact/autocomplete';
import React, { HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';

type EfrontBranchesAutocompleteProps = {
  onSelect: (Branch: Branch) => void;
  defaultValue?: Branch;
} & Omit<HTMLAttributes<HTMLInputElement>, 'onSelect' | 'defaultValue'>;

const getNameWithParents = (branch: EfrontBranch): string[] => {
  if (branch.parent) {
    return [...getNameWithParents(branch.parent), branch.name];
  }

  return [branch.name];
};

export const EfrontBranchesAutocomplete: React.FC<
  EfrontBranchesAutocompleteProps
> = ({ onSelect, defaultValue, ...rest }) => {
  const { t } = useTranslation();

  const [searchResults, setSearchResults] = useState<EfrontBranch[]>([]);
  const [fieldValue, setFieldValue] = useState<string>('');

  const onCompleteMethod = async (e: AutoCompleteCompleteEvent) => {
    const efrontBranches = await client.efrontBranches.getEfrontBranches({
      take: 50,
      filters: [like('name', e.query)],
      sort: ['name,asc'],
    });

    setSearchResults(
      efrontBranches.result.map((b) => ({
        ...b,
        name: getNameWithParents(b).join(' / '),
      })),
    );
  };

  return (
    <>
      <AutoComplete
        dropdown
        value={fieldValue || defaultValue}
        field="name"
        placeholder={t('efront.selectName')}
        suggestions={searchResults}
        completeMethod={onCompleteMethod}
        onSelect={(e) => {
          onSelect(e.value);
        }}
        {...rest}
        onChange={(e) => setFieldValue(e.value)}
      />
    </>
  );
};
