import { GlobalToast } from '@/app';
import { useContext } from 'react';

export type ToastType = (
  summary: string,
  detail: string,
  life?: number | undefined,
) => void;

export type MultipleToastType = {
  severity: string;
  summary: string;
  detail: string;
  life?: number;
};

export const useToast = () => {
  const toast = useContext<any>(GlobalToast);

  const baseToast = (
    severity: string,
    summary: string,
    detail: string,
    life?: number,
  ) =>
    toast.current.show({
      severity,
      summary,
      detail,
      life: life ?? 3000,
    });

  const multipleToasts = (toasts: MultipleToastType[]) =>
    toast.current.show(
      toasts.map((toast) => ({ ...toast, life: toast.life ?? 3000 })),
    );

  return toast?.current
    ? {
        success: baseToast.bind(this, 'success'),
        warning: baseToast.bind(this, 'warn'),
        info: baseToast.bind(this, 'info'),
        error: baseToast.bind(this, 'error'),
        multiple: multipleToasts,
      }
    : null;
};
