import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { t } from 'i18next';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

export const AppSessionExpiryListener = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    auth.events.addSilentRenewError(() => {
      setIsVisible(true);
    });
  }, []);

  return (
    <Dialog
      onHide={() => {
        setIsVisible(false);
        navigate(RedirectPaths[RedirectPathsEnum.LOGOUT]());
      }}
      blockScroll
      visible={isVisible}
      header={<h1>{t('auth.sessionExpired')}</h1>}
      draggable={false}
      closable={false}
      data-testid="session-expired-modal"
    >
      {t('auth.sessionExpired.login')}
      <FlexContainer justify="flex-end" className="mt-5">
        <AppButton
          label={t('dialog.proceed')}
          onClick={() => {
            setIsVisible(false);
            navigate(RedirectPaths[RedirectPathsEnum.LOGOUT]());
          }}
        />
      </FlexContainer>
    </Dialog>
  );
};
