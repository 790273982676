import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { SystemEmailsConfigFormValues } from '@/client/system-emails';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { useSaveSystemEmailTypeConfig } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { checkIfSystemEmailExists } from '@/system-settings';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SystemEmailsConfigureEmailForm } from '../components/forms';

const pathItems = (account: Account, t: TranslationFunctionType) => [
  {
    label: account?.name,
    url: !account?.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('generic.system.emails'),
    className: 'active',
    url: RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES](),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('generic.email.configs'),
    url: RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TYPES_CONFIGS](),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('button.create'),
    url: RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TYPES_CONFIGS_CREATE](),
    template: AppBreadCrumbTemplate,
  },
];

const initialValues: SystemEmailsConfigFormValues = {
  type: undefined,
  sendTime: undefined,
  active: false,
};

export const SystemEmailsCreateTypeConfigPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const account = useAppSelector(selectCurrentAccount);
  const toast = useToast();

  const saveSystemEmailTypeConfig = useSaveSystemEmailTypeConfig();

  const handleSubmit = async (data: SystemEmailsConfigFormValues) => {
    try {
      const systemEmailExists = await checkIfSystemEmailExists({
        type: data.type as string,
        account: account as Account,
      });

      if (systemEmailExists) {
        toast?.error(
          t('system.email.type.config.emailType'),
          t('system.email.type.config.unavailable'),
        );

        return;
      }

      await saveSystemEmailTypeConfig.create({
        ...data,
        account: account?.isSystem ? null : (account?.id as string),
      });

      navigate(RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TYPES_CONFIGS]());

      toast?.success(
        t('toast.success'),
        account?.isSystem
          ? t('system.email.type.config.predefined.created')
          : t('system.email.type.config.custom.created'),
      );
    } catch (e) {
      handleAxiosError(e as AxiosError, toast);
    }
  };

  const handleCancelUpdate = () =>
    navigate(RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TYPES_CONFIGS]());

  return (
    <>
      <AppBreadCrumb model={pathItems(account as Account, t)} />
      <h1>{t('system.email.type.config.create')}</h1>
      <FlexContainer justify="flex-start">
        <SystemEmailsConfigureEmailForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onCancel={handleCancelUpdate}
        />
      </FlexContainer>
    </>
  );
};
