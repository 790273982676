import { languageDisplayCode } from '@/api/enums';
import {
  CampaignStatusesEnum,
  CampaignStatusMessages,
} from '@/client/campaigns';
import { User } from '@/client/users';
import { TranslationFunctionType } from '@/common/types';
import { getEmailTypesLabels } from '@/utils/helpers';

export const displayCreatedBy = (
  t: TranslationFunctionType,
  createdBy?: User | null,
) => {
  if (!createdBy) return t('generic.unkown');

  return `${createdBy.firstName} ${createdBy.lastName}`;
};

export const displaySenderProfile = (
  t: TranslationFunctionType,
  senderProfileName?: string,
) => {
  if (!senderProfileName) return t('generic.unkown');

  return t('campaign.template.createdByName', { name: senderProfileName });
};

export const displaySystemEmailTemplateName = (
  name: string,
  t: TranslationFunctionType,
) => {
  const [emailType, language] = name.split(' ');

  return `${getEmailTypesLabels(t)[emailType]}, ${
    languageDisplayCode[language]
  }`;
};

export const displayCampaignStatus = (
  t: TranslationFunctionType,
  status?: CampaignStatusesEnum,
) => {
  if (!status) return t('generic.unkown');

  return t(CampaignStatusMessages[status]);
};
