import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { SendTestEmailFormValues } from '@/client/campaigns';
import { CourseEntityEnrollEnum } from '@/client/courses';
import { SecurityCultureSendSurveyFormValues } from '@/client/security-culture/types';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { SecurityCultureSendSurveyForm } from '@/components/security-culture';
import { useNotifications } from '@/hooks/notifications.hook';
import { useSendEmail } from '@/hooks/query/security-culture.hooks';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { getTargetEntityIdsByType } from '@/utils/targets';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';

const StyledText = styled.div`
  line-height: var(--small-line-height);
  font-size: var(--small-font-size);
  margin-block: var(--default-padding);
`;

const pathItems = (account: Account, t: TranslationFunctionType) => [
  {
    label: account?.name,
    url: !account?.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('security.culture.send.survey'),
    url: RedirectPaths[RedirectPathsEnum.SECURITY_CULTURE_SEND_SURVEY](),
    template: AppBreadCrumbTemplate,
  },
];

export const SecurityCultureSendSurveyPage: React.FC = () => {
  const currentAccount = useAppSelector(selectCurrentAccount);
  const toast = useToast();
  const [emailId, setEmailId] = useState<string | undefined>(undefined);

  const {
    lastMessage,
    setNotificationParam: setAccountId,
    notificationParam: notificationAccountId,
    isConnected,
  } = useNotifications(client.securityCulture.surveyEmailNotifyUrl);

  useEffect(() => {
    if (!notificationAccountId) return;
    handleNotificationTestEmail(lastMessage);
  }, [JSON.stringify(lastMessage)]);

  const handleNotificationTestEmail = (messages: any) => {
    const event = messages?.data?.event;

    if (
      !event ||
      ![
        'survey.emails.sending.finished',
        'survey.emails.sending.error',
      ].includes(event)
    )
      return;

    if (event === 'survey.emails.sending.finished') {
      toast?.success(
        t('toast.success'),
        t('security.culture.emailsSent'),
        5000,
      );
    }

    if (event === 'survey.emails.sending.error') {
      toast?.error(
        t('toast.error'),
        t('security.culture.emailsNotSent', {
          error: messages?.data?.payload?.error?.message,
        }),
        5000,
      );
    }

    setEmailId(undefined);
    setAccountId(undefined);
  };

  const handleFormSubmit = async (
    data: SecurityCultureSendSurveyFormValues,
  ) => {
    await SendEmails({
      targets: data.targets,
      url: data.url,
    });
  };

  const handleSendTestEmail = async (
    data: SecurityCultureSendSurveyFormValues,
    test: SendTestEmailFormValues,
  ) => {
    await SendEmails({
      branches: [],
      groups: [],
      users: [],
      url: data.url,
      test: {
        email: test.email,
        firstName: test.firstName,
        lastName: test.lastName,
      },
    });
  };

  const sendServeyEmails = useSendEmail();

  const SendEmails = async (data: SecurityCultureSendSurveyFormValues) => {
    const emailId = v4();
    setEmailId(emailId);
    setAccountId(currentAccount?.id);

    // Wait for a notify connection to establish
    await new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (isConnected.current) {
          clearInterval(intervalId);
          resolve(true);
        }
      }, 100);
    });

    try {
      if (isConnected.current && currentAccount) {
        const targets = data?.targets?.every(
          ({ id }) => id !== currentAccount.id,
        )
          ? {
              branches: getTargetEntityIdsByType(
                data.targets || [],
                CourseEntityEnrollEnum.BRANCHES,
              ),
              groups: getTargetEntityIdsByType(
                data.targets || [],
                CourseEntityEnrollEnum.GROUPS,
              ),
            }
          : {};

        await sendServeyEmails.send({
          accountId: currentAccount?.id as string,
          ...targets,
          url: data.url,
          test: data.test
            ? {
                email: data.test.email,
                firstName: data.test.firstName,
                lastName: data.test.lastName,
              }
            : undefined,
          emailId,
        });
      }
    } catch (e) {
      handleAxiosError(e as AxiosError, toast);
    }
  };

  return (
    <FlexContainer direction="column" align="flex-start">
      <div className="w-full">
        <AppBreadCrumb model={pathItems(currentAccount as Account, t)} />
        <h1>{t('security.culture.send.survey')}</h1>
        <StyledText>{t('security.culture.descr')}</StyledText>

        {currentAccount && (
          <SecurityCultureSendSurveyForm
            onSubmit={handleFormSubmit}
            onSendTestEmail={handleSendTestEmail}
            account={currentAccount}
            loading={emailId ? LoadingStatuses.LOADING : LoadingStatuses.IDLE}
          />
        )}
      </div>
    </FlexContainer>
  );
};
