import { Tooltip, TooltipProps } from 'primereact/tooltip';
import React from 'react';
import styled from 'styled-components';

const StyledIcon = styled.i`
  font-size: var(--xsmall-font-size);
  color: var(--gray-darker);
  cursor: pointer;
`;

type AppIconTooltipPropsType = {
  target: string;
  content: string;
} & TooltipProps;

export const AppIconTooltip: React.FC<AppIconTooltipPropsType> = ({
  target,
  content,
  ...rest
}) => {
  return (
    <>
      <Tooltip
        target={`#${target}`}
        content={content}
        position="top"
        my="center bottom-10"
        {...rest}
      />
      <StyledIcon className="pi pi-info-circle ml-1" id={target} />
    </>
  );
};
