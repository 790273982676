import { FlexContainer } from '@/ui/styled-ui';
import {
  colorRedLight,
  colorRedMain,
} from '@/utils/helpers/css-variables.helper';
import { t } from 'i18next';
import { Chart } from 'primereact/chart';
import React, { useEffect, useState } from 'react';
import { tooltipDefaultSettings } from '../charts.helper';

export type LineChartProps = {
  id: string;
  values: (number | undefined)[];
  labels: string[];
};

export const LineChart: React.FC<LineChartProps> = ({ id, values, labels }) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const data = {
      labels,
      datasets: [
        {
          data: values,
          fill: false,
          tension: 0.1,
          borderWidth: 3,
          borderColor: colorRedLight,
          pointBackgroundColor: colorRedMain,
          pointRadius: 8,
          pointBorderWidth: 0,
        },
      ],
    };
    const options = {
      circular: false,
      pointStyle: 'circle',
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          ...tooltipDefaultSettings(),
          callbacks: {
            title: () => null,
            label: (tooltipItem: any) => {
              const title = data.datasets[0].data[tooltipItem.dataIndex];
              return `${t('generic.score')} ${title}`;
            },
            afterLabel: (tooltipItem: any) => {
              const subtitle = data.labels[tooltipItem.dataIndex];
              return subtitle;
            },
          },
        },
        datalabels: {
          display: false,
        },
      },
      scales: {
        x: {
          ticks: {
            display: false,
          },
          grid: {
            display: false,
            drawBorder: false,
          },
          offset: true,
        },
        y: {
          ticks: {
            callback: (value: number) => {
              if (value === 0 || value === 100) {
                return value;
              }
              return '';
            },
          },
          border: {
            display: false,
          },
          grid: {
            display: false,
            drawBorder: false,
          },
          beginAtZero: true,
          suggestedMin: 0,
          suggestedMax: 100,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    };

    setChartData(data);
    setChartOptions(options);
  }, [values]);

  return (
    <FlexContainer direction="column" width="100%">
      <Chart
        id={id}
        type="line"
        data={chartData}
        options={chartOptions}
        className="chart"
        width="100%"
        height="220px"
      />
    </FlexContainer>
  );
};
