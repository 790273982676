import { ListingRequest, ListingResponse } from '@/api/types';
import { PageTemplate, PageTemplateMeta } from '@/client/page-templates';

export type ListPageTemplatesRequest = ListingRequest;
export type ListPageTemplatesResponse = ListingResponse<PageTemplate>;

export const PageTemplatesEnum = {
  LANDING: 'LANDING',
  REDIRECT: 'REDIRECT',
};

export type PageTemplatesType =
  | typeof PageTemplatesEnum.LANDING
  | typeof PageTemplatesEnum.REDIRECT;

export type LandingPageTemplateFormState = {
  name: string;
  captureCredentials: boolean;
  redirectUrl?: string | null;
  redirectPage?: PageTemplate;
  redirectType: 'url' | 'template';
  active?: boolean;
  meta?: PageTemplateMeta;
};

export type RedirectPageTemplateFormState = {
  name: string;
  active?: boolean;
  meta?: PageTemplateMeta;
};

export type SavePageTemplateRequest = {
  name: string;
  captureCredentials?: boolean;
  redirectUrl?: string | null;
  redirectPage?: string | null;
  editor: object;
  html: string;
  account: string | null;
  active?: boolean;
};

export type UpdatePageTemplate = {
  name?: string;
  captureCredentials?: boolean;
  redirectUrl?: string | null;
  redirectPage?: string | null;
  editor?: object;
  html?: string;
  account?: string | null;
  active?: boolean;
};

export type UpdatePageTemplateRequest = {
  pageTemplateId: string;
  updates: UpdatePageTemplate;
};
