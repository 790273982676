import { SenderProfileFormState } from '@/client/sender-profiles';
import {
  LoadingStateType,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { FormikInput, FormikSwitch } from '@/components/form';
import {
  createSenderProfileSchema,
  updateSenderProfileSchema,
} from '@/components/sender-profiles/forms/validations';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import { AppButton } from '@/ui/buttons';
import { FlexContainer, FormContainer } from '@/ui/styled-ui';
import { BackButton } from '@/ui/styled-ui/BackButton';
import { Field, Form, Formik, FormikValues } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';

type SenderProfileFormProps = {
  initialValues: SenderProfileFormState;
  state?: LoadingStateType;
  onSubmit: (data: FormikValues) => void;
  isCreate?: boolean;
  passwordVisibility: boolean;
  onPasswordToggle: () => void;
  campaignId?: string;
};

export const SenderProfileForm: React.FC<SenderProfileFormProps> = ({
  initialValues,
  state,
  onSubmit,
  isCreate,
  passwordVisibility,
  onPasswordToggle,
  campaignId,
}) => {
  const { t } = useTranslation();
  const currentAccount = useAppSelector(selectCurrentAccount);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={
        isCreate ? createSenderProfileSchema(t) : updateSenderProfileSchema(t)
      }
      onSubmit={onSubmit}
    >
      {({ errors, setFieldValue }) => (
        <FormContainer width={initialValues.meta?.cloneOf ? 100 : undefined}>
          {campaignId && (
            <BackButton
              to={RedirectPaths[RedirectPathsEnum.CAMPAIGNS_EDIT](campaignId)}
              text={t('campaign.back')}
              options={{
                state: {
                  activeStep: 2,
                  isTemplate: !!currentAccount?.isSystem,
                },
              }}
            />
          )}
          <h1 className="mt-4 mb-6">
            {isCreate
              ? t('sender.create')
              : t('sender.edit', {
                  name: initialValues.name && initialValues.name,
                })}
          </h1>
          <Form className="w-full" autoComplete="off">
            <FlexContainer
              gap={24}
              width="100%"
              justify="space-between"
              align="flex-start"
            >
              <div className="w-full">
                <div className="field w-full mb-4">
                  <Field
                    id="name"
                    label={t('generic.name')}
                    name="name"
                    className="w-full"
                    component={FormikInput}
                    placeholder={t('sender.name')}
                    required
                  />
                </div>
                <div className="field w-full mb-4">
                  <Field
                    id="senderName"
                    label={t('sender.senderName')}
                    name="senderName"
                    className="w-full"
                    component={FormikInput}
                    placeholder={t('sender.senderName')}
                  />
                </div>
                <div className="field w-full mb-4">
                  <Field
                    id="fromAddress"
                    label={
                      <>
                        {t('sender.smtp')}
                        <span className="red"> *</span>
                        <i
                          className="pi pi-question-circle from-tooltip-icon ml-2"
                          data-pr-tooltip={t('sender.smtp.info')}
                        />
                      </>
                    }
                    name="fromAddress"
                    className="w-full"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue(
                        'fromAddress',
                        e.target.value.replace(/ /g, ''),
                      )
                    }
                    component={FormikInput}
                    placeholder="name@example.com"
                  />
                  <Tooltip target=".from-tooltip-icon" />
                </div>
                <div className="field w-full mb-4">
                  <Field
                    id="interface-type"
                    label={t('sender.type')}
                    name="interfaceType"
                    className="w-full"
                    component={FormikInput}
                    placeholder={t('sender.type')}
                    disabled
                    required
                  />
                </div>
                <div className="field w-full mb-4">
                  <Field
                    id="host"
                    label={t('sender.host')}
                    name="host"
                    className="w-full"
                    component={FormikInput}
                    placeholder="smtp.example.com:25"
                    required
                  />
                </div>
                <div className="field w-full mb-4">
                  <Field
                    id="username"
                    label={t('generic.username')}
                    type="username"
                    name="username"
                    className="w-full"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue(
                        'username',
                        e.target.value.replace(/ /g, ''),
                      )
                    }
                    component={FormikInput}
                    placeholder={t('generic.username')}
                  />
                </div>
                <div className="w-full mb-4 flex justify-end align-center">
                  <div className="field w-full mb-0">
                    <Field
                      id="password"
                      label={t('generic.password')}
                      name="password"
                      type={passwordVisibility ? 'text' : 'password'}
                      className="w-full"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(
                          'password',
                          e.target.value.replace(/ /g, ''),
                        )
                      }
                      component={FormikInput}
                      placeholder={t('generic.password')}
                    />
                  </div>

                  <AppButton
                    icon={passwordVisibility ? 'pi pi-eye' : 'pi pi-eye-slash'}
                    type="text"
                    severity="secondary"
                    onClick={onPasswordToggle}
                    className="input-eye-button"
                  />
                </div>
                <Tooltip target=".custom-tooltip-icon" />
                <div className="field-checkbox mb-6">
                  <Field
                    inputId="ignore-errors"
                    name="ignoreCertErrors"
                    label={
                      <FlexContainer>
                        {t('sender.ignoreErrors')}
                        <i
                          className="pi pi-question-circle custom-tooltip-icon ml-2"
                          data-pr-tooltip={t('sender.ignoreErrors.info')}
                        />
                      </FlexContainer>
                    }
                    component={FormikSwitch}
                  />
                </div>
                <AppButton
                  isSubmit
                  severity="secondary"
                  label={isCreate ? t('button.create') : t('button.save')}
                  className="w-4 mt-4 mb-5"
                  state={state}
                  isDisabled={!!Object.keys(errors).length}
                />
              </div>
              {initialValues.meta?.cloneOf && (
                <div className="w-full">
                  <div className="field w-full mb-4">
                    <label htmlFor="cloneOf">
                      {t('generic.cloneOf')} {t('templates.redirectTemplate')}
                    </label>
                    <InputText
                      id="cloneOf"
                      name="cloneOf"
                      value={initialValues.meta?.cloneOf}
                      className="w-full"
                      disabled
                    />
                  </div>

                  <div className="field w-full mb-4">
                    <label htmlFor="sourceCampaignId">
                      {t('generic.cloneOf')} {t('campaign')}
                    </label>
                    <InputText
                      id="sourceCampaignId"
                      name="sourceCampaignId"
                      value={initialValues.meta?.sourceCampaignId}
                      className="w-full"
                      disabled
                    />
                  </div>
                </div>
              )}
            </FlexContainer>
          </Form>
        </FormContainer>
      )}
    </Formik>
  );
};
