import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import {
  SecurityCultureSurvey,
  SecurityCultureSurveyTarget,
} from '@/client/security-culture/types';
import { LoadingStatuses } from '@/common/constants';
import { TargetEntities } from '@/common/types/targets.type';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { displayMiltipleItems } from '@/utils/helpers/ui.helper';
import { AxiosError } from 'axios';
import { Dialog, DialogProps } from 'primereact/dialog';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  width: 500px;
`;

type ChangeSurveyTargetsModalProps = {
  currentSurvey?: SecurityCultureSurvey | null;
  onTriggerRefetch: (surveys: string[]) => void;
} & DialogProps;

export const ChangeSurveyTargetsModal: React.FC<
  ChangeSurveyTargetsModalProps
> = ({ currentSurvey, onTriggerRefetch, onHide, visible }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const [surveyTargets, setSurveyTargets] = useState<
    SecurityCultureSurveyTarget[]
  >([]);
  const [selectedTarget, setSelectedTarget] =
    useState<SecurityCultureSurveyTarget>();
  const [state, setState] = useState<LoadingStatuses>(LoadingStatuses.IDLE);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const fetchTargets = async () => {
    try {
      if (!currentAccount) return;
      setState(LoadingStatuses.LOADING);
      const response = await client.securityCulture.getSurveyTargets(
        currentAccount.id,
      );

      setSurveyTargets(
        response
          .map((item: SecurityCultureSurveyTarget, index: number) => ({
            ...item,
            id: index,
          }))
          .filter(
            ({ surveys }: SecurityCultureSurveyTarget) => surveys?.length,
          ),
      );

      setState(LoadingStatuses.IDLE);
    } catch (e) {
      setState(LoadingStatuses.IDLE);
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  useEffect(() => {
    fetchTargets();
  }, []);

  useEffect(() => {
    if (state === LoadingStatuses.IDLE) {
      setSelectedTarget(
        surveyTargets.find(({ surveys }) =>
          surveys.some((id) => id === currentSurvey?.id),
        ),
      );
    }
  }, [currentSurvey, state]);

  const formatTarget = (targets: TargetEntities) => {
    const targetsValues = Object.values(targets || [])?.flat();

    return targetsValues?.length
      ? displayMiltipleItems(
          targetsValues?.map(({ name }) => name)?.join(', '),
          t,
        )
      : currentAccount?.name;
  };

  const optionTemplate = (surveyTarget: SecurityCultureSurveyTarget) => {
    const name = formatTarget(surveyTarget?.targets);
    return (
      <FlexContainer
        align="flex-start"
        direction="column"
        gap={4}
        className="ml-2"
      >
        <div>{name}</div>
      </FlexContainer>
    );
  };

  const handleChangeSurveyTargets = async () => {
    setIsSubmitting(true);
    await onTriggerRefetch(selectedTarget?.surveys || []);
    setIsSubmitting(false);
    onHide();
  };

  return (
    <StyledDialog
      blockScroll
      visible={visible}
      header={<h1>{t('security.culture.select.target.group')}</h1>}
      onHide={onHide}
      draggable={false}
      data-testid="reports-export-modal"
    >
      <FlexContainer gap={24} justify="flex-start" className="mb-4">
        <Dropdown
          value={selectedTarget}
          dataKey="id"
          optionLabel="name"
          valueTemplate={optionTemplate}
          options={surveyTargets}
          className="w-full ml-3"
          loading={state === LoadingStatuses.LOADING}
          itemTemplate={optionTemplate}
          onChange={(e: DropdownChangeEvent) => {
            setSelectedTarget(e.value);
          }}
        />
      </FlexContainer>

      <FlexContainer justify="flex-end" className="mt-5">
        <AppButton
          label={t('button.cancel')}
          severity="secondary"
          type="outlined"
          onClick={onHide}
          className="mr-3"
          isDisabled={isSubmitting}
          data-testid="change-target-group-cancel"
        />
        <AppButton
          label={t('generic.select')}
          state={isSubmitting ? LoadingStatuses.LOADING : LoadingStatuses.IDLE}
          isDisabled={isSubmitting}
          onClick={handleChangeSurveyTargets}
          data-testid="change-target-group-submit"
        />
      </FlexContainer>
    </StyledDialog>
  );
};
