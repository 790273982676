import { Token, TokenResponse } from '@/client/auth';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

export const authService = (client: AxiosInstance) => {
  const handshakeToken = async (token: string): Promise<Token | undefined> => {
    try {
      const result = await client.post<
        TokenResponse,
        AxiosResponse<TokenResponse>
      >('/auth/handshake', null, {
        headers: {
          'x-set-cookie': true,
          Authorization: `Bearer ${token}`,
        },
      });

      return new Token(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const signOut = async (token?: string): Promise<boolean | undefined> => {
    const headers: Record<string, string | boolean> = {};

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    try {
      await client.get<TokenResponse, AxiosResponse<TokenResponse>>(
        '/auth/sign-out',
        {
          headers,
        },
      );

      return true;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const refreshToken = async (token?: string): Promise<Token | undefined> => {
    const headers: Record<string, string | boolean> = {
      'x-set-cookie': true,
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    try {
      const result = await client.post<
        TokenResponse,
        AxiosResponse<TokenResponse>
      >('/auth/refresh-token', null, {
        headers,
      });

      return new Token(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  return { handshakeToken, refreshToken, signOut };
};
