import { isKeycloakEnabled } from '@/auth';
import { AuthCPProtected } from '@/auth/components/AuthCPProtected';
import { AuthHandshake } from '@/auth/components/AuthHandshake';
import { AuthKeycloakProtected } from '@/auth/components/AuthKeycloakProtected';
import { AuthLoadUser } from '@/auth/components/AuthLoadUser';
import React from 'react';

type AuthProps = {
  children: React.ReactNode;
};

const Auth: React.FC<AuthProps> = ({ children }) =>
  isKeycloakEnabled() ? (
    <AuthKeycloakProtected>{children}</AuthKeycloakProtected>
  ) : (
    <AuthHandshake>
      <AuthCPProtected>{children}</AuthCPProtected>
    </AuthHandshake>
  );

export const AuthProtected: React.FC = () => (
  <Auth>
    <AuthLoadUser />
  </Auth>
);
