import { AppButton } from '@/ui/buttons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledDiv = styled.div`
  @media only screen and (max-device-width: 812px) and (orientation: portrait) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 998;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .phone {
      height: 50px;
      width: 100px;
      border: 3px solid white;
      border-radius: 10px;
      animation: rotate 1.5s ease-in-out infinite alternate;
    }

    .message {
      color: var(--white-main);
      font-size: var(--medium-font-size);
      line-height: var(--medium-line-height);
      margin-top: var(--big-padding);
    }

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(-90deg);
      }
      100% {
        transform: rotate(-90deg);
      }
    }

    -moz-animation: cssAnimation 0s ease-in 3s forwards;
    /* Firefox */
    -webkit-animation: cssAnimation 0s ease-in 3s forwards;
    /* Safari and Chrome */
    -o-animation: cssAnimation 0s ease-in 3s forwards;
    /* Opera */
    animation: cssAnimation 0s ease-in 3s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    @keyframes cssAnimation {
      0% {
        opacity: 1;
      }
      90% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        display: none;
      }
    }
    @-webkit-keyframes cssAnimation {
      0% {
        opacity: 1;
      }
      90% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        display: none;
      }
    }
  }

  display: none;
`;

const StyledCloseButton = styled(AppButton)`
  padding: 0 !important;
  width: var(--medium-button-height) !important;
  height: var(--medium-button-height) !important;
  bottom: calc(2 * var(--default-padding));
  left: calc(4 * var(--default-padding));

  &.p-button.p-button-outlined {
    background: transparent !important;
    color: var(--white-main) !important;
    border-color: var(--white-main) !important;
  }
`;

const OrientationWarning = () => {
  const { t } = useTranslation();
  const [dismiss, setDismiss] = useState(false);
  return (
    <>
      {!dismiss && (
        <StyledDiv>
          <StyledCloseButton
            icon="pi pi-times"
            severity="success"
            type="outlined"
            rounded
            onClick={() => setDismiss(true)}
          />
          <div className="phone"></div>
          <div className="message">{t('message.rotate.device')}</div>
        </StyledDiv>
      )}
    </>
  );
};

export default OrientationWarning;
