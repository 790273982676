export enum LanguagesEnum {
  ZH = 'zh_CN', // Chinese (Simplified)
  CZ = 'cs_CZ', // Czech
  DK = 'da_DK', // Danish
  NL = 'nl_NL', // Dutch
  EN = 'en_GB', // English
  FI = 'fi_FI', // Finnish
  FR = 'fr_FR', // French
  DE = 'de_DE', // German
  KL = 'kl_GL', // Greenlandic
  IT = 'it_IT', // Italian
  LV = 'lv_LV', // Latvian
  LT = 'lt_LT', // Lithuanian
  NO = 'nb_NO', // Norwegian
  PL = 'pl_PL', // Polish
  PG = 'pt_PT', // Portuguese
  ES = 'es_ES', // Spanish
  SE = 'sv_SE', // Swedish
  TR = 'tr_TR', // Turkish
  VI = 'vi_VN', // Vietnamese
  AR = 'ar_SA', // Arabic
  UA = 'uk_UA', //Ukrainian
  RO = 'ro_RO', // Romanian
}

export const languageDisplayLabel: { [key: string]: string } = {
  [LanguagesEnum.ZH]: '中文 [Chinese (Simplified)]',
  [LanguagesEnum.CZ]: 'Čeština [Czech]',
  [LanguagesEnum.DK]: 'Dansk [Danish]',
  [LanguagesEnum.NL]: 'Nederlands [Dutch]',
  [LanguagesEnum.EN]: 'English [English]',
  [LanguagesEnum.FI]: 'Suomi [Finnish]',
  [LanguagesEnum.FR]: 'Français [French]',
  [LanguagesEnum.DE]: 'Deutsch [German]',
  [LanguagesEnum.KL]: 'Kalaallisut [Greenlandic]',
  [LanguagesEnum.IT]: 'Italiano [Italian]',
  [LanguagesEnum.LV]: 'Latviešu [Latvian]',
  [LanguagesEnum.LT]: 'Lietuvių [Lithuanian]',
  [LanguagesEnum.NO]: 'Norsk [Norwegian]',
  [LanguagesEnum.PL]: 'Polski [Polish]',
  [LanguagesEnum.PG]: 'Português [Portuguese]',
  [LanguagesEnum.ES]: 'Español [Spanish]',
  [LanguagesEnum.SE]: 'Svenska [Swedish]',
  [LanguagesEnum.TR]: 'Türkçe [Turkish]',
  [LanguagesEnum.VI]: 'Tiếng Việt [Vietnamese]',
  [LanguagesEnum.AR]: 'اَلْعَرَبِيَّة [Arabic]',
  [LanguagesEnum.UA]: 'Украї́нська мо́ва [Ukrainian]',
  [LanguagesEnum.RO]: 'Română [Romanian]',
};

export const languageDisplayCode: { [key: string]: string } = {
  [LanguagesEnum.ZH]: 'ZH', // Chinese (Simplified)
  [LanguagesEnum.CZ]: 'CZ', // Czech
  [LanguagesEnum.DK]: 'DK', // Danish
  [LanguagesEnum.NL]: 'NL', // Dutch
  [LanguagesEnum.EN]: 'EN', // English
  [LanguagesEnum.FI]: 'FI', // Finnish
  [LanguagesEnum.FR]: 'FR', // French
  [LanguagesEnum.DE]: 'DE', // German
  [LanguagesEnum.KL]: 'KL', // Greenlandic
  [LanguagesEnum.IT]: 'IT', // Italian
  [LanguagesEnum.LV]: 'LV', // Latvian
  [LanguagesEnum.LT]: 'LT', // Lithuanian
  [LanguagesEnum.NO]: 'NO', // Norwegian
  [LanguagesEnum.PL]: 'PL', // Polish
  [LanguagesEnum.PG]: 'PG', // Portuguese
  [LanguagesEnum.ES]: 'ES', // Spanish
  [LanguagesEnum.SE]: 'SE', // Swedish
  [LanguagesEnum.TR]: 'TR', // Turkish
  [LanguagesEnum.VI]: 'VI', // Vietnamese
  [LanguagesEnum.AR]: 'AR', // Arabic
  [LanguagesEnum.UA]: 'UA', // Ukrainian
  [LanguagesEnum.RO]: 'RO', // Romanian
};
