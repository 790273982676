export enum FilePurpose {
  IMPORT = 'import',
  EXPORT = 'export',
  REDIRECT_PAGES = 'redirectPages',
  COURSE = 'course',
  MATERIAL = 'material',
  THUMBNAIL = 'thumbnail',
  SURVEY = 'survey',
}

export enum UsersImportErrors {
  USER_ALREADY_EXISTS_IN_ANOTHER_ACCOUNT = 'user-already-exists-in-another-account',
  USER_ROLE_NOT_ALLOWED = 'user-role-not-allowed',
  USERNAME_REQUIRED = 'username-required',
}
