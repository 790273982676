import { ValidationError } from '@/api/types';
import { useToast } from '@/hooks/useToast';
import { t } from 'i18next';

export const getApiBadRequestErrorsToString = (
  errors: ValidationError[],
): string =>
  errors.reduce((a, e) => {
    let message = e.errors?.join(' ');

    if (e.children && e.children.length > 0) {
      message = `${message} ${getApiBadRequestErrorsToString(e.children)}`;
    }

    return `${a} ${message}`;
  }, '');

export const handleAxiosError = (
  e: any,
  toast: ReturnType<typeof useToast>,
) => {
  let message = t('error.message', {
    message: e.message,
  });

  if (e.response) {
    message = t('error.message', {
      message: getApiBadRequestErrorsToString(
        e.response.data?.message as ValidationError[],
      ),
    });
  } else if (e.errors) {
    message = t('error.message', {
      message: Array.isArray(e.errors)
        ? getApiBadRequestErrorsToString(e.errors)
        : e.errors,
    });
  }

  toast?.error(
    t('error'),
    t('error.processRequest', {
      message,
    }),
  );
};
