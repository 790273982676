import { FlexContainer } from '@/ui/styled-ui';
import { InputText } from 'primereact/inputtext';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

type TemplateSearchProps = {
  count: number;
  take: number;
  skip: number;
  onPageChange: (value: number) => void;
  onRowsChange: (value: number) => void;
  onSearch: (value: string) => void;
  search?: string;
  children?: React.ReactNode;
  rowsPerPage?: number[];
};

export const TemplatesFilters: React.FC<TemplateSearchProps> = ({
  count,
  take,
  skip,
  onPageChange,
  onRowsChange,
  onSearch,
  search,
  children,
  rowsPerPage,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FlexContainer
        className="mt-3"
        wrap="wrap"
        justify="space-between"
        align="flex-end"
      >
        <div className="w-6 p-input-icon-left">
          <InputText
            className="w-full m-1"
            value={search}
            onInput={(event: SyntheticEvent<HTMLInputElement>) => {
              const value = (event.target as HTMLInputElement).value;
              onSearch(value);
            }}
            placeholder={t('templates.searchName')}
            autoComplete="off"
          />
          <i className="pi pi-search" />
        </div>
        {count ? (
          <Paginator
            className="p-0"
            rows={take}
            first={skip}
            totalRecords={count}
            pageLinkSize={3}
            onPageChange={(event: PaginatorPageChangeEvent) => {
              onRowsChange(event.rows);
              onPageChange(event.first);
            }}
            template="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
            currentPageReportTemplate={`{first} ${t('filter.to')} {last} ${t(
              'filter.of',
            )} {totalRecords}`}
            rowsPerPageOptions={rowsPerPage ?? [12, 24]}
          />
        ) : null}
      </FlexContainer>
      {children}
    </>
  );
};
