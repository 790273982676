import { LanguagesEnum } from '@/api/enums';
import { learnerOnlyLanguages } from '@/common/constants/languages';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentUser } from '@/store/features/users';
import { isThereTranslation } from '@/utils/helpers';
import { Calendar, CalendarProps } from 'primereact/calendar';
import React from 'react';
import styled from 'styled-components';

const StyledCalendar = styled(Calendar)`
  &.p-calendar {
    &.p-calendar-w-btn.p-calendar-w-btn-left {
      .p-button {
        background-color: var(--beige-main);
        color: var(--black-main);
        border: none;
        border-start-start-radius: var(--default-border-radius);
        border-bottom-left-radius: var(--default-border-radius);
        padding-left: var(--small-padding);
        span {
          font-size: var(--medium-font-size);
        }
      }

      .p-inputtext {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
      }
    }
  }

  &.white-input {
    .p-inputtext,
    .p-button {
      background-color: var(--white-main) !important;
      border-color: var(--white-main);
    }
  }
`;

const AppCalendar: React.FC<CalendarProps> = ({
  dateFormat = 'M dd, yy',
  ...rest
}) => {
  const user = useAppSelector(selectCurrentUser);

  return (
    <StyledCalendar
      locale={
        isThereTranslation() &&
        !learnerOnlyLanguages.includes(user?.language as LanguagesEnum)
          ? user?.language
          : 'en'
      }
      dateFormat={dateFormat}
      {...rest}
    />
  );
};

export default AppCalendar;
