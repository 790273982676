import { equal, isIn, nested } from '@/api/helpers';
import { Account } from '@/client/accounts';
import {
  Course,
  CourseAvailabilityFormValues,
  CourseAvailabilityOptionsEnum,
  CourseAvailabilityTypeEnum,
} from '@/client/courses';
import { AccountsAutoSelectHelper } from '@/components/courses/modals/AccountsAutoSelectHelper';
import { FormikDropdown } from '@/components/form';
import { AccountsMultiselectInput } from '@/components/form/selectors';
import {
  useAccountsPartialRequest,
  useUnavailableAccountsPartialRequest,
} from '@/hooks/query';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { courseAvailabilityOptions } from '@/utils/helpers';
import { Field, Form, Formik } from 'formik';
import { Dialog, DialogProps } from 'primereact/dialog';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { courseAvailabilitySchema } from '../validations';

const StyledDialog = styled(Dialog)`
  width: 500px;
`;

type CourseAvailabilityModalProps = {
  onSubmit: (data: CourseAvailabilityFormValues) => void;
  extended?: boolean;
  type: CourseAvailabilityTypeEnum;
  course: Course;
} & DialogProps;

export const CourseAvailabilityModal: React.FC<
  CourseAvailabilityModalProps
> = ({ onSubmit, onHide, type, course, visible }) => {
  const { t } = useTranslation();
  const [initialValues] = useState<CourseAvailabilityFormValues>({
    accountsType: CourseAvailabilityOptionsEnum.CUSTOM,
    accounts: [],
  });

  const isMakeAvailable = type === CourseAvailabilityTypeEnum.MAKE_AVAILABLE;

  const {
    accounts: autoAvailableAccounts,
    refetch,
    isLoading,
  } = isMakeAvailable
    ? useUnavailableAccountsPartialRequest(
        {
          enabled: false,
          filters: [
            nested('meta', [equal('autoCourseAvailability', true)]),
            equal('active', true),
            ...(course?.account?.id ? [equal('id', course?.account?.id)] : []),
          ],
        },
        course?.id,
      )
    : useAccountsPartialRequest({
        enabled: false,
        filters: [
          nested('meta', [equal('autoCourseAvailability', true)]),
          isIn('availableCourses', [course?.id]),
          equal('active', true),
          ...(course?.account?.id ? [equal('id', course?.account?.id)] : []),
        ],
      });

  // A state to track if the selection of accounts was manual or with the help of a preselect
  const [autoTriggeredSelection, setAutoTriggeredSelection] = useState(false);
  const handleAccountsSelect = (
    account: Account[],
    manualChange: boolean,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    setFieldValue('accounts', account);
    if (manualChange) {
      setAutoTriggeredSelection(false);
      setFieldValue('accountsType', CourseAvailabilityOptionsEnum.CUSTOM);
    }
    if (autoTriggeredSelection) {
      setAutoTriggeredSelection(false);
    }
  };

  return (
    <StyledDialog
      blockScroll
      visible={visible}
      header={
        <h1>
          {isMakeAvailable
            ? t('generic.accounts.available')
            : t('generic.accounts.unavailable')}
        </h1>
      }
      onHide={onHide}
      onShow={refetch}
      draggable={false}
      data-testid="make-course-available-modal"
    >
      {isLoading && <ProgressSpinner />}
      {!isLoading && (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={courseAvailabilitySchema(t)}
        >
          {({ setFieldValue, values, errors }) => (
            <>
              <AccountsAutoSelectHelper
                accountsType={values.accountsType}
                autoSelectedAccounts={autoAvailableAccounts}
                onSetAutoTriggeredSelection={setAutoTriggeredSelection}
                onSetFieldValue={setFieldValue}
              />
              <Form>
                <div className="field w-full mb-4">
                  <Field
                    id="accounts-type"
                    name="accountsType"
                    label={t('accounts')}
                    className="w-full"
                    component={FormikDropdown}
                    onChange={(e: DropdownChangeEvent) =>
                      setFieldValue('accountsType', e.value)
                    }
                    options={courseAvailabilityOptions(t)}
                  />
                </div>

                <div className="field w-full mb-4">
                  <label>{`${t('generic.selected')} (${
                    values.accounts?.length
                      ? values.accounts?.length
                      : t('generic.none')
                  })`}</label>
                  {isMakeAvailable ? (
                    <AccountsMultiselectInput
                      className="w-full"
                      onChange={(e, manualChange) =>
                        handleAccountsSelect(e, manualChange, setFieldValue)
                      }
                      selectedOptions={
                        values.accountsType ===
                        CourseAvailabilityOptionsEnum.AUTO
                          ? autoAvailableAccounts
                          : values.accounts
                      }
                      getOnlyUnavailableAccounts
                      additionalFilters={[
                        equal('active', true),
                        ...(course?.account?.id
                          ? [equal('id', course?.account?.id)]
                          : []),
                      ]}
                      courseId={course?.id}
                    />
                  ) : (
                    <AccountsMultiselectInput
                      className="w-full"
                      onChange={(e, manualChange) =>
                        handleAccountsSelect(e, manualChange, setFieldValue)
                      }
                      additionalFilters={[
                        isIn('availableCourses', [course?.id]),
                        equal('active', true),
                        ...(course?.account?.id
                          ? [equal('id', course?.account?.id)]
                          : []),
                      ]}
                      selectedOptions={
                        values.accountsType ===
                        CourseAvailabilityOptionsEnum.AUTO
                          ? autoAvailableAccounts
                          : values.accounts
                      }
                    />
                  )}
                </div>

                <FlexContainer justify="flex-end" className="mt-5">
                  <AppButton
                    label={t('button.cancel')}
                    severity="secondary"
                    type="outlined"
                    onClick={() => {
                      onHide();
                    }}
                    className="mr-3"
                    data-testid="make-course-available-cancel-form"
                  />
                  <AppButton
                    label={
                      isMakeAvailable
                        ? t('generic.accounts.available')
                        : t('generic.accounts.unavailable')
                    }
                    severity="secondary"
                    isSubmit
                    data-testid="make-course-available-submit-form"
                    isDisabled={!!Object.keys(errors).length}
                  />
                </FlexContainer>
              </Form>
            </>
          )}
        </Formik>
      )}
    </StyledDialog>
  );
};
