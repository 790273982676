import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: var(--medium-padding);
  margin: var(--default-padding) 0;
`;

type CardGridContainerProps = {
  children?: ReactNode;
};

const CardGridContainer: React.FC<
  CardGridContainerProps & React.HTMLAttributes<HTMLElement>
> = ({ children, ...rest }) => (
  <StyledCardsContainer {...rest}>{children}</StyledCardsContainer>
);

export default CardGridContainer;
