import { isKeycloakEnabled } from '@/auth';
import { client } from '@/client';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';

export const useEFrontReindex = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);

  const reindex = async () => {
    setLoading(true);

    try {
      await client.efrontSync.forceEFrontBranchReindex();
      await client.efrontSync.forceEFrontGroupReindex();

      setIsSuccess(true);
    } catch (e) {
      setIsSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    isSuccess,
    reindex,
  };
};

// Separate notification hook for efront sync, because it runs constantly.
export const useEFrontSyncNotifications = () => {
  const isKeycloakAuth = isKeycloakEnabled();
  const { user } = useAuth();

  const [lastMessage, setLastMessage] = useState<{
    event: string;
    data: any;
  } | null>(null);
  const isConnected = useRef(false);

  useEffect(() => {
    const sourcePolyfill = new EventSourcePolyfill(
      client.efrontSync.getNotificationsSSEUrl(),
      {
        withCredentials: true,
        headers: isKeycloakAuth
          ? { Authorization: `Bearer ${user?.access_token}` }
          : {},
      },
    );

    // Check that a connection is established
    sourcePolyfill.onopen = () => {
      isConnected.current = true;
    };

    sourcePolyfill.onmessage = (message) => {
      isConnected.current = true;
      const data = JSON.parse(message.data);
      setLastMessage({ ...message, data } as any);
    };

    // If an error is encountered during connection the Eventsource will set the connection state to false, but will continue to try to connect
    sourcePolyfill.onerror = () => {
      isConnected.current = false;
    };

    return () => {
      sourcePolyfill.close();
      isConnected.current = false;
      setLastMessage(null);
    };
  }, [isKeycloakAuth, user?.access_token]);

  return lastMessage;
};
