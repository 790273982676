import { equal } from '@/api/helpers';
import { FiltersType, ListingRequest } from '@/api/types';
import { client } from '@/client';
import { PageTemplate, PageTemplatesEnum } from '@/client/page-templates';
import PagePlaceholderImage from '@/images/placeholders/page-template.svg';
import { FlexContainer } from '@/ui/styled-ui';
import { escapeRegExp } from 'lodash';
import { DropdownProps } from 'primereact/dropdown';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LazyLoadingSelect } from '../generic/LazyLoadingSelect';

const StyledFlexContainer = styled(FlexContainer)`
  .template-thumbnail {
    width: 48px;
    height: 48px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: var(--gray-pale);
  }
`;

type RedirectPageSelectInputProps = {
  onChange: (value: PageTemplate) => void;
  additionalFilters?: FiltersType;
  defaultValue?: PageTemplate;
} & Omit<HTMLAttributes<HTMLSelectElement>, 'onChange' | 'defaultValue'>;

export const RedirectPageSelectInput: React.FC<
  RedirectPageSelectInputProps
> = ({ onChange, className, additionalFilters, defaultValue }) => {
  const { t } = useTranslation();
  const fetchPageTemplates = async (params: ListingRequest) =>
    await client.pageTemplates.getPageTemplates(params);

  const selectedItemTemplate = (
    template: PageTemplate,
    props: DropdownProps,
  ) => {
    if (template) {
      return (
        <StyledFlexContainer justify="flex-start" gap={8}>
          <div
            className="template-thumbnail"
            style={{
              backgroundImage: `url(${escapeRegExp(
                template?.thumbnail?.signedUrl ?? PagePlaceholderImage,
              )}`,
            }}
          />
          <div>{template.name}</div>
          {/* TODO: add language when available for tempaltes */}
        </StyledFlexContainer>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const optionItemTemplate = (template: PageTemplate) => {
    return (
      <StyledFlexContainer justify="flex-start" gap={8}>
        <div
          className="template-thumbnail"
          style={{
            backgroundImage: `url(${escapeRegExp(
              template?.thumbnail?.signedUrl ?? PagePlaceholderImage,
            )}`,
          }}
        />
        <div>{template.name}</div>
        {/* TODO: add language when available for tempaltes */}
      </StyledFlexContainer>
    );
  };

  return (
    <LazyLoadingSelect
      fetchService={fetchPageTemplates}
      selectedValue={defaultValue}
      filter
      className={className}
      optionLabel="name"
      handleOnChange={onChange}
      placeholder={t('templates.selectRedirect')}
      itemTemplate={optionItemTemplate}
      valueTemplate={selectedItemTemplate}
      additionalFilters={[
        equal('type', PageTemplatesEnum.REDIRECT),
        ...(additionalFilters ?? []),
        equal('active', true),
      ]}
    />
  );
};
