import classNames from 'classnames';
import { get } from 'lodash';
import { InputNumber, InputNumberProps } from 'primereact/inputnumber';
import React, { SyntheticEvent, useEffect } from 'react';

export type FormikInputNumberProps = {
  field: {
    name: string;
    value: number;
    onChange: () => void;
    onBlur: (e: SyntheticEvent<HTMLInputElement>) => void;
  };
  form: {
    touched: Record<string, any>;
    errors: Record<string, any>;
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void;
  };
  label?: string;
  icon?: string;
};

export const FormikInputNumber: React.FC<
  FormikInputNumberProps & InputNumberProps
> = ({ field, form, label, className, required, icon, ...rest }) => {
  const fieldError = get(form.errors, field.name);
  const fieldTouched = get(form.touched, field.name);

  return (
    <>
      <label
        htmlFor={field.name}
        className={classNames({
          'p-error': fieldTouched && !!fieldError,
        })}
      >
        {label}
        {required ? <span className="red"> *</span> : ''}
      </label>
      {icon ? (
        <div className="p-input-icon-right w-full">
          <InputNumber
            {...field}
            value={field.value}
            onValueChange={field.onChange}
            {...rest}
            className={classNames(className, {
              'p-invalid': fieldTouched && !!fieldError,
            })}
          />
          <i className={icon} />
        </div>
      ) : (
        <InputNumber
          {...field}
          value={field.value}
          onValueChange={field.onChange}
          {...rest}
          className={classNames(className, {
            'p-invalid': fieldTouched && !!fieldError,
          })}
        />
      )}
      {fieldTouched && !!fieldError ? (
        <small className="p-error">{fieldError}</small>
      ) : null}
    </>
  );
};
