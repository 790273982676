import { processListingParams } from '@/api/helpers';
import {
  ListEfrontCoursesRequest,
  ListEfrontCoursesResponse,
} from '@/client/efront';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

export const efrontCourseService = (client: AxiosInstance) => {
  const getEfrontCourses = async (
    params: ListEfrontCoursesRequest,
  ): Promise<ListEfrontCoursesResponse> => {
    try {
      const result = await client.get<
        ListEfrontCoursesResponse,
        AxiosResponse<ListEfrontCoursesResponse>
      >('efront-integration/courses', {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  return {
    getEfrontCourses,
  };
};
