import { FiltersType, ListingRequest } from '@/api/types';
import { client } from '@/client';
import { EfrontCourse } from '@/client/courses';
import { PartialRequestMultiselect } from '@/components/form/generic/PartialRequestMultiselect';
import { FlexContainer } from '@/ui/styled-ui';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

type EfrontCoursesMultiselectInputProps = {
  onChange: (value: EfrontCourse[]) => void;
  additionalFilters?: FiltersType;
  selectedOptions?: EfrontCourse[];
  isDisabled?: boolean;
} & Omit<HTMLAttributes<HTMLSelectElement>, 'onChange'>;

export const EfrontCoursesMultiselectInput: React.FC<
  EfrontCoursesMultiselectInputProps
> = ({
  onChange,
  className,
  additionalFilters,
  selectedOptions,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const fetchEfrontCourses = isDisabled
    ? undefined
    : async (params: ListingRequest) =>
        await client.efrontCourses.getEfrontCourses(params);

  const optionTemplate = (course: EfrontCourse) => {
    return (
      <FlexContainer
        align="flex-start"
        direction="column"
        gap={4}
        className="ml-2"
      >
        <div>{course?.name}</div>
        <span className="secondary-text">{course?.meta?.eFrontPublicId}</span>
      </FlexContainer>
    );
  };

  return (
    <PartialRequestMultiselect
      fetchService={fetchEfrontCourses}
      filter
      display="chip"
      optionLabel="name"
      dataKey="id"
      placeholder={t('courses.select')}
      maxSelectedLabels={2}
      itemTemplate={optionTemplate}
      additionalFilters={additionalFilters}
      selectedOptions={selectedOptions}
      className={className}
      handleOnChange={onChange}
      disabled={isDisabled}
    />
  );
};
