import {
  FiltersType,
  GroupedFilterConditions,
  ListingRequest,
} from '@/api/types';

type ProcessListingParamsType = ListingRequest & { filters?: string };

export const processListingParams = (
  params: ListingRequest | any,
): ProcessListingParamsType => {
  let parsedParams: ListingRequest = params;

  const length = params.filters?.conditions
    ? (params.filters as GroupedFilterConditions).conditions?.length
    : (params.filters as FiltersType)?.length;

  if (params.filters && length > 0) {
    parsedParams = {
      ...parsedParams,
      filters: JSON.stringify(params.filters),
    } as ProcessListingParamsType;
  }

  return parsedParams as ProcessListingParamsType;
};
