import { processListingParams } from '@/api/helpers';
import {
  ListEfrontBranchesRequest,
  ListEfrontBranchesResponse,
} from '@/client/efront';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

export const efrontBranchService = (client: AxiosInstance) => {
  const getEfrontBranches = async (
    params: ListEfrontBranchesRequest,
  ): Promise<ListEfrontBranchesResponse> => {
    try {
      const result = await client.get<
        ListEfrontBranchesResponse,
        AxiosResponse<ListEfrontBranchesResponse>
      >('efront-integration/branches', {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  return {
    getEfrontBranches,
  };
};
