import { Branch } from '@/client/branches';
import { Group } from '@/client/groups';
import { User } from '@/client/users';

export const removeById = (data: any[], ids: string[]) =>
  data.filter((d) => !ids?.includes(d.id));

export const mapToArrayOfIds = (array: { id: string }[]) =>
  array?.map((obj: { id: string }) => obj.id);

export const updateChildrenUsersAfterUsersListChange = (
  state: { id: string; name: string; users: User[] }[],
  user: User,
) =>
  state
    .map((chu) => {
      if (!chu.users.map((u) => u.id).includes(user.id)) return chu;

      return {
        ...chu,
        users: removeById(chu.users, [user.id]),
      };
    })
    .filter((chu) => chu.users.length);

export const clearChildrenUsersFromParent = (
  parents: { id: string; name: string; users: User[] }[],
  childrenParents: string[],
) => {
  const filteredParents = parents.filter((bu) =>
    childrenParents?.includes(bu.id),
  );
  return filteredParents
    ?.map((data) => data?.users.map((user) => user.id))
    .flat();
};

export type TargetGroupStateType = {
  branches: Branch[];
  groups: Group[];
  users: User[];
  usersList: User[];
  branchesUsers: { id: string; name: string; users: User[] }[];
  groupsUsers: { id: string; name: string; users: User[] }[];
};

export const targetGroupInitialValues = {
  branches: [],
  groups: [],
  users: [],
  usersList: [],
  branchesUsers: [],
  groupsUsers: [],
};
