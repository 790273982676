import { LanguagesEnum } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import {
  Course,
  CourseAttempt,
  CourseStatusAttemptEnum,
} from '@/client/courses';
import { User } from '@/client/users';
import { useMixpanel } from '@/hooks/useMixpanel';
import { pendoEvent, pendoProperty, usePendo } from '@/hooks/usePendo';
import { useToast } from '@/hooks/useToast';
import { scormFactory } from '@/scorm/scorm.factory';
import { ScormVersion } from '@/scorm/types';
import { hubspotTrackCourseCompletion } from '@/utils/hubspot';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

type ScormContextProps = {
  values: Record<string, any>;
  errors: Record<string, any>;
};

const ScormContext = createContext<ScormContextProps>({
  values: {},
  errors: {},
});

type ScormProviderProps = {
  children: React.ReactNode;
  version: ScormVersion;
  attempt?: CourseAttempt;
  attemptLanguage?: LanguagesEnum;
  course: Course;
  user: User;
  setStatus?: (status?: CourseStatusAttemptEnum) => void;
  setIsSubmittingStatus?: (isSubmittingStatus: boolean) => void;
  isPreview: boolean;
};

export const ScormProvider: React.FC<ScormProviderProps> = ({
  children,
  version,
  attempt,
  attemptLanguage,
  course,
  user,
  setStatus,
  setIsSubmittingStatus,
  isPreview,
}) => {
  const toast = useToast();
  const [scormValues, setScormValues] = useState<Record<string, any>>(
    attempt?.payload ?? {},
  );
  const [scormErrors, setScormErrors] = useState<Record<string, any>>({});
  const hasSetSubmittingStatus = useRef<boolean>(false);

  useEffect(() => {
    if (attemptLanguage) {
      const saveAttemptLanguage = async () => {
        if (attempt?.course?.id && attempt.id && attemptLanguage) {
          await client.learnerCourses.updateCourseAttempt({
            courseId: attempt.course.id,
            attemptId: attempt.id,
            language: attemptLanguage,
          });
        }
      };
      saveAttemptLanguage();
    }
  }, [attemptLanguage]);

  const saveScormValues = async (
    payload: any,
    status: CourseStatusAttemptEnum,
    timeSpent: number,
    score: number,
  ) => {
    if (attempt?.course?.id && attempt.id) {
      try {
        if (
          status === CourseStatusAttemptEnum.COMPLETED &&
          attempt.status !== CourseStatusAttemptEnum.COMPLETED &&
          !hasSetSubmittingStatus.current
        ) {
          setIsSubmittingStatus && setIsSubmittingStatus(true);
          hasSetSubmittingStatus.current = true;
        }

        await client.learnerCourses.updateCourseAttempt({
          courseId: attempt.course.id,
          attemptId: attempt.id,
          payload: {
            payload,
            status,
            timeSpent,
            score,
          },
        });

        if (setStatus) {
          setStatus(status);
        }

        if (user?.account?.freeTrialEndsAt) {
          hubspotTrackCourseCompletion(course, status);
        }
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      } finally {
        setIsSubmittingStatus && setIsSubmittingStatus(false);
      }
    }
  };

  const scormInstance = scormFactory(
    user,
    version,
    {
      onSetValue: (element, value, api) => {
        setScormValues(api);
      },
      onCommit: (
        api: any,
        status: CourseStatusAttemptEnum,
        timeSpent: number,
        score: number,
      ) => {
        saveScormValues(api?.cmi, status, timeSpent, score);
      },
      onInit: (api) => {
        api?.loadFromJSON(attempt?.payload ?? {});
      },
      onError: (error) => {
        setScormErrors(error);
        toast?.error(t('error'), error);
      },
    },
    isPreview,
  );

  return (
    <ScormContext.Provider value={{ values: scormValues, errors: scormErrors }}>
      {children}
    </ScormContext.Provider>
  );
};

// Custom hook to use the SCORM context
export const useScorm = () => useContext(ScormContext);
