import { like } from '@/api/helpers';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import {
  AutoComplete,
  AutoCompleteCompleteEvent,
} from 'primereact/autocomplete';
import React, { HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';

type AccountsAutocompleteInputProps = {
  onSelect: (value: Account) => void;
  defaultValue?: Account;
  disabled?: boolean;
} & Omit<HTMLAttributes<HTMLInputElement>, 'onSelect' | 'defaultValue'>;

export const AccountsAutocompleteInput: React.FC<
  AccountsAutocompleteInputProps
> = ({ onSelect, defaultValue, disabled, ...rest }) => {
  const { t } = useTranslation();

  const [searchResults, setSearchResults] = useState<Account[]>([]);
  const [fieldValue, setFieldValue] = useState<string>('');

  const onCompleteMethod = async (e: AutoCompleteCompleteEvent) => {
    const accounts = await client.accounts.getAccounts({
      take: 50,
      filters: [like('name', e.query)],
      sort: ['name,asc'],
    });

    setSearchResults(
      accounts.result.map((a) => ({
        ...a,
        name: a.name,
      })),
    );
  };

  return (
    <AutoComplete
      {...rest}
      dropdown
      disabled={disabled}
      value={fieldValue ? fieldValue : defaultValue}
      field="name"
      placeholder={t('account.selectPlease')}
      suggestions={searchResults}
      completeMethod={onCompleteMethod}
      onSelect={(e) => {
        onSelect(e.value);
      }}
      onChange={(e) => setFieldValue(e.value)}
    />
  );
};
