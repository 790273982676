import { equal, like } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { CourseStatusAttemptEnum, MaterialType } from '@/client/courses';
import { Actions, Subjects } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { useLearnerCourses } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { PosterCard } from '@/ui/poster-card';
import { CardGridContainer, FlexContainer } from '@/ui/styled-ui';
import { postersColumnsWidth } from '@/utils/helpers';
import { debounce } from 'lodash';
import { InputText } from 'primereact/inputtext';
import React, { FormEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledCardGridContainer = styled(CardGridContainer)`
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 0;

  @media screen and (max-width: ${postersColumnsWidth.THREE + 200}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: ${postersColumnsWidth.TWO + 200}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: ${postersColumnsWidth.ONE + 200}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const PostersPage: React.FC = () => {
  const { t } = useTranslation();
  const { can } = usePermission();
  const navigate = useNavigate();
  const currentAccount = useAppSelector(selectCurrentAccount);
  // TODO: TBD if we have search
  const [multiSearchValue, setMultiSearchValue] = useState('');
  const debouncedSetMultiSearchValue = useMemo(
    () =>
      debounce((event: FormEvent) => {
        setMultiSearchValue((event.target as HTMLInputElement).value);
      }, 300),
    [],
  );

  const { isLoading, learnerCourses: posters } = useLearnerCourses({
    skip: 0,
    type: CourseStatusAttemptEnum.IN_PROGRESS,
    filters: [
      equal('type', MaterialType.POSTER),
      like('name', multiSearchValue),
    ],
    staleTime: Infinity,
  });

  const pathItems = [
    {
      label: currentAccount?.name,
      url: !currentAccount?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](currentAccount?.id as string)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('posters'),
      url: RedirectPaths[RedirectPathsEnum.POSTERS](),
      template: AppBreadCrumbTemplate,
    },
  ];

  return (
    <>
      <AppBreadCrumb model={pathItems} />
      <FlexContainer direction="column" align="flex-start" className="mb-4">
        <h1 className="mb-4">{t('posters')}</h1>
        <FlexContainer
          justify="space-between"
          gap={24}
          align="flex-start"
          wrap="wrap"
        >
          <div className="p-input-icon-left flex-auto min-w-300 w-full">
            <InputText
              className="w-full"
              onInput={debouncedSetMultiSearchValue}
              placeholder={t('generic.name.search')}
              autoComplete="off"
            />
            <i className="pi pi-search" />
          </div>
          {can(Actions.CREATE, Subjects.COURSES) &&
            currentAccount?.isSystem && (
              <AppButton
                label={t('button.createNew')}
                severity="secondary"
                onClick={() => {
                  navigate(RedirectPaths[RedirectPathsEnum.POSTER_CREATE]());
                }}
              />
            )}
        </FlexContainer>
      </FlexContainer>
      <StyledCardGridContainer>
        {posters?.result.map((poster) => (
          <PosterCard key={poster.id} poster={poster} />
        ))}
      </StyledCardGridContainer>
    </>
  );
};
