import { ValidationError } from '@/api/types';
import { t } from 'i18next';

export class BadRequestException extends Error {
  public readonly statusCode: number = 400;

  public constructor(
    public message: string = t('error.badRequest'),
    public readonly errors?: ValidationError[],
  ) {
    super(message);
  }
}
