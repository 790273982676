import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { getExceptionFromAxiosError } from '../helpers';
import { MessageResponseModel } from '../models';
import { AccountAD, UpdateAccountADRequest } from './types';

export const accountADService = (client: AxiosInstance) => {
  const ACCOUNT_BASE_URL = '/account';
  const AD_DIRECTORY_INTEGRATION = 'integrations/active-directory';

  const getAccountADConfig = async (accountId: string): Promise<AccountAD> => {
    try {
      const result = await client.get<AccountAD, AxiosResponse<AccountAD>>(
        `${ACCOUNT_BASE_URL}/${accountId}/${AD_DIRECTORY_INTEGRATION}`,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveAccountADConfig = async (
    request: UpdateAccountADRequest,
  ): Promise<AccountAD> => {
    try {
      const result = await client.post<AccountAD, AxiosResponse<AccountAD>>(
        `${ACCOUNT_BASE_URL}/${request.accountId}/${AD_DIRECTORY_INTEGRATION}`,
        request.updates,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const forceADSync = async (
    accountId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${ACCOUNT_BASE_URL}/${accountId}/${AD_DIRECTORY_INTEGRATION}/force-sync`,
        {
          withCredentials: true,
        },
      );

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const adSyncNotifyUrl = (accountId: string) =>
    `${client.defaults.baseURL}account/${accountId}/${AD_DIRECTORY_INTEGRATION}/notify`;

  return {
    getAccountADConfig,
    saveAccountADConfig,
    forceADSync,
    adSyncNotifyUrl,
  };
};
