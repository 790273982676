import { availableLanguages } from '@/common/constants/languages';
import { TranslationFunctionType } from '@/common/types';
import moment from 'moment';
import { array, date, number, object, string } from 'yup';

const parseDate = (value: Date | null, originalValue: Date | null) => {
  if (originalValue) {
    return moment(originalValue, true).seconds(0).millisecond(0).toDate();
  }

  return null;
};

export const launchCampaignSchema = (
  t: TranslationFunctionType,
  isNewPhishingSelectorEnabled: boolean | undefined,
) =>
  object().shape(
    {
      emailTemplate: string().uuid().required(t('error.select.emailTemplate')),
      landingPage: string().uuid().required(t('error.select.landingPage')),
      ...(isNewPhishingSelectorEnabled
        ? {
            targets: array().min(1, t('error.select.oneTargetGroup')),
            excludes: array(),
          }
        : {
            targetGroup: object({
              usersList: array().min(1, t('error.select.oneUser')),
            }),
          }),
      sendingProfile: object().required(t('error.select.sender')),
      periodType: string().equals(['drip', 'blast']),
      launchDate: date()
        .required(t('error.select.launch'))
        .transform(parseDate),
      sendByDate: date()
        .transform(parseDate)
        .when(
          ['periodType', 'launchDate'],
          ([periodType, launchDate], schema) =>
            periodType === 'drip' && launchDate
              ? schema
                  .required(t('error.select.sendDate'))
                  .min(moment(launchDate), t('error.select.pastLaunch'))
              : schema.notRequired(),
        ),
      name: string().required(t('error.select.name')),
    },
    [
      ['sendByDate', 'periodType'],
      ['sendByDate', 'launchDate'],
    ],
  );

export const saveCampaignTemplateSchema = (t: TranslationFunctionType) =>
  object().shape({
    emailTemplate: string().uuid().required(t('error.select.emailTemplate')),
    landingPage: string().uuid().required(t('error.select.landingPage')),
    sendingProfile: object().required(t('error.select.sender')),
    name: string().required(t('error.select.name')),
    language: string().required(t('error.requiredLanguage')),
    difficulty: number()
      .required(t('error.requiredDifficulty'))
      .oneOf([1, 2, 3, 4, 5]),
    clickedLinksPercentage: number()
      .nullable()
      .min(1, t('error.OneToOneHundredNumber'))
      .max(100, t('error.OneToOneHundredNumber')),
    submittedDataPercentage: number()
      .nullable()
      .min(1, t('error.OneToOneHundredNumber'))
      .max(100, t('error.OneToOneHundredNumber')),
  });
