import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import {
  SystemEmailsConfigFormValues,
  SystemEmailTypeConfig,
} from '@/client/system-emails';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { StateProvider } from '@/components/state-provider';
import {
  useSystemEmailTypeConfig,
  useUpdateSystemEmailTypeConfig,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import {
  checkIfSystemEmailExists,
  SystemEmailsConfigureEmailForm,
} from '@/system-settings';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const pathItems = (
  account: Account,
  systemEmailConfig: SystemEmailTypeConfig,
  t: TranslationFunctionType,
) => [
  {
    label: account?.name,
    url: !account?.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('generic.system.emails'),
    className: 'active',
    url: RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES](),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('generic.email.configs'),
    url: RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TYPES_CONFIGS](),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('system.email.type.config.edit'),
    url: RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TYPES_CONFIGS_EDIT](
      systemEmailConfig?.id,
    ),
    template: AppBreadCrumbTemplate,
  },
];

export const SystemEmailsUpdateTypeConfigPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { systemEmailTypeConfig, isLoading } = useSystemEmailTypeConfig({
    configId: id,
  });

  return (
    <StateProvider
      state={systemEmailTypeConfig}
      isLoading={isLoading}
      loadingMessage={t('system.email.type.config.data.loading')}
      errorMessage={t('system.email.type.config.data.error')}
    >
      <SystemEmailsUpdateTypeConfigWithState
        systemEmailTypeConfig={systemEmailTypeConfig as SystemEmailTypeConfig}
      />
    </StateProvider>
  );
};

const SystemEmailsUpdateTypeConfigWithState: React.FC<{
  systemEmailTypeConfig: SystemEmailTypeConfig;
}> = ({ systemEmailTypeConfig }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const account = useAppSelector(selectCurrentAccount);
  const toast = useToast();

  const updateSystemEmailTypeConfig = useUpdateSystemEmailTypeConfig();

  const initialValues = {
    type: systemEmailTypeConfig?.type,
    sendTime: systemEmailTypeConfig?.sendTime,
    active: systemEmailTypeConfig?.active,
  };

  const handleSubmit = async (data: SystemEmailsConfigFormValues) => {
    try {
      const systemEmailExists = await checkIfSystemEmailExists({
        type: data.type as string,
        id: systemEmailTypeConfig.id as string,
        account: account as Account,
      });

      if (systemEmailExists) {
        toast?.error(
          t('system.email.type.config.emailType'),
          t('system.email.type.config.unavailable'),
        );

        return;
      }

      await updateSystemEmailTypeConfig.update({
        configId: systemEmailTypeConfig.id as string,
        ...data,
      });
      navigate(RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TYPES_CONFIGS]());

      toast?.success(t('toast.success'), t('system.email.type.config.edited'));
    } catch (e) {
      handleAxiosError(e as AxiosError, toast);
    }
  };

  const handleCancelUpdate = () =>
    navigate(RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TYPES_CONFIGS]());

  return (
    <>
      <AppBreadCrumb
        model={pathItems(
          account as Account,
          systemEmailTypeConfig as SystemEmailTypeConfig,
          t,
        )}
      />
      <h1>{t('system.email.type.config.edit')}</h1>
      <FlexContainer justify="flex-start">
        <SystemEmailsConfigureEmailForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onCancel={handleCancelUpdate}
        />
      </FlexContainer>
    </>
  );
};
