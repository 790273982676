import { equal } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { SenderProfilesDatatable } from '@/components/sender-profiles/datatables';
import { useAppSelector } from '@/hooks/store';
import { SenderProfilesTabs } from '@/pages/sender-profiles';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const SenderProfilesPage = () => {
  const { t } = useTranslation();
  const currentAccount = useAppSelector(selectCurrentAccount);

  const breadCrumbItems = [
    {
      label: (currentAccount as Account)?.name,
      url: !currentAccount?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](currentAccount?.id as string)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('sender.predefined'),
      url: RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_PREDEFINED](),
      template: AppBreadCrumbTemplate,
    },
  ];

  const filters = [equal('account', null)];

  return (
    <>
      <AppBreadCrumb model={breadCrumbItems} />
      <h1>{t('sender.predefined')}</h1>
      <SenderProfilesTabs />
      <SenderProfilesDatatable
        defaultFilters={filters}
        withActions
        withAddNew={currentAccount?.isSystem}
        withToolbar
        filterByPredefined
      />
    </>
  );
};
