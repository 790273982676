import { languageDisplayLabel, LanguagesEnum } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import { Course, MaterialType } from '@/client/courses';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import PagePlaceholderImage from '@/images/placeholders/page-template.svg';
import { selectCurrentUser } from '@/store/features/users';
import { AppButton, AppDropdownButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import {
  getTranslationByLanguage,
  isUserLanguageAvailableinMaterial,
} from '@/utils/helpers';
import { downloadFileByUrl } from '@/utils/helpers/download.helper';
import { hubspotTrackMaterialDownloaded } from '@/utils/hubspot';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledImageCard = styled(Card)`
  @media screen and (max-width: 1500px) {
    gap: var(--default-padding) !important;
    padding: var(--default-padding) !important;
  }

  &.p-card {
    display: flex;
    gap: calc(3 * var(--default-padding));
    border: 1px solid var(--gray-dark);
    border-radius: var(--small-border-radius);
    padding: var(--default-padding) var(--default-padding)
      var(--default-padding) calc(2 * var(--default-padding));
    box-shadow: none;
    overflow: hidden;
    word-break: break-word;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }

    &.disabled {
      opacity: 0.6;
      pointer-events: none !important;
    }

    .template-description {
      padding: 0;

      p {
        margin: 0;
      }
    }

    .p-card-body {
      flex: 1 1 65%;
      display: flex;
      flex-direction: column;
      padding: 0;
      .p-card-title {
        margin-bottom: 0;
        font-size: var(--medium-font-size);
        line-height: var(--medium-line-height);
        color: var(--black-main);
      }
    }

    .p-card-header {
      flex: 0 0 30%;
      display: flex;
      max-width: 180px;
      min-width: 180px;
      height: 254px;
      overflow: hidden;
      justify-content: center;

      .image-card-thumbnail {
        width: auto;
        height: 100%;
      }
    }

    .p-card-footer {
      padding-top: var(--medium-padding);
      height: 100%;
    }

    .p-card-content {
      padding: var(--xsmall-padding) 0 0;
      font-size: var(--medium-font-size);
      line-height: var(--medium-line-height);
      color: var(--black-main);
    }
  }
`;

const StyledFlexContainer = styled(FlexContainer)`
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  gap: var(--small-padding);
`;

const StyledAppButton = styled(AppButton)`
  &.p-button {
    flex: 1 1 30%;
    padding: 0 var(--small-padding);
    height: var(--small-button-height) !important;
    max-width: max-content;
    border-radius: var(--small-border-radius);

    .p-button-icon {
      font-size: var(--small-font-size);
    }

    .p-button-label {
      word-break: keep-all;
    }
  }
`;

const StyledPlayButton = styled(AppButton)`
  &.p-button.p-button-xs {
    height: var(--small-button-height) !important;
    width: var(--small-button-height) !important;
    padding: 0 0 0 3px;
  }
`;

const StyledDropdown = styled(Dropdown)`
  &.p-dropdown {
    flex: 1 0 50%;
    min-height: auto;

    .p-dropdown-label {
      height: var(--small-button-height);
      min-height: auto;
    }
  }
`;

export type DocumentCardProps = {
  template: Course;
  additionalClass?: string;
  isDisabled?: boolean;
  isSystem?: boolean;
  withActions?: boolean;
  isLearnerView?: boolean;
};

export const DocumentCard: React.FC<DocumentCardProps> = ({
  template,
  additionalClass,
  isDisabled,
  isSystem,
  withActions,
  isLearnerView,
  ...rest
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const currentUser = useAppSelector(selectCurrentUser);

  const [selectedLanguage, setSelectedLanguage] = useState<LanguagesEnum>(
    currentUser?.language &&
      isUserLanguageAvailableinMaterial(
        template.courseLanguages,
        currentUser?.language as LanguagesEnum,
      )
      ? (currentUser?.language as LanguagesEnum)
      : LanguagesEnum.EN,
  );

  const navigateToCourseAttempt = async (e: Event) => {
    e.stopPropagation();

    try {
      const lastAttempt =
        await client.learnerCourses.getLearnerCourseLastAttempt(template.id);

      const attemptId = lastAttempt?.id;
      navigate(
        RedirectPaths[RedirectPathsEnum.DOCUMENT_VIEW](template.id, attemptId),
        { state: { from: location.pathname } },
      );
    } catch (e: any) {
      if (e?.statusCode === 404) {
        createLearnerCourseNewAttempt();
      } else {
        handleAxiosError(e as AxiosError, toast);
      }
    }
  };

  const createLearnerCourseNewAttempt = async () => {
    try {
      const newAttempt =
        await client.learnerCourses.createLearnerCourseNewAttempt(template.id);
      navigate(
        RedirectPaths[RedirectPathsEnum.DOCUMENT_VIEW](
          template.id,
          newAttempt.id,
        ),
        { state: { from: location.pathname } },
      );
    } catch (e: any) {
      handleAxiosError(e as AxiosError, toast);
    }
  };

  const downloadHandler = async (
    templateId: string,
    selectedLanguage: LanguagesEnum,
  ) => {
    try {
      const courseFile = await client.files.getCourseFile(
        templateId,
        selectedLanguage,
      );

      if (isLearnerView) {
        await client.learnerCourses.createLearnerCourseNewAttempt(templateId);
      }

      if (courseFile.file) {
        downloadFileByUrl(courseFile.file);
        toast?.success(t('toast.success'), t('material.download.success'));

        if (currentUser?.account?.freeTrialEndsAt) {
          hubspotTrackMaterialDownloaded(template);
        }
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const className = classNames(
    {
      disabled: isDisabled,
    },
    additionalClass,
  );

  const header = (
    <img
      className="image-card-thumbnail flex-1"
      src={template.courseThumbnail?.signedUrl || PagePlaceholderImage}
      alt={template.name}
    />
  );

  const footer = (
    <FlexContainer direction="column" justify="space-between" height="100%">
      {(template.type === MaterialType.CUSTOM_MATERIAL && !isLearnerView) ||
      template.type === MaterialType.RESOURCE ? (
        <StyledFlexContainer>
          <StyledDropdown
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.value)}
            options={template.courseLanguages.map((x) => ({
              label: languageDisplayLabel[x],
              value: x,
            }))}
            placeholder={t('materials.selectLanguage')}
          />
          <StyledAppButton
            icon="pi pi-download"
            size="sm"
            label={t('generic.download')}
            severity={'info'}
            isDisabled={!selectedLanguage}
            onClick={() =>
              downloadHandler(template.id, selectedLanguage ?? LanguagesEnum.EN)
            }
          />
        </StyledFlexContainer>
      ) : null}

      {template.type === MaterialType.CUSTOM_MATERIAL && isLearnerView && (
        <StyledPlayButton
          icon="pi pi-caret-right"
          size="xs"
          severity="plain"
          className="align-self-end mt-auto"
          isDisabled={!selectedLanguage}
          onClick={(e: any) => navigateToCourseAttempt(e)}
        />
      )}
    </FlexContainer>
  );

  const items = [
    {
      label: isSystem ? t('generic.edit') : t('generic.view.details'),
      icon: isSystem ? 'pi pi-pencil' : 'pi pi-bars',
      command: () => {
        navigate(
          RedirectPaths[
            template.type === MaterialType.CUSTOM_MATERIAL
              ? RedirectPathsEnum.CUSTOM_MATERIALS_EDIT
              : RedirectPathsEnum.RESOURCES_EDIT
          ](template.id),
        );
      },
    },
  ];

  const title = (
    <FlexContainer
      justify={withActions ? 'space-between' : 'flex-start'}
      align="baseline"
      width="100%"
    >
      {(isLearnerView
        ? getTranslationByLanguage(
            template?.courseTranslations,
            currentUser?.language
              ? (currentUser?.language as LanguagesEnum)
              : LanguagesEnum.EN,
          )?.name
        : template?.name) ?? ''}
      {withActions && (
        <AppDropdownButton
          menuId={`card-menu-${template.id}`}
          items={items}
          type="text"
          severity="secondary"
          icon="pi pi-ellipsis-h"
          size="sm"
        />
      )}
    </FlexContainer>
  );
  return (
    <StyledImageCard
      id={template.id}
      title={title}
      className={className}
      header={header}
      footer={footer}
      {...rest}
    >
      <div
        className="template-description ql-editor"
        dangerouslySetInnerHTML={{
          __html:
            (isLearnerView
              ? getTranslationByLanguage(
                  template?.courseTranslations,
                  currentUser?.language
                    ? (currentUser?.language as LanguagesEnum)
                    : LanguagesEnum.EN,
                )?.description
              : template?.description) ?? '',
        }}
      />
    </StyledImageCard>
  );
};
