import { UserMeta } from '@/client/users';
import { LoadingStateType } from '@/common/constants';
import { FormikInput } from '@/components/form';
import { AppButton } from '@/ui/buttons';
import { FormContainer } from '@/ui/styled-ui';
import { Field, Form, Formik, FormikValues } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { updateUserPropertiesSchema } from './validations';

type UserPropertiesFormProps = {
  initialValues: UserMeta;
  onSubmit: (data: FormikValues) => void;
  state?: LoadingStateType;
  isDisabled?: boolean;
};

export const UserPropertiesForm: React.FC<UserPropertiesFormProps> = ({
  initialValues,
  onSubmit,
  state,
  isDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={updateUserPropertiesSchema(t)}
      onSubmit={onSubmit}
    >
      {({ values, errors }) => (
        <FormContainer>
          <Form className="w-full">
            <div className="field w-full">
              <Field
                id="companyName"
                name="companyName"
                label={t('generic.companyName')}
                className="w-full"
                value={values.companyName}
                component={FormikInput}
                disabled={isDisabled}
              />
            </div>
            <div className="field w-full">
              <Field
                id="department"
                name="department"
                label={t('generic.department')}
                className="w-full"
                value={values.department}
                component={FormikInput}
                disabled={isDisabled}
              />
            </div>
            <div className="field w-full">
              <Field
                id="manager"
                name="manager"
                label={t('generic.manager')}
                className="w-full"
                value={values.manager}
                component={FormikInput}
                disabled={isDisabled}
              />
            </div>
            <div className="field w-full">
              <Field
                id="managerEmail"
                name="managerEmail"
                label={t('generic.managerEmail')}
                className="w-full"
                value={values.managerEmail}
                component={FormikInput}
                disabled={isDisabled}
              />
            </div>
            <div className="field w-full">
              <Field
                id="country"
                name="country"
                label={t('generic.country')}
                className="w-full"
                value={values.country}
                component={FormikInput}
                disabled={isDisabled}
              />
            </div>
            <div className="field w-full">
              <Field
                id="jobTitle"
                name="jobTitle"
                label={t('generic.jobTitle')}
                className="w-full"
                value={values.jobTitle}
                component={FormikInput}
                disabled={isDisabled}
              />
            </div>
            <div className="field w-full">
              <Field
                id="mobilePhone"
                name="mobilePhone"
                label={t('generic.mobilePhone')}
                className="w-full"
                value={values.mobilePhone}
                component={FormikInput}
                disabled={isDisabled}
              />
            </div>
            <div className="field w-full">
              <Field
                id="officeLocation"
                name="officeLocation"
                label={t('generic.officeLocation')}
                className="w-full"
                value={values.officeLocation}
                component={FormikInput}
                disabled={isDisabled}
              />
            </div>

            <AppButton
              isSubmit
              severity="secondary"
              label={t('button.save')}
              className="w-4 mt-4 mb-5"
              state={state}
              isDisabled={!!Object.keys(errors).length || isDisabled}
            />
          </Form>
        </FormContainer>
      )}
    </Formik>
  );
};
