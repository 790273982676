import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export const AppHelmet = () => {
  const currentAccount = useAppSelector(selectCurrentAccount);

  return (
    <Helmet>
      <link rel="icon" href={currentAccount?.platformSettings?.favIcon} />
      <link
        rel="apple-touch-icon"
        href={currentAccount?.platformSettings?.favIcon}
      />
      <meta
        name="description"
        content={currentAccount?.platformSettings?.name}
      />
      <title>{currentAccount?.platformSettings?.name}</title>
    </Helmet>
  );
};
