import { processListingParams } from '@/api/helpers';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { MessageResponseModel } from '@/client/models';
import i18n from '@/i18n';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import {
  AttemptsReport,
  CourseCompletionReportsType,
  ExportUsersReportRequest,
  ListUsersAwarenessReportRequest,
  ListUsersAwarenessReportResponse,
  ListUsersSummaryReportRequest,
  ListUsersSummaryReportResponse,
  OverviewReportsType,
  userAwarenessReportsType,
} from './types';

export const reportsService = (client: AxiosInstance) => {
  const ACCOUNTS_BASE_URL = '/accounts';
  const COURSES_BASE_URL = '/courses';
  const REPORTS_BASE_URL = 'reports';

  const getCourseCompletionReports = async (
    accountId?: string,
  ): Promise<CourseCompletionReportsType> => {
    try {
      const result = await client.get<
        CourseCompletionReportsType,
        AxiosResponse<CourseCompletionReportsType>
      >(
        `${ACCOUNTS_BASE_URL}/${accountId}/${REPORTS_BASE_URL}/course-completion`,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getUserAwarenessReports = async (
    accountId?: string,
  ): Promise<userAwarenessReportsType> => {
    try {
      const result = await client.get<
        userAwarenessReportsType,
        AxiosResponse<userAwarenessReportsType>
      >(
        `${ACCOUNTS_BASE_URL}/${accountId}/${REPORTS_BASE_URL}/user-awareness`,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getOverviewReports = async (
    accountId?: string,
  ): Promise<OverviewReportsType> => {
    try {
      const result = await client.get<
        OverviewReportsType,
        AxiosResponse<OverviewReportsType>
      >(`${ACCOUNTS_BASE_URL}/${accountId}/${REPORTS_BASE_URL}/overview`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getUsersSummaryReport = async (
    accountId: string,
    params: ListUsersSummaryReportRequest,
  ): Promise<ListUsersSummaryReportResponse> => {
    try {
      const result = await client.get<
        ListUsersSummaryReportResponse,
        AxiosResponse<ListUsersSummaryReportResponse>
      >(`${ACCOUNTS_BASE_URL}/${accountId}/${REPORTS_BASE_URL}/users-summary`, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getUsersAwarenessReport = async (
    accountId: string,
    params: ListUsersAwarenessReportRequest,
  ): Promise<ListUsersAwarenessReportResponse> => {
    try {
      const result = await client.get<
        ListUsersAwarenessReportResponse,
        AxiosResponse<ListUsersAwarenessReportResponse>
      >(
        `${ACCOUNTS_BASE_URL}/${accountId}/${REPORTS_BASE_URL}/users-awareness`,
        {
          withCredentials: true,
          params: processListingParams(params),
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const exportUsersSummaryReport = async (
    accountId: string,
    request: ExportUsersReportRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${ACCOUNTS_BASE_URL}/${accountId}/reports/users-summary/export`,
        { ...request },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const exportUsersAwarenessReport = async (
    accountId: string,
    request: ExportUsersReportRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${ACCOUNTS_BASE_URL}/${accountId}/reports/users-awareness/export`,
        { ...request },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const rebuildSummaryReport = async (
    accountId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${ACCOUNTS_BASE_URL}/${accountId}/reports/users-summary/rebuild`,
        {},
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getCourseAttemptsReport = async (
    courseId: string,
  ): Promise<AttemptsReport[]> => {
    try {
      const result = await client.get<
        AttemptsReport[],
        AxiosResponse<AttemptsReport[]>
      >(`${COURSES_BASE_URL}/${courseId}/${REPORTS_BASE_URL}/attempt-stats`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const reportsNotifyUrl = (accountId: string) =>
    `${client.defaults.baseURL}accounts/${accountId}/reports/notify`;

  return {
    getCourseCompletionReports,
    getUserAwarenessReports,
    getOverviewReports,
    getUsersSummaryReport,
    getUsersAwarenessReport,
    exportUsersSummaryReport,
    exportUsersAwarenessReport,
    rebuildSummaryReport,
    getCourseAttemptsReport,
    reportsNotifyUrl,
  };
};
