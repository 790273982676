import { GroupCondition } from '@/api/enums';
import { equal, group, handleAxiosError, like, nested } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { Campaign } from '@/client/campaigns';
import { FilterNamesEnum } from '@/client/helpers';
import { Actions, Subjects } from '@/client/users';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { availableLanguagesDropdown } from '@/common/constants/languages';
import { TranslationFunctionType } from '@/common/types';
import {
  CampaignTemplatesModal,
  CatalogueCampaign,
} from '@/components/campaigns';
import {
  DataTableFilters,
  FilterType,
  FilterTypeEnum,
} from '@/components/tables/crud';
import { useCampaigns, useCloneCampaign } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { AppChip } from '@/ui/chip';
import { Slider } from '@/ui/slider';
import { FlexContainer } from '@/ui/styled-ui';
import { BackButton } from '@/ui/styled-ui/BackButton';
import {
  getPhishingSignOptions,
  getPhishingTypeOptions,
} from '@/utils/helpers';
import {
  difficultyFilterOptions,
  senderOptions,
} from '@/utils/helpers/ui.helper';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { SyntheticEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledAppChip = styled(AppChip)`
  cursor: pointer;
  border-bottom: 1px solid transparent;

  &:hover {
    border-color: var(--red-dark);
  }
`;

const StyledStickyContent = styled(FlexContainer)`
  min-height: max-content;
  position: sticky;
  top: 0;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--white-main);
  z-index: 2;
  padding: var(--default-padding);
`;

const StyledContainer = styled(FlexContainer)`
  flex-direction: column;
  background-color: var(--beige-main);
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--default-padding) var(--big-padding);
  flex: 1 1 0;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    background-color: var(--beige-main);
  }
  &::-webkit-scrollbar-thumb {
    border: 4px solid var(--beige-main);
  }
  &::-webkit-scrollbar-corner {
    background-color: var(--beige-main);
  }
`;

const getBreadcrumbs = (
  account: Account,
  t: TranslationFunctionType,
): MenuItem[] => {
  return [
    {
      label: account?.name,
      url: !account?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('campaigns'),
      url: RedirectPaths[RedirectPathsEnum.CAMPAIGNS](),
      template: AppBreadCrumbTemplate,
    },
  ];
};

const getFilters = (t: TranslationFunctionType): FilterType[] => [
  {
    label: t('generic.lang'),
    field: FilterNamesEnum.EMAIL_TEMPLATE_BY_LANGUAGE,
    placeholder: t('generic.lang.search'),
    type: FilterTypeEnum.MULTI_SELECT,
    options: availableLanguagesDropdown,
  },
  {
    label: t('generic.difficulty'),
    field: FilterNamesEnum.DIFFICULTY,
    placeholder: t('generic.select'),
    type: FilterTypeEnum.MULTI_SELECT,
    options: difficultyFilterOptions,
  },
  {
    label: t('campaign.template.phishingType'),
    field: FilterNamesEnum.CAMPAIGN_PHISHING_TYPE,
    placeholder: t('generic.select'),
    type: FilterTypeEnum.SELECT,
    options: getPhishingTypeOptions(t),
  },
  {
    label: t('campaign.template.phishingSign'),
    field: FilterNamesEnum.CAMPAIGN_PHISHING_SIGN,
    placeholder: t('generic.select'),
    type: FilterTypeEnum.SELECT,
    options: getPhishingSignOptions(t),
  },
  {
    label: t('generic.sender'),
    field: FilterNamesEnum.SENDER,
    type: FilterTypeEnum.SELECT,
    options: senderOptions(t),
    placeholder: t('generic.select'),
  },
];

export const CampaignsCatalogueListPage: React.FC = () => {
  const { t } = useTranslation();
  const { can } = usePermission();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const { apiFilters, onFilter } = useTable();
  const navigate = useNavigate();
  const toast = useToast();

  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>();
  const [isLoading, setIsLoading] = useState(LoadingStatuses.IDLE);
  const [search, setSearch] = useState('');
  const debouncedSearchValue = useMemo(
    () =>
      debounce((event: SyntheticEvent<HTMLInputElement>) => {
        setSearch((event.target as HTMLInputElement).value);
      }, 300),
    [],
  );

  const { isLoading: isCampaignsLoading, campaigns } = useCampaigns({
    take: 200,
    skip: 0,
    isTemplate: 1,
    withTemplates: 1,
    filters: [
      ...(apiFilters ? apiFilters : []),
      ...(search
        ? [
            group(GroupCondition.OR, [
              like('name', search),
              nested('sendingProfile', [like('name', search)]),
            ]),
          ]
        : []),
      equal('isTemplate', true),
    ],
  });

  const jumpToReleventDiv = (id: string) => {
    const releventDiv = document.getElementById(id);
    if (!releventDiv) return;
    releventDiv.scrollIntoView({ behavior: 'smooth' });
  };

  const cloneCampaign = useCloneCampaign();

  const handleCreateNewCampaignFromTemplate = async (
    selectedCampaign?: Campaign,
  ) => {
    try {
      if (!currentAccount || !selectedCampaign) return;
      setIsLoading(LoadingStatuses.LOADING);

      const response = await cloneCampaign.clone({
        campaignId: selectedCampaign?.id,
        account: currentAccount?.id,
      });

      setIsLoading(LoadingStatuses.IDLE);

      if (response && !response?.id) return;

      navigate(RedirectPaths[RedirectPathsEnum.CAMPAIGNS_EDIT](response?.id), {
        state: { isTemplate: false },
      });
      toast?.success(t('toast.success'), t('campaign.created'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
      setIsLoading(LoadingStatuses.IDLE);
    }
  };

  return (
    <>
      <StyledStickyContent className="without-padding">
        <AppBreadCrumb model={getBreadcrumbs(currentAccount as Account, t)} />
        <FlexContainer justify="flex-start" gap={12}>
          <BackButton to={RedirectPaths[RedirectPathsEnum.CAMPAIGNS]()} />
          <h1 className="m-0">{t('campaigns.catalogue.title')}</h1>
        </FlexContainer>
        <FlexContainer
          justify="space-between"
          align="flex-start"
          wrap="wrap"
          gap={8}
          className="mt-4 w-full"
        >
          <DataTableFilters
            filters={getFilters(t)}
            onFilter={onFilter}
            className="flex-initial"
            tableName={TableNamesEnum.TEMPLATES_CATALOGUE}
          />
          <div className="p-input-icon-left flex-auto min-w-300">
            <InputText
              className="w-full"
              onInput={debouncedSearchValue}
              placeholder={t('templates.searchNameOrSender')}
              autoComplete="off"
            />
            <i className="pi pi-search" />
          </div>
        </FlexContainer>
        <FlexContainer className="mt-4">
          <Slider>
            <FlexContainer gap={8} justify="flex-start" align="flex-start">
              {!!campaigns?.result.length &&
                !isCampaignsLoading &&
                campaigns.result.map((campaign: Campaign, index) => (
                  <StyledAppChip
                    key={index}
                    label={campaign.name}
                    type="error"
                    className="min-w-max"
                    onClick={() => jumpToReleventDiv(campaign.id)}
                  />
                ))}
            </FlexContainer>
          </Slider>
        </FlexContainer>
      </StyledStickyContent>
      <StyledContainer>
        {isCampaignsLoading && !campaigns && (
          <FlexContainer direction="column" className="mt-5">
            <ProgressSpinner />
            <h3>{t('campaign.template.email.loading')}</h3>
          </FlexContainer>
        )}
        {!isCampaignsLoading && campaigns?.result.length === 0 && (
          <FlexContainer direction="column" className="mt-5">
            <h3>{t('campaign.template.email.empty')}</h3>
          </FlexContainer>
        )}
        {campaigns?.result &&
          campaigns?.result.map((campaign: Campaign) => (
            <CatalogueCampaign
              key={campaign.id}
              campaign={campaign}
              actions={
                <FlexContainer className="buttons-container">
                  <AppButton
                    label={t('generic.preview')}
                    severity="secondary"
                    className="flex-1"
                    onClick={() => setSelectedCampaign(campaign)}
                    isDisabled={!isLoading}
                  />
                  {can(Actions.CREATE, Subjects.CAMPAIGNS) && (
                    <AppButton
                      label={t('campaign.create')}
                      className="flex-1"
                      onClick={() =>
                        handleCreateNewCampaignFromTemplate(campaign)
                      }
                      state={
                        isCampaignsLoading
                          ? LoadingStatuses.LOADING
                          : LoadingStatuses.IDLE
                      }
                      isDisabled={!isLoading}
                    />
                  )}
                </FlexContainer>
              }
            />
          ))}
      </StyledContainer>
      <CampaignTemplatesModal
        visible={!!selectedCampaign}
        onHide={() => setSelectedCampaign(undefined)}
        preselectedCampaign={selectedCampaign}
        isCatalogue={true}
      />
    </>
  );
};
