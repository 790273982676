import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TOKEN_EXPIRES_KEY } from '@/common/constants/user';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentUserState } from '@/store/features/users';
import moment from 'moment/moment';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type AuthCPProtectedProps = {
  children: React.ReactNode;
};

export const AuthCPProtected: React.FC<AuthCPProtectedProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const tokenExpiresOn = sessionStorage.getItem(TOKEN_EXPIRES_KEY);

  const { loaded, user } = useAppSelector(selectCurrentUserState);

  useEffect(() => {
    if (
      !tokenExpiresOn ||
      moment(tokenExpiresOn).utc().isBefore(moment().utc()) ||
      (!user && loaded)
    ) {
      navigate(RedirectPaths[RedirectPathsEnum.UNAUTHORIZED]());
    }
  }, [tokenExpiresOn, user, loaded]);

  return <>{children}</>;
};
