import { GroupCondition } from '@/api/enums';
import { group, handleAxiosError, like } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { User } from '@/client/users';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { roleTranslations } from '@/common/constants/roles';
import { TranslationFunctionType } from '@/common/types';
import { DataTable, DataTableColumnType } from '@/components/tables/crud';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import {
  selectCurrentAccount,
  setCurrentAccount,
} from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { ITEMS_PER_REQUEST } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: max-content;
  font-size: var(--medium-font-size);
  line-height: var(--medium-line-height);
  color: var(--red-main);

  i {
    align-self: center;
  }

  &:hover {
    cursor: pointer;
    color: var(--red-dark);
  }
`;

const StyledText = styled.div`
  line-height: var(--small-line-height);
  font-size: var(--small-font-size);
  margin-block: var(--default-padding);
`;

const pathItems = (account: Account, t: TranslationFunctionType) => [
  {
    label: account?.name,
    url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('user.find'),
    url: RedirectPaths[RedirectPathsEnum.FIND_USER](),
    template: AppBreadCrumbTemplate,
  },
];

export const FindUserPage: React.FC = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const account = useAppSelector(selectCurrentAccount);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [foundUsers, setFoundUsers] = useState<User[]>();
  const [loadingState, setLoadingState] = useState<LoadingStatuses>(
    LoadingStatuses.IDLE,
  );

  const [multiSearchValue, setMultiSearchValue] = useState('');
  const [message, setMessage] = useState('');

  const handleSearch = async () => {
    if (!multiSearchValue) return;
    setLoadingState(LoadingStatuses.LOADING);

    try {
      const users = await client.users.getUsers({
        take: ITEMS_PER_REQUEST,
        skip: 0,
        filters: [
          ...(multiSearchValue.length
            ? [
                group(GroupCondition.OR, [
                  like('username', multiSearchValue),
                  like('email', multiSearchValue),
                ]),
              ]
            : []),
        ],
      });

      if (users?.count === 0 && multiSearchValue) {
        setMessage(t('user.find.noResults'));
      }

      if (users?.result.length && !message) {
        setFoundUsers(users.result);
      }

      setLoadingState(LoadingStatuses.IDLE);
    } catch (e) {
      handleAxiosError(e as AxiosError, toast);
    }
  };

  const goToUsersAccount = async (account: Account) => {
    if (!account) return;
    try {
      const response = await client.accounts.getAccount(account.id);
      dispatch(setCurrentAccount(response));
      navigate(RedirectPaths[RedirectPathsEnum.ADMIN_DASHBOARD]());
    } catch (e) {
      handleAxiosError(e as AxiosError, toast);
    }
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'name',
      header: t('generic.name'),
      sortable: false,
      filterable: false,
      render: (row: User) => <span>{row?.name}</span>,
    },
    {
      field: 'username',
      header: t('generic.username'),
      sortable: false,
      filterable: false,
      render: (row: User) => row.username,
    },
    {
      field: 'email',
      header: t('generic.email'),
      sortable: false,
      filterable: false,
      render: (row: User) => row.email,
    },
    {
      field: 'role',
      header: t('generic.role'),
      sortable: false,
      filterable: false,
      render: (row: User) => roleTranslations(row.role, t),
    },
    {
      field: 'account',
      header: t('account'),
      sortable: false,
      filterable: false,
      render: (row: User) => row.account.name,
    },
    {
      field: 'accountLink',
      header: '',
      sortable: false,
      filterable: false,
      className: 'flex justify-content-end',
      render: (row: User) => (
        <StyledLink onClick={() => goToUsersAccount(row.account)}>
          <i className="pi pi-external-link x2 ml-2" />
        </StyledLink>
      ),
    },
  ];

  return (
    <FlexContainer direction="column" align="flex-start">
      <div className="w-full">
        <AppBreadCrumb model={pathItems(account as Account, t)} />
        <h1>{t('user.find')}</h1>
        <StyledText>{t('user.find.text')}</StyledText>
        <FlexContainer>
          <div className="p-input-icon-left flex-auto min-w-300">
            <InputText
              className="w-full"
              onInput={(e) => {
                setFoundUsers(undefined);
                setMessage('');
                setMultiSearchValue((e.target as HTMLInputElement).value);
              }}
              placeholder={t('user.search.username.email')}
              autoComplete="off"
            />
            <i className="pi pi-search" />
          </div>
          <AppButton
            state={loadingState}
            label={t('generic.search')}
            className="mt-1 ml-2"
            onClick={handleSearch}
          />
        </FlexContainer>
        {!!foundUsers && !!multiSearchValue && (
          <DataTable
            classNames="simple-datatable find-user mt-4"
            data={foundUsers}
            count={foundUsers.length}
            isLoading={loadingState === LoadingStatuses.LOADING}
            columns={columns}
          />
        )}
        {!!message && <StyledText>{message}</StyledText>}
      </div>
    </FlexContainer>
  );
};
