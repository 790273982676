import { ListingRequest, ListingResponse } from '@/api/types';
import { Branch } from '@/client/branches';
import { File } from '@/client/files';
import { Group } from '@/client/groups';
import { MessageResponseModel } from '@/client/models';
import { User } from '@/client/users';
import {
  TargetEntities,
  TargetEntitiesRequest,
  TargetEntity,
} from '@/common/types/targets.type';
import { GenericForbiddenApiError } from '@/hooks/query';
import { UseMutationResult } from 'react-query';

// Send Survey Types
export type SecurityCultureSendSurveyFormValues = {
  url: string;
  targets?: TargetEntity[];
  branches?: Branch[];
  groups?: Group[];
  users?: User[];
  test?: SecurityCultureTestEmail;
};

export type SecurityCultureSendSurvey = {
  branches?: string[];
  groups?: string[];
  users?: string[];
  url: string;
  test?: SecurityCultureTestEmail;
  emailId: string;
};

export type SecurityCultureSendSurveyRequest = {
  accountId: string;
} & SecurityCultureSendSurvey;

export type SecurityCultureEmailMutation = UseMutationResult<
  MessageResponseModel,
  GenericForbiddenApiError,
  SecurityCultureSendSurveyRequest
>;

export type SecurityCultureTestEmail = {
  email?: string;
  firstName?: string;
  lastName?: string;
};

// Upload Survey Types
export type SecurityCultureUploadSurveyFormValues = {
  id?: string;
  file?: File;
  date?: Date;
  targets?: TargetEntity[];
  published?: boolean;
  isDemo?: boolean;
};

export type SecurityCultureUploadSurveyRequest = {
  id?: string;
  accountId: string;
  file?: string;
  date?: Date;
  targets?: TargetEntitiesRequest;
  status?: SurveyStatus;
  isDemo?: boolean;
};

export type SecurityCultureUploadSurveyMutation = UseMutationResult<
  SecurityCultureSurvey,
  GenericForbiddenApiError,
  SecurityCultureUploadSurveyRequest
>;

// Survey Types
export type SecurityCultureSurvey = {
  id?: string;
  file?: File;
  date?: Date;
  targets?: TargetEntities;
  status?: SurveyStatus;
  previousScore?: number;
  score?: number;
  benchmarkScore?: number;
  participated?: number;
  totalParticipants?: number;
  relatedSurveys: SecurityCultureSurvey[];
  isDemo?: boolean;
};

export type SecurityCultureSurveyTarget = {
  surveys: string[];
  targets: TargetEntities;
};

export type ListSurveysRequest = ListingRequest;
export type ListSurveysResponse = ListingResponse<SecurityCultureSurvey>;

// Survey Category Types
export type SurveyCategory = {
  id: string;
  name: SurveyCategoryEnum;
  result: number;
  previousResult: number;
  benchmarkResult: number;
  isLow: boolean;
};

export type ListSurveyCategoriesRequest = ListingRequest & {
  accountId: string;
  surveyId: string;
};
export type ListSurveyCategoriesResponse = ListingResponse<SurveyCategory>;

// Survey Category Question Types
export type SurveyQuestion = {
  id: string;
  surveyCategory?: SurveyCategoryEnum;
  language?: string;
  description?: string;
  result?: number;
  previousResult?: number;
  benchmarkResult?: number;
  priority?: number;
};

export type ListSurveyCategoryQuestionsRequest = ListingRequest & {
  accountId: string;
  surveyId: string;
  categoryName: SurveyCategoryEnum;
};
export type ListSurveyCategoryQuestionsResponse =
  ListingResponse<SurveyQuestion>;

// Survey Category Recommendation Types
export type SurveyRecommendation = {
  id: string;
  surveyCategory?: SurveyCategoryEnum;
  language?: string;
  title?: string;
  description?: string;
};

export type ListSurveyCategoryRecommendationsRequest = ListingRequest & {
  accountId: string;
  surveyId: string;
  categoryName: SurveyCategoryEnum;
};
export type ListSurveyCategoryRecommendationsResponse =
  ListingResponse<SurveyRecommendation>;

// Survey Feedback Types
export type SurveyFeedback = {
  id: string;
  survey?: string;
  description?: string;
  priority?: number;
};

export type ListSurveyFeedbackRequest = ListingRequest & {
  accountId: string;
  surveyId: string;
};
export type ListSurveyFeedbackResponse = ListingResponse<SurveyFeedback>;

// Delete Survey Types
export type DeleteSurveyRequest = {
  accountId: string;
  surveyId: string;
};

export type DeleteSurveyMutation = UseMutationResult<
  boolean,
  GenericForbiddenApiError,
  string
>;

// Enums
export enum SurveyStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export enum SurveyCategoryEnum {
  MANAGEMENT = 'management',
  COLLEAGUES = 'colleagues',
  RESPONSIBILITY = 'responsibility',
  PSYCHOLOGICAL_SAFETY = 'psychologicalSafety',
  RESOURCES = 'resources',
  TIME = 'time',
}
