import React from 'react';

import { AppSpinner } from '@/ui/app-spinner';
import { AbsoluteCenter, FlexContainer } from '@/ui/styled-ui';

type LoadingPageProps = {
  message: string;
};

export const LoadingPage: React.FC<LoadingPageProps> = ({ message }) => (
  <AbsoluteCenter>
    <FlexContainer direction="column">
      <h1>{message}</h1>
      <AppSpinner width={100} height={100} strokeWidth={8} duration={1.5} />
    </FlexContainer>
  </AbsoluteCenter>
);
