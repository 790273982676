import { DataTableColumnType } from '@/components/tables/crud/types';
import { Column } from 'primereact/column';
import {
  DataTable as PrimeDataTable,
  DataTableExpandedRows,
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableRowEvent,
} from 'primereact/datatable';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DynamicColumns } from './DynamicColumns';

const StyledDataTable = styled(PrimeDataTable)<{ hasMultipleSelect?: boolean }>`
  &.p-datatable {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    border: 1px solid var(--gray-dark);
    border-radius: var(--medium-border-radius);

    tr td[role='cell'] div {
      align-items: flex-start;
    }

    th[role='columnheader'] {
      background-color: var(--beige-main) !important;
      border: none !important;
      padding: var(--medium-padding) !important;
      font-size: var(--xsmall-font-size);
      line-height: var(--xsmall-line-height);

      &:last-child {
        width: 80px !important;
      }

      &:only-child {
        text-align: left !important;
        .p-column-header-content {
          justify-content: flex-start !important;
        }
      }

      &:not(.p-highlight) {
        span[data-pc-section='sort'] {
          display: none;
        }
      }
    }

    td[role='cell'] {
      border: none !important;
      padding: var(--default-padding) var(--medium-padding) !important;
      font-size: var(--small-font-size);
      line-height: var(--small-line-height);

      &:last-child {
        height: 82px !important;
        padding: var(--medium-padding) !important;
      }

      &:only-child {
        text-align: left !important;
      }
    }

    tr {
      font-weight: 500 !important;
      color: var(--black-main) !important;

      &:nth-child(even) {
        border: none !important;
        background-color: var(--beige-main) !important;
      }

      &:not(.p-datatable-row-expansion):hover {
        background-color: var(--orange-pale) !important;
        color: var(--black-main) !important;
      }
    }

    &.rowspan-datatable {
      tr {
        background-color: transparent !important;

        td[role='cell'] {
          border-bottom: 1px solid var(--gray-opacity) !important;
          padding: var(--medium-padding) !important;
        }

        td[rowspan] {
          vertical-align: top;
        }

        &:not(.p-datatable-row-expansion):hover {
          background-color: transparent !important;
        }
      }
    }

    &.p-datatable-gridlines .p-datatable-thead > tr > th,
    &.p-datatable-gridlines .p-datatable-tbody > tr > td {
      font-size: var(--small-font-size);
      line-height: var(--small-line-height);
      padding: 10px;
    }

    .p-datatable-wrapper {
      border: none !important;
      border-radius: 0 0 var(--medium-border-radius) var(--medium-border-radius);

      ::-webkit-scrollbar-track {
        margin: 12px;
      }

      .p-datatable-emptymessage td[role='cell']:last-child {
        text-align: center;
        font-size: var(--medium-font-size);
        line-height: var(--medium-line-height);
      }
    }

    &:has(.p-datatable-footer),
    &:has(.p-paginator) {
      .p-datatable-wrapper {
        border-radius: 0 !important;
      }
    }

    .p-sortable-column:focus {
      box-shadow: var(--box-shadow-red-inset);
    }

    .p-sortable-column.p-highlight,
    .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
      color: var(--red-main);
      .p-sortable-column-icon {
        color: var(--red-main);
      }
    }

    .p-datatable-header {
      display: flex;
      padding: var(--medium-padding);
      background-color: var(--white-main);
      border: none !important;
      border-radius: var(--medium-border-radius) var(--medium-border-radius) 0 0;
    }

    .p-datatable-footer {
      border: none !important;
      border-radius: 0 0 var(--medium-border-radius) var(--medium-border-radius);
      display: flex;
      padding: var(--xsmall-padding) var(--medium-padding);
      background-color: var(--white-main);
      line-height: var(--xsmall-line-height);
      font-size: var(--xsmall-font-size);
      font-weight: 400;
      text-align: start;
    }

    .p-paginator {
      padding: var(--medium-padding);
      border: none !important;
      border-radius: 0 0 var(--medium-border-radius) var(--medium-border-radius);
    }

    &:hover {
      cursor: ${(props) => (props.onRowClick ? 'pointer' : 'default')};
    }

    .p-datatable-tbody .p-row-toggler.p-link {
      color: var(--gray-darker);

      &:enabled:hover {
        background: var(--beige-main);
      }
      &:focus {
        box-shadow: var(--box-shadow-red);
      }
    }

    .p-paginator-page {
      font-size: var(--medium-font-size);
      margin: 4px;
    }

    .p-paginator .p-dropdown .p-dropdown-label {
      padding-right: 0;
      line-height: var(--body-font-size);
      height: auto;
    }
  }
`;

type CrudTableProps = {
  data?: Array<any>;
  isLoading: boolean;
  selectedItems?: any;
  setSelectedItems?: any;
  toolbar?: ReactNode;
  filters?: any;
  columns: DataTableColumnType[];
  visibleColumns?: string[];
  isSelectMultiple?: boolean;
  rows?: number;
  skip?: number;
  onSort?: (event: DataTablePageEvent) => void;
  onPage?: (event: DataTablePageEvent) => void;
  onRowClick?: (event: DataTableRowClickEvent) => void;
  sort?: [string, 'asc' | 'desc'] | null;
  count?: number;
  onRowToggle?: (event: DataTableRowEvent) => void;
  rowExpansionTemplate?: (data: any) => any;
  allowExpansion?: boolean;
  expandedRows?: DataTableExpandedRows[];
  classNames?: string;
  dataKey?: string;
  footer?: ReactNode;
  rowGroupMode?: 'rowspan';
  groupRowsBy?: string;
};

export const DataTable: React.FC<CrudTableProps> = ({
  data,
  isLoading,
  selectedItems,
  setSelectedItems,
  toolbar,
  filters,
  rows = 10,
  skip = 10,
  columns,
  visibleColumns,
  onSort,
  sort,
  count,
  onPage,
  onRowClick,
  onRowToggle,
  allowExpansion,
  expandedRows,
  rowExpansionTemplate,
  classNames,
  dataKey,
  footer,
  rowGroupMode,
  groupRowsBy,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <StyledDataTable
      lazy
      dataKey={dataKey ?? 'id'}
      rowGroupMode={rowGroupMode}
      groupRowsBy={groupRowsBy}
      value={
        isLoading
          ? Array(9)
              .fill('')
              .map((_, index) => ({
                id: index,
              }))
          : data
      }
      header={toolbar}
      selection={selectedItems}
      onSelectionChange={(e) => setSelectedItems(e.value)}
      filters={filters}
      onSort={onSort}
      rowHover
      emptyMessage={t('filter.noItems')}
      paginator={data && Number(count) > 4 && !!onPage}
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate={`{first} ${t('filter.to')} {last} ${t(
        'filter.of',
      )} {totalRecords}`}
      paginatorClassName="justify-content-end"
      totalRecords={count}
      rows={rows}
      first={skip}
      pageLinkSize={3}
      onPage={onPage}
      onRowClick={onRowClick}
      rowsPerPageOptions={[5, 10, 20, 50, 100]}
      sortField={sort ? sort[0] : undefined}
      sortOrder={sort ? (sort[1] === 'asc' ? 1 : -1) : undefined}
      onRowToggle={onRowToggle}
      expandedRows={expandedRows}
      rowExpansionTemplate={rowExpansionTemplate}
      className={classNames}
      footer={footer}
      {...rest}
    >
      {allowExpansion && <Column expander style={{ width: '5rem' }} />}
      {DynamicColumns({
        columns,
        visibleColumns,
        isLoading,
      })}
    </StyledDataTable>
  );
};
