import { Account } from '@/client/accounts';
import { Branch } from '@/client/branches';
import {
  CourseEnrollmentTypeEnum,
  CourseEntityScheduleEnum,
} from '@/client/courses';
import { Group } from '@/client/groups';
import { User } from '@/client/users';
import {
  accountsEnrollSchema,
  branchesEnrollSchema,
  branchesUnenrollSchema,
  groupsEnrollSchema,
  groupsUnenrollSchema,
  usersEnrollSchema,
  usersUnenrollSchema,
} from '@/components/courses/validations/enroll';
import { TFunction } from 'i18next';

type CourseScheduleFactory = (
  t: TFunction,
  type: CourseEnrollmentTypeEnum,
  account: Account,
  branch?: Branch,
  group?: Group,
  user?: User,
) => Record<
  CourseEntityScheduleEnum,
  (
    setWarningMessage: (translation: string) => void,
    setModalHeading: (translation: string) => void,
    setValidationSchema: (validationSchema: any) => void,
  ) => void
>;

export const courseScheduleFactory: CourseScheduleFactory = (
  t,
  type,
  account,
  branch,
  group,
  user,
) => {
  const isEnroll = type === CourseEnrollmentTypeEnum.ENROLL;
  return {
    [CourseEntityScheduleEnum.ACCOUNT]: (
      setWarningMessage,
      setModalHeading,
      setValidationSchema,
    ) => {
      isEnroll && setWarningMessage(t('courses.enroll.branchesUsers.warning'));
      setModalHeading(
        t(`courses.${type}.accountName`, { accountName: account?.name }),
      );
      setValidationSchema(isEnroll ? accountsEnrollSchema(t) : undefined);
    },
    [CourseEntityScheduleEnum.BRANCH]: (
      setWarningMessage,
      setModalHeading,
      setValidationSchema,
    ) => {
      isEnroll && setWarningMessage(t('courses.enroll.users.warning'));
      setModalHeading(
        branch ? t(`courses.${type}.branch`) : t(`courses.${type}.branches`),
      );
      setValidationSchema(
        isEnroll ? branchesEnrollSchema(t) : branchesUnenrollSchema(t),
      );
    },
    [CourseEntityScheduleEnum.GROUP]: (
      setWarningMessage,
      setModalHeading,
      setValidationSchema,
    ) => {
      isEnroll && setWarningMessage(t('courses.enroll.users.warning'));
      setModalHeading(
        group ? t(`courses.${type}.group`) : t(`courses.${type}.groups`),
      );
      setValidationSchema(
        isEnroll ? groupsEnrollSchema(t) : groupsUnenrollSchema(t),
      );
    },
    [CourseEntityScheduleEnum.USER]: (
      setWarningMessage,
      setModalHeading,
      setValidationSchema,
    ) => {
      isEnroll && setWarningMessage(t('courses.enroll.branches.warning'));
      setModalHeading(
        user ? t(`courses.${type}.user`) : t(`courses.${type}.users`),
      );
      setValidationSchema(
        isEnroll ? usersEnrollSchema(t) : usersUnenrollSchema(t),
      );
    },
  };
};
