import { client } from '@/client';
import { UpdateMe } from '@/client/profile-settings';
import { User } from '@/client/users';
import { GenericForbiddenApiError, UpdateMeMutation } from '@/hooks/query';
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

export const useMe = (
  params: UseQueryOptions,
): UseQueryResult<User> & {
  user: User | undefined;
} => {
  const {
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    ['me'],
    () => client.profileSettingsService.getMe(),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    user: data,
    ...(rest as UseQueryResult<User>),
  };
};

export const useUpdateMe = (): {
  update: (payload: UpdateMe) => Promise<User>;
} & UpdateMeMutation => {
  const { mutateAsync, ...rest } = useMutation<
    User,
    GenericForbiddenApiError,
    UpdateMe
  >('updateMe', (payload: UpdateMe) =>
    client.profileSettingsService.updateMe(payload),
  );

  return { update: mutateAsync, ...(rest as any) };
};
