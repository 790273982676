import { TranslationFunctionType } from '@/common/types';
import { useOverviewReports } from '@/hooks/query';
import { FormatDate } from '@/ui/date';
import { CardReports, FlexContainer } from '@/ui/styled-ui';
import React from 'react';
import styled from 'styled-components';

const StyledCardReportsContainer = styled(CardReports)`
  flex: 1 1 30%;
  display: flex;
  flex-direction: column;
  background-color: var(--beige-main);
  padding: var(--default-padding) var(--big-padding);
  border-radius: var(--medium-border-radius);
  margin: 0;
  overflow: hidden;
`;

type OverviewReportsPropsType = {
  accountId: string;
  t: TranslationFunctionType;
};

export const OverviewReports = ({ accountId, t }: OverviewReportsPropsType) => {
  const { overviewReports, isLoading } = useOverviewReports({ accountId });
  let activePhishingDate;

  if (overviewReports) {
    activePhishingDate = overviewReports.nextPhishingCampaignDate ? (
      <>
        {t('generic.from')}{' '}
        <FormatDate date={overviewReports.nextPhishingCampaignDate} />
      </>
    ) : null;
  }

  return (
    <FlexContainer gap={24} wrap="wrap" align="stretch">
      <StyledCardReportsContainer
        isLoading={isLoading}
        title={t('reports.active.users')}
        count={overviewReports?.activeUsers}
      />
      <StyledCardReportsContainer
        isLoading={isLoading}
        title={t('reports.past.campaigns')}
        count={overviewReports?.pastPhishingCampaigns}
      />
      <StyledCardReportsContainer
        isLoading={isLoading}
        title={t('reports.active.campaigns')}
        count={overviewReports?.activePhishingCampaigns}
        description={activePhishingDate}
      />
    </FlexContainer>
  );
};
