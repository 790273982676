import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import { SendHisoricalDataFormValues } from '@/client/accounts';
import { LoadingStatuses } from '@/common/constants';
import { useNotifications } from '@/hooks/notifications.hook';
import { useToast } from '@/hooks/useToast';
import { AppButton } from '@/ui/buttons';
import { AppCalendar } from '@/ui/calendar';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { Dialog, DialogProps } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 } from 'uuid';
import { historicalDataSchema } from '../forms/validations';

const StyledDialog = styled(Dialog)`
  width: 500px;
`;
type SendHisoricalDataModalProps = {
  accountId?: string;
} & DialogProps;

export const SendHisoricalDataModal: React.FC<SendHisoricalDataModalProps> = ({
  onHide,
  visible,
  accountId,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [initialValues] = useState<SendHisoricalDataFormValues>({
    startDate: undefined,
    endDate: undefined,
  });

  const [isSendingHistoricalData, setIsSendingHistoricalData] =
    useState<boolean>(false);
  const {
    lastMessage,
    setNotificationParam: setKey,
    notificationParam: key,
    isConnected,
  } = useNotifications(client.tracking.trackingNotifyUrl);

  const handleSendHistoricalData = async (
    data: SendHisoricalDataFormValues,
  ) => {
    if (accountId) {
      const notificationKey = v4();
      setKey(notificationKey);
      setIsSendingHistoricalData(true);

      // Wait for a notify connection to establish
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (isConnected.current) {
            clearInterval(intervalId);
            resolve(true);
          }
        }, 100);
      });

      try {
        if (isConnected.current) {
          await client.tracking.sendHistoricalData({
            key: notificationKey,
            accounts: [accountId],
            startDate: data.startDate,
            endDate: data.endDate,
          });
        }
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
        setKey(undefined);
        setIsSendingHistoricalData(false);
      }
    }
  };

  useEffect(() => {
    if (!key) return;
    handleSyncNotification(lastMessage);
  }, [JSON.stringify(lastMessage)]);

  const handleSyncNotification = async (messages: { data: any } | null) => {
    if (
      !messages?.data?.event ||
      ![
        'tracking.mixpanel.send.events.finished',
        'tracking.mixpanel.send.events.warning',
        'tracking.mixpanel.send.events.failed',
      ].includes(messages?.data?.event)
    )
      return;

    if (messages.data.event === 'tracking.mixpanel.send.events.finished') {
      toast?.success(
        t('toast.success'),
        t('account.send.historicalData.finished'),
      );
    }

    if (messages.data.event === 'tracking.mixpanel.send.events.warning') {
      toast?.info(t('toast.info'), t('account.send.historicalData.warning'));
    }

    if (messages.data.event === 'tracking.mixpanel.send.events.failed') {
      toast?.error(t('toast.error'), t('account.send.historicalData.failed'));
    }

    setKey(undefined);
    setIsSendingHistoricalData(false);
    onHide();
  };

  return (
    <StyledDialog
      blockScroll
      visible={visible}
      header={<h1>{t('account.send.historicalData')}</h1>}
      onHide={onHide}
      draggable={false}
      data-testid="send-historical-data-modal"
      closable={!isSendingHistoricalData}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={historicalDataSchema(t)}
        onSubmit={handleSendHistoricalData}
      >
        {({ setFieldValue, values, touched, errors }) => (
          <Form>
            <div className="field w-full mb-4">
              <label>{t('generic.startDate')}</label>
              <AppCalendar
                id="start-date"
                name="startDate"
                className="w-full"
                placeholder={t('generic.select.startDate')}
                maxDate={values.endDate ?? new Date()}
                value={values.startDate}
                onChange={(event: any) => {
                  setFieldValue(
                    'startDate',
                    moment(event.value?.toString()).utc(true).toDate(),
                  );
                }}
                readOnlyInput
                icon="pi pi-calendar"
                disabled={isSendingHistoricalData}
              />
            </div>
            <div className="field w-full mb-4">
              <label
                className={classNames({
                  'p-error': touched.endDate && !!errors.endDate,
                })}
              >
                {t('generic.endDate')}
                <span className="red"> *</span>
              </label>
              <AppCalendar
                id="end-date"
                name="endDate"
                className="w-full"
                placeholder={t('generic.select.endDate')}
                minDate={values.startDate}
                maxDate={new Date()}
                value={values.endDate}
                onChange={(event: any) => {
                  setFieldValue(
                    'endDate',
                    moment(event.value?.toString()).utc(true).toDate(),
                  );
                }}
                readOnlyInput
                icon="pi pi-calendar"
              />
              {touched.endDate && !!errors.endDate && (
                <small className="p-error">{errors.endDate}</small>
              )}
            </div>

            <FlexContainer justify="flex-end" className="mt-5">
              <AppButton
                label={t('button.cancel')}
                severity="secondary"
                type="outlined"
                onClick={onHide}
                className="mr-3"
                data-testid="send-historical-data-cancel-form"
                isDisabled={isSendingHistoricalData}
              />
              <AppButton
                state={
                  isSendingHistoricalData
                    ? LoadingStatuses.LOADING
                    : LoadingStatuses.IDLE
                }
                label={t('button.send')}
                isSubmit
                data-testid="send-historical-data-submit-form"
                isDisabled={isSendingHistoricalData}
              />
            </FlexContainer>
          </Form>
        )}
      </Formik>
    </StyledDialog>
  );
};
