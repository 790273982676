import { SELECTED_COLUMNS_KEY } from '@/common/constants';
import { RootState } from '@/store/store';
import {
  createSelector,
  createSlice,
  Draft,
  PayloadAction,
} from '@reduxjs/toolkit';

type SelectedColumnsState = {
  current: { [k: string]: string[] } | null;
};

const initialState: SelectedColumnsState = {
  current:
    JSON.parse(localStorage.getItem(SELECTED_COLUMNS_KEY) as string) ?? null,
};

const selectedColumnsSlice = createSlice({
  name: 'selected-columns',
  initialState,
  reducers: {
    setSelectedColumns: (
      state: Draft<SelectedColumnsState>,
      action: PayloadAction<{ [k: string]: string[] }>,
    ) => {
      state.current = action.payload;

      localStorage.setItem(
        SELECTED_COLUMNS_KEY,
        JSON.stringify(action.payload),
      );
    },
  },
});

export const selectSelectedColumns = createSelector(
  (state: RootState) => state.selectedColumns,
  (SelectedColumnsState) => SelectedColumnsState.current,
);

export const { setSelectedColumns } = selectedColumnsSlice.actions;

export default selectedColumnsSlice.reducer;
