import { equal } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { UserDatatable } from '@/components/users/datatables';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { MenuItem } from 'primereact/menuitem';
import React from 'react';
import { useTranslation } from 'react-i18next';

const getBreadcrumbs = (
  account: Account,
  t: TranslationFunctionType,
): MenuItem[] => {
  return [
    {
      label: account?.name,
      url: !account?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('users'),
      url: RedirectPaths[RedirectPathsEnum.USERS](),
      template: AppBreadCrumbTemplate,
    },
  ];
};

export const UsersPage = () => {
  const { t } = useTranslation();
  const currentAccount = useAppSelector(selectCurrentAccount);

  return (
    <>
      <AppBreadCrumb model={getBreadcrumbs(currentAccount as Account, t)} />
      <h1>{t('users')}</h1>
      <UserDatatable
        withActions
        withBranches
        withGroups={1}
        withAddNew
        withToolbar
        withImport
        defaultFilters={[equal('account', currentAccount?.id as string)]}
        defaultTableFilters={{ active: 'yes' }}
      />
    </>
  );
};
