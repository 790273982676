import { AppButton } from '@/ui/buttons';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import React, { SyntheticEvent, useRef } from 'react';

type DataTableActionsProps = {
  menuItems?: MenuItem[];
  disabled?: boolean;
};

export const DataTableActions: React.FC<DataTableActionsProps> = ({
  menuItems,
  disabled,
}) => {
  const itemActionMenu = useRef<any>(null);

  const toggleItemActionMenu = (event: SyntheticEvent<HTMLButtonElement>) => {
    if (!itemActionMenu.current) {
      return;
    }

    itemActionMenu.current.toggle(event);
  };

  return (
    <>
      <Menu model={menuItems} popup ref={itemActionMenu} id="overlay_menu" />
      <AppButton
        severity="secondary"
        className="text-color"
        icon="pi pi-ellipsis-h"
        type="text"
        isDisabled={disabled}
        aria-controls="overlay_menu"
        onClick={toggleItemActionMenu}
      />
    </>
  );
};
