import { EMAIL_REGEX, PASSWORD_REGEX } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { object, string } from 'yup';

export const updateProfileSettingsSchema = (t: TranslationFunctionType) =>
  object({
    firstName: string().required(t('error.requiredField')),
    lastName: string().required(t('error.requiredField')),
    email: string()
      .required(t('error.requiredField'))
      .matches(EMAIL_REGEX, t('error.invalidEmail')),
    password: string()
      .matches(PASSWORD_REGEX, t('error.password.complexity'))
      .min(8, t('error.password.length', { length: 8 })),
    repeatPassword: string().when(['password'], ([password], schema) => {
      if (password)
        return schema
          .oneOf([password], t('profile.settings.passwords.not.match'))
          .required(t('error.requiredField'));

      return schema;
    }),
    language: string().required(t('error.requiredField')),
  });
