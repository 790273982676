import { equal } from '@/api/helpers';
import { CourseStatusAttemptEnum, MaterialType } from '@/client/courses';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { useLearnerCourses } from '@/hooks/query';
import CoursesComingSoonImage from '@/images/placeholders/courses-coming-soon.svg';
import CoursesNoActiveImage from '@/images/placeholders/courses-no-active.svg';
import CoursesNoCompleted from '@/images/placeholders/courses-no-completed.svg';
import { AppButton } from '@/ui/buttons';
import { DocumentCard } from '@/ui/document-card';
import { CardGridContainer, FlexContainer } from '@/ui/styled-ui';
import { templatesColumnsWidth } from '@/utils/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LearnerLayout } from '../LearnerLayout';
import { LearnerDashboardMaterialsTabs } from './LearnerDashboardMaterialsTabs';

const StyledCardGridContainer = styled(CardGridContainer)`
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 0;

  @media screen and (max-width: ${templatesColumnsWidth.ONE}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const StyledFlexContainer = styled(FlexContainer)`
  border: 1px solid var(--gray-dark);
  border-radius: var(--xsmall-border-radius);
  background-color: var(--beige-main);
  padding: var(--default-padding);
  overflow-x: hidden;

  h2 {
    margin-top: var(--default-padding);
    margin-bottom: 0;
  }

  p {
    margin-top: var(--small-padding);
    margin-bottom: var(--default-padding);
  }
`;

const StyledImg = styled.img`
  height: auto;
  width: 100%;
`;

export const LearnerDashboardMaterialsPage: React.FC<{
  type: MaterialType;
  status: CourseStatusAttemptEnum;
}> = ({ type, status }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLoading, learnerCourses: materials } = useLearnerCourses({
    skip: 0,
    type: status,
    filters: [equal('type', type)],
    staleTime: Infinity,
  });

  const {
    isLoading: areCustomMaterialsCompletedLoading,
    learnerCourses: customMaterialsCompleted,
  } = useLearnerCourses({
    skip: 0,
    take: 0,
    type: CourseStatusAttemptEnum.COMPLETED,
    filters: [equal('type', MaterialType.CUSTOM_MATERIAL)],
    staleTime: Infinity,
  });

  const atLeastOneCompleted = !!customMaterialsCompleted?.count;

  return (
    <LearnerLayout isLoading={isLoading || areCustomMaterialsCompletedLoading}>
      <LearnerDashboardMaterialsTabs />
      {!areCustomMaterialsCompletedLoading &&
        !isLoading &&
        !materials?.result.length &&
        !atLeastOneCompleted &&
        status === CourseStatusAttemptEnum.IN_PROGRESS &&
        type !== MaterialType.RESOURCE && (
          <StyledFlexContainer direction="column" className="mt-4" flex="1">
            <StyledImg
              style={{ maxHeight: '216px' }}
              src={CoursesComingSoonImage}
              alt={t('materials.noCourses.heading')}
            />
            <h2>{t('materials.noCourses.heading')}</h2>
            <p>{t('materials.noCourses.subheading')}</p>
          </StyledFlexContainer>
        )}
      {!areCustomMaterialsCompletedLoading &&
        !isLoading &&
        !materials?.result.length &&
        status === CourseStatusAttemptEnum.IN_PROGRESS &&
        !!atLeastOneCompleted && (
          <StyledFlexContainer direction="column" className="mt-4" flex="1">
            <StyledImg
              style={{ maxHeight: '190px' }}
              src={CoursesNoActiveImage}
              alt={t('materials.noActiveCourses.heading')}
            />
            <h2>{t('materials.noActiveCourses.heading')}</h2>
            <p>{t('materials.noActiveCourses.subheading')}</p>
            <AppButton
              label={t('materials.completed')}
              severity="danger"
              onClick={() =>
                navigate(
                  RedirectPaths[
                    RedirectPathsEnum.LEARNER_DASHBOARD_MATERIALS_COMPLETED
                  ](),
                )
              }
            />
          </StyledFlexContainer>
        )}
      {!areCustomMaterialsCompletedLoading &&
        !isLoading &&
        !materials?.result.length &&
        !atLeastOneCompleted &&
        status === CourseStatusAttemptEnum.COMPLETED && (
          <StyledFlexContainer direction="column" className="mt-4" flex="1">
            <StyledImg
              style={{ maxHeight: '316px' }}
              src={CoursesNoCompleted}
              alt={t('materials.noCompleted.heading')}
            />
            <h2>{t('materials.noCompleted.heading')}</h2>
            <p>{t('materials.noCompleted.subheading')}</p>
          </StyledFlexContainer>
        )}

      {!!materials?.result.length && (
        <StyledCardGridContainer>
          {materials?.result.map((material) => (
            <DocumentCard key={material.id} template={material} isLearnerView />
          ))}
        </StyledCardGridContainer>
      )}
    </LearnerLayout>
  );
};
