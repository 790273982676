import { equal } from '@/api/helpers';
import { client } from '@/client';

export enum KeycloakThemes {
  CYBERPILOT = 'cyberpilot',
  ITM8 = 'itm8',
}

export const keycloakThemeOptions = [
  {
    label: 'CyberPilot',
    value: KeycloakThemes.CYBERPILOT,
  },
  {
    label: 'ITM8',
    value: KeycloakThemes.ITM8,
  },
];

export const handleCheckIfSubdomainExists = async (subdomain?: string) => {
  if (!subdomain) return false;

  const resellers = await client.resellers.getResellers({
    filters: [equal('subdomain', subdomain)],
  });

  return !!resellers.count;
};
