import { useToast } from '@/hooks/useToast';
import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledButton = styled.button`
  padding: calc(var(--small-padding) / 2) var(--small-padding);
  font-size: var(--small-font-size);
  line-height: var(--xsmall-line-height);
  border: none;
  border-radius: 16px;
  outline: none;
  color: var(--red-main);
  background: var(--orange-pale);
  &:hover {
    box-shadow: 0 1px 0 0 var(--red-main);
    cursor: pointer;
  }
  &:active {
    color: var(--white-main);
    background: var(--red-main);
  }
}
`;

export type CopyToClipboardButtonProps = {
  clipboardValue: string;
  clipboardText: string;
};

export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  clipboardValue,
  clipboardText,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  return (
    <>
      <StyledButton
        className="clipboard-hint"
        onClick={async () => {
          await navigator.clipboard.writeText(clipboardValue);
          toast?.success('', t('clipboard.copied'));
        }}
      >
        {clipboardText}
      </StyledButton>
      <Tooltip
        target=".clipboard-hint"
        content={t('clipboard.click')}
        position="top"
      />
    </>
  );
};
