import { FlexContainer } from '@/ui/styled-ui';
import { InputText } from 'primereact/inputtext';
import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledDiv = styled.div`
  width: 50%;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

type CoursesSearchProps = {
  onSearch: (value: string) => void;
  children?: React.ReactNode;
};

export const CoursesFilters: React.FC<CoursesSearchProps> = ({
  onSearch,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FlexContainer wrap="wrap" justify="space-between" align="flex-end">
        <StyledDiv className="p-input-icon-left">
          <InputText
            className="w-full"
            onInput={(event: SyntheticEvent<HTMLInputElement>) => {
              const value = (event.target as HTMLInputElement).value;
              onSearch(value);
            }}
            placeholder={t('courses.search.name')}
            autoComplete="off"
          />
          <i className="pi pi-search" />
        </StyledDiv>
      </FlexContainer>
      {children}
    </>
  );
};
