import { getExceptionFromAxiosError } from '@/client/helpers';
import { User } from '@/client/users';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { User as AuthUser } from 'oidc-client-ts';

export const currentUserService = (client: AxiosInstance) => {
  const ME_BASE_URL = '/me';

  const getMe = async (): Promise<User> => {
    try {
      const result = await client.get<User, AxiosResponse<User>>(ME_BASE_URL, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateLastLoginDate = async (user: AuthUser): Promise<User> => {
    try {
      const result = await client.patch<User, AxiosResponse<User>>(
        `${ME_BASE_URL}/authenticated`,
        {},
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  return { getMe, updateLastLoginDate };
};
