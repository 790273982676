import { LanguagesEnum } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { CourseAttempt, CourseStatusAttemptEnum } from '@/client/courses';
import { LearnerCourseDispatchResponse } from '@/client/learner-course';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { AppContext } from '@/common/context/AppContext';
import { TranslationFunctionType } from '@/common/types';
import { CourseLanguageChanger } from '@/components/courses/CourseLanguageChanger';
import { CourseLanguageSelectScreen } from '@/components/courses/CourseLanguageSelectScreen';
import { useCourse, useCourseAttempt } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { ScormProvider } from '@/scorm/context/ScormContext';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { AppChip } from '@/ui/chip';
import { FlexContainer } from '@/ui/styled-ui';
import {
  getTranslatedCourseStatusAttempt,
  getTranslationByLanguage,
  isUserLanguageAvailableInCourse,
} from '@/utils/helpers';
import { isMobile } from '@/utils/helpers/ui.helper';
import { AxiosError } from 'axios';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import OrientationWarning from '../components/OrientationWarning';

const StyledFlexContainer = styled(FlexContainer)`
  word-break: break-word;

  &.stretch {
    height: 100%;
  }

  @media screen and (max-width: 424px) {
    &.stretch {
      height: auto;
    }
  }
`;

const StyledButton = styled(AppButton)`
  width: 50px;
  height: 50px;
  padding: 0 !important;

  &.minimize-button,
  &.exit-button {
    position: absolute;
    right: var(--default-padding);
    top: var(--medium-padding);
    z-index: 999;
  }

  &.minimize-button {
    background: var(--purple-middle);
    border-color: var(--purple-middle);
  }
`;

const StyledAppChip = styled(AppChip)`
  margin-left: var(--small-padding);

  &.mobile .p-chip-text {
    margin: var(--xxsmall-padding);
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--small-padding);

  h1 {
    margin-bottom: 0;

    @media screen and (max-width: 767px) {
      font-size: var(--small-font-size);
      line-height: var(--small-line-height);
    }
  }
`;

const StyledSpinnerDiv = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--white-main);
  padding: var(--default-padding);
  border-radius: var(--default-border-radius);
  opacity: 0.8;
  z-index: 9;
`;

const StyledIframeContainer = styled.div`
  position: relative;
  display: flex;
  margin: auto;
  width: 70%;

  &.mobile {
    position: static;
  }

  &::before {
    content: '';
    width: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    display: block;
  }

  @media screen and (max-width: 767px) {
    & {
      width: 100%;
    }
  }
`;

const StyledIframe = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
`;

const getAppChipType = (status: string | undefined) => {
  switch (status) {
    case CourseStatusAttemptEnum.IN_PROGRESS:
      return 'secondary';
    case CourseStatusAttemptEnum.COMPLETED:
      return 'primary';
    case CourseStatusAttemptEnum.FAILED:
      return 'error';
    default:
      return 'secondary';
  }
};

const customCoursePropertyValueMap: Record<string, string | boolean> = {
  True: true,
  False: false,
};

export const ScormPlayerPage: React.FC = () => {
  const { t } = useTranslation();
  const { triggerIsFullScreen } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { id: courseId, attemptId } = useParams();
  const currentUser = useAppSelector(selectCurrentUser);
  const [status, setStatus] = useState<CourseStatusAttemptEnum>();
  const [isScormStarted, setIsScormStarted] = useState<boolean>(false);
  const [isUrlLoading, setIsUrlLoading] = useState<boolean>(false);
  const [scormDetails, setScormDetails] =
    useState<LearnerCourseDispatchResponse>();

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [frameLoaded, setFrameLoaded] = useState(false);
  const [isSubmittingStatus, setIsSubmittingStatus] = useState<boolean>(false);

  useEffect(() => {
    if (!triggerIsFullScreen) return;

    if (isMobile()) {
      triggerIsFullScreen(true);
    }

    return () => {
      triggerIsFullScreen(false);
    };
  }, []);

  useEffect(() => {
    if (!currentUser?.firstName || !frameLoaded || !isScormStarted) return;

    let interval: any = null;

    const setUserData = () => {
      if (
        typeof (iframeRef.current?.contentWindow as any)?.GetPlayer ===
        'undefined'
      ) {
        return;
      }

      const player = (iframeRef.current?.contentWindow as any)?.GetPlayer();

      if (player) {
        player.SetVar('Firstname', currentUser?.firstName);
        player.SetVar('ITperson', currentUser?.email);
        player.SetVar('useremail', currentUser?.email);
        player.SetVar('usermanager', currentUser?.meta?.manager ?? '');

        if (
          scormDetails?.properties &&
          Object.keys(scormDetails?.properties).length > 0
        ) {
          Object.entries(scormDetails.properties)
            .filter(([k, v]) => !!scormDetails?.properties && !!v)
            .forEach(([k, v]) => {
              let value: string | boolean = v;

              if (customCoursePropertyValueMap[v]) {
                value = customCoursePropertyValueMap[v];
              }

              player.SetVar(k, value);
            });
        }

        if (interval) clearInterval(interval);
      }
    };

    interval = setInterval(setUserData, 200);
  }, [
    frameLoaded,
    currentUser?.firstName,
    currentUser?.email,
    isScormStarted,
    scormDetails?.properties,
  ]);

  // Fetch course data
  const { course, isLoading: isCourseLoading } = useCourse({
    courseId,
    staleTime: Infinity,
  });

  const { courseAttempt: scormCourseAttempt, isLoading: isAttemptLoading } =
    useCourseAttempt({
      courseId,
      attemptId,
    });

  const [selectedLanguage, setSelectedLanguage] = useState<
    LanguagesEnum | undefined
  >(undefined);

  const [attemptLanguage, setAttemptLanguage] = useState<
    LanguagesEnum | undefined
  >(undefined);

  useEffect(() => {
    if (
      (course?.selfHosted && course?.courseLanguages?.length === 1) ||
      !currentUser ||
      !course ||
      isAttemptLoading
    ) {
      return;
    }

    // Set initial preselected language if not available set to English
    setSelectedLanguage(
      scormCourseAttempt?.language
        ? scormCourseAttempt.language
        : currentUser?.language &&
          course &&
          isUserLanguageAvailableInCourse(
            course?.courseFiles,
            currentUser?.language as LanguagesEnum,
          )
        ? (currentUser?.language as LanguagesEnum)
        : LanguagesEnum.EN,
    );
  }, [currentUser, course, scormCourseAttempt]);

  const courseName = getTranslationByLanguage(
    course?.courseTranslations ?? [],
    currentUser?.language
      ? (currentUser?.language as LanguagesEnum)
      : LanguagesEnum.EN,
  )?.name;

  useEffect(() => {
    setStatus(scormCourseAttempt?.status);
  }, [scormCourseAttempt]);

  useEffect(() => {
    if (course?.selfHosted && course?.courseLanguages?.length === 1) {
      setIsScormStarted(true);
    }

    if (scormCourseAttempt?.language) {
      setIsScormStarted(true);
    }

    fetchScormUrl();
  }, [course, scormCourseAttempt]);

  useEffect(() => {
    fetchScormUrl();
  }, [selectedLanguage]);

  const getLanguage = () => {
    if (course?.selfHosted && course?.courseLanguages?.length === 1) {
      return course?.courseLanguages[0];
    }

    if (course && !course?.selfHosted) {
      return LanguagesEnum.EN;
    }

    return selectedLanguage;
  };

  const fetchScormUrl = async () => {
    const courseLanguage = getLanguage();

    if (course && courseLanguage) {
      try {
        setScormDetails(undefined);
        setIsUrlLoading(true);
        const data = await client.courses.getCourseUrl(
          course.id,
          courseLanguage,
        );
        setScormDetails(data);
        setAttemptLanguage(courseLanguage);
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      } finally {
        setIsUrlLoading(false);
      }
    }
  };

  const getBreadcrumbs = (t: TranslationFunctionType): MenuItem[] => [
    {
      label: t('courses'),
      url:
        location?.state?.from ||
        RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE](),
      template: AppBreadCrumbTemplate,
    },
    {
      label: courseName,
      url: RedirectPaths[RedirectPathsEnum.COURSE_INFO](course?.id ?? ''),
      template: AppBreadCrumbTemplate,
      data: { state: location?.state },
    },
  ];

  const navigateToCourseInfo = async () => {
    if (course) {
      navigate(RedirectPaths[RedirectPathsEnum.COURSE_INFO](course?.id), {
        state: location?.state,
      });
    }
  };

  return (
    <>
      {(!currentUser || isAttemptLoading || isCourseLoading) && (
        <FlexContainer direction="column" className="h-full">
          <FlexContainer direction="column" className="mt-5 flex-1">
            <ProgressSpinner />
            <h3>{t('course.loading')}</h3>
          </FlexContainer>
        </FlexContainer>
      )}
      {!isAttemptLoading && !isCourseLoading && currentUser && course && (
        <StyledFlexContainer
          direction="column"
          align="flex-start"
          className={!isScormStarted && course.selfHosted ? 'stretch' : ''}
          gap={24}
        >
          {!isMobile() && (
            <>
              <AppBreadCrumb model={getBreadcrumbs(t)} className="no-space" />
              <FlexContainer justify="space-between" wrap="wrap">
                <StyledDiv>
                  <AppButton
                    type="outlined"
                    icon="pi pi-arrow-left"
                    size={isMobile() ? 'xs' : 'sm'}
                    ariaLabel={t('course.player.back')}
                    isDisabled={isSubmittingStatus}
                    onClick={
                      isMobile()
                        ? () =>
                            navigate(
                              location?.state?.from ||
                                RedirectPaths[
                                  RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE
                                ](),
                            )
                        : navigateToCourseInfo
                    }
                  />
                  <h1>{courseName}</h1>
                  <StyledAppChip
                    className={isMobile() ? 'mobile' : ''}
                    key={status}
                    label={getTranslatedCourseStatusAttempt(status)}
                    type={getAppChipType(status)}
                  />
                </StyledDiv>
                <StyledDiv>
                  {isScormStarted &&
                    course.selfHosted &&
                    course?.courseLanguages?.length > 1 &&
                    !isMobile() && (
                      <CourseLanguageChanger
                        language={selectedLanguage}
                        course={course}
                        shouldShowWarning={true}
                        isDisabled={isSubmittingStatus}
                        onChange={(value) => setSelectedLanguage(value)}
                      />
                    )}

                  {isScormStarted && !isMobile() && (
                    <AppButton
                      type="outlined"
                      icon="pi pi-times"
                      size={isMobile() ? 'xs' : 'sm'}
                      ariaLabel={t('courses.back')}
                      isDisabled={isSubmittingStatus}
                      onClick={() =>
                        navigate(
                          location?.state?.from ||
                            RedirectPaths[
                              RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE
                            ](),
                        )
                      }
                    />
                  )}
                </StyledDiv>
              </FlexContainer>
            </>
          )}

          {(isScormStarted || !course.selfHosted) &&
            !isUrlLoading &&
            scormDetails && (
              <ScormProvider
                course={course}
                attempt={scormCourseAttempt as CourseAttempt}
                attemptLanguage={attemptLanguage}
                version={scormDetails.version}
                user={currentUser}
                setStatus={setStatus}
                setIsSubmittingStatus={setIsSubmittingStatus}
                isPreview={false}
              >
                <StyledIframeContainer
                  id="scorm-iframe"
                  className={isMobile() ? 'mobile' : undefined}
                >
                  {isSubmittingStatus && (
                    <StyledSpinnerDiv>
                      <ProgressSpinner />
                      <span>{t('course.processing.results')}</span>
                    </StyledSpinnerDiv>
                  )}
                  {frameLoaded && <OrientationWarning />}

                  {isMobile() && (
                    <StyledButton
                      className="exit-button"
                      size="xs"
                      icon="pi pi-times"
                      isDisabled={isSubmittingStatus}
                      onClick={() =>
                        navigate(
                          RedirectPaths[
                            RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE
                          ](),
                        )
                      }
                    />
                  )}
                  <StyledIframe
                    src={scormDetails.url}
                    ref={iframeRef}
                    onLoad={() => setFrameLoaded(true)}
                  />
                </StyledIframeContainer>
              </ScormProvider>
            )}

          {!isScormStarted &&
            course.selfHosted &&
            !scormCourseAttempt?.language && (
              <CourseLanguageSelectScreen
                course={course}
                language={selectedLanguage}
                isLoading={isUrlLoading}
                isDisabled={!scormDetails}
                setIsCourseStarted={setIsScormStarted}
                setSelectedLanguage={setSelectedLanguage}
              />
            )}
        </StyledFlexContainer>
      )}
    </>
  );
};
