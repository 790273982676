import { notEqual } from '@/api/helpers';
import { ListingRequest } from '@/api/types';
import { client } from '@/client';
import { Branch } from '@/client/branches';
import { Group } from '@/client/groups';
import React, { HTMLAttributes, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PartialRequestMultiselect } from '../form/generic';

type EfrontGroupsMultiselectProps = {
  onSelect: (value: Group[]) => void;
  branch: Branch;
} & Omit<HTMLAttributes<HTMLInputElement>, 'onSelect'>;

export const EfrontGroupsMultiselect: React.FC<
  EfrontGroupsMultiselectProps
> = ({ onSelect, branch, ...rest }) => {
  const { t } = useTranslation();
  const fetchEfrontGroups = async (params: ListingRequest) =>
    await client.efrontGroups.getEfrontGroups(params);

  return (
    <PartialRequestMultiselect
      fetchService={fetchEfrontGroups}
      preselectAll
      filter
      display="chip"
      optionLabel="name"
      dataKey="id"
      placeholder={t('efront.select.groups')}
      maxSelectedLabels={3}
      customDataFilter={(group: Group) =>
        group?.meta?.eFrontBranchId === branch?.meta?.eFrontId
      }
      additionalFilters={[notEqual('efrontBranch', null)]}
      handleOnChange={onSelect}
    />
  );
};
