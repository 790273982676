import { EMAIL_REGEX } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { array, object, string } from 'yup';

export const resellerSchema = (t: TranslationFunctionType) =>
  object({
    name: string().required(t('error.requiredField')),
    email: string()
      .required(t('error.requiredField'))
      .matches(EMAIL_REGEX, t('error.invalidEmail')),
    phone: string().required(t('error.requiredField')),
    mainAccount: object().required(t('error.requiredField')),
    baseUrl: string().required(t('error.requiredField')),
    accounts: array().nullable(),
    theme: string().required(t('error.requiredField')),
    logo: string().nullable(),
    favIcon: string().nullable(),
  });

export const systemResellerSchema = (t: TranslationFunctionType) =>
  object({
    name: string().required(t('error.requiredField')),
    email: string()
      .required(t('error.requiredField'))
      .matches(EMAIL_REGEX, t('error.invalidEmail')),
    phone: string().required(t('error.requiredField')),
    baseUrl: string().required(t('error.requiredField')),
    theme: string().nullable(),
    logo: string().nullable(),
    favIcon: string().nullable(),
  });
