import { handleAxiosError } from '@/api/helpers';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import BeePlugin from '@mailupinc/bee-plugin';
import { IEntityContentJson } from '@mailupinc/bee-plugin/dist/types/bee';
import { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
const beePageEditor = new BeePlugin();

type BeePluginPageContainerProps = {
  template: any;
  handleChange: (page: IEntityContentJson) => void;
  handleSave: (page: object, html: string) => void;
  handleSendTest?: (html: string) => void;
};

export const BeePluginPageContainer: React.FC<BeePluginPageContainerProps> = ({
  template,
  handleChange,
  handleSave,
  handleSendTest,
}) => {
  const account = useAppSelector(selectCurrentAccount);
  const toast = useToast();
  const ref = useRef(null);
  const [beeToken, setBeeToken] = useState<string | undefined>();
  const clientId = process.env.REACT_APP_BEE_PLUGIN_PAGE_CLIENT_ID || '';
  const secret = process.env.REACT_APP_BEE_PLUGIN_PAGE_SECRET || '';

  // If the plugin was already initialized and the token is present, don't get it
  useEffect(() => {
    const getToken = async () => {
      if (beePageEditor.token) {
        setBeeToken(beePageEditor.token);
      } else {
        const token = await beePageEditor.getToken(clientId, secret);
        setBeeToken(token.access_token);
      }
    };
    getToken();
  }, []);

  useEffect(() => {
    if (!beeToken) {
      return;
    }

    const loadEditor = async () => {
      try {
        if (!account) {
          return;
        }

        beePageEditor.start(
          {
            container: 'beeplugin-editor',
            uid: account.id,
            trackChanges: true,
            preventClose: true,
            onChange: (page) => {
              handleChange(JSON.parse(page));
            },
            onSend: (html) => {
              if (!handleSendTest) return;
              handleSendTest(html);
            },
            onSave: (page, html) => {
              // if (!handleSave) return;
              handleSave(JSON.parse(page), html);
            },
            commenting: true,
            defaultForm: {
              structure: {
                fields: {
                  username: {
                    type: 'text',
                    label: 'Username *',
                    placeholder: 'Username',
                    canBeModified: true,
                    canBeRemovedFromLayout: true,
                    attributes: {
                      required: true,
                      placeholder: 'Type your username',
                    },
                  },
                  email: {
                    type: 'email',
                    label: 'Email *',
                    placeholder: 'Email',
                    canBeModified: true,
                    canBeRemovedFromLayout: true,
                    removeFromLayout: true,
                    attributes: {
                      placeholder: 'Type your email',
                    },
                  },
                  password: {
                    type: 'password',
                    label: 'Password *',
                    placeholder: 'Password',
                    canBeModified: true,
                    canBeRemovedFromLayout: true,
                    attributes: {
                      required: true,
                      placeholder: 'Type your password',
                    },
                  },
                  date: {
                    type: 'date',
                    label: 'Date of birth',
                    canBeModified: true,
                    removeFromLayout: true,
                    canBeRemovedFromLayout: true,
                    attributes: {
                      placeholder: 'Select date of birth',
                    },
                  },
                  tel: {
                    type: 'tel',
                    label: 'Phone number',
                    canBeModified: true,
                    removeFromLayout: true,
                    canBeRemovedFromLayout: true,
                    attributes: {
                      placeholder: 'Type phone number',
                    },
                  },
                  url: {
                    type: 'url',
                    label: 'Website',
                    canBeModified: true,
                    removeFromLayout: true,
                    canBeRemovedFromLayout: true,
                    attributes: {
                      placeholder: 'Add your website',
                    },
                  },
                  message: {
                    type: 'textarea',
                    label: 'Message',
                    canBeModified: true,
                    removeFromLayout: true,
                    canBeRemovedFromLayout: true,
                    attributes: {
                      placeholder: 'Add your message',
                    },
                  },
                  gender: {
                    type: 'select',
                    label: 'Gender',
                    canBeModified: true,
                    removeFromLayout: true,
                    canBeRemovedFromLayout: true,
                    options: [
                      {
                        type: 'option',
                        label: 'Male',
                        value: 'M',
                      },
                      {
                        type: 'option',
                        label: 'Female',
                        value: 'F',
                      },
                      {
                        type: 'option',
                        label: 'Not telling',
                        value: '-',
                      },
                    ],
                  },
                  privacy: {
                    type: 'checkbox',
                    label:
                      'Accept privacy policy. [Read it here](http://example.com)',
                    canBeModified: true,
                    removeFromLayout: true,
                    canBeRemovedFromLayout: true,
                    attributes: {
                      required: true,
                    },
                  },
                  submit: {
                    type: 'submit',
                    canBeModified: true,
                    canBeRemovedFromLayout: true,
                    attributes: {
                      value: 'SUBMIT',
                      name: 'submit_button',
                    },
                  },
                },
                layout: [
                  ['username'],
                  ['email'],
                  ['password'],
                  ['date'],
                  ['tel'],
                  ['url'],
                  ['gender'],
                  ['message'],
                  ['privacy'],
                  ['submit'],
                ],
                attributes: {
                  'accept-charset': 'UTF-8',
                  action: 'http://example.com/read-form-script',
                  autocomplete: 'off',
                  enctype: 'multipart/form-data',
                  method: 'post',
                  novalidate: false,
                  target: '_self',
                },
                title: 'Login form',
                description: 'Default login form',
              },
            },
            contentDefaults: {
              form: {
                labelsOptions: {
                  color: '#000000',
                },
                fieldsOptions: {
                  color: '#000000',
                  backgroundColor: '#ffffff',
                  outlineColor: '#3AAEE0',
                  borderRadius: '4px',
                  borderTop: '1px solid gray',
                  borderRight: '1px solid gray',
                  borderBottom: '1px solid gray',
                  borderLeft: '1px solid gray',
                },
                buttonsOptions: {
                  marginTop: '12px',
                  align: 'center',
                  width: 'auto',
                  margingLeft: 'auto',
                  marginRight: 'auto',
                },
              },
            },
          },
          template,
        );
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    };

    loadEditor();
  }, [beeToken]);

  return (
    <div>
      <div id="beeplugin-editor" ref={ref} style={{ height: '100%' }}></div>
    </div>
  );
};
