import { equal, nested } from '@/api/helpers';
import { SendTestEmailFormValues } from '@/client/campaigns';
import { testEmailSchema } from '@/components/campaigns/validations/test-email';
import { FormikInput, FormikRadio } from '@/components/form';
import { UsersAutocompleteInput } from '@/components/form/autocomplete';
import { useAppSelector } from '@/hooks/store';
import { selectCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { Field, Form, Formik } from 'formik';
import { Dialog, DialogProps } from 'primereact/dialog';
import { RadioButtonChangeEvent } from 'primereact/radiobutton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  width: 500px;
`;

type SendTestEmailModalProps = {
  email: string;
  onSubmit: (data: SendTestEmailFormValues) => void;
  extended?: boolean;
  withName?: boolean;
} & DialogProps;

export const SendTestEmailModal: React.FC<SendTestEmailModalProps> = ({
  email,
  onSubmit,
  onHide,
  visible,
  extended = true,
  withName,
}) => {
  const { t } = useTranslation();
  const user = useAppSelector(selectCurrentUser);
  const [initialValues, setInitialValues] = useState<SendTestEmailFormValues>({
    emailType: 'custom',
    email: email,
    firstName: undefined,
    lastName: undefined,
    position: undefined,
    admin: undefined,
  });

  useEffect(() => {
    setInitialValues((state) => ({
      ...state,
      email,
    }));
  }, [email]);
  return (
    <StyledDialog
      blockScroll
      visible={visible}
      header={<h1>{t('camapign.testEmail.send')}</h1>}
      onHide={onHide}
      draggable={false}
      data-testid="send-test-email-modal"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={testEmailSchema(t)}
      >
        {({ setFieldValue, values }) => (
          <Form data-testid="send-test-email-form">
            {extended && (
              <FlexContainer gap={24} justify="flex-start" className="mb-4">
                <div className="field-radio">
                  <Field
                    id="emailType"
                    type="radio"
                    name="emailType"
                    value="custom"
                    label={t('campaign.testEmail.custom')}
                    shapeType="circle"
                    onChange={(e: RadioButtonChangeEvent) =>
                      setFieldValue('emailType', e.value)
                    }
                    checked={values.emailType === 'custom'}
                    component={FormikRadio}
                  />
                </div>
                <div className="field-radio">
                  <Field
                    id="emailType"
                    type="radio"
                    name="emailType"
                    value="admin"
                    label={t('roles.admin')}
                    shapeType="circle"
                    onChange={(e: RadioButtonChangeEvent) =>
                      setFieldValue('emailType', e.value)
                    }
                    checked={values.emailType === 'admin'}
                    component={FormikRadio}
                  />
                </div>
              </FlexContainer>
            )}

            {values.emailType === 'custom' ? (
              <>
                <div className="field w-full">
                  <Field
                    id="email"
                    name="email"
                    label={t('generic.email')}
                    className="w-full"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('email', e.target.value.replace(/ /g, ''))
                    }
                    component={FormikInput}
                    required
                    data-testid="send-test-email-custom-email-field"
                    disabled={!user?.account.isSystem}
                  />
                </div>
                {(withName || extended) && (
                  <>
                    <div className="field w-full">
                      <Field
                        id="firstName"
                        name="firstName"
                        label={t('generic.firstName')}
                        className="w-full"
                        component={FormikInput}
                        data-testid="send-test-email-custom-first-name-field"
                      />
                    </div>
                    <div className="field w-full">
                      <Field
                        id="lastName"
                        name="lastName"
                        label={t('generic.lastName')}
                        className="w-full"
                        component={FormikInput}
                        data-testid="send-test-email-custom-last-name-field"
                      />
                    </div>
                  </>
                )}
                {extended && (
                  <>
                    <div className="field w-full">
                      <Field
                        id="position"
                        name="position"
                        label={t('generic.position')}
                        className="w-full"
                        component={FormikInput}
                        data-testid="send-test-email-custom-position-field"
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              <UsersAutocompleteInput
                id="admin"
                name="admin"
                dropdown
                className="w-full mb-4"
                onChange={(value) => setFieldValue('admin', value)}
                additionalFilters={[
                  equal('active', true),
                  nested('role', [equal('code', 'admin')]),
                ]}
              />
            )}

            <FlexContainer justify="flex-end" className="mt-5">
              <AppButton
                label={t('button.cancel')}
                severity="secondary"
                type="outlined"
                onClick={onHide}
                className="mr-3"
                data-testid="send-test-email-cancel-form"
              />
              <AppButton
                label={t('button.send')}
                severity="secondary"
                isSubmit
                data-testid="send-test-email-submit-form"
              />
            </FlexContainer>
          </Form>
        )}
      </Formik>
    </StyledDialog>
  );
};
