import { GroupCondition } from '@/api/enums';
import { equal, group, handleAxiosError, nested } from '@/api/helpers';
import { FiltersType } from '@/api/types';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { Campaign } from '@/client/campaigns';
import { MaterialType } from '@/client/courses';
import { ExportUsersReportFormValues, UsersReportType } from '@/client/reports';
import { LoadingStatuses } from '@/common/constants';
import { useNotifications } from '@/hooks/notifications.hook';
import { useAppSelector } from '@/hooks/store';
import { useMixpanel } from '@/hooks/useMixpanel';
import { pendoEvent, pendoProperty, usePendo } from '@/hooks/usePendo';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import { Field, Form, Formik } from 'formik';
import { Dialog, DialogProps } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { RadioButtonChangeEvent } from 'primereact/radiobutton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 } from 'uuid';
import { FormikRadio } from '../form';

const StyledDialog = styled(Dialog)`
  width: 500px;
`;

const StyledA = styled.a`
  color: rgb(0, 0, 238);
  text-decoration: underline;

  &:active {
    color: var(--red-main);
  }
`;

type ReportsExportModalProps = {
  type: UsersReportType;
  filters?: FiltersType;
  sort?: [string, 'asc' | 'desc'] | null;
  campaign?: Campaign;
} & DialogProps;

export const ReportsExportModal: React.FC<ReportsExportModalProps> = ({
  type,
  filters,
  sort,
  campaign,
  onHide,
  visible,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const account = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const { pendoTrack } = usePendo();
  const { track } = useMixpanel();
  const [initialValues] = useState<ExportUsersReportFormValues>({
    isAllData: true,
  });
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [downloadUrl, setDownloadUrl] = useState();
  const [exportKey, setExportKey] = useState<string>();

  const exportAwarenessReport = (account: Account, isAllData: boolean) => {
    const awarenessExportKey = v4();
    if (currentUser?.account.isTrackingEnabled) {
      track('Export Awareness report data', {
        'Data type selection': isAllData ? 'All data' : 'Filtered data',
        'Report type': 'Awareness',
      });
    }

    const defaultFilter = account?.meta?.enableMaterialsUpload
      ? group(GroupCondition.OR, [
          nested('course', [equal('type', MaterialType.COURSE)]),
          nested('course', [equal('type', MaterialType.CUSTOM_MATERIAL)]),
        ])
      : nested('course', [equal('type', MaterialType.COURSE)]);

    const exportFilters = filters
      ? [...filters, defaultFilter]
      : [defaultFilter];

    client.reports.exportUsersAwarenessReport(account.id, {
      filters: isAllData ? [defaultFilter] : exportFilters,
      exportKey: awarenessExportKey,
      sort: sort && sort?.length > 0 ? [sort.join(',')] : [],
    });

    setExportKey(awarenessExportKey);
  };

  const exportSummaryReport = (account: Account, isAllData: boolean) => {
    const summaryExportKey = v4();
    if (currentUser?.account.isTrackingEnabled) {
      track('Export Summary report data', {
        'Data type selection': isAllData ? 'All data' : 'Filtered data',
        'Report type': 'Summary',
      });
    }

    client.reports.exportUsersSummaryReport(account.id, {
      filters: isAllData ? [] : filters,
      exportKey: summaryExportKey,
      sort: sort && sort?.length > 0 ? [sort.join(',')] : [],
    });

    setExportKey(summaryExportKey);
  };

  const exportPhishingReport = (
    exportCampaignId: string,
    isAllData: boolean,
  ) => {
    const phishingExportKey = v4();
    if (currentUser?.account.isTrackingEnabled) {
      track('Export Phishing report data', {
        'Data type selection': isAllData ? 'All data' : 'Filtered data',
        'Report type': 'Phishing',
      });
    }

    client.campaigns.exportPhishingReport(exportCampaignId, {
      filters: isAllData ? [] : filters,
      exportKey: phishingExportKey,
    });

    setExportKey(phishingExportKey);
  };

  const trackExportPendoEvent = () => {
    let pendoEventName;
    const pendoEventProps = {
      [pendoProperty.forAccountId]: account?.id ?? '',
      [pendoProperty.forAccountName]: account?.name ?? '',
    } as { [key: string]: string };

    switch (type) {
      case UsersReportType.AWARENESS: {
        pendoEventName = pendoEvent.awarenessReportExported;
        break;
      }
      case UsersReportType.SUMMARY: {
        pendoEventName = pendoEvent.summaryReportExported;
        break;
      }
      case UsersReportType.PHISHING: {
        pendoEventName = pendoEvent.campaignsReportExported;
        pendoEventProps[pendoProperty.campaignId] = campaign?.id ?? '';
        pendoEventProps[pendoProperty.campaignName] = campaign?.name ?? '';
        break;
      }
      default:
        break;
    }

    if (pendoEventName) {
      pendoTrack(pendoEventName, pendoEventProps);
    }
  };

  let lastMessageExport: {
    event: string;
    data: any;
  } | null;
  let setExportId: React.Dispatch<React.SetStateAction<string | undefined>>;
  let exportId: string | undefined;
  let isConnectedExport: React.MutableRefObject<boolean>;

  if (type !== UsersReportType.PHISHING) {
    const {
      lastMessage,
      setNotificationParam: setAccountId,
      notificationParam: accountId,
      isConnected,
    } = useNotifications(client.reports.reportsNotifyUrl);

    lastMessageExport = lastMessage;
    setExportId = setAccountId;
    exportId = accountId;
    isConnectedExport = isConnected;
  } else {
    const {
      lastMessage,
      setNotificationParam: setCampaignId,
      notificationParam: campaignId,
      isConnected,
    } = useNotifications(client.campaigns.campaignsNotifyUrl);

    lastMessageExport = lastMessage;
    setExportId = setCampaignId;
    exportId = campaignId;
    isConnectedExport = isConnected;
  }

  const exportReport = async ({ isAllData }: ExportUsersReportFormValues) => {
    if (campaign?.id || account) {
      setIsDownloading(true);
      campaign?.id ? setExportId(campaign?.id) : setExportId(account?.id);
      setDownloadUrl(undefined);

      // Wait for a notify connection to establish
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (isConnectedExport.current) {
            clearInterval(intervalId);
            resolve(true);
          }
        }, 100);
      });

      try {
        if (isConnectedExport.current) {
          if (type === UsersReportType.AWARENESS && account) {
            exportAwarenessReport(account, isAllData);
          } else if (type === UsersReportType.SUMMARY && account) {
            exportSummaryReport(account, isAllData);
          } else if (type === UsersReportType.PHISHING && campaign?.id) {
            exportPhishingReport(campaign?.id, isAllData);
          }

          if (currentUser?.account.isTrackingEnabled) {
            trackExportPendoEvent();
          }
        }
      } catch (e) {
        setIsDownloading(false);
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  useEffect(() => {
    if (!exportId) return;
    handleSyncNotification(lastMessageExport);
  }, [JSON.stringify(lastMessageExport)]);

  const handleSyncNotification = async (messages: { data: any } | null) => {
    if (
      !messages?.data?.event ||
      ![
        'awareness-report.export.finished',
        'summary-report.export.finished',
        'campaign-results.export.finished',
      ].includes(messages?.data?.event) ||
      (messages.data?.exportKey !== exportKey &&
        messages.data.payload?.exportKey !== exportKey)
    )
      return;

    if (
      (messages.data.event === 'awareness-report.export.finished' ||
        messages.data.event === 'summary-report.export.finished' ||
        messages.data.event === 'campaign-results.export.finished') &&
      (messages.data?.exportKey === exportKey ||
        messages.data?.payload?.exportKey === exportKey)
    ) {
      type !== UsersReportType.PHISHING
        ? setDownloadUrl(messages.data.downloadUrl)
        : setDownloadUrl(messages.data.payload.downloadUrl);
      setIsDownloading(false);
    }

    setExportId(undefined);
    setExportKey(undefined);
  };

  return (
    <StyledDialog
      blockScroll
      visible={visible}
      header={<h1>{t('generic.export')}</h1>}
      onHide={() => {
        onHide();
        setDownloadUrl(undefined);
      }}
      draggable={false}
      data-testid="reports-export-modal"
      closable={!isDownloading}
    >
      <Formik initialValues={initialValues} onSubmit={exportReport}>
        {({ setFieldValue, values }) => (
          <Form>
            <FlexContainer gap={24} justify="flex-start" className="mb-4">
              <div className="field-radio">
                <Field
                  id="exportType"
                  type="radio"
                  name="exportType"
                  value={true}
                  label={t('reports.export.allData')}
                  shapeType="circle"
                  onChange={(e: RadioButtonChangeEvent) => {
                    setDownloadUrl(undefined);
                    setFieldValue('isAllData', e.value);
                  }}
                  checked={!!values.isAllData}
                  component={FormikRadio}
                  disabled={isDownloading}
                />
              </div>
              <div className="field-radio">
                <Field
                  id="exportType"
                  type="radio"
                  name="exportType"
                  value={false}
                  label={t('reports.export.filteredData')}
                  shapeType="circle"
                  onChange={(e: RadioButtonChangeEvent) => {
                    setDownloadUrl(undefined);
                    setFieldValue('isAllData', e.value);
                  }}
                  checked={!values.isAllData}
                  component={FormikRadio}
                  disabled={isDownloading}
                />
              </div>
            </FlexContainer>

            {downloadUrl && (
              <FlexContainer>
                <Message
                  severity="success"
                  text={
                    <div>
                      {t('reports.export.ready.p1')}{' '}
                      <StyledA href={downloadUrl}>
                        {t('reports.export.ready.p2')}
                      </StyledA>
                    </div>
                  }
                />
              </FlexContainer>
            )}

            <FlexContainer justify="flex-end" className="mt-5">
              <AppButton
                label={t('button.cancel')}
                severity="secondary"
                type="outlined"
                onClick={() => {
                  onHide();
                  setDownloadUrl(undefined);
                }}
                className="mr-3"
                data-testid="reports-export-cancel-form"
                isDisabled={isDownloading}
              />
              <AppButton
                icon="pi pi-download"
                state={
                  isDownloading ? LoadingStatuses.LOADING : LoadingStatuses.IDLE
                }
                label={t('generic.export')}
                isSubmit
                data-testid="reports-export-submit-form"
                isDisabled={isDownloading}
              />
            </FlexContainer>
          </Form>
        )}
      </Formik>
    </StyledDialog>
  );
};
