import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { Campaign } from '@/client/campaigns';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { OldCampaignsSyncForm } from '@/components/old-campaigns-sync/forms';
import { useMigrateCampaign } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type InitialValues = {
  account?: Account;
  campaigns?: Campaign[];
};
export const OldCampaignsWizardPage: React.FC = () => {
  const { t } = useTranslation();
  const account = useAppSelector(selectCurrentAccount);
  const toast = useToast();
  const navigate = useNavigate();
  const { migrate } = useMigrateCampaign();

  const initialValues: InitialValues = {
    account: undefined,
    campaigns: undefined,
  };

  const pathItems = [
    {
      label: (account as Account)?.name,
      url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](
        (account as Account)?.id,
      ),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('sidebar.campaigns'),
      url: RedirectPaths[RedirectPathsEnum.OLD_CAMPAIGNS_WIZARD](),
      template: AppBreadCrumbTemplate,
    },
  ];

  const handleSyncCampaigns = async (data: {
    account: Account;
    campaigns: Campaign[];
  }) => {
    await migrate({
      accountId: data.account.id,
      campaignIds: data.campaigns.map((c) => c.id),
    });

    navigate(RedirectPaths[RedirectPathsEnum.ACCOUNTS]());
  };

  const handleSubmit = async (data: FormikValues) => {
    try {
      await handleSyncCampaigns(data as any);

      toast?.success(t('toast.success'), t('campaign.request.sync'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  return (
    <>
      <AppBreadCrumb model={pathItems} />

      <OldCampaignsSyncForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </>
  );
};
