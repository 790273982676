import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { CourseEntityEnrollEnum } from '@/client/courses';
import {
  SecurityCultureUploadSurveyFormValues,
  SurveyStatus,
} from '@/client/security-culture/types';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { SecurityCultureUploadSurveyForm } from '@/components/security-culture';
import { useNotifications } from '@/hooks/notifications.hook';
import {
  useEditSurvey,
  useSurvey,
  useUploadSurvey,
} from '@/hooks/query/security-culture.hooks';
import { useAppSelector } from '@/hooks/store';
import { MultipleToastType, useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { getTargetEntityIdsByType } from '@/utils/targets';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const SecurityCultureUploadSurveyPage: React.FC = () => {
  const toast = useToast();
  const { id: surveyId } = useParams();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();
  const { survey, isLoading } = useSurvey({
    surveyId,
    accountId: currentAccount?.id,
  });

  const {
    lastMessage,
    setNotificationParam: setSurveyNotifyId,
    notificationParam: surveyNotifyId,
    isConnected,
  } = useNotifications(client.securityCulture.surveyNotifyUrl);

  const handleSurveyUploadNotify = async (surveyId: string) => {
    if (surveyId) {
      setSurveyNotifyId(surveyId);
      setIsUploading(true);

      // Wait for a notify connection to establish
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (isConnected.current) {
            clearInterval(intervalId);
            resolve(true);
          }
        }, 100);
      });
    }
  };

  useEffect(() => {
    if (!surveyNotifyId) return;
    handleSyncNotification(lastMessage);
  }, [JSON.stringify(lastMessage)]);

  const parseUploadErrors = (messageErrors: any): MultipleToastType[] => {
    const errors = [] as MultipleToastType[];

    // Use setTimeout to delay error toast
    Object.values(messageErrors).forEach((row: any) => {
      const rowErrors: string[] = [];

      Object.values(row.errors).forEach((x: any) =>
        rowErrors.push(x.join(', ')),
      );

      errors.push({
        severity: 'error',
        summary: t('toast.error'),
        detail: rowErrors.join(', '),
        life: 15000,
      });
    });

    return errors;
  };

  const handleSyncNotification = async (messages: { data: any } | null) => {
    if (
      !messages?.data?.event ||
      ![
        'survey.results.import.finished',
        'survey.results.import.warning',
        'survey.results.import.error',
      ].includes(messages?.data?.event)
    )
      return;

    if (messages?.data?.event === 'survey.results.import.finished') {
      const toastMessage = survey?.id
        ? t('security.culture.survey.edited')
        : t('security.culture.survey.uploaded');

      toast?.success(t('toast.success'), toastMessage);

      navigate(RedirectPaths[RedirectPathsEnum.SECURITY_CULTURE]());
    }

    if (messages?.data?.event === 'survey.results.import.warning') {
      setTimeout(() => {
        toast?.multiple(parseUploadErrors(messages?.data?.payload?.errors));
      }, 0);
    }

    if (messages?.data?.event === 'survey.results.import.error') {
      toast?.error(t('toast.error'), messages?.data?.payload?.error, 5000);
    }

    setSurveyNotifyId(undefined);
    setIsUploading(false);
  };

  const uploadSurvey = useUploadSurvey();
  const editSurvey = useEditSurvey();
  const handleUploadSurveySubmit = async (
    data: SecurityCultureUploadSurveyFormValues,
  ) => {
    if (!currentAccount) return;

    const action = data.id ? editSurvey.edit : uploadSurvey.upload;

    try {
      const response = await action({
        accountId: currentAccount.id,
        id: data.id,
        date: data.date,
        targets: data?.targets?.every(({ id }) => id !== currentAccount.id)
          ? {
              branches: getTargetEntityIdsByType(
                data.targets || [],
                CourseEntityEnrollEnum.BRANCHES,
              ),
              groups: getTargetEntityIdsByType(
                data.targets || [],
                CourseEntityEnrollEnum.GROUPS,
              ),
            }
          : {},
        status: data.published ? SurveyStatus.PUBLISHED : SurveyStatus.DRAFT,
        file: data?.file?.id,
        isDemo: !!currentAccount.isSystem,
      });

      // Notify only on file change
      if ((data?.file?.id === survey?.file?.id && survey?.id) || !data?.file) {
        const toastMessage = data?.id
          ? t('security.culture.survey.edited')
          : t('security.culture.survey.uploaded');

        toast?.success(t('toast.success'), toastMessage);

        navigate(RedirectPaths[RedirectPathsEnum.SECURITY_CULTURE]());
      } else {
        handleSurveyUploadNotify(response.id || '');
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const pathItems = (account: Account, t: TranslationFunctionType) => [
    {
      label: account?.name,
      url: !account?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
      disabled: isUploading,
    },
    {
      label: t('security.culture'),
      url: RedirectPaths[RedirectPathsEnum.SECURITY_CULTURE](),
      template: AppBreadCrumbTemplate,
      disabled: isUploading,
    },

    {
      label: survey?.id
        ? currentAccount?.isSystem
          ? t('security.culture.edit.demo.survey')
          : t('security.culture.edit.survey')
        : currentAccount?.isSystem
        ? t('security.culture.new.demo.survey')
        : t('security.culture.new.survey'),
      url: RedirectPaths[RedirectPathsEnum.SECURITY_CULTURE_UPLOAD_SURVEY](),
      template: AppBreadCrumbTemplate,
      disabled: true,
    },
  ];

  return (
    <>
      {isLoading && (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
          <h3>{t('security.culture.survey.loading')}</h3>
        </FlexContainer>
      )}
      {!isLoading && (
        <FlexContainer direction="column" align="flex-start">
          <div className="w-full">
            <AppBreadCrumb model={pathItems(currentAccount as Account, t)} />
            <h1>
              {survey?.id
                ? currentAccount?.isSystem
                  ? t('security.culture.edit.demo.survey')
                  : t('security.culture.edit.survey')
                : currentAccount?.isSystem
                ? t('security.culture.new.demo.survey')
                : t('security.culture.new.survey')}
            </h1>
            <SecurityCultureUploadSurveyForm
              survey={survey}
              state={
                isUploading ? LoadingStatuses.LOADING : LoadingStatuses.IDLE
              }
              onSubmit={handleUploadSurveySubmit}
              isDemoForm={currentAccount?.isSystem}
            />
          </div>
        </FlexContainer>
      )}
    </>
  );
};
