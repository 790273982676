import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import { useToast } from '@/hooks/useToast';
import { LoadingPage } from '@/pages';
import { revokeHubspotCookies } from '@/utils/hubspot';
import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

const CompleteSignIn: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();

  useEffect(() => {
    if (!auth.isAuthenticated) {
      auth.signinSilent();
      return;
    }

    // Just in case revoke the cookies also here, Hubspot will reattach them automatically.
    revokeHubspotCookies();

    window.history.replaceState({}, document.title, window.location.pathname);

    const updateLastLoginDate = async () => {
      if (auth.user) {
        try {
          await client.default.updateLastLoginDate(auth.user);
        } catch (e) {
          handleAxiosError(e as AxiosError, toast);
        }
      }
    };

    updateLastLoginDate();

    navigate('/', {
      replace: true,
    });
  }, [auth.isAuthenticated]);

  return <LoadingPage message={t('auth.signIn.wait')} />;
};

export default CompleteSignIn;
