import {
  ChangeEnrollDateFormValues,
  CourseScheduleType,
} from '@/client/courses';
import { changeEnrollDateSchema } from '@/components/courses/validations/enroll';
import { AppButton } from '@/ui/buttons';
import { AppCalendar } from '@/ui/calendar';
import { FlexContainer } from '@/ui/styled-ui';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { Dialog, DialogProps } from 'primereact/dialog';
import { FormEvent } from 'primereact/ts-helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  width: 500px;
`;

const StyledCalendar = styled(AppCalendar)`
  .p-datepicker {
    border: 0;
    box-shadow: none;
  }
`;

type ChangeEnrollDateModalProps = {
  onSubmit: (data: ChangeEnrollDateFormValues) => Promise<void>;
  date?: Date;
  maxDate?: Date;
} & DialogProps;

export const ChangeEnrollDateModal: React.FC<ChangeEnrollDateModalProps> = ({
  date,
  onSubmit,
  onHide,
  visible,
  maxDate,
}) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] =
    useState<ChangeEnrollDateFormValues>({
      date: undefined,
      type: CourseScheduleType.STATIC,
    });

  useEffect(() => {
    if (date) {
      setInitialValues({
        date: moment(date).local().toDate(),
        type: CourseScheduleType.STATIC,
      });
    }
  }, [date]);

  return (
    <StyledDialog
      blockScroll
      visible={visible}
      header={<h1>{t('courses.change.enrollDate')}</h1>}
      onHide={onHide}
      draggable={false}
      data-testid={'enroll-change-date-modal'}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={changeEnrollDateSchema(t)}
        validateOnMount
      >
        {({ setFieldValue, values, errors }) => (
          <Form>
            <div className="field w-full mb-4">
              <StyledCalendar
                id="enroll-date"
                name="enrollDate"
                className="w-full"
                placeholder={t('courses.select.enrollDate')}
                minDate={new Date()}
                maxDate={maxDate ? new Date(maxDate as Date) : undefined}
                value={values.date}
                onChange={(event: FormEvent) => {
                  setFieldValue(
                    'date',
                    moment(event.value?.toString()).utc(true).toDate(),
                  );
                }}
                readOnlyInput
                inline
              />
            </div>

            <FlexContainer justify="flex-end" className="mt-5">
              <AppButton
                label={t('button.cancel')}
                severity="secondary"
                type="outlined"
                onClick={onHide}
                className="mr-3"
                data-testid={'enroll-change-date-cancel-form'}
              />
              <AppButton
                label={t('generic.enroll')}
                isSubmit
                isDisabled={!!Object.keys(errors).length}
                data-testid={'enroll-change-date-submit-form'}
              />
            </FlexContainer>
          </Form>
        )}
      </Formik>
    </StyledDialog>
  );
};
