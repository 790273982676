import {
  colorPurpleDark,
  platformFontFamily,
  smallFontSize,
  xxxSmallBorderRadius,
} from '@/utils/helpers/css-variables.helper';

export const tooltipDefaultSettings = () => ({
  displayColors: false,
  padding: { top: 8, bottom: 8, left: 16, right: 16 },
  backgroundColor: colorPurpleDark,
  cornerRadius: xxxSmallBorderRadius?.replace('px', ''),
  bodyFont: {
    size: smallFontSize,
    family: platformFontFamily,
  },
});
