import { AppUnauthorized } from '@/app';
import { client } from '@/client';
import { AppRoutesPaths } from '@/common/constants';
import { LoadingPage } from '@/pages';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { Navigate } from 'react-router-dom';

type AppKeycloakRedirectIfNotLoggedInProps = {
  children: React.ReactNode;
};

export const AuthKeycloakRedirectIfNotLogged: React.FC<
  AppKeycloakRedirectIfNotLoggedInProps
> = ({ children }) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const [isInterceptorSet, setIsInterceptorSet] = useState<boolean>(false);

  const getKeyCloakToken = (): string => {
    return auth.user?.access_token as string;
  };

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      auth.signinRedirect();
    }
  }, [
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect,
  ]);

  useEffect(() => {
    if (!auth.isAuthenticated) return;

    const interceptor = client.axiosRef.interceptors.request.use((config) => {
      const token = getKeyCloakToken();

      (config.headers as any).set('Authorization', `Bearer ${token}`);

      return config;
    });

    setIsInterceptorSet(true);

    return () => {
      client.axiosRef.interceptors.request.eject(interceptor);
    };
  }, [auth.isAuthenticated, auth?.user?.access_token]);

  if (!isInterceptorSet) {
    return <LoadingPage message={t('user.loading')} />;
  }

  if (auth.activeNavigator === 'signinRedirect') {
    return <AppUnauthorized text={t('auth.signIn.redirect')} />;
  }

  if (!auth.isAuthenticated) {
    return <Navigate to={AppRoutesPaths.UNAUTHORIZED_ROUTE} />;
  }

  return <>{children}</>;
};
