export enum TableNamesEnum {
  COURSES_LIST = 'COURSES_LIST',
  COURSES_ACCOUNT_SCHEDULE = 'COURSES_ACCOUNT_SCHEDULE',
  COURSE_SCHEDULE = 'COURSE_SCHEDULE',
  COURSE_ACCOUNTS = 'COURSE_ACCOUNTS',
  COURSE_BRANCHES = 'COURSE_BRANCHES',
  COURSE_GROUPS = 'COURSE_GROUPS',
  COURSE_USERS = 'COURSE_USERS',
  COURSE_ANALYTICS = 'COURSE_ANALYTICS',
  CAMPAIGNS_LIST_SYSTEM = 'CAMPAIGNS_LIST_SYSTEM',
  CAMPAIGNS_LIST_CUSTOMER = 'CAMPAIGNS_LIST_CUSTOMER',
  CAMPAIGNS_TEMPLATES_LIST = 'CAMPAIGNS_TEMPLATES_LIST',
  CAMPAIGN_REPORTS = 'CAMPAIGN_REPORTS',
  SUMMARY_REPORTS = 'SUMMARY_REPORTS',
  AWARENESS_REPORTS = 'AWARENESS_REPORTS',
  RESOURCES_LIST = 'RESOURCES_LIST',
  CUSTOM_MATERIALS_LIST = 'CUSTOM_MATERIALS_LIST',
  MATERIAL_ACCOUNT_SCHEDULE = 'MATERIAL_ACCOUNT_SCHEDULE',
  MATERIAL_SCHEDULE = 'MATERIAL_SCHEDULE',
  MATERIAL_ACCOUNTS = 'MATERIAL_ACCOUNTS',
  MATERIAL_BRANCHES = 'MATERIAL_BRANCHES',
  MATERIAL_GROUPS = 'MATERIAL_GROUPS',
  MATERIAL_USERS = 'MATERIAL_USERS',
  ACCOUNTS_LIST = 'ACCOUNTS_LIST',
  USERS_LIST = 'USERS_LIST',
  USER_COURSES_LIST = 'USER_COURSES_LIST',
  USER_MATERIALS_LIST = 'USER_MATERIALS_LIST',
  BRANCHES_LIST = 'BRANCHES_LIST',
  BRANCHES_USERS_LIST = 'BRANCHES_USERS_LIST',
  GROUPS_LIST = 'GROUPS_LIST',
  EMAIL_TEMPLATES_LIST = 'EMAIL_TEMPLATES_LIST',
  EMAIL_CONFIGS_LIST = 'EMAIL_CONFIGS_LIST',
  RESELLERS_LIST = 'RESELLERS_LIST',
  RESELLERS_ACCOUNTS_LIST = 'RESELLERS_ACCOUNTS_LIST',
  SENDER_PROFILES_LIST = 'SENDER_PROFILES_LIST',
  TEMPLATES_CATALOGUE = 'TEMPLATES_CATALOGUE',
  SURVEYS_LIST = 'SURVEYS_LIST',
}

export const TableNamesKeys = {
  [TableNamesEnum.COURSES_LIST]: 'courses-list',
  [TableNamesEnum.COURSES_ACCOUNT_SCHEDULE]: 'courses-account-schedule',
  [TableNamesEnum.COURSE_SCHEDULE]: 'course-schedule',
  [TableNamesEnum.COURSE_ACCOUNTS]: 'course-accounts',
  [TableNamesEnum.COURSE_BRANCHES]: 'course-branches',
  [TableNamesEnum.COURSE_GROUPS]: 'course-groups',
  [TableNamesEnum.COURSE_USERS]: 'course-users',
  [TableNamesEnum.COURSE_ANALYTICS]: 'course-analytics',
  [TableNamesEnum.CAMPAIGNS_LIST_SYSTEM]: 'campaigns-list-system',
  [TableNamesEnum.CAMPAIGNS_LIST_CUSTOMER]: 'campaigns-list-customer',
  [TableNamesEnum.CAMPAIGNS_TEMPLATES_LIST]: 'campaigns-templates-list',
  [TableNamesEnum.CAMPAIGN_REPORTS]: 'campaign-reports',
  [TableNamesEnum.SUMMARY_REPORTS]: 'summary-reports',
  [TableNamesEnum.AWARENESS_REPORTS]: 'awareness-reports',
  [TableNamesEnum.RESOURCES_LIST]: 'resources-list',
  [TableNamesEnum.CUSTOM_MATERIALS_LIST]: 'custom-materials-list',
  [TableNamesEnum.MATERIAL_ACCOUNT_SCHEDULE]: 'material-account-schedule',
  [TableNamesEnum.MATERIAL_SCHEDULE]: 'material-schedule',
  [TableNamesEnum.MATERIAL_ACCOUNTS]: 'material-accounts',
  [TableNamesEnum.MATERIAL_BRANCHES]: 'material-branches',
  [TableNamesEnum.MATERIAL_GROUPS]: 'material-groups',
  [TableNamesEnum.MATERIAL_USERS]: 'material-users',
  [TableNamesEnum.ACCOUNTS_LIST]: 'accounts-list',
  [TableNamesEnum.USERS_LIST]: 'users-list',
  [TableNamesEnum.USER_COURSES_LIST]: 'user-courses-list',
  [TableNamesEnum.USER_MATERIALS_LIST]: 'user-materials-list',
  [TableNamesEnum.BRANCHES_LIST]: 'branches-list',
  [TableNamesEnum.BRANCHES_USERS_LIST]: 'branches-users-list',
  [TableNamesEnum.GROUPS_LIST]: 'groups-list',
  [TableNamesEnum.EMAIL_TEMPLATES_LIST]: 'email-templates-list',
  [TableNamesEnum.EMAIL_CONFIGS_LIST]: 'email-configs-list',
  [TableNamesEnum.RESELLERS_LIST]: 'resellers-list',
  [TableNamesEnum.RESELLERS_ACCOUNTS_LIST]: 'resellers-accounts-list',
  [TableNamesEnum.SENDER_PROFILES_LIST]: 'sender-profiles-list',
  [TableNamesEnum.TEMPLATES_CATALOGUE]: 'templates-catalogue-list',
  [TableNamesEnum.SURVEYS_LIST]: 'surveys-list',
};

export const OPEN_FILTERS_KEY = 'open-filters';

export const SELECTED_COLUMNS_KEY = 'selected-columns';
