import { EmailType } from '@/client/system-emails';
import { TranslationFunctionType } from '@/common/types';

export const templateBuilderLegend = (t: TranslationFunctionType) => [
  { clipboardValue: '{{.RId}}', clipboardText: t('templates.legend.uniqueId') },
  {
    clipboardValue: '{{.FirstName}}',
    clipboardText: t('templates.legend.firstName'),
  },
  {
    clipboardValue: '{{.LastName}}',
    clipboardText: t('templates.legend.lastName'),
  },
  {
    clipboardValue: '{{.Position}}',
    clipboardText: t('templates.legend.position'),
  },
  {
    clipboardValue: '{{.Email}}',
    clipboardText: t('templates.legend.emailAdress'),
  },
  {
    clipboardValue: '{{.From}}',
    clipboardText: t('templates.legend.spoofSender'),
  },
  {
    clipboardValue: '{{.TrackingURL}}',
    clipboardText: t('templates.legend.trackingUrl'),
  },
  {
    clipboardValue: '{{.Tracker}}',
    clipboardText: t('templates.legend.tracker'),
  },
  { clipboardValue: '{{.URL}}', clipboardText: t('templates.legend.url') },
  {
    clipboardValue: '{{.BaseURL}}',
    clipboardText: t('templates.legend.baseUrl'),
  },
  {
    clipboardValue: '{{.HoneypotLink}}',
    clipboardText: t('templates.legend.honeypot'),
  },
];

export const TemplateSystemEmailsTemplatesBuilderLegend = (
  t: TranslationFunctionType,
  emailType?: EmailType,
) => {
  let clipboardValues = [
    {
      clipboardValue: '{{.FullName}}',
      clipboardText: t('templates.legend.fullName'),
    },
    {
      clipboardValue: '{{.FirstName}}',
      clipboardText: t('templates.legend.firstName'),
    },
    {
      clipboardValue: '{{.LastName}}',
      clipboardText: t('templates.legend.lastName'),
    },
    {
      clipboardValue: '{{.Username}}',
      clipboardText: t('templates.legend.username'),
    },
    {
      clipboardValue: '{{.Email}}',
      clipboardText: t('templates.legend.emailAdress'),
    },
    {
      clipboardValue: '<img src={{.LogoUrl}} alt="Logo" />',
      clipboardText: t('templates.legend.logo'),
    },
    {
      clipboardValue: '{{.PlatformName}}',
      clipboardText: t('templates.legend.platformName'),
    },
    {
      clipboardValue: '{{.PlatformPhone}}',
      clipboardText: t('templates.legend.platformPhone'),
    },
    {
      clipboardValue: '{{.PlatformEmail}}',
      clipboardText: t('templates.legend.platformEmail'),
    },
  ];

  if (
    [
      EmailType.INCOMPLETE_COURSE_REMINDER,
      EmailType.COURSE_ENROLLMENT,
      EmailType.COURSE_AVAILABILITY,
      EmailType.WELCOME,
      EmailType.ACCOUNT_MIGRATION,
      EmailType.WELCOME_SSO,
      EmailType.ACCOUNT_MIGRATION_SSO,
      EmailType.WELCOME_FREE_TRIAL,
    ].includes(emailType as EmailType)
  ) {
    clipboardValues = [
      ...clipboardValues,
      {
        clipboardValue: '{{.LoginUrl}}',
        clipboardText:
          emailType === EmailType.WELCOME ||
          emailType === EmailType.WELCOME_FREE_TRIAL
            ? t('templates.legend.loginUrl.prefix')
            : t('templates.legend.loginUrl'),
      },
    ];
  }

  if (
    [
      EmailType.WELCOME,
      EmailType.ACCOUNT_MIGRATION,
      EmailType.WELCOME_FREE_TRIAL,
    ].includes(emailType as EmailType)
  ) {
    clipboardValues = [
      ...clipboardValues,
      {
        clipboardValue: '{{.Password}}',
        clipboardText: t('templates.legend.tempPassword'),
      },
    ];
  }

  if (
    [
      EmailType.INCOMPLETE_COURSE_REMINDER,
      EmailType.COURSE_ENROLLMENT,
    ].includes(emailType as EmailType)
  ) {
    clipboardValues = [
      ...clipboardValues,
      {
        clipboardValue:
          (emailType as EmailType) === EmailType.COURSE_ENROLLMENT
            ? `{{#each courses}} {{name}} {{#if hasDueDate }}- ${t(
                'system.email.dueDate',
              )}: {{dueDate}} {{/if }} {{/each}}`
            : `{{#each courses}} {{name}} - {{numberOfDays}} ${t(
                'generic.units.days',
              )} {{#if hasDueDate }}- ${t(
                'system.email.dueDate',
              )}: {{dueDate}} {{/if }} {{/each}}`,
        clipboardText: t('templates.legend.coursesAsText'),
      },
      {
        clipboardValue:
          (emailType as EmailType) === EmailType.COURSE_ENROLLMENT
            ? `<ul>{{#each courses}}<li><a href="{{url}}">{{name}}</a> {{#if hasDueDate }} - ${t(
                'system.email.dueDate',
              )}: {{dueDate}} {{/if }}</li>{{/each}}</ul>`
            : `<ul>{{#each courses}}<li><a href="{{url}}">{{name}}</a> - {{numberOfDays}} ${t(
                'generic.units.days',
              )} {{#if hasDueDate }} - ${t(
                'system.email.dueDate',
              )}: {{dueDate}} {{/if }}</li>{{/each}}</ul>`,
        clipboardText: t('templates.legend.coursesAsHtml'),
      },
      {
        clipboardValue: '{{dueDate}}',
        clipboardText: t('templates.legend.dueDate'),
      },
    ];
  }

  if ([EmailType.COURSE_AVAILABILITY].includes(emailType as EmailType)) {
    clipboardValues = [
      ...clipboardValues,
      {
        clipboardValue: '{{#each courses}} {{name}} {{/each}}',
        clipboardText: t('templates.legend.coursesAsText'),
      },
      {
        clipboardValue:
          '<ul>{{#each courses}}<li><a href="{{url}}">{{name}}</a></li>{{/each}}</ul>',
        clipboardText: t('templates.legend.coursesAsHtml'),
      },
    ];
  }

  if ([EmailType.SECURITY_CULTURE].includes(emailType as EmailType)) {
    clipboardValues = [
      ...clipboardValues,
      {
        clipboardValue: '{{.SecurityCultureURL}}',
        clipboardText: t('security.culture.surveyUrl'),
      },
    ];
  }

  return clipboardValues;
};
