import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { Actions, Subjects } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { SurveysDatatable } from '@/components/security-culture/datatables/SurveysDatatable';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const pathItems = (account: Account, t: TranslationFunctionType) => [
  {
    label: account?.name,
    url: !account?.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: account.isSystem
      ? t('security.culture.demo')
      : t('security.culture'),
    url: RedirectPaths[RedirectPathsEnum.SECURITY_CULTURE](),
    template: AppBreadCrumbTemplate,
  },
];

export const SecurityCulturePage: React.FC = () => {
  const currentAccount = useAppSelector(selectCurrentAccount);
  const navigate = useNavigate();
  const { can } = usePermission();
  const [showCreate, setShowCreate] = useState(!currentAccount?.isSystem);

  return (
    <FlexContainer direction="column" align="flex-start">
      <div className="w-full">
        <AppBreadCrumb model={pathItems(currentAccount as Account, t)} />
        <FlexContainer justify="space-between">
          <h1 className="mb-0">
            {currentAccount?.isSystem
              ? t('security.culture.demo')
              : t('security.culture')}
          </h1>
          {can(Actions.CREATE, Subjects.SECURITY_CULTURE) && showCreate && (
            <AppButton
              label={
                currentAccount?.isSystem
                  ? t('security.culture.new.demo.survey')
                  : t('security.culture.upload.new.survey')
              }
              severity="secondary"
              onClick={() => {
                navigate(
                  RedirectPaths[
                    RedirectPathsEnum.SECURITY_CULTURE_UPLOAD_SURVEY
                  ](),
                );
              }}
            />
          )}
        </FlexContainer>
        <div className="mt-5">
          <SurveysDatatable setShowCreate={setShowCreate} />
        </div>
      </div>
    </FlexContainer>
  );
};
