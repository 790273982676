export enum LoadingStatuses {
  IDLE = 'idle',
  LOADING = 'loading',
  ERROR = 'error',
}

export type LoadingStateType =
  | typeof LoadingStatuses.IDLE
  | typeof LoadingStatuses.LOADING
  | typeof LoadingStatuses.ERROR;
