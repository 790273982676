import { CourseStatusAttemptEnum } from '@/client/courses';
import { File } from '@/client/files';
import { isMobile } from '@/utils/helpers/ui.helper';
import { t } from 'i18next';
import 'pdfjs-dist/build/pdf.worker.min.mjs';
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import styled from 'styled-components';
import { AppButton } from '../buttons';
import { FlexContainer } from '../styled-ui';

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: sticky;
  z-index: 9;
  top: calc(-1 * var(--default-padding));
  background-color: var(--white-main);
  padding: var(--small-padding) 0;
  margin-top: calc(-1 * var(--default-padding));

  @media screen and (max-width: 1300px) {
    width: calc(100% + (2 * var(--default-padding)));
    padding: var(--default-padding) var(--medium-padding);
    margin-left: calc(-1 * var(--default-padding));
  }
`;

const StyledSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  width: 180px;
  right: var(--small-padding);
  bottom: var(--xsmall-padding);
  text-align: right;
  font-size: var(--xsmall-font-size);

  @media screen and (min-width: 1200px) {
    right: 150px;
    top: 50%;
    transform: translate(0, -50%);
  }

  @media screen and (min-width: 1300px) {
    right: 130px;
  }
`;

type PDFViewerProps = {
  file: File;
  documentWidth?: number;
  status?: CourseStatusAttemptEnum;
  currentPage?: number;
  onPageChange: (page: number) => void;
  onComplete: () => void;
};

const PDFViewer: React.FC<PDFViewerProps> = ({
  file,
  documentWidth,
  status,
  currentPage,
  onPageChange,
  onComplete,
}) => {
  const [isDocumentLoaded, setIsDocumentLoaded] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [scale, setScale] = useState<number>(isMobile() ? 1 : 0.75);
  const [rotate, setRotate] = useState<number>(0);
  const [isCompleted, setIsCompleted] = useState<boolean>(
    status === CourseStatusAttemptEnum.COMPLETED,
  );

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setPageNumber(currentPage || 1);
    setTotalPages(numPages);
    setIsDocumentLoaded(true);
  };

  useEffect(() => {
    document
      .querySelector('.content.without-sidebar')
      ?.scrollTo({ top: 0, behavior: 'smooth' });

    onPageChange(pageNumber);
  }, [pageNumber]);

  return (
    <>
      <StyledDiv>
        {/* Left part of the toolbar */}
        <FlexContainer width="180px" justify="flex-start">
          <AppButton
            severity="info"
            isDisabled={pageNumber === 1 || !isDocumentLoaded}
            size={isMobile() ? 'xs' : 'sm'}
            icon={isMobile() ? 'pi pi-chevron-left' : undefined}
            label={!isMobile() ? t('generic.previousPage') : undefined}
            onClick={() => setPageNumber(pageNumber - 1)}
          />
        </FlexContainer>

        {/* Central part of the toolbar */}
        <FlexContainer gap={!isMobile() ? 24 : 12} width="auto">
          <AppButton
            severity="info"
            isDisabled={!isDocumentLoaded}
            icon="pi pi-sync"
            size={isMobile() ? 'xs' : 'sm'}
            ariaLabel={t('generic.rotate')}
            onClick={() => setRotate(rotate + 90)}
          />

          {!isMobile() && (
            <p>
              {t('generic.pageNumOfTotal', {
                num: pageNumber,
                total: totalPages,
              })}
            </p>
          )}

          <AppButton
            severity="info"
            isDisabled={scale === 1 || !isDocumentLoaded}
            icon="pi pi-search-plus"
            size={isMobile() ? 'xs' : 'sm'}
            ariaLabel={t('generic.zoomIn')}
            onClick={() => setScale(scale + 0.25)}
          />
          <AppButton
            onClick={() => setScale(scale - 0.25)}
            isDisabled={scale <= 0.5 || !isDocumentLoaded}
            severity="info"
            icon="pi pi-search-minus"
            size={isMobile() ? 'xs' : 'sm'}
            ariaLabel={t('generic.zoomOut')}
          />
        </FlexContainer>

        {/* Right part of the toolbar */}
        <FlexContainer width="180px" justify="flex-end">
          {(pageNumber !== totalPages || !isDocumentLoaded) && (
            <AppButton
              severity="info"
              isDisabled={!isDocumentLoaded}
              size={isMobile() ? 'xs' : 'sm'}
              icon={isMobile() ? 'pi pi-chevron-right' : undefined}
              label={!isMobile() ? t('generic.nextPage') : undefined}
              onClick={() => setPageNumber(pageNumber + 1)}
            />
          )}

          {pageNumber === totalPages && isDocumentLoaded && (
            <div className="flex flex-column">
              <AppButton
                isDisabled={isCompleted}
                size={isMobile() ? 'xs' : 'sm'}
                icon={isMobile() ? 'pi pi-check' : undefined}
                label={
                  !isMobile()
                    ? !isCompleted
                      ? t('generic.complete')
                      : t('generic.completed')
                    : undefined
                }
                onClick={() => {
                  setIsCompleted(true);
                  onComplete();
                }}
              />
              {status !== CourseStatusAttemptEnum.COMPLETED && (
                <StyledSpan>{t('materials.completeAction.note')}</StyledSpan>
              )}
            </div>
          )}
        </FlexContainer>
      </StyledDiv>

      {/* PDF Document */}
      <FlexContainer
        height="100%"
        direction="column"
        justify="flex-start"
        className="relative"
      >
        <Document
          file={file.signedUrl}
          loading={t('generic.loading')}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(e: Error) => console.dir(e)}
          onSourceError={(e: Error) => console.dir(e)}
        >
          <Page
            pageNumber={pageNumber}
            scale={scale}
            rotate={rotate}
            loading={t('generic.loading')}
            width={documentWidth ?? 300}
            onLoadError={(e: Error) => console.dir(e)}
            onRenderError={(e: Error) => console.dir(e)}
          />
        </Document>
        {isMobile() && (
          <p>
            {t('generic.pageNumOfTotal', {
              num: pageNumber,
              total: totalPages,
            })}
          </p>
        )}
      </FlexContainer>
    </>
  );
};

export default PDFViewer;
