import { TranslationFunctionType } from '@/common/types';
import { array, object, string } from 'yup';

export const step1Schema = (t: TranslationFunctionType) =>
  object({
    id: string().required(t('error.requiredField')),
    type: string().required(t('error.requiredField')).oneOf(['branch']),
  });

export const step3Schema = () =>
  object({
    id: array(),
  });
