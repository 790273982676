import { MenuItem } from 'primereact/menuitem';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { default as styled } from 'styled-components';

const StyledPath = styled(NavLink)`
  text-decoration: none;
  font-size: var(--xsmall-font-size);
  line-height: var(--xsmall-line-height);
  color: var(--black-main);
  font-weight: 500;

  &:visited {
    font-size: var(--xsmall-font-size);
    line-height: var(--xsmall-line-height);
    color: var(--black-main);
  }

  &:hover {
    text-decoration: underline;
  }

  &.active {
    font-weight: 700;
    &:hover {
      text-decoration: none;
    }
  }
`;

export const AppBreadCrumbTemplate = (item: MenuItem) => (
  <StyledPath
    className={item.className}
    to={item.url ? item.url : '/'}
    state={item?.data?.state}
  >
    <span>{item.label}</span>
  </StyledPath>
);
