import { LanguagesEnum } from '@/api/enums';
import { handleAxiosError, notEqual } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { Group } from '@/client/groups';
import { Role } from '@/client/roles';
import { UserOrigin } from '@/client/users/types/origins.enum';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { UserForm } from '@/components/users/forms';
import { useGroupsPartialRequest, useRoles, useSaveUser } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUserState } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { queryStateConverter } from '@/utils/helpers';
import {
  HubspotProperty,
  hubspotTrack,
  HubspotValue,
} from '@/utils/hubspot/hubspot.helper';
import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import { MenuItem } from 'primereact/menuitem';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LoadingPage } from '../LoadingPage';

const getBreadcrumbs = (
  account: Account,
  t: TranslationFunctionType,
): MenuItem[] => [
  {
    label: account?.name,
    url: !account?.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('users'),
    url: RedirectPaths[RedirectPathsEnum.USERS](),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('user.new'),
    url: RedirectPaths[RedirectPathsEnum.USERS_CREATE](),
    template: AppBreadCrumbTemplate,
  },
];

export const CreateUserPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const account = useAppSelector(selectCurrentAccount);
  const { user } = useAppSelector(selectCurrentUserState);
  const { roles } = useRoles({
    take: 20,
  });

  const { groups, isLoading: isGroupsLoading } = useGroupsPartialRequest({
    accountId: account?.id,
    sort: ['name,asc'],
    filters: [notEqual('isForPhishing', true)],
  });

  const initialValues = {
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: undefined,
    role: undefined,
    active: true,
    eFrontSync: false,
    branch: undefined,
    language: account?.defaultLanguage ?? LanguagesEnum.DK,
    origin: undefined,
    assignedAccounts: undefined,
  };

  const createUser = useSaveUser();

  const handleUserAction = async (data: FormikValues) => {
    await createUser.create({
      firstName: data.firstName,
      lastName: data.lastName,
      username: data.username,
      active: data.active,
      eFrontSync: true,
      accountId: (account as Account)?.id,
      password: data.password,
      email: data.email,
      role: data.role.id,
      language: data.language,
      branch: data.branch ? data.branch.id : null,
      groups: data.groups ? data.groups.map(({ id }: Group) => id) : null,
      origin: data.isManual ? UserOrigin.MANUAL : undefined,
      assignedAccounts: data.assignedAccounts
        ? data.assignedAccounts.map(({ id }: Account) => id)
        : null,
    });

    navigate(RedirectPaths[RedirectPathsEnum.USERS]());

    if (user?.account?.freeTrialEndsAt) {
      hubspotTrack({ [HubspotProperty.NEW_USER_CREATED]: HubspotValue.YES });
    }
  };

  const handleSubmit = async (data: FormikValues) => {
    try {
      await handleUserAction(data);

      toast?.success(t('toast.success'), t('user.created'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  if (isGroupsLoading) {
    return <LoadingPage message={t('generic.loading')} />;
  }

  return (
    <>
      <AppBreadCrumb model={getBreadcrumbs(account as Account, t)} />
      <h1>{t('user.new')}</h1>
      <FlexContainer justify="flex-start">
        <UserForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          state={queryStateConverter(createUser)}
          isCreate
          roles={roles?.result as Role[]}
          groups={groups as Group[]}
        />
      </FlexContainer>
    </>
  );
};
