import { equal, handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import {
  SecurityCultureSurvey,
  SurveyFeedback,
  SurveyStatus,
} from '@/client/security-culture/types';
import {
  LAST_SELECTED_SC_SURVEY,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { SecurityCultureDashboardBarChart } from '@/components/security-culture/SecurityCultureDashboardBarChart';
import {
  useSurveyCategories,
  useSurveyFeedback,
} from '@/hooks/query/security-culture.hooks';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { DateFormats } from '@/system-settings/enums';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { DoughnutChart } from '@/ui/charts/doughnut-chart';
import { LineChart } from '@/ui/charts/line-chart';
import { SemiDoughnutChart } from '@/ui/charts/semi-doughnut-chart';
import { FlexContainer } from '@/ui/styled-ui';
import { ChartIcon } from '@/utils/helpers';
import { parseFloatingNumber } from '@/utils/helpers/ui.helper';
import { t } from 'i18next';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SecurityCulturePageActionHeader } from './SecurityCulturePageActionHeader';

const StyledIconWrapper = styled.span`
  display: flex;
  width: 32px;
  height: 32px;
  background: var(--white-main);
  cursor: default;
  justify-content: center;
  align-items: center;
  border-radius: var(--xxsmall-border-radius);
  transition: all 0.4s;

  &:hover {
    background-color: var(--white-main);
  }

  i {
    font-size: 18px;
  }
`;

const StyledCardContainer = styled(FlexContainer)`
  background-color: var(--beige-main);
  padding: var(--default-padding) var(--big-padding);
  border-radius: var(--medium-border-radius);
  margin: 0;
  overflow: hidden;

  &.stretch-children {
    .children {
      overflow-y: hidden;
      overflow-x: auto;
      padding-bottom: var(--medium-padding);

      > div {
        flex: 1;

        > div {
          width: 100%;
        }
      }
    }
  }
`;

const StyledContainer = styled(FlexContainer)`
  @media screen and (max-width: 1450px) {
    & {
      flex-wrap: wrap;

      > div {
        flex: none;
      }
    }
  }
`;

const StyledValueSpan = styled.span`
  font-size: var(--heading-xlarge-font-size);
  font-weight: 700;
`;

const StyledValueChangeSpan = styled.span<{ difference: number }>`
  font-size: var(--body-font-size);
  color: var(--green-main);

  color: ${({ difference }) =>
    difference > 0
      ? 'var(--green-main)'
      : difference < 0
      ? 'var(--red-main)'
      : 'black'};
`;

const StyledTableDiv = styled.div`
  width: 100%;

  .table-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid var(--gray-dark);
    padding: var(--xsmall-padding) 0;
    gap: var(--medium-padding);

    &:last-child {
      border-bottom: none;
    }

    .table-text {
      min-width: max-content;
      font-weight: 500;
      font-size: var(--small-font-size);
      line-height: var(--small-line-height);
    }

    .table-count {
      text-align: end;
      font-weight: 700;
      font-size: var(--body-font-size);
      line-height: var(--body-line-height);
    }
  }
`;

const StyledFeedbackContainer = styled(FlexContainer)`
  width: 100%;
  border-bottom: 1px solid var(--gray-dark);
  padding: var(--xsmall-padding) 0;
  gap: var(--medium-padding);

  &:last-child {
    border-bottom: none;
  }

  svg {
    flex-shrink: 0;
  }
`;

const pathItems = (account: Account) => [
  {
    label: account?.name,
    url: !account?.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
];

export const SecurityCultureSurveyPage: React.FC = () => {
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const queryParams = new URLSearchParams(location.search);
  const [surveyIdToFetch, setSurveyIdToFetch] = useState(
    localStorage.getItem(`${LAST_SELECTED_SC_SURVEY}-${currentAccount?.id}`) ||
      queryParams.get('surveyId') ||
      undefined,
  );

  const [scoreDifference, setScoreDifference] = useState(0);
  const [survey, setVisibleSurvey] = useState<SecurityCultureSurvey>();
  const [allSurveys, setAllSurveys] = useState<SecurityCultureSurvey[]>([]);
  const [overTimeParsed, setOverTimeParsed] = useState<{
    values: number[];
    labels: string[];
  }>({ values: [], labels: [] });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showEmptyScreen, setShowEmptyScreen] = useState<boolean>(false);

  const {
    categories,
    isLoading: IsCategoriesLoading,
    refetch: refetchCategories,
  } = useSurveyCategories({
    surveyId: survey?.id,
    accountId: currentAccount?.id,
  });
  const {
    feedback,
    isLoading: IsFeedbackLoading,
    refetch: refetchFeedback,
  } = useSurveyFeedback({
    take: 3,
    accountId: currentAccount?.id,
    surveyId: survey?.id,
    sort: [['priority', 'asc'].join(',')],
  });

  useEffect(() => {
    const scoreDiff = Number(survey?.score) - Number(survey?.previousScore);
    setScoreDifference(Number(scoreDiff));

    const allSurveysReversed = allSurveys?.slice()?.reverse();
    const overTimeParsed = allSurveysReversed.reduce(
      (object, survey) => {
        if (survey.score !== null && survey.score !== undefined) {
          object.values.push(parseFloatingNumber(survey.score));
          object.labels.push(
            moment(survey.date).format(DateFormats.FULL_MONTH_YEAR),
          );
        }

        return object;
      },
      { values: [] as number[], labels: [] as string[] },
    );

    if (survey?.isDemo) {
      // Hardcode over time chart data for demo surveys
      overTimeParsed.values.unshift(...[50, 60]);
      overTimeParsed.labels.unshift(
        ...[
          moment(survey.date)
            .add(-2, 'month')
            .format(DateFormats.FULL_MONTH_YEAR),
          moment(survey.date)
            .add(-1, 'month')
            .format(DateFormats.FULL_MONTH_YEAR),
        ],
      );
    }

    setOverTimeParsed(overTimeParsed);

    refetchCategories();
    refetchFeedback();
  }, [survey]);

  const fetchInitialSurveyId = async () => {
    try {
      setIsLoading(true);
      const response = await client.securityCulture.getSurveys(
        currentAccount?.id,
        {
          sort: ['date,desc'],
          filters: currentUser?.account.isSystem
            ? []
            : [equal('status', SurveyStatus.PUBLISHED)],
          take: 1,
        },
      );

      if (response.result[0]) {
        setSurveyIdToFetch(response.result[0].id);
      } else {
        setShowEmptyScreen(true);
      }
    } catch (e) {
      handleAxiosError(e, toast);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!surveyIdToFetch) {
      fetchInitialSurveyId();
    }
  }, []);

  return (
    <FlexContainer
      direction="column"
      align="flex-start"
      justify="flex-start"
      height="100%"
    >
      <div className="w-full pb-4">
        <AppBreadCrumb model={pathItems(currentAccount as Account)} />
        <FlexContainer justify="space-between">
          <h1 className="mb-0">{t('security.culture')}</h1>
        </FlexContainer>
        <FlexContainer direction="column" gap={24}>
          <SecurityCulturePageActionHeader
            surveyIdToFetch={surveyIdToFetch}
            setIsLoading={setIsLoading}
            setVisibleSurvey={(survey: SecurityCultureSurvey) => {
              if (survey.id) {
                navigate(`?surveyId=${survey.id}`, { replace: true });
              }
              setVisibleSurvey(survey);
            }}
            setAllSurveys={setAllSurveys}
            fetchInitialSurveyId={fetchInitialSurveyId}
          />

          {survey &&
            !IsCategoriesLoading &&
            !IsFeedbackLoading &&
            !isLoading && (
              <>
                <StyledContainer
                  align="stretch"
                  justify="space-between"
                  gap={24}
                >
                  <StyledCardContainer
                    direction="column"
                    gap={24}
                    justify="space-between"
                  >
                    <FlexContainer justify="space-between" gap={24}>
                      <span className="uppercase">
                        {t('security.culture.score')}
                      </span>
                      <StyledIconWrapper id="security-culture-score">
                        <i className="pi pi-info-circle" />
                      </StyledIconWrapper>
                      <Tooltip
                        target="#security-culture-score"
                        position="bottom"
                        content={t('security.culture.score.tooltip')}
                      />
                    </FlexContainer>
                    <FlexContainer
                      align="flex-start"
                      justify="space-between"
                      gap={24}
                    >
                      <FlexContainer direction="column" align="flex-start">
                        <StyledValueSpan>
                          {parseFloatingNumber(survey?.score)}
                        </StyledValueSpan>
                        {survey?.score !== null &&
                          survey?.previousScore !== null && (
                            <StyledValueChangeSpan difference={scoreDifference}>
                              {scoreDifference > 0 ? '+' : '-'}{' '}
                              {parseFloatingNumber(Math.abs(scoreDifference))}
                            </StyledValueChangeSpan>
                          )}
                      </FlexContainer>

                      <SemiDoughnutChart
                        id="sc-score"
                        value={parseFloatingNumber(survey?.score)}
                        controlValue={parseFloatingNumber(
                          survey?.benchmarkScore,
                        )}
                        valueTooltipSuffix={t('security.culture.target.group')}
                        controlValueTooltipSuffix={t(
                          'security.culture.other.organisations',
                        )}
                      />
                    </FlexContainer>
                  </StyledCardContainer>
                  <StyledCardContainer direction="column" gap={24}>
                    <FlexContainer justify="space-between" gap={24}>
                      <span className="uppercase">
                        {t('security.culture.overTime')}
                      </span>
                      <StyledIconWrapper id="security-culture-over-time">
                        <i className="pi pi-info-circle" />
                      </StyledIconWrapper>
                      <Tooltip
                        target="#security-culture-over-time"
                        position="bottom"
                        content={t('security.culture.overTime.tooltip')}
                      />
                    </FlexContainer>
                    <LineChart
                      id="sc-over-time"
                      values={overTimeParsed.values}
                      labels={overTimeParsed.labels}
                    />
                  </StyledCardContainer>
                </StyledContainer>

                <StyledCardContainer
                  direction="column"
                  gap={24}
                  className="stretch-children"
                >
                  <FlexContainer justify="space-between" gap={24}>
                    <span className="uppercase">
                      {t('security.culture.score.bars')}
                    </span>
                    <StyledIconWrapper id="security-culture-score-bars">
                      <i className="pi pi-info-circle" />
                    </StyledIconWrapper>
                    <Tooltip
                      target="#security-culture-score-bars"
                      position="bottom"
                      content={t('security.culture.score.bars.tooltip')}
                    />
                  </FlexContainer>
                  <FlexContainer
                    direction="row"
                    justify="flex-start"
                    className="children"
                  >
                    {categories?.result?.map((item: any) => (
                      <SecurityCultureDashboardBarChart
                        item={item}
                        surveyId={survey.id}
                        key={item.id}
                        maxValue={
                          categories?.result?.reduce(
                            (max, item) =>
                              item.result > max ? item.result : max,
                            0,
                          ) + 1
                        }
                      />
                    ))}
                    {!categories?.count && (
                      <p className="mx-auto">{t('security.culture.no.data')}</p>
                    )}
                  </FlexContainer>
                </StyledCardContainer>

                <StyledContainer gap={24} align="stretch">
                  <StyledCardContainer
                    direction="column"
                    gap={24}
                    justify="flex-start"
                    flex="0.3"
                  >
                    <FlexContainer justify="space-between" gap={24}>
                      <span className="uppercase">
                        {t('security.culture.participation')}
                      </span>
                      <StyledIconWrapper id="security-culture-participation">
                        <i className="pi pi-info-circle" />
                      </StyledIconWrapper>
                      <Tooltip
                        target="#security-culture-participation"
                        position="bottom"
                        content={t('security.culture.participation.tooltip')}
                      />
                    </FlexContainer>
                    <FlexContainer direction="column" className="mt-5" gap={32}>
                      <DoughnutChart
                        allCount={survey.totalParticipants || 0}
                        singleCount={survey.participated || 0}
                        id="sc-participation"
                        cutout="70%"
                        width="200px"
                        isPercentCount
                        isDashboard
                      />
                      <StyledTableDiv>
                        <div className="table-row">
                          <span className="table-text">
                            {t('security.culture.participated.employees')}
                          </span>
                          <span className="table-count">
                            {survey.participated || 0}
                          </span>
                        </div>
                        <div className="table-row">
                          <span className="table-text">
                            {t('security.culture.all.employees')}
                          </span>
                          <span className="table-count">
                            {survey.totalParticipants || 0}
                          </span>
                        </div>
                      </StyledTableDiv>
                    </FlexContainer>
                  </StyledCardContainer>
                  <StyledCardContainer
                    direction="column"
                    gap={24}
                    justify="flex-start"
                    flex="0.7"
                  >
                    <FlexContainer justify="space-between" gap={24}>
                      <span className="uppercase">
                        {t('security.culture.feedback.from.employees')}
                      </span>
                      <StyledIconWrapper id="security-culture-feedback">
                        <i className="pi pi-info-circle" />
                      </StyledIconWrapper>
                      <Tooltip
                        target="#security-culture-feedback"
                        position="bottom"
                        content={t(
                          'security.culture.feedback.from.employees.tooltip',
                        )}
                      />
                    </FlexContainer>
                    <FlexContainer
                      direction="column"
                      className="children"
                      height="100%"
                    >
                      {feedback?.result?.map((item: SurveyFeedback) => (
                        <StyledFeedbackContainer
                          justify="flex-start"
                          key={item.id}
                        >
                          <ChartIcon />
                          <p>{item.description}</p>
                        </StyledFeedbackContainer>
                      ))}
                      {!feedback?.count && (
                        <p className="m-auto">
                          {t('security.culture.no.data')}
                        </p>
                      )}
                    </FlexContainer>
                    {!!feedback?.count && (
                      <FlexContainer
                        direction="row"
                        justify="flex-end"
                        className="mt-auto"
                      >
                        <AppButton
                          type="outlined"
                          label={t('button.view.all')}
                          onClick={() =>
                            navigate(
                              RedirectPaths[
                                RedirectPathsEnum.SECURITY_CULTURE_FEEDBACK
                              ](survey.id as string),
                            )
                          }
                        />
                      </FlexContainer>
                    )}
                  </StyledCardContainer>
                </StyledContainer>
              </>
            )}
        </FlexContainer>
      </div>

      {(IsCategoriesLoading || IsFeedbackLoading || isLoading || !survey) &&
        !showEmptyScreen && (
          <FlexContainer direction="column" className="m-auto">
            <ProgressSpinner />
            <h3>{t('security.culture.survey.loading')}</h3>
          </FlexContainer>
        )}

      {!isLoading &&
        !IsCategoriesLoading &&
        !IsFeedbackLoading &&
        !allSurveys?.length &&
        showEmptyScreen && (
          <FlexContainer direction="column" className="m-auto">
            <h3>{t('security.culture.no.surveys')}</h3>
          </FlexContainer>
        )}
    </FlexContainer>
  );
};
