import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useQueryParams = (
  transformers?: Record<
    string,
    { encode?: (value: any) => any; decode?: (value: any) => any }
  >,
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params: Record<string, any> = Object.fromEntries(searchParams);

  return {
    params: useMemo(
      () =>
        Object.keys(params).reduce((a, k) => {
          const transformer =
            transformers && k in transformers ? transformers[k] : null;

          if (transformer && transformer.decode) {
            return { ...a, [k]: transformer.decode(params[k]) };
          }

          return { ...a, [k]: params[k] };
        }, {}),
      [JSON.stringify(params)],
    ) as Record<string, any>,
    setSearchParams: (newParams: Record<string, any>) => {
      const transformed = Object.keys(newParams).reduce((a, k) => {
        const transformer =
          transformers && k in transformers ? transformers[k] : null;

        return {
          ...a,
          [k]:
            transformer && transformer.encode
              ? transformer.encode(newParams[k])
              : newParams[k],
        };
      }, {});

      setSearchParams(
        {
          ...params,
          ...transformed,
        },
        {
          replace: true,
        },
      );
    },
  };
};
