import { client } from '@/client';
import {
  AttemptsReport,
  CourseCompletionReportsType,
  OverviewReportsType,
  userAwarenessReportsType,
} from '@/client/reports';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

const queryKeyCourseCompletion = 'courseCompletionReports';
const queryKeyUserAwareness = 'userAwarenessReports';
const queryKeyOverviewReports = 'overviewReports';

export const useCourseCompletionReports = (
  params: UseQueryOptions & {
    accountId?: string;
  },
): UseQueryResult<CourseCompletionReportsType> & {
  courseCompletionReports: CourseCompletionReportsType | undefined;
} => {
  const {
    accountId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKeyCourseCompletion, accountId],
    () =>
      accountId
        ? client.reports.getCourseCompletionReports(accountId)
        : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<CourseCompletionReportsType>),
    },
  );
  return {
    courseCompletionReports: data,
    ...(rest as UseQueryResult<CourseCompletionReportsType>),
  };
};

export const useUserAwarenessReports = (
  params: UseQueryOptions & {
    accountId?: string;
  },
): UseQueryResult<userAwarenessReportsType> & {
  userAwarenessReports: userAwarenessReportsType | undefined;
} => {
  const {
    accountId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKeyUserAwareness, accountId],
    () =>
      accountId ? client.reports.getUserAwarenessReports(accountId) : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<userAwarenessReportsType>),
    },
  );

  return {
    userAwarenessReports: data,
    ...(rest as UseQueryResult<userAwarenessReportsType>),
  };
};

export const useOverviewReports = (
  params: UseQueryOptions & {
    accountId?: string;
  },
): UseQueryResult<OverviewReportsType> & {
  overviewReports: OverviewReportsType | undefined;
} => {
  const {
    accountId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKeyOverviewReports, accountId],
    () =>
      accountId ? client.reports.getOverviewReports(accountId) : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<OverviewReportsType>),
    },
  );

  return {
    overviewReports: data,
    ...(rest as UseQueryResult<OverviewReportsType>),
  };
};

export const useCourseAttemptsReports = (
  params: UseQueryOptions & {
    courseId?: string;
  },
): UseQueryResult<AttemptsReport[]> & {
  attemptStats: AttemptsReport[] | undefined;
} => {
  const {
    courseId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    ['courseAttemptsReports', courseId],
    () =>
      courseId ? client.reports.getCourseAttemptsReport(courseId) : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<AttemptsReport[]>),
    },
  );

  return {
    attemptStats: data,
    ...(rest as UseQueryResult<AttemptsReport[]>),
  };
};
