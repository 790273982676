import {
  CoursePlanner,
  PlannerTemplateType,
  PlannerType,
} from '@/client/planner/types';
import { Actions, Subjects } from '@/client/users';
import { TargetEntities } from '@/common/types/targets.type';
import { CreatePlannerPrompt } from '@/components/planner/modals/CreatePlannerPrompt';
import { CreateUpdatePlannerModal } from '@/components/planner/modals/CreateUpdatePlannerModal';
import { PlannerTargets } from '@/components/planner/PlannerTargets';
import { PlannerTimeline } from '@/components/planner/PlannerTimeline';
import { usePlanners } from '@/hooks/query/planner.hooks';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import AppTag from '@/ui/tag/AppTag';
import { branchAdminCheck } from '@/utils/helpers';
import { t } from 'i18next';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AccountEnrollmentActionHeader } from './AccountEnrollmentActionHeader';
import { EnrollmentTabs } from './EnrollmentTabs';

const StyledDiv = styled.div`
  > div {
    padding: var(--default-padding);
    padding-bottom: var(--medium-padding);
    background: var(--beige-main);
    margin: 0 calc(var(--default-padding) * -1);
    gap: 7.5px;
    display: flex;
    flex-direction: column;
    margin-bottom: var(--default-padding);

    &:first-child {
      border-top: 1px solid var(--gray-dark);
    }

    &:last-child {
      border-bottom: 1px solid var(--gray-dark);
    }
  }
`;

const StyledContainer = styled(FlexContainer)`
  padding-bottom: var(--default-padding);

  label {
    color: var(--gray-darker);
    font-size: var(--small-font-size);
  }

  span {
    color: var(--black-main);
    font-size: var(--small-font-size);
    position: relative;

    &:before {
      position: absolute;
      color: var(--gray-darker);
      content: '•';
      left: -10px;
    }
  }
`;

const StyledAppButton = styled(AppButton)`
  &.p-button.p-component {
    height: 28px;
    padding: 2px var(--small-padding);
    background-color: var(--white-main);
    border-color: transparent;
    color: var(--black-main);

    span:before {
      display: none;
    }

    &:hover {
      color: var(--black-main) !important;
      border-color: var(--red-pale) !important;
      background-color: var(--red-pale) !important;
    }
  }
`;

const StyledP = styled.p`
  font-size: var(--small-font-size);
`;

export const AccountTrainingPlannerPage: React.FC = () => {
  const account = useAppSelector(selectCurrentAccount);
  const user = useAppSelector(selectCurrentUser);
  const [isPlannerModalVisible, setIsPlannerModalVisible] = useState(false);
  const [plannerType, setPlannerType] = useState<PlannerType | undefined>(
    undefined,
  );
  const [plannerTemplateType, setPlannerTemplateType] = useState<
    PlannerTemplateType | undefined
  >(undefined);
  const [isCreatePromptVisible, setIsCreatePromptVisible] = useState(false);
  const [planForEdit, setPlanForEdit] = useState<CoursePlanner>();
  const [visiblePlans, setVisiblePlans] = useState<CoursePlanner[]>();
  const { skip, take, setSkip } = useTable();

  const { planners, isLoading, refetch } = usePlanners(
    {
      skip,
      take,
      sort: ['created,asc'],
    },
    account?.id,
  );
  const { can } = usePermission();

  useEffect(() => {
    setVisiblePlans(planners?.result || []);
  }, [planners]);

  const isBranchAdmin = branchAdminCheck(user, account);

  const hasExcludes = (excludes: TargetEntities) => {
    return (
      (Array.isArray(excludes.branches) && excludes.branches.length > 0) ||
      (Array.isArray(excludes.groups) && excludes.groups.length > 0) ||
      (Array.isArray(excludes.users) && excludes.users.length > 0)
    );
  };

  const onCreatePlannerPromptHide = () => {
    setIsCreatePromptVisible(false);
    setPlannerTemplateType(undefined);
    setPlannerType(undefined);
  };

  return (
    <>
      {isLoading && (
        <FlexContainer direction="column" height="100%" className="mt-5">
          <ProgressSpinner />
          <h3>{t('course.content.loading')}</h3>
        </FlexContainer>
      )}

      {account && !isLoading && (
        <div className="flex flex-column h-full">
          <AccountEnrollmentActionHeader
            account={account}
            onTriggerRefetch={refetch}
            isTrainingPlanner
            setIsCreatePromptVisible={setIsCreatePromptVisible}
          />
          <EnrollmentTabs />

          {visiblePlans?.length ? (
            <StyledDiv>
              {visiblePlans?.map((plan: CoursePlanner, index: number) => (
                <div key={index}>
                  <FlexContainer wrap="wrap" gap={16} justify="space-between">
                    <h3 className="m-0">{plan.name}</h3>
                    <div>
                      {(can(Actions.UPDATE, Subjects.COURSE_PLANNER) &&
                        !isBranchAdmin) ||
                      (isBranchAdmin && plan.editable) ? (
                        <FlexContainer gap={16}>
                          {plan.type === PlannerType.TRAINING_PLAN && (
                            <FlexContainer gap={12} wrap="nowrap">
                              <StyledP>
                                {t(
                                  t('planner.upcomingCourses', {
                                    platformName:
                                      account?.platformSettings?.name,
                                  }),
                                )}
                              </StyledP>
                              <AppTag
                                value={
                                  plan.autoEnroll
                                    ? t('generic.toggle.on')
                                    : t('generic.toggle.off')
                                }
                                type={plan.autoEnroll ? 'primary' : 'secondary'}
                              />
                            </FlexContainer>
                          )}
                          <StyledAppButton
                            label={t('generic.edit')}
                            onClick={() => {
                              setPlanForEdit(plan);
                              setPlannerType(plan.type);
                              setIsPlannerModalVisible(true);
                            }}
                          />
                        </FlexContainer>
                      ) : null}
                    </div>
                  </FlexContainer>

                  <StyledContainer
                    gap={16}
                    justify="flex-start"
                    width="auto"
                    className="pb-0"
                  >
                    <Tooltip
                      position="top"
                      target={`.${CSS.escape(plan.id)}`}
                      content={t('planned.selection.parentBranches.tooltip')}
                    />
                    <StyledContainer wrap="wrap" gap={16} justify="flex-start">
                      <label>{t('courses.enrollTo')}</label>
                      <PlannerTargets
                        targets={plan.targets}
                        isInclude
                        className={plan.id}
                      />

                      {!!hasExcludes(plan.excludes) && (
                        <>
                          <label>{t('courses.excluding')}</label>
                          <PlannerTargets targets={plan.excludes} />
                        </>
                      )}
                    </StyledContainer>
                  </StyledContainer>

                  <PlannerTimeline plan={plan} />
                </div>
              ))}
            </StyledDiv>
          ) : (
            <FlexContainer direction="column" flex="1" height="100%">
              <h3>{t('planner.noItems')}</h3>
            </FlexContainer>
          )}

          {!!planners?.count && planners?.count > 10 && (
            <Paginator
              className="justify-content-center pb-3 mr-auto"
              rows={take}
              first={skip}
              totalRecords={planners?.count}
              pageLinkSize={3}
              onPageChange={(event: PaginatorPageChangeEvent) => {
                setSkip(event.first);
              }}
              template="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
              currentPageReportTemplate={`{first} ${t('filter.to')} {last} ${t(
                'filter.of',
              )} {totalRecords}`}
            />
          )}

          <CreateUpdatePlannerModal
            plan={planForEdit}
            refetch={refetch}
            visible={isPlannerModalVisible}
            onHide={() => {
              setPlanForEdit(undefined);
              setIsPlannerModalVisible(false);
              onCreatePlannerPromptHide();
            }}
            plannerType={plannerType}
            plannerTemplateType={plannerTemplateType}
          />

          <CreatePlannerPrompt
            visible={isCreatePromptVisible}
            onHide={onCreatePlannerPromptHide}
            setPlannerType={setPlannerType}
            plannerType={plannerType}
            setPlannerTemplateType={setPlannerTemplateType}
            setIsPlannerModalVisible={setIsPlannerModalVisible}
          />
        </div>
      )}
    </>
  );
};
