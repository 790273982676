import { FiltersType, ListingRequest } from '@/api/types';
import { client } from '@/client';
import { MessageResponseModel } from '@/client/models';
import {
  AddAccountsToAResellerRequest,
  ListResellersAccountsResponse,
  ListResellersResponse,
  RemoveAccountsToAResellerRequest,
  Reseller,
  SaveResellerRequest,
  SystemReseller,
  UpdateResellerRequest,
  UpdateSystemResellerRequest,
} from '@/client/resellers';
import { partialRequests } from '@/utils/helpers';
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import {
  AddAccountsToAResellerMutation,
  DeleteResellerMutation,
  GenericForbiddenApiError,
  SaveResellerMutation,
  UpdateSystemResellerMutation,
} from './types';

const queryKey = 'resellers';
const singleQueryKey = 'reseller';

export const useResellers = (
  params: UseQueryOptions & {
    token?: string;
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  } = {},
): UseQueryResult<ListResellersResponse> & {
  resellers: ListResellersResponse | undefined;
} => {
  const {
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;
  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(sort), JSON.stringify(filters)],
    () => client.resellers.getResellers(params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    resellers: data,
    ...(rest as UseQueryResult<ListResellersResponse>),
  };
};

export const useReseller = (
  params: UseQueryOptions & {
    resellerId?: string;
  },
): UseQueryResult<Reseller> & {
  reseller: Reseller | undefined;
} => {
  const {
    resellerId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [singleQueryKey, resellerId],
    () => (resellerId ? client.resellers.getReseller(resellerId) : undefined),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    reseller: data,
    ...(rest as UseQueryResult<Reseller>),
  };
};

export const useSystemReseller = (
  params: UseQueryOptions,
): UseQueryResult<SystemReseller> & {
  systemReseller: SystemReseller | undefined;
} => {
  const {
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    ['systemReseller'],
    () => client.resellers.getSystemReseller(),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    systemReseller: data,
    ...(rest as UseQueryResult<SystemReseller>),
  };
};

export const useSaveReseller = (): {
  create: (payload: SaveResellerRequest) => Promise<Reseller>;
} & SaveResellerMutation => {
  const { mutateAsync, ...rest } = useMutation<
    Reseller,
    GenericForbiddenApiError,
    SaveResellerRequest
  >('addReseller', (payload: SaveResellerRequest) =>
    client.resellers.saveReseller(payload),
  );

  return { create: mutateAsync, ...(rest as any) };
};

export const useUpdateReseller = (): {
  update: (payload: UpdateResellerRequest) => Promise<Reseller>;
} & SaveResellerMutation => {
  const { mutateAsync, ...rest } = useMutation<
    Reseller,
    GenericForbiddenApiError,
    UpdateResellerRequest
  >('updateReseller', (payload: UpdateResellerRequest) =>
    client.resellers.updateReseller(payload),
  );

  return { update: mutateAsync, ...(rest as any) };
};

export const useUpdateSystemReseller = (): {
  update: (payload: UpdateSystemResellerRequest) => Promise<SystemReseller>;
} & UpdateSystemResellerMutation => {
  const { mutateAsync, ...rest } = useMutation<
    SystemReseller,
    GenericForbiddenApiError,
    UpdateSystemResellerRequest
  >('updateSystemReseller', (payload: UpdateSystemResellerRequest) =>
    client.resellers.updateSystemReseller(payload),
  );

  return { update: mutateAsync, ...(rest as any) };
};

export const useDeleteReseller = (): {
  delete: (resellerId: string) => Promise<MessageResponseModel>;
} & DeleteResellerMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    string
  >('deleteReseller', (resellerId: string) =>
    client.resellers.deleteReseller(resellerId),
  );

  return { delete: mutateAsync, ...(rest as any) };
};

export const useAccountsToAResellers = (
  params: UseQueryOptions & {
    token?: string;
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  } & { resellerId?: string },
): UseQueryResult<ListResellersAccountsResponse> & {
  accounts: ListResellersAccountsResponse | undefined;
} => {
  const {
    resellerId,
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;
  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(sort), JSON.stringify(filters)],
    () =>
      resellerId
        ? client.resellers.getResellersAccounts(params, resellerId)
        : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    accounts: data,
    ...(rest as UseQueryResult<ListResellersAccountsResponse>),
  };
};

export const useSaveAccountsToAReseller = (): {
  add: (
    payload: AddAccountsToAResellerRequest,
  ) => Promise<MessageResponseModel>;
} & AddAccountsToAResellerMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    AddAccountsToAResellerRequest
  >('addAccountsToAReseller', (payload: AddAccountsToAResellerRequest) =>
    client.resellers.saveAccountsToAReseller({
      resellerId: payload.resellerId,
      accounts: payload.accounts,
    }),
  );

  return { add: mutateAsync, ...(rest as any) };
};

export const useRemoveAccountsToAReseller = (): {
  remove: (
    payload: RemoveAccountsToAResellerRequest,
  ) => Promise<MessageResponseModel>;
} & AddAccountsToAResellerMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    RemoveAccountsToAResellerRequest
  >('removeAccountsToAReseller', (payload: RemoveAccountsToAResellerRequest) =>
    client.resellers.removeAccountsToAReseller({
      resellerId: payload.resellerId,
      accountId: payload.accountId,
    }),
  );

  return { remove: mutateAsync, ...(rest as any) };
};
