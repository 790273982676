import {
  CoursePlanner,
  PlannerCourseSchedule,
  PlannerCRUDCourse,
  PlannerType,
} from '@/client/planner/types';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { updateTimeline } from '@/utils/planner';
import { t } from 'i18next';
import { ScrollPanel } from 'primereact/scrollpanel';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { PlannerImageCard } from './PlannerImageCard';

const StyledScrollPanel = styled(ScrollPanel)`
  width: 100%;
  height: 190px;
`;

const StyledDiv = styled.div`
  margin: 0 var(--xsmall-padding);

  &.date-header {
    position: relative;

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 1px;
      left: -17px;
      height: 100%;
      background-color: var(--gray-dark);
      vertical-align: middle;
    }
  }
`;

const StyledItemDiv = styled.div`
  display: flex;

  &.red {
    .date-span {
      color: var(--red-main);
    }

    .date-header {
      &:before {
        background-color: var(--red-main);
      }
    }
  }
`;

const StyledDateDiv = styled.div`
  display: flex;
  width: var(--xsmall-line-height);

  > span {
    font-size: var(--xsmall-font-size);
    width: 100%;
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    text-align: center;
    color: var(--gray-darker);
    margin: auto;
    background-color: var(--beige-main);
    z-index: 99;
    padding: 6px 0;
  }
`;

const StyledPlaceholderWrapper = styled.div`
  display: flex;
  overflow: hidden;
  padding-bottom: var(--default-padding);

  &.red {
    .date-span {
      color: var(--red-main);
    }

    .date-header {
      &:before {
        background-color: var(--red-main);
      }
    }
  }
`;

const StyledPlaceholderDiv = styled.div`
  width: 160px;
  height: 170px;
  border: 2px dashed var(--gray-dark);
  border-radius: var(--xsmall-border-radius);
`;

type PlannerTimelineProps = {
  plan: CoursePlanner;
};

export const PlannerTimeline: React.FC<PlannerTimelineProps> = ({ plan }) => {
  const scrollPanelRef = useRef<any>(null);

  const [timelineItems, setTimelineItems] = useState<PlannerCRUDCourse[]>([]);
  const [isOnboardingPlan, setIsOnboardingPlan] = useState(false);
  const [arePastCoursesVisible, setArePastCoursesVisible] = useState(false);

  useEffect(() => {
    const courses =
      plan.type === PlannerType.TRAINING_PLAN
        ? plan.staticCourseSchedules
        : plan.dynamicCourseSchedules;

    updateTimeline(
      courses.map((item: PlannerCourseSchedule) => ({
        ...item,
        ...item.course,
      })),
      setTimelineItems,
      plan.type === PlannerType.ONBOARDING_PLAN,
    );

    setIsOnboardingPlan(plan.type === PlannerType.ONBOARDING_PLAN);
  }, [plan]);

  const Placeholder = (isRed = false) => (
    <StyledPlaceholderWrapper className={isRed ? 'red' : ''}>
      <div className="flex">
        <StyledDateDiv>
          <span className="date-span">
            {isOnboardingPlan ? `0 ${t('days')}` : t('now')}
          </span>
        </StyledDateDiv>
        <StyledDiv className="date-header relative">
          <StyledPlaceholderDiv />
        </StyledDiv>
      </div>
    </StyledPlaceholderWrapper>
  );

  return (
    <div>
      {!!timelineItems?.length && (
        <StyledScrollPanel ref={scrollPanelRef}>
          <div className="flex">
            {!arePastCoursesVisible &&
              timelineItems.some(({ isPastDate }) => isPastDate) && (
                <FlexContainer className="mr-3" height="170px" width="auto">
                  <AppButton
                    size="xs"
                    icon="pi pi-history"
                    onClick={() => setArePastCoursesVisible(true)}
                    tooltip={t('planner.see.past.courses')}
                  />
                </FlexContainer>
              )}
            {timelineItems.map((item, index) => {
              return (
                (!item.isPastDate ||
                  (item.isPastDate && arePastCoursesVisible)) && (
                  <StyledItemDiv
                    key={index}
                    className={
                      item.firstNonPastDate && !isOnboardingPlan ? 'red' : ''
                    }
                  >
                    {item.isDateHeader && (
                      <StyledDateDiv>
                        <span className="date-span">
                          {item.firstNonPastDate && !isOnboardingPlan
                            ? t('now')
                            : item.dateKey}
                        </span>
                      </StyledDateDiv>
                    )}
                    <StyledDiv
                      className={
                        item.isDateHeader ? 'date-header relative' : 'relative'
                      }
                    >
                      <PlannerImageCard
                        item={item}
                        isOnboardingPlan={isOnboardingPlan}
                      />
                    </StyledDiv>
                  </StyledItemDiv>
                )
              );
            })}
            {timelineItems?.every(({ isPastDate }) => isPastDate) &&
              !isOnboardingPlan &&
              Placeholder(true)}
          </div>
        </StyledScrollPanel>
      )}

      {!timelineItems?.length && Placeholder()}
    </div>
  );
};
