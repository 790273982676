import { PlannerCRUDCourse, PlannerType } from '@/client/planner/types';
import { DateFormats } from '@/system-settings/enums';
import moment from 'moment';
import {
  convertPeriodStringToDays,
  parsePeriodStringToUserReadable,
} from '../helpers';

export const getDateKey = (
  item: PlannerCRUDCourse,
  isOnboardingPlan: boolean,
): string => {
  if (isOnboardingPlan) {
    return parsePeriodStringToUserReadable(item.periodValue, item.periodUnit);
  } else {
    const itemDate = moment(item?.enrollDate)?.startOf('day') || moment();
    return itemDate.format(DateFormats.DATEONLY_SHORT);
  }
};

export const getIsFutureOrToday = (item: PlannerCRUDCourse): boolean => {
  const itemDate = moment(item?.enrollDate)?.startOf('day') || moment();
  return itemDate?.isSameOrAfter(moment().startOf('day'));
};

export const isSameDateAsToday = (item: PlannerCRUDCourse) => {
  const itemDate =
    moment(item?.enrollDate)?.startOf('day') || moment().startOf('day');
  const sameDate = moment(itemDate).isSame(moment(), 'day');
  return sameDate;
};

export const updateTimeline = (
  items: PlannerCRUDCourse[],
  setTimelineItems: any,
  isOnboardingPlan?: boolean,
) => {
  const sortedItems: PlannerCRUDCourse[] = [...items];
  const timelineItems: PlannerCRUDCourse[] = [];
  let lastDate: string;
  let firstNonPastDateIndex: number | null = null;
  let lastItemIndexForCurrentDate: number | null = null;

  if (isOnboardingPlan) {
    sortedItems.sort((a, b) => {
      const aDays = convertPeriodStringToDays(a.periodValue, a.periodUnit);
      const bDays = convertPeriodStringToDays(b.periodValue, b.periodUnit);
      return aDays - bDays;
    });
  } else {
    sortedItems.map((x) =>
      x.releaseDate &&
      !x.enrollDate &&
      moment(x.releaseDate).isAfter(moment(), 'days')
        ? (x.enrollDate = moment.utc(x.releaseDate).set({ h: 9 }).toDate())
        : x,
    );
    sortedItems.sort(
      (a, b) => moment(a.enrollDate)?.diff(moment(b.enrollDate)) || 0,
    );
  }

  sortedItems.forEach((item, index) => {
    const dateKey = getDateKey(item, !!isOnboardingPlan);
    const isFutureOrToday = getIsFutureOrToday(item);

    if (dateKey !== lastDate) {
      if (lastItemIndexForCurrentDate !== null) {
        timelineItems[lastItemIndexForCurrentDate].isLastOfDateGroup = true;
      }

      timelineItems.push({
        ...item,
        isPastDate: !isFutureOrToday,
        isDateHeader: true,
        dateKey,
        isLastOfDateGroup: false,
      });
      lastDate = dateKey;
      lastItemIndexForCurrentDate = timelineItems.length - 1;
    } else {
      timelineItems.push({
        ...item,
        isPastDate: !isFutureOrToday,
        isDateHeader: false,
        isLastOfDateGroup: false,
      });
      lastItemIndexForCurrentDate = timelineItems.length - 1;
    }

    if (isFutureOrToday && firstNonPastDateIndex === null) {
      firstNonPastDateIndex = index;
    }
  });

  if (lastItemIndexForCurrentDate !== null) {
    timelineItems[lastItemIndexForCurrentDate].isLastOfDateGroup = true;
  }

  if (firstNonPastDateIndex !== null) {
    timelineItems[firstNonPastDateIndex].firstNonPastDate = true;
  }

  setTimelineItems(timelineItems);

  return firstNonPastDateIndex;
};

export const getPlannerCoursesByEnrollType = (
  plannerType: PlannerType,
  newUsersSelectedCourses: PlannerCRUDCourse[],
  existingUsersSelectedCourses: PlannerCRUDCourse[],
) => {
  return plannerType === PlannerType.ONBOARDING_PLAN
    ? newUsersSelectedCourses
    : existingUsersSelectedCourses;
};
