import { Branch } from '@/client/branches';
import { EfrontSyncRequest } from '@/client/efront';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EfrontBranchesAutocomplete } from './EfrontBranchesAutocomplete';
import { step1Schema } from './validation-schema';

type EfrontTypeSelectFormContainerProps = {
  initialValues: EfrontSyncRequest;
  onValuesChange: (values: EfrontSyncRequest) => void;
};

export const EfrontTypeSelectFormContainer: React.FC<
  EfrontTypeSelectFormContainerProps
> = ({ initialValues, onValuesChange }) => {
  const { t } = useTranslation();

  return (
    <Formik
      validateOnMount
      validationSchema={step1Schema(t)}
      initialValues={initialValues}
      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
      onSubmit={() => {}}
    >
      {({ values, setFieldValue }) => {
        useEffect(() => {
          onValuesChange(values);
        }, [values]);

        return (
          <Form className="mt-6">
            {values.type == 'branch' && (
              <div className="field w-6 mb-6 mt-4">
                <label>
                  {t('efront.select.branch')}
                  <span className="red"> *</span>
                </label>
                <EfrontBranchesAutocomplete
                  className="w-full"
                  defaultValue={values.branch}
                  onSelect={(value: Branch) => {
                    setFieldValue('id', value?.meta?.eFrontId);
                    setFieldValue('branch', value);
                  }}
                />
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
