import { FlexContainer } from '@/ui/styled-ui';
import React, { ReactNode } from 'react';

type DataTableToolbarProps = {
  children?: ReactNode;
  justify?: string;
  align?: string;
};

type Props = DataTableToolbarProps;

export const DataTableToolbar: React.FC<Props> = ({
  children,
  align = 'flex-start',
  justify = 'space-between',
}) => (
  <FlexContainer gap={16} justify={justify} align={align}>
    {children}
  </FlexContainer>
);
