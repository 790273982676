import { BreadCrumb } from 'primereact/breadcrumb';
import { MenuItem } from 'primereact/menuitem';
import React from 'react';
import styled from 'styled-components';

const StyledBreadCrumb = styled(BreadCrumb)`
  &.p-breadcrumb {
    margin-bottom: var(--medium-padding);
    border: 0;
    padding: 0;
    font-size: var(--xsmall-font-size);
    line-height: var(--xsmall-line-height);
    flex-shrink: 0;

    &.no-space {
      margin: 0px;
    }

    ol li.p-menuitem-separator {
      padding: 0;
      margin: 0 6px;
      color: var(--gray-dark);
      font-size: var(--small-font-size);
      line-height: var(--xsmall-line-height);

      > svg {
        display: none;
      }

      &::before {
        content: '/';
        display: inline-block;
        color: var(--gray-dark);
        font-weight: 700;
      }
      &:first-of-type {
        display: none;
      }
    }
  }
`;

type AppBreadCrumbProps = {
  model: MenuItem[];
} & React.HTMLAttributes<HTMLElement>;

const AppBreadCrumb: React.FC<AppBreadCrumbProps> = ({ model, ...rest }) => (
  <StyledBreadCrumb model={model} {...rest} />
);

export default AppBreadCrumb;
