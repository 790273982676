import { client } from '@/client';
import { AccountSMTPSettings } from '@/client/accounts';
import {
  GenericForbiddenApiError,
  UpdateSystemSendingConfigMutation,
} from '@/hooks/query/types';
import { omit } from 'lodash';
import { useMutation } from 'react-query';

export const useSaveSystemEmailConfig = (): {
  update: (
    payload: AccountSMTPSettings & { id: string },
  ) => Promise<AccountSMTPSettings>;
} & UpdateSystemSendingConfigMutation => {
  const { mutateAsync, ...rest } = useMutation<
    AccountSMTPSettings,
    GenericForbiddenApiError,
    AccountSMTPSettings & { id: string }
  >(
    'updateSystemSendingConfig',
    (payload: AccountSMTPSettings & { id: string }) =>
      client.systemEmailSendingConfigService.updateSystemSendingConfig(
        omit(payload, ['id']),
        payload.id,
      ),
  );

  return { update: mutateAsync, ...(rest as any) };
};
