import classNames from 'classnames';
import { get } from 'lodash';
import { InputText, InputTextProps } from 'primereact/inputtext';
import React, { SyntheticEvent, useEffect } from 'react';

export type FormikInputProps = {
  field: {
    name: string;
    value: string;
    onChange: () => void;
    onBlur: (e: SyntheticEvent<HTMLInputElement>) => void;
  };
  form: {
    touched: Record<string, any>;
    errors: Record<string, any>;
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void;
  };
  label?: string;
  additionOnBlur?: () => void;
};

export const FormikInput: React.FC<FormikInputProps & InputTextProps> = ({
  field,
  form,
  label,
  className,
  required,
  additionOnBlur,
  ...rest
}) => {
  const fieldError = get(form.errors, field.name);
  const fieldTouched = get(form.touched, field.name);

  return (
    <>
      {!!label && (
        <label
          htmlFor={field.name}
          className={classNames({
            'p-error': fieldTouched && !!fieldError,
          })}
        >
          {label}
          {required ? <span className="red"> *</span> : ''}
        </label>
      )}
      <InputText
        {...field}
        value={field.value ?? ''}
        {...rest}
        className={classNames(className, {
          'p-invalid': fieldTouched && !!fieldError,
        })}
        onBlur={(e: SyntheticEvent<HTMLInputElement>) => {
          field.onBlur(e);
          form.setFieldValue(field.name, field.value?.trim());

          if (additionOnBlur) {
            additionOnBlur();
          }
        }}
        autoComplete="off"
      />
      {fieldTouched && !!fieldError ? (
        <small className="p-error">{fieldError}</small>
      ) : null}
    </>
  );
};
