import { equal, handleAxiosError, nested } from '@/api/helpers';
import { Account } from '@/client/accounts';
import {
  CourseScheduleType,
  EnrollManyToManyFormValues,
  ForceCompleteFormValues,
  MaterialType,
  UserStatusEnum,
} from '@/client/courses';
import { FilterNamesEnum, getFiltersFromColumns } from '@/client/helpers';
import { Actions, Subjects, User, UserCourses } from '@/client/users';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { userStatusOptions } from '@/common/constants/enroll-types';
import {
  ForceCompleteModal,
  UserEnrollModal,
} from '@/components/courses/modals';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import {
  useForceComplete,
  useUserCourses,
  useUsersSchedules,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { DateFormats } from '@/system-settings/enums';
import { AppButton } from '@/ui/buttons';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import {
  determineScoreColor,
  determineUserStatusColor,
} from '@/utils/helpers/ui.helper';
import { HubspotProperty, hubspotTrack, HubspotValue } from '@/utils/hubspot';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import { DataTableRowClickEvent } from 'primereact/datatable';
import { MenuItem } from 'primereact/menuitem';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

type UserCoursesDatatableProps = {
  user: User;
};

export const UserCoursesDatatable: React.FC<UserCoursesDatatableProps> = ({
  user,
}) => {
  const account = useAppSelector(selectCurrentAccount);
  const navigate = useNavigate();
  const toast = useToast();
  const { can } = usePermission();
  const { id } = useParams();

  const [showEnrollModal, setEnrollModal] = useState(false);
  const [loadingState, setLoadingState] = useState<LoadingStatuses>(
    LoadingStatuses.IDLE,
  );
  const [showForceCompleteModal, setShowForceCompleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserCourses>();

  const { skip, take, sort, apiFilters, onFilter, onSort, onPage } = useTable();

  const { isLoading, userCourses, refetch } = useUserCourses({
    accountId: account?.id,
    userId: user?.id,
    take,
    skip,
    filters: [
      nested('course', [equal('type', MaterialType.COURSE)]),
      ...apiFilters,
    ],
    sort: sort && sort.length > 0 ? [sort.join(',')] : [],
  });

  const completeCourse = useForceComplete();
  const handleForceComplete = async (data: ForceCompleteFormValues) => {
    if (!selectedUser?.course?.id) return;

    try {
      await completeCourse.complete({
        courseId: selectedUser?.course.id,
        userId: id as string,
        score: data.score,
        accountId: (account as Account)?.id,
      });
      refetch();
      setShowForceCompleteModal(false);
      toast?.success(
        t('toast.success'),
        t('course.forceComplete.success', { course: selectedUser.course.name }),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const checkAndTrackHubspotEvent = () => {
    if (user?.account?.freeTrialEndsAt) {
      hubspotTrack({
        [HubspotProperty.NEW_USER_ENROLLED_TO_A_COURSE]: HubspotValue.YES,
      });
    }
  };

  const usersSchedules = useUsersSchedules();
  const handleSubmitEnrollUsers = async (data: EnrollManyToManyFormValues) => {
    try {
      await usersSchedules.add({
        courses: data?.courses?.map((course) => course.id) ?? [],
        users: data?.users?.map((user) => user.id) ?? [],
        date: data.date,
        autoEnroll: data.autoEnroll,
        type: CourseScheduleType.STATIC,
      });

      setLoadingState(LoadingStatuses.LOADING);

      setTimeout(() => {
        refetch();
        setLoadingState(LoadingStatuses.IDLE);
        setEnrollModal(false);
        toast?.success(t('toast.success'), t('courses.user.enroll.success'));
      }, 2000);

      checkAndTrackHubspotEvent();
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const menuItems = (user: UserCourses) => {
    const menu: MenuItem[] = [];

    if (
      can(Actions.UPDATE, Subjects.COURSE_FORCE_COMPLETE) &&
      [UserStatusEnum.ENROLLED, UserStatusEnum.IN_PROGRESS].includes(
        user.status,
      )
    ) {
      menu.push({
        label: t('course.forceComplete'),
        icon: 'pi pi-check',
        command: () => {
          setShowForceCompleteModal(true);
          setSelectedUser(user);
        },
      });
    }

    return menu;
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'course',
      header: t('reports.courses.enrolled'),
      sortable: false,
      filterable: true,
      filters: {
        field: FilterNamesEnum.AWARENESS_REPORTS_COURSE_NAME,
        type: FilterTypeEnum.TEXT,
        placeholder: t('generic.name.search'),
      },
      render: (row: UserCourses) => row?.course?.name,
    },
    {
      field: 'enrollDate',
      header: t('generic.enrollDate'),
      sortable: true,
      filterable: false,
      render: (row: UserCourses) =>
        row?.enrollDate ? (
          <FormatDate date={row?.enrollDate} />
        ) : (
          <span>&#8212;</span>
        ),
    },
    {
      field: 'completeDate',
      header: t('reports.courses.completionDate'),
      sortable: false,
      filterable: false,
      render: (row: UserCourses) =>
        row?.attempt?.completed ? (
          <FormatDate date={row.attempt?.completed} />
        ) : (
          <span>&#8212;</span>
        ),
    },
    ...(account?.courseDueDays
      ? [
          {
            field: 'enrollment.dueDate',
            header: t('generic.dueDate'),
            sortable: true,
            filterable: true,
            filters: {
              label: t('filter.overdueCourses'),
              field: FilterNamesEnum.COURSE_ENTITY_USER_HAS_DUE_COURSES,
              type: FilterTypeEnum.SELECT_YES_NO,
              placeholder: t('generic.select'),
            },
            className: 'no-padding',
            render: (row: UserCourses) => (
              <>
                {row?.enrollment?.dueDate ? (
                  <FormatDate
                    format={DateFormats.TIMEDATE}
                    date={row.enrollment.dueDate}
                  />
                ) : (
                  <span>&#8212;</span>
                )}
              </>
            ),
          } as DataTableColumnType,
        ]
      : []),
    {
      field: 'status',
      header: t('generic.status'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.SELECT,
        options: userStatusOptions(t),
        field: FilterNamesEnum.ENTITY_BY_STATUS,
      },
      render: (row: UserCourses) => determineUserStatusColor(row?.status, t),
    },
    {
      field: 'score',
      header: t('reports.score'),
      sortable: false,
      filterable: false,
      render: (row: UserCourses) => (
        <FlexContainer justify="flex-start">
          {row?.course?.type !== MaterialType.COURSE ? (
            <span className="p-2">&#8212;</span>
          ) : row?.attempt?.score ? (
            determineScoreColor(row.attempt.status, row.attempt.score)
          ) : (
            determineScoreColor('enrolled', 0)
          )}
        </FlexContainer>
      ),
    },
    {
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      className: 'no-padding',
      style: {
        width: '80px',
        textAlign: 'center',
      },
      render: (row: UserCourses) => {
        return (
          <FlexContainer direction="column" className="group-cell">
            <div className="group-row justify-content-center">
              <DataTableActions
                menuItems={menuItems(row)}
                disabled={menuItems(row).length < 1}
              />
            </div>
          </FlexContainer>
        );
      },
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['course'];
  //

  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        <DataTableFilters
          filters={getFiltersFromColumns(columns)}
          onFilter={onFilter}
          className="flex-initial"
          tableName={TableNamesEnum.USER_COURSES_LIST}
        />
      </FlexContainer>
      <AppButton
        label={t('generic.enroll')}
        aria-controls="overlay_menu"
        onClick={() => setEnrollModal(true)}
      />
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={TableNamesEnum.USER_COURSES_LIST}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  const onRowClick = (e: DataTableRowClickEvent) =>
    navigate(
      RedirectPaths[RedirectPathsEnum.COURSES_CONTENT](e.data?.course?.id),
    );

  return (
    <>
      <DataTable
        dataKey="dataId"
        data={userCourses?.result.map((x, i) => ({
          ...x,
          dataId: `${x.id}-${x.course?.id}-${i}`,
        }))}
        count={userCourses?.count as number}
        isLoading={isLoading}
        toolbar={toolbar}
        columns={columns}
        visibleColumns={visibleColumns}
        onPage={onPage}
        rows={take}
        skip={skip}
        onSort={onSort}
        sort={sort}
        onRowClick={onRowClick}
      />

      <ForceCompleteModal
        visible={showForceCompleteModal}
        onSubmit={handleForceComplete}
        onHide={() => setShowForceCompleteModal(false)}
        userName={user.name}
        courseName={selectedUser?.course.name}
      />

      <UserEnrollModal
        account={account as Account}
        visible={showEnrollModal}
        onSubmit={handleSubmitEnrollUsers}
        onHide={() => setEnrollModal(false)}
        selectedUser={user}
        loadingState={loadingState}
      />
    </>
  );
};
