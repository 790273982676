import { GroupCondition } from '@/api/enums';
import { equal, group } from '@/api/helpers';
import { client } from '@/client';
import { CampaignFormValues } from '@/client/campaigns';
import { PageTemplate, PageTemplatesEnum } from '@/client/page-templates';
import { ThumbnailState } from '@/client/thumbnails/enums';
import { Actions, Subjects } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { DialogContext } from '@/common/context';
import { TemplatesFilters } from '@/components/templates';
import { ThumbnailsGenerationNotify } from '@/components/thumbnails';
import { usePageTemplates } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTemplatesFilters } from '@/hooks/templates';
import { usePermission } from '@/hooks/usePermission';
import { selectCurrentAccount } from '@/store/features/account';
import { ImageCard } from '@/ui/image-card';
import { CardGridContainer, FlexContainer } from '@/ui/styled-ui';
import { displayCreatedBy } from '@/utils/helpers';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

type PageTemplateStepProps = {
  values: CampaignFormValues;
  valuesSetter: any;
  exitPrompt?: boolean;
};

// this is used in case of selected email ahead in pages and no visible for the current page
const initialSelectedTemplateLoad: (
  pageTemplateId?: string,
) => Promise<PageTemplate | undefined> = async (pageTemplateId?: string) => {
  if (!pageTemplateId) return undefined;

  const response = await client.pageTemplates.getPageTemplates({
    filters: [equal('id', pageTemplateId)],
  });

  return response?.result?.[0];
};

export const PageTemplateStep: React.FC<PageTemplateStepProps> = ({
  values,
  valuesSetter,
  exitPrompt,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { can } = usePermission();
  const { id: campaignId } = useParams();
  const { setDialogData } = useContext(DialogContext);
  const currentAccount = useAppSelector(selectCurrentAccount);

  const account = useAppSelector(selectCurrentAccount);
  const {
    take,
    nameFilter,
    search,
    skip,
    handleSearch,
    handleChangeSkip,
    handleChangeDefaultRows,
  } = useTemplatesFilters();

  const { isLoading, pageTemplates, refetch } = usePageTemplates({
    take,
    skip,
    filters: [
      group(GroupCondition.OR, [
        equal('account', account?.id),
        equal('account', null),
      ]),
      ...nameFilter,
      equal('type', PageTemplatesEnum.LANDING),
      equal('active', true),
    ],
  });
  const [selectedTemplateName, setSelectedTemplateName] = useState(
    `${t('generic.none')}`,
  );

  useEffect(() => {
    const loadTemplate = async () => {
      const template = await initialSelectedTemplateLoad(values.landingPage);

      if (template) setSelectedTemplateName(template.name);
    };

    loadTemplate();
  }, [values.landingPage]);

  const menuItems = (
    landingPageTemplate: PageTemplate,
    campaignId?: string,
  ) => {
    const items: MenuItem[] = [];
    const createdInAccount =
      landingPageTemplate.account?.id === currentAccount?.id;

    if (
      can(Actions.CREATE, Subjects.PAGES) &&
      !createdInAccount &&
      !currentAccount?.isSystem
    ) {
      items.push({
        label: t('generic.copyEdit'),
        icon: 'pi pi-copy',
        command: () => {
          if (exitPrompt) {
            return navigate(
              RedirectPaths[RedirectPathsEnum.LANDING_PAGE_TEMPLATES_CREATE](),
              {
                state: { landingPageTemplate, campaignId },
              },
            );
          }

          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.unsavedChanges'),
            message: t('dialog.unsavedChanges.confirm'),
            onAccept: () =>
              navigate(
                RedirectPaths[
                  RedirectPathsEnum.LANDING_PAGE_TEMPLATES_CREATE
                ](),
                {
                  state: { landingPageTemplate, campaignId },
                },
              ),
          });
        },
      });
    }

    if (
      can(Actions.UPDATE, Subjects.PAGES) &&
      (createdInAccount || currentAccount?.isSystem)
    ) {
      items.push({
        label: t('generic.edit'),
        icon: 'pi pi-pencil',
        command: () => {
          if (exitPrompt) {
            return navigate(
              RedirectPaths[RedirectPathsEnum.LANDING_PAGE_TEMPLATES_UPDATE](
                landingPageTemplate.id,
              ),
              {
                state: { campaignId },
              },
            );
          }

          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.unsavedChanges'),
            message: t('dialog.unsavedChanges.confirm'),
            onAccept: () =>
              navigate(
                RedirectPaths[RedirectPathsEnum.LANDING_PAGE_TEMPLATES_UPDATE](
                  landingPageTemplate.id,
                ),
                {
                  state: { campaignId },
                },
              ),
          });
        },
      });
    }

    return items;
  };

  return (
    <>
      <h2>
        {t('campaign.select.landingPage')}{' '}
        <span id="selected-template-tag">{`(${selectedTemplateName})`}</span>
      </h2>
      <TemplatesFilters
        count={pageTemplates?.count ?? 0}
        take={take}
        skip={skip}
        onPageChange={handleChangeSkip}
        onRowsChange={handleChangeDefaultRows}
        onSearch={handleSearch}
        search={search}
      />
      {isLoading && !pageTemplates && (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
          <h3>{t('templates.page.loading')}</h3>
        </FlexContainer>
      )}
      {!pageTemplates?.result.length && !isLoading && (
        <FlexContainer className="mt-5">
          <h3>{t('templates.page.empty')}</h3>
        </FlexContainer>
      )}
      {!isLoading && !!pageTemplates?.result.length && (
        <>
          <ThumbnailsGenerationNotify
            refetch={refetch}
            templates={pageTemplates?.result}
          />
          <CardGridContainer>
            {pageTemplates?.result.map((template: PageTemplate) => (
              <ImageCard
                isLoading={
                  template.thumbnailState === ThumbnailState.PROCESSING
                }
                key={template.id}
                id={template.id}
                title={template.name}
                thumbnail={template.thumbnail?.signedUrl}
                actions={menuItems(template, campaignId)}
                author={displayCreatedBy(t, template.createdBy)}
                templateType="page"
                interactionType={
                  menuItems(template, campaignId).length
                    ? 'withSelectAndActions'
                    : 'withSelect'
                }
                selectName={template?.name}
                isSelected={values.landingPage === template.id}
                onChange={(event) => valuesSetter('landingPage', event.value)}
                data-testid={`campaign-form-page-template-step-template-${template.id}`}
              />
            ))}
          </CardGridContainer>
        </>
      )}
    </>
  );
};
