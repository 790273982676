import { addLocale } from 'primereact/api';

// information from https://github.com/primefaces/primelocale

import da from '@/locales/localazy/da.json';
import de from '@/locales/localazy/de.json';
import en from '@/locales/localazy/en-US.json';
import nb from '@/locales/localazy/nb.json';
import nl from '@/locales/localazy/nl.json';
import sv from '@/locales/localazy/sv.json';

type CalendarTranslation = {
  clear: string;
  dayNames: string[];
  dayNamesShort: string[];
  dayNamesMin: string[];
  monthNames: string[];
  monthNamesShort: string[];
  am: string;
  pm: string;
  today: string;
};

const translate = (lang: CalendarTranslation) => ({
  clear: lang.clear,
  dayNames: lang.dayNames,
  dayNamesShort: lang.dayNamesShort,
  dayNamesMin: lang.dayNamesMin,
  monthNames: lang.monthNames,
  monthNamesShort: lang.monthNamesShort,
  am: lang.am,
  pm: lang.pm,
  today: lang.today,
});

export const isThereTranslation = (): boolean =>
  [da, en, sv, de, nb, nl].every(
    (locale) =>
      Object.keys(locale).length !== 0 &&
      Object.keys(locale).includes('dayNames'),
  );

if (isThereTranslation()) {
  addLocale('en_GB', translate(en as CalendarTranslation & typeof en));
  addLocale('da_DK', translate(da as CalendarTranslation & typeof da));
  addLocale('sv_SE', translate(sv as CalendarTranslation & typeof sv));
  addLocale('nb_NO', translate(nb as CalendarTranslation & typeof nb));
  addLocale('nl_NL', translate(nl as CalendarTranslation & typeof nl));
  addLocale('de_DE', translate(de as CalendarTranslation & typeof de));
  addLocale('kl_GL', translate(en as CalendarTranslation & typeof en)); // fallback to english
}
