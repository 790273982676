import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { ResellerFormState } from '@/client/resellers';
import { Actions, Subjects } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { ResellerForm } from '@/components/resellers';
import { useSaveReseller } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { KeycloakThemes, queryStateConverter } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import { MenuItem } from 'primereact/menuitem';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const getPathItems = (
  currentAccount: Account,
  t: TranslationFunctionType,
): MenuItem[] => [
  {
    label: currentAccount?.name,
    url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('resellers'),
    url: RedirectPaths[RedirectPathsEnum.RESELLERS](),
    template: AppBreadCrumbTemplate,
  },

  {
    label: t('reseller.createNew'),
    url: RedirectPaths[RedirectPathsEnum.CREATE_RESELLERS](),
    template: AppBreadCrumbTemplate,
  },
];

export const CreateResellersPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { cannot } = usePermission();
  const currentAccount = useAppSelector(selectCurrentAccount);

  const [initialValues, setInitialValues] = useState<ResellerFormState>({
    name: '',
    email: '',
    phone: '',
    baseUrl: '',
    mainAccount: undefined,
    accounts: [],
    theme: KeycloakThemes.CYBERPILOT,
    logo: undefined,
    emailLogo: undefined,
  });
  const createReseller = useSaveReseller();

  const handleSubmit = async (data: FormikValues) => {
    if (!data) return;

    let selectedAccountsIds: string[] = [];
    if (data?.accounts?.length) {
      selectedAccountsIds = data.accounts.map((account: Account) => account.id);
    }

    try {
      await createReseller.create({
        name: data.name,
        email: data.email,
        phone: data.phone,
        baseUrl: data.baseUrl,
        mainAccount: data.mainAccount.id,
        accounts: selectedAccountsIds,
        theme: data.theme ? data.theme : null,
        logo: data.logo ? data.logo : null,
        emailLogo: data.emailLogo ? data.emailLogo : null,
        favIcon: data.favIcon ? data.favIcon : null,
      });

      toast?.success(t('toast.success'), t('reseller.created'));
      navigate(RedirectPaths[RedirectPathsEnum.RESELLERS]());
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  return (
    <>
      {currentAccount && (
        <AppBreadCrumb model={getPathItems(currentAccount, t)} />
      )}

      <FlexContainer justify="flex-start">
        <ResellerForm
          isCreate
          initialValues={initialValues}
          onSubmit={handleSubmit}
          state={queryStateConverter(createReseller)}
          disabled={
            cannot(Actions.UPDATE, Subjects.RESELLERS) ||
            !currentAccount?.isSystem
          }
        />
      </FlexContainer>
    </>
  );
};
