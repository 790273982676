import { FilePurpose } from '@/api/enums';
import { File } from '@/client/files';
import { SystemResellerFormState } from '@/client/resellers';
import { Actions, Subjects } from '@/client/users';
import { LoadingStateType } from '@/common/constants';
import { FormikDropdown } from '@/components/form';
import { FormikInput } from '@/components/form/FormikInput';
import { usePermission } from '@/hooks/usePermission';
import { AppButton } from '@/ui/buttons';
import { ImageUpload } from '@/ui/image-upload';
import { FlexContainer, FormContainer } from '@/ui/styled-ui';
import { keycloakThemeOptions } from '@/utils/helpers';
import { Field, Form, Formik } from 'formik';
import { DropdownChangeEvent } from 'primereact/dropdown';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { systemResellerSchema } from './validations';

type SystemResellerFormProps = {
  initialValues: SystemResellerFormState;
  preselectedLogo?: File;
  preselectedEmailLogo?: File;
  preselectedFavIcon?: File;
  state?: LoadingStateType;
  onSubmit: (data: SystemResellerFormState) => void;
  disabled?: boolean;
};

export const SystemResellerForm: React.FC<SystemResellerFormProps> = ({
  initialValues,
  preselectedLogo,
  preselectedEmailLogo,
  preselectedFavIcon,
  state,
  onSubmit,
  disabled,
}) => {
  const { t } = useTranslation();
  const { cannot } = usePermission();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={systemResellerSchema(t)}
      onSubmit={onSubmit}
      validateOnMount
    >
      {({ errors, setFieldValue, setFieldTouched }) => {
        return (
          <FormContainer width={100}>
            <Form className="w-full" autoComplete="off">
              <FlexContainer
                gap={24}
                width="100%"
                justify="space-between"
                align="flex-start"
              >
                <div className="w-full">
                  <div className="field w-full mb-4">
                    <Field
                      id="name"
                      name="name"
                      label={t('generic.name')}
                      className="w-full"
                      component={FormikInput}
                      placeholder={t('reseller.name')}
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div className="field w-full mb-4">
                    <Field
                      id="email"
                      name="email"
                      label={t('generic.email')}
                      className="w-full"
                      component={FormikInput}
                      placeholder={t('reseller.email')}
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div className="field w-full mb-4">
                    <Field
                      id="phone"
                      name="phone"
                      label={t('generic.phone')}
                      className="w-full"
                      component={FormikInput}
                      placeholder={t('reseller.phone')}
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div className="field w-full mb-4">
                    <Field
                      id="baseUrl"
                      name="baseUrl"
                      label={t('reseller.baseUrl')}
                      className="w-full"
                      component={FormikInput}
                      placeholder={t('reseller.baseUrl')}
                      required
                      disabled={
                        disabled ||
                        cannot(Actions.READ, Subjects.RESELLERS_DOMAIN_CONFIG)
                      }
                    />
                  </div>
                  <AppButton
                    isSubmit
                    severity="secondary"
                    label={t('button.save')}
                    className="w-4 mt-4 mb-5"
                    state={state}
                    isDisabled={
                      !!Object.keys(errors).length ||
                      disabled ||
                      isSubmitDisabled
                    }
                  />
                </div>
                <div className="w-full">
                  <div className="field w-full mb-4">
                    <Field
                      id="theme"
                      label={t('reseller.theme')}
                      name="theme"
                      showClear
                      filter
                      onChange={(e: DropdownChangeEvent) =>
                        setFieldValue('theme', e.value)
                      }
                      onBlur={() => setFieldTouched('theme', true)}
                      className="w-full"
                      component={FormikDropdown}
                      placeholder={t('generic.select')}
                      options={keycloakThemeOptions}
                      disabled={disabled}
                    />
                  </div>
                  <div className="field w-full mb-4">
                    <ImageUpload
                      label={t('reseller.platformLogo')}
                      onFileUpload={(file: File | null) =>
                        setFieldValue('logo', file?.id)
                      }
                      purpose={FilePurpose.THUMBNAIL}
                      preselectedFile={preselectedLogo}
                      setIsSubmitDisabled={setIsSubmitDisabled}
                      isDisabled={disabled}
                    />
                  </div>
                  <div className="field w-full mb-4">
                    <ImageUpload
                      label={t('reseller.emailLogo')}
                      onFileUpload={(file: File | null) =>
                        setFieldValue('emailLogo', file?.id)
                      }
                      purpose={FilePurpose.THUMBNAIL}
                      preselectedFile={preselectedEmailLogo}
                      setIsSubmitDisabled={setIsSubmitDisabled}
                      isDisabled={disabled}
                    />
                  </div>
                  <div className="field w-full mb-4">
                    <ImageUpload
                      label={t('reseller.favIcon')}
                      onFileUpload={(file: File | null) =>
                        setFieldValue('favIcon', file?.id)
                      }
                      purpose={FilePurpose.THUMBNAIL}
                      preselectedFile={preselectedFavIcon}
                      setIsSubmitDisabled={setIsSubmitDisabled}
                      isDisabled={disabled}
                    />
                  </div>
                </div>
              </FlexContainer>
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};
