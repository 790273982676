import { TranslationFunctionType } from '@/common/types';
import { array, object, string } from 'yup';

export const oldCampaignsSchema = (t: TranslationFunctionType) =>
  object({
    account: object()
      .shape({
        id: string().required(t('error.requiredField')),
        name: string().required(t('error.requiredField')),
      })
      .required(),
    campaigns: array(
      object().shape({
        id: string().required(t('error.requiredField')),
        name: string().required(t('error.requiredField')),
      }),
    ).required(t('error.requiredField')),
  });
