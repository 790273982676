import { DOMAIN_REGEX } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { array, object, string } from 'yup';

export const hubspotSchema = (t: TranslationFunctionType) =>
  object({
    hubspotId: string().required(t('error.requiredField')),
    hubspotDomains: array()
      .test('valid-domains', t('error.invalidDomain'), (domains) => {
        if (!domains || domains.length === 0) {
          return true;
        }

        const invalidDomains = domains.filter(
          (domain) => !DOMAIN_REGEX.test(domain),
        );

        return invalidDomains.length === 0;
      })
      .min(1, t('error.requiredField')),
  });
