import { BadRequestException, UnauthorizedException } from '@/api/exceptions';
import { client, Token, TokenRequest, TokenResponse } from '@/client';
import { GenericApiError } from '@/hooks/query';
import { useMutation } from 'react-query';

export const useHandshake = (): {
  request: (request: TokenRequest) => Promise<Token>;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  error: UnauthorizedException | BadRequestException | null;
} => {
  const { mutateAsync, ...rest } = useMutation<
    Token,
    GenericApiError,
    TokenRequest
  >(
    (request: TokenRequest) =>
      client.auth.handshakeToken(request.token) as Promise<Token>,
  );

  return { request: mutateAsync, ...rest };
};

export const useSignOut = (): {
  request: () => Promise<boolean>;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  error: UnauthorizedException | BadRequestException | null;
} => {
  const { mutateAsync, ...rest } = useMutation<boolean, GenericApiError>(
    () => client.auth.signOut() as Promise<boolean>,
  );

  return { request: mutateAsync, ...rest };
};
