import { Account } from '@/client/accounts';
import { EfrontRecommendation, EfrontSyncType } from '@/client/efront';
import {
  useAccountsPartialRequest,
  useEfrontRecommendations,
} from '@/hooks/query';
import { AbsoluteLoader } from '@/ui/styled-ui';
import { Message } from 'primereact/message';
import { Tree } from 'primereact/tree';
import { TreeNode } from 'primereact/treenode';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type EfrontMappingContainerProps = {
  type: EfrontSyncType;
  id: string;
  onTypeIdChange: (disabled: boolean) => void;
};

const StyledMessage = styled(Message)`
  width: 100%;
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);

  .p-inline-message-text {
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
  }
`;

const StyledNode = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const StyledTree = styled(Tree)`
  padding: var(--small-padding);
`;

const checkForMappedFlag = (
  rec: EfrontRecommendation[],
  accounts: Account[],
): boolean =>
  !!rec.find((r) => {
    const isAlreadySyncedAccount =
      r.targetType === 'account' &&
      !!accounts.find((acc) => acc?.meta?.eFrontId === r.efrontId);

    if (r.isMapped || isAlreadySyncedAccount) {
      return true;
    }

    if (r.children && r.children?.length > 0) {
      return checkForMappedFlag(r.children, accounts);
    }

    return false;
  });

const iconsPerType = {
  account: 'pi pi-briefcase',
  branch: 'pi pi-list',
  group: 'pi pi-users',
};

const convertRecommendationsToTreeNodes = (
  rec: EfrontRecommendation[],
  accounts: Account[],
): TreeNode[] =>
  rec.map((r) => {
    const isAlreadySyncedAccount =
      r.targetType === 'account' &&
      !!accounts.find((acc) => acc?.meta?.eFrontId === r.efrontId);

    return {
      id: r.efrontId,
      label: r.name,
      icon: iconsPerType[r.targetType as 'account' | 'branch' | 'group'],
      children: r.children
        ? convertRecommendationsToTreeNodes(r.children, accounts)
        : [],
      expanded: true,
      style: r.isMapped || isAlreadySyncedAccount ? { color: 'red' } : {},
      data: r,
    };
  });

export const EfrontMappingContainer: React.FC<EfrontMappingContainerProps> = ({
  type,
  id,
  onTypeIdChange,
}) => {
  const { t } = useTranslation();

  const { recommendations, isLoading } = useEfrontRecommendations({ id, type });
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [hasMissingMappedBranches, setHasMissingMappedBranches] =
    useState<boolean>(false);

  const { accounts } = useAccountsPartialRequest({});

  useEffect(() => {
    if (!recommendations?.pairs || recommendations?.pairs.length === 0) {
      return;
    }

    const checkGroupsWithoutMappedBranched =
      recommendations.pairs.filter(
        (p) => p.targetType === 'group' && p.assignedTo === null,
      ).length > 0;

    const warnings = checkForMappedFlag(recommendations.pairs, accounts ?? []);

    onTypeIdChange(checkGroupsWithoutMappedBranched || warnings);
    setHasMissingMappedBranches(checkGroupsWithoutMappedBranched);
    setShowWarning(warnings);
  }, [JSON.stringify(recommendations), accounts]);

  const tree = useMemo(
    () =>
      convertRecommendationsToTreeNodes(
        recommendations?.pairs ?? [],
        accounts ?? [],
      ),
    [recommendations?.pairs, accounts],
  );

  if (isLoading) {
    return <AbsoluteLoader message={t('efront.wait')} fullScreen />;
  }

  return (
    <div>
      {showWarning && (
        <StyledMessage
          severity="error"
          text={t('efront.error')}
          className="mt-4"
        />
      )}

      {hasMissingMappedBranches && (
        <StyledMessage
          severity="error"
          text={t('efront.error.map')}
          className="mt-4"
        />
      )}

      <StyledTree
        className="my-4"
        value={tree}
        nodeTemplate={(node, options) => (
          <StyledNode className={options.className}>
            <span>{node.label}</span>
            <span>
              {t('efront.willMap', {
                node: node.data.targetType,
              })}
            </span>
          </StyledNode>
        )}
      />
    </div>
  );
};
