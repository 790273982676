import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Actions, Subjects } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import {
  AwarenessAndCompletionReports,
  CampaignsReports,
  OverviewReports,
} from '@/components/dashboards/admin';
import { useCampaignsSummary } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import dashboardAdmin from '@/images/placeholders/dashboard-admin.svg';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUserState } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { branchAdminCheck } from '@/utils/helpers';
import { HubspotProperty, hubspotTrack, HubspotValue } from '@/utils/hubspot';
import { Message } from 'primereact/message';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--beige-main);
  border-radius: var(--medium-border-radius);
  padding: var(--default-padding);

  img {
    width: 300px;
  }

  p {
    padding: 0;
    margin: 0;
    line-height: var(--medium-line-height);
    font-weight: 500;
    text-align: center;
  }

  h1 {
    text-align: center;
    margin-top: var(--default-padding);
    margin-bottom: var(--small-padding);
  }
`;

export const AdminDashboardPage = () => {
  const { t } = useTranslation();
  const { cannot } = usePermission();
  const account = useAppSelector(selectCurrentAccount);
  const { user } = useAppSelector(selectCurrentUserState);
  const navigate = useNavigate();

  const isBranchAdmin = branchAdminCheck(user, account);

  const { isLoading: isLoadingSummary, campaignsSummary } = useCampaignsSummary(
    {
      accountId: account?.id,
    },
  );

  useEffect(() => {
    if (user?.account?.freeTrialEndsAt) {
      hubspotTrack({
        [HubspotProperty.ADMIN_DASHBOARD_OPENED]: HubspotValue.YES,
      });
    }
  }, []);

  if (cannot(Actions.READ, Subjects.ADMIN_DASHBOARD) || !account) {
    return (
      <FlexContainer height="100%" direction="column" gap={24}>
        <h1>{t('profile.dashboard.denied')}</h1>
      </FlexContainer>
    );
  }

  if (account?.isSystem) {
    return (
      <FlexContainer height="auto" minHeight="100%" align="stretch">
        <StyledDiv>
          <img src={dashboardAdmin} />
          <h1>{t('profile.welcome.admin.heading')}</h1>
          <p>
            {t('profile.welcome.admin.p1')}
            <br /> {t('profile.welcome.admin.p2')}
          </p>
        </StyledDiv>
      </FlexContainer>
    );
  }

  const pathItems = [
    {
      label: account?.name,
      url: !account?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id as string)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('generic.dashboard'),
      url: RedirectPaths[RedirectPathsEnum.ADMIN_DASHBOARD](),
      template: AppBreadCrumbTemplate,
    },
  ];

  return (
    <>
      <AppBreadCrumb model={pathItems} />
      <h1 className="mb-5">{t('generic.dashboard')}</h1>
      <FlexContainer
        direction="column"
        width="100%"
        align="stretch"
        minWidth="min-content"
        gap={24}
      >
        {account?.activeDirectoryIntegration?.lastSyncErrorLog &&
          !isBranchAdmin && (
            <Message
              severity="warn"
              text={t('sync.error.dashboard.admin.message')}
              onClick={() =>
                navigate(
                  RedirectPaths[RedirectPathsEnum.ACCOUNT_AZURE_AD](account.id),
                )
              }
              style={{ cursor: 'pointer' }}
            />
          )}
        <OverviewReports accountId={account.id} t={t} />
        <AwarenessAndCompletionReports accountId={account.id} t={t} />
        <CampaignsReports
          campaignsSummary={campaignsSummary}
          isLoading={isLoadingSummary}
          t={t}
          isDashboard
        />
      </FlexContainer>
    </>
  );
};
