import { TranslationFunctionType } from '@/common/types';
import { boolean, object, string } from 'yup';

export const groupSchema = (t: TranslationFunctionType) =>
  object({
    name: string().required(t('error.requiredField')),
    autoAssign: boolean().default(false),
    active: boolean().default(true),
    parent: object().typeError(t('error.validGroup')),
    branch: object().typeError(t('error.validGroup')),
  });
