import * as Sentry from '@sentry/react';
import packageJson from '../package.json';

const ignoreErrors: (string | RegExp)[] = [
  /network error/i,
  /forbidden/i,
  /load failed/i,
  /not Found/i,
  'No error message',
  'NetworkError when attempting to fetch resource.',
  'Failed to fetch',
  'login_required',
  'The operation was aborted.',
  'Operation is not supported',
  'Token is not active',
  'IFrame timed out without a response',
  'ResizeObserver loop completed with undelivered notifications.',
];

const initSentry = () => {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: [
            process.env.REACT_APP_API_URL ?? 'localhost',
          ],
        }),
      ],
      release: packageJson.version,
      environment: process.env.REACT_APP_ENVIRONMENT,
      tracesSampleRate: 1.0,
      ignoreErrors,
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
      ],
    });
  }
};

export default initSentry;
