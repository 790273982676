import { getExceptionFromAxiosError } from '@/client/helpers';
import { MessageResponseModel } from '@/client/models';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

export const hubspotService = (client: AxiosInstance) => {
  const HUBSPOT_BASE_URL = 'hubspot-integration';

  const sendSyncRequest = async (): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `/${HUBSPOT_BASE_URL}/link`,
        {},
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const hubspotNotifyUrl = () =>
    `${client.defaults.baseURL}${HUBSPOT_BASE_URL}/notify`;

  return {
    sendSyncRequest,
    hubspotNotifyUrl,
  };
};
