import { processListingParams } from '@/api/helpers';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { getExceptionFromAxiosError } from '../helpers';
import { MessageResponseModel } from '../models';
import {
  AddAccountsToAResellerRequest,
  ListResellersAccountsRequest,
  ListResellersAccountsResponse,
  ListResellersRequest,
  ListResellersResponse,
  RemoveAccountsToAResellerRequest,
  Reseller,
  SaveResellerRequest,
  SystemReseller,
  UpdateResellerRequest,
  UpdateSystemResellerRequest,
} from './types';

export const resellersService = (client: AxiosInstance) => {
  const RESELLERS_BASE_URL = '/resellers';
  const SYSTEM_RESELLERS_URL = 'system';

  const getResellers = async (
    params: ListResellersRequest,
  ): Promise<ListResellersResponse> => {
    try {
      const result = await client.get<
        ListResellersResponse,
        AxiosResponse<ListResellersResponse>
      >(RESELLERS_BASE_URL, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getReseller = async (resellerId?: string): Promise<Reseller> => {
    try {
      const result = await client.get<Reseller, AxiosResponse<Reseller>>(
        `${RESELLERS_BASE_URL}/${resellerId}`,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getSystemReseller = async (): Promise<SystemReseller> => {
    try {
      const result = await client.get<
        SystemReseller,
        AxiosResponse<SystemReseller>
      >(`${RESELLERS_BASE_URL}/${SYSTEM_RESELLERS_URL}`, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveReseller = async (
    request: SaveResellerRequest,
  ): Promise<Reseller> => {
    try {
      const result = await client.post<Reseller, AxiosResponse<Reseller>>(
        RESELLERS_BASE_URL,
        request,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateReseller = async (
    request: UpdateResellerRequest,
  ): Promise<Reseller> => {
    try {
      const result = await client.patch<Reseller, AxiosResponse<Reseller>>(
        `${RESELLERS_BASE_URL}/${request.resellerId}`,
        request.updates,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateSystemReseller = async (
    request: UpdateSystemResellerRequest,
  ): Promise<Reseller> => {
    try {
      const result = await client.patch<Reseller, AxiosResponse<Reseller>>(
        `${RESELLERS_BASE_URL}/${SYSTEM_RESELLERS_URL}`,
        request.updates,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deleteReseller = async (
    accountId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${RESELLERS_BASE_URL}/${accountId}`, {
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getResellersAccounts = async (
    params: ListResellersAccountsRequest,
    resellerId: string,
  ): Promise<ListResellersAccountsResponse> => {
    try {
      const result = await client.get<
        ListResellersAccountsResponse,
        AxiosResponse<ListResellersAccountsResponse>
      >(`${RESELLERS_BASE_URL}/${resellerId}/accounts`, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveAccountsToAReseller = async (
    request: AddAccountsToAResellerRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${RESELLERS_BASE_URL}/${request.resellerId}/accounts`,
        { accounts: request.accounts },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const removeAccountsToAReseller = async (
    request: RemoveAccountsToAResellerRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${RESELLERS_BASE_URL}/${request.resellerId}/accounts/${request.accountId}`,
        {
          withCredentials: true,
        },
      );

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw e as AxiosError;
    }
  };

  return {
    getResellers,
    getReseller,
    getSystemReseller,
    saveReseller,
    updateReseller,
    updateSystemReseller,
    deleteReseller,
    getResellersAccounts,
    saveAccountsToAReseller,
    removeAccountsToAReseller,
  };
};
