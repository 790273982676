import React from 'react';

import { AbsoluteCenter } from '@/ui/styled-ui';

type ErrorScreenProps = {
  errorMessage: string;
};

export const ErrorPage: React.FC<ErrorScreenProps> = ({ errorMessage }) => (
  <AbsoluteCenter>{errorMessage}</AbsoluteCenter>
);
