import { useAppSelector } from '@/hooks/store';
import { selectCurrentAccount } from '@/store/features/account';
import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  background: var(--purple-middle);
  padding: var(--small-padding) var(--default-padding);
  border-radius: var(--default-border-radius);
  margin: 0 var(--medium-padding) var(--small-padding);
  p {
    margin: 0;
    color: var(--white-main);
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
    font-weight: 500;
  }
`;

export const SidebarAccountName: React.FC = () => {
  const account = useAppSelector(selectCurrentAccount);

  return (
    <StyledDiv>
      <p>{account?.name}</p>
    </StyledDiv>
  );
};
