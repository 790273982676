import { t } from 'i18next';

export class UnprocessableEntityException extends Error {
  public readonly statusCode: number = 422;

  public constructor(
    public message: string = t('error.unprocessableEntity'),
    public readonly errors: string[],
  ) {
    super(message);
  }
}
