import { FiltersType, GroupedFilterConditions } from '@/api/types';
import { client } from '@/client';
import { CourseAttempt, CourseStatusAttemptEnum } from '@/client/courses';
import { ListLearnerCoursesResponse } from '@/client/learner-course';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

const queryKey = 'learnerCourses';
const singleQueryKey = 'learnerCourse';

export const useLearnerCourses = (
  params: UseQueryOptions & {
    type: CourseStatusAttemptEnum;
    take?: number;
    skip?: number;
    filters?: FiltersType | GroupedFilterConditions;
    search?: string;
  },
): UseQueryResult<ListLearnerCoursesResponse> & {
  learnerCourses: ListLearnerCoursesResponse | undefined;
} => {
  const {
    type,
    take = 200,
    skip = 0,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    search,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [
      queryKey,
      take,
      skip,
      type,
      JSON.stringify(filters),
      JSON.stringify(search),
    ],
    () => client.learnerCourses.getLearnerCourses(params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    learnerCourses: data,
    ...(rest as UseQueryResult<ListLearnerCoursesResponse>),
  };
};

export const useCourseAttempt = (
  params: UseQueryOptions & {
    courseId?: string;
    attemptId?: string;
  },
): UseQueryResult<CourseAttempt> & {
  courseAttempt: CourseAttempt | undefined;
} => {
  const {
    courseId,
    attemptId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [singleQueryKey, courseId],
    () =>
      courseId
        ? client.learnerCourses.getLearnerCourseAttempt(courseId, attemptId)
        : undefined,
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<CourseAttempt>),
    },
  );

  return {
    courseAttempt: data,
    ...(rest as UseQueryResult<CourseAttempt>),
  };
};
