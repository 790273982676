import { LanguagesEnum } from '@/api/enums';
import i18n from '@/i18n';
import { DateFormats } from '@/system-settings/enums';
import moment from 'moment';
import 'moment/min/locales.min';
import React from 'react';

type FormatDateProps = {
  date: string | Date | undefined;
  format?: string;
  locale?: string;
};

export const FormatDate: React.FC<FormatDateProps> = ({
  date,
  format = DateFormats.DATEONLY,
  locale,
}) => {
  if (!date) {
    return null;
  }

  if (!locale) {
    locale = i18n.language ?? LanguagesEnum.EN;
  }

  // By default moment will convert to the local borwser time
  return <>{moment(date).locale(locale).format(format)}</>;
};
