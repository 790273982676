import { Skeleton } from 'primereact/skeleton';
import React, { ReactEventHandler, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledCardReportsSingleDoughnut = styled.div`
  min-width: min-content;
  overflow: hidden;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--small-padding);
    min-width: max-content;

    .reports-card-title {
      font-weight: 500;
      font-size: var(--small-font-size);
      line-height: var(--small-line-height);
      text-transform: uppercase;
      margin: 0;
    }
    .icon-wrapper {
      height: 32px;
      width: 32px;
      background-color: var(--white-main);
      display: flex;
      justify-content: center;
      border-radius: var(--xxsmall-border-radius);

      .custom-icon {
        align-self: center;
        fill: var(--purple-dark);
        cursor: pointer;
      }
    }
  }

  .card-content {
    margin: var(--xsmall-padding) 0;
    display: flex;
    gap: 32px;

    .chart {
      display: flex;
      align-items: center;
    }

    .table {
      width: 100%;
    }

    .table-row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: baseline;
      border-bottom: 1px solid var(--gray-dark);
      padding: var(--xsmall-padding) 0;
      gap: var(--medium-padding);

      &:last-child {
        border-bottom: none;
      }

      .table-text {
        min-width: max-content;
        font-weight: 500;
        font-size: var(--small-font-size);
        line-height: var(--small-line-height);
      }

      .table-count {
        text-align: end;
        font-weight: 700;
        font-size: var(--body-font-size);
        line-height: var(--body-line-height);
      }
    }
  }

  .reports-card-count {
    font-weight: 700;
    font-size: calc(2 * var(--medium-font-size));
    line-height: calc(2 * var(--xsmall-line-height));
    margin: var(--small-padding) 0;
  }
`;

const StyledLink = styled(Link)`
  color: var(--black-main);
  text-decoration: none;

  &:hover {
    color: var(--red-main);
  }
`;

type CardReportsSingleDoughnutProps = {
  isLoading: boolean;
  title: string;
  icon: ReactNode;
  onClick?: ReactEventHandler;
  children: ReactNode;
  tableData: {
    text: string;
    count: number;
    page?: {
      to: string;
      filters?: any;
      isDashboardLink?: boolean;
    };
  }[];
};

const CardReportsSingleDoughnut: React.FC<CardReportsSingleDoughnutProps> = ({
  isLoading,
  title,
  icon,
  onClick,
  children,
  tableData,
  ...rest
}) => (
  <StyledCardReportsSingleDoughnut {...rest}>
    <div className="header">
      <h3 className="reports-card-title">{title}</h3>
      <span className="icon-wrapper">
        <svg
          onClick={onClick}
          className="custom-icon"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {icon}
        </svg>
      </span>
    </div>

    {isLoading ? (
      <div className="card-content align-items-center">
        <Skeleton shape="circle" size="160px" />
        <div className="table">
          <div className="table-row mb-2 mt-2 align-items-center">
            <Skeleton className="table-text" width="80%" height="14px" />
            <Skeleton className="table-count" width="10%" height="22px" />
          </div>
          <div className="table-row mb-2 mt-2 align-items-center">
            <Skeleton className="table-text" width="80%" height="14px" />
            <Skeleton className="table-count" width="10%" height="22px" />
          </div>
          <div className="table-row mb-2 mt-2 align-items-center">
            <Skeleton className="table-text" width="80%" height="14px" />
            <Skeleton className="table-count" width="10%" height="22px" />
          </div>
          <div className="table-row mb-2 mt-2 align-items-center">
            <Skeleton className="table-text" width="80%" height="14px" />
            <Skeleton className="table-count" width="10%" height="22px" />
          </div>
        </div>
      </div>
    ) : (
      <div className="card-content">
        <div className="chart">{children}</div>
        <div className="table">
          {tableData.map((row) => (
            <div className="table-row" key={row.text}>
              {row?.page ? (
                <StyledLink
                  to={{
                    pathname: row?.page?.to,
                    search: row?.page?.filters
                      ? `filters=${JSON.stringify(row?.page?.filters)}`
                      : undefined,
                  }}
                  state={{ isDashboardLink: row.page?.isDashboardLink }}
                  className="table-text"
                >
                  <span>{row.text}</span>
                </StyledLink>
              ) : (
                <span className="table-text">{row.text}</span>
              )}
              <span className="table-count">{row.count}</span>
            </div>
          ))}
        </div>
      </div>
    )}
  </StyledCardReportsSingleDoughnut>
);

export default CardReportsSingleDoughnut;
