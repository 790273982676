import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { Campaign, CampaignStatusesEnum } from '@/client/campaigns';
import {
  LoadingStateType,
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { CampaignReportsDatatable } from '@/components/campaigns/datatables';
import { CampaignsReports } from '@/components/dashboards/admin';
import { useCampaign } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useMixpanel } from '@/hooks/useMixpanel';
import { pendoEvent, pendoProperty, usePendo } from '@/hooks/usePendo';
import { useToast } from '@/hooks/useToast';
import { LoadingPage } from '@/pages';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { AxiosError } from 'axios';
import mixpanel from 'mixpanel-browser';
import { MenuItem } from 'primereact/menuitem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const getBreadcrumbs = (
  account: Account,
  campaign: Campaign,
  t: TranslationFunctionType,
): MenuItem[] => {
  return [
    {
      label: account?.name,
      url: !account?.isSystem
        ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
        : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('campaigns'),
      url: RedirectPaths[RedirectPathsEnum.CAMPAIGNS](),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('generic.campaign.report'),
      url: RedirectPaths[RedirectPathsEnum.CAMPAIGNS_REPORT](campaign?.id),
      template: AppBreadCrumbTemplate,
    },
  ];
};

export const CampaignsReportPage: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const toast = useToast();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const [syncState, setSyncState] = useState<LoadingStateType>(
    LoadingStatuses.IDLE,
  );
  const { pendoTrack } = usePendo();
  const { track } = useMixpanel();
  const currentUser = useAppSelector(selectCurrentUser);
  const { isLoading, campaign, refetch } = useCampaign({
    campaignId: id,
  });

  useEffect(() => {
    if (currentUser?.account.isTrackingEnabled && (mixpanel as any)?.__loaded) {
      track('View Phishing reports');

      pendoTrack(pendoEvent.campaignsReportVisited, {
        [pendoProperty.forAccountId]: currentAccount?.id ?? '',
        [pendoProperty.forAccountName]: currentAccount?.name ?? '',
        [pendoProperty.campaignId]: campaign?.id ?? '',
        [pendoProperty.campaignName]: campaign?.name ?? '',
      });
    }
  }, [(mixpanel as any)?.__loaded]);

  const [shouldRefetchUsers, setShouldRefetchUsers] = useState(false);

  const toggleShouldRefetchUsers = () =>
    setShouldRefetchUsers((state) => !state);

  const handleRefreshReports = async () => {
    try {
      let syncActivated = false;
      if (
        [CampaignStatusesEnum.ACTIVE, CampaignStatusesEnum.SCHEDULED].includes(
          campaign?.status as CampaignStatusesEnum,
        )
      ) {
        setSyncState(LoadingStatuses.LOADING);
        syncActivated = true;
        await client.campaigns.syncCampaignResults(campaign?.id as string);
      }

      // Used in case of sync delay
      if (syncActivated) {
        setTimeout(async () => {
          await refetch();
          toggleShouldRefetchUsers();
          setSyncState(LoadingStatuses.IDLE);
        }, 3000);
      } else {
        await refetch();
        toggleShouldRefetchUsers();
      }
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  if (!campaign || isLoading) {
    return <LoadingPage message={t('camapign.report.load')} />;
  }

  return (
    <>
      <AppBreadCrumb
        model={getBreadcrumbs(currentAccount as Account, campaign, t)}
      />
      <FlexContainer justify="space-between">
        <h1 className="m-0">
          {campaign?.name} - {t('generic.campaign.report')}
        </h1>
        <AppButton
          label={t('campaign.report.refresh')}
          type="outlined"
          onClick={handleRefreshReports}
          state={syncState}
        />
      </FlexContainer>

      <CampaignsReports
        campaignsSummary={campaign.summary}
        isLoading={isLoading}
        t={t}
        className="my-4"
      />

      <CampaignReportsDatatable
        onShouldRefetchChange={toggleShouldRefetchUsers}
        shouldRefetch={shouldRefetchUsers}
        campaign={campaign}
        withToolbar
      />
    </>
  );
};
