import { Branch } from '@/client/branches';
import { EfrontGroupSyncRequest } from '@/client/efront';
import { Group } from '@/client/groups';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EfrontGroupsMultiselect } from './EfrontGroupsMultiselect';
import { step3Schema } from './validation-schema';

type EfrontGroupsFormContainerProps = {
  initialValues: EfrontGroupSyncRequest;
  branch: Branch;
  onValuesChange: (values: EfrontGroupSyncRequest) => void;
};

export const EfrontGroupsFormContainer: React.FC<
  EfrontGroupsFormContainerProps
> = ({ initialValues, branch, onValuesChange }) => {
  const { t } = useTranslation();

  return (
    <Formik
      validateOnMount
      validationSchema={step3Schema()}
      initialValues={initialValues}
      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
      onSubmit={() => {}}
    >
      {({ values, setFieldValue }) => {
        useEffect(() => {
          onValuesChange(values);
        }, [values]);

        return (
          <Form className="mt-6">
            <p>{t('efront.groups.found')}</p>
            <div className="flex flex-column field w-6 mb-6 mt-4">
              <label>{t('efront.select.groups')}</label>
              <EfrontGroupsMultiselect
                className="w-full"
                branch={branch}
                onSelect={(value: Group[]) => {
                  setFieldValue(
                    'id',
                    value?.map((group: Group) => group?.meta?.eFrontId),
                  );
                  setFieldValue('groups', value);
                }}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
