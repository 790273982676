import { getExceptionFromAxiosError } from '@/client/helpers';
import { UpdateMe } from '@/client/profile-settings';
import { User } from '@/client/users';
import { AxiosError, AxiosInstance } from 'axios';

export const profileSettingsService = (client: AxiosInstance) => {
  const BASE_ME_URL = '/me';
  const getMe = async (): Promise<User> => {
    try {
      const result = await client.get(BASE_ME_URL, { withCredentials: true });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateMe = async (request: UpdateMe): Promise<User> => {
    try {
      const result = await client.patch(BASE_ME_URL, request, {
        withCredentials: true,
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  return { getMe, updateMe };
};
