import { OPEN_FILTERS_KEY } from '@/common/constants';
import { FilterType } from '@/components/tables/crud/types';
import { RootState } from '@/store/store';
import {
  createSelector,
  createSlice,
  Draft,
  PayloadAction,
} from '@reduxjs/toolkit';

type FilterValues = Record<
  string,
  {
    value: any;
    filter: FilterType;
  }
>;

type OpenFiltersState = {
  current: { [k: string]: FilterValues } | null;
};

const initialState: OpenFiltersState = {
  current: JSON.parse(localStorage.getItem(OPEN_FILTERS_KEY) as string) ?? null,
};

const openFiltersSlice = createSlice({
  name: 'open-filters',
  initialState,
  reducers: {
    setOpenFilters: (
      state: Draft<OpenFiltersState>,
      action: PayloadAction<{ [k: string]: FilterValues }>,
    ) => {
      state.current = action.payload;

      localStorage.setItem(OPEN_FILTERS_KEY, JSON.stringify(action.payload));
    },
  },
});

export const selectOpenFilters = createSelector(
  (state: RootState) => state.openFilters,
  (openFiltersState) => openFiltersState.current,
);

export const { setOpenFilters } = openFiltersSlice.actions;

export default openFiltersSlice.reducer;
