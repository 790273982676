import { AxiosInstance } from 'axios';

export const thumbnailsService = (client: AxiosInstance) => {
  const THUMBNAILS_BASE_URL = 'thumbnails';

  const thumbnailsNotifyUrl = () =>
    `${client.defaults.baseURL}${THUMBNAILS_BASE_URL}/notify`;

  return { thumbnailsNotifyUrl };
};
