import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import classNames from 'classnames';
import { Badge } from 'primereact/badge';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useLearner } from './context';

const StyledTabMenu = styled(TabMenu)`
  &.p-tabmenu {
    @media screen and (max-width: 812px) {
      position: static !important;
      width: 100%;
      padding-top: 0 !important;
      overflow: visible;
    }
    position: fixed !important;
    top: 70px;
    margin-left: calc(var(--default-padding) * -1);
    padding-left: var(--default-padding);
    border-radius: var(--default-border-radius) var(--default-border-radius) 0 0;
    width: calc(100% - 2 * var(--big-padding));
    max-width: calc(var(--max-dashboard-width));
    background-color: var(--white-main);
    z-index: 10;
    padding-top: var(--default-padding);
    padding-bottom: var(--default-padding) !important;
    margin-bottom: 0;
  }
  .p-menuitem-text {
    font-weight: 700;
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);
    text-transform: capitalize;
  }

  .p-menuitem-link {
    padding: var(--xsmall-padding) 0 !important;
    margin-right: var(--xsmall-padding) !important;
    border-bottom: 3px solid transparent !important;
  }

  .p-highlight {
    .p-menuitem-link {
      border-bottom: 3px solid var(--red-main) !important;
    }
  }
`;

const StyledBadge = styled(Badge)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--small-button-height);
  height: var(--small-button-height);
  font-size: var(--xsmall-font-size);
  line-height: var(--xsmall-line-height);
  font-weight: 600;
  border-radius: 50%;
  background-color: var(--red-main);
  top: -3px;
  right: -6px;
`;

export const LearnerDashboardTabs: React.FC = () => {
  const { t } = useTranslation();
  const { coursesToDo, customMaterialsToDo, hasMaterials, hasCustomMaterials } =
    useLearner();
  const getItemTemplate = (url: string, current: string) => (item: MenuItem) =>
    (
      <Link
        to={url}
        className={classNames({
          'p-menuitem-link': true,
          'p-menuitem-link-active': current.includes(url),
        })}
        role="menuitem"
      >
        <div
          className={
            item.data.toDo
              ? 'p-menuitem-text p-overlay-badge'
              : 'p-menuitem-text'
          }
        >
          <span>{item.label}</span>
          {!!item.data.toDo && (
            <StyledBadge value={item.data.toDo} severity="danger" />
          )}
        </div>
      </Link>
    );

  const getTabItems = (): MenuItem[] => {
    const tabItems: MenuItem[] = [
      {
        label: t('courses'),
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE](),
          location.pathname,
        ),
        data: {
          toDo: coursesToDo,
          urls: [
            RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE](),
            RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_COMPLETED](),
          ],
        },
      },
    ];

    if (hasMaterials) {
      tabItems.push({
        label: t('materials'),
        template: getItemTemplate(
          hasCustomMaterials
            ? RedirectPaths[
                RedirectPathsEnum.LEARNER_DASHBOARD_MATERIALS_TODO
              ]()
            : RedirectPaths[
                RedirectPathsEnum.LEARNER_DASHBOARD_MATERIALS_RESOURCES
              ](),
          location.pathname,
        ),
        data: {
          toDo: customMaterialsToDo,
          urls: [
            RedirectPaths[
              RedirectPathsEnum.LEARNER_DASHBOARD_MATERIALS_RESOURCES
            ](),
            RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_MATERIALS_TODO](),
            RedirectPaths[
              RedirectPathsEnum.LEARNER_DASHBOARD_MATERIALS_COMPLETED
            ](),
          ],
        },
      });
    }
    // TODO: Uncomment when we add posters
    /*
tabItems.push({
label: t('posters'),
template: getItemTemplate(
RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_POSTERS_ALL](),
location.pathname,
),
data: [
RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_POSTERS_ALL](),
RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_POSTERS_COURSE](),
RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_POSTERS_CYBER](),
RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_POSTERS_GDPR](),
RedirectPaths[
RedirectPathsEnum.LEARNER_DASHBOARD_POSTERS_INFOGRAPHICS
](),
RedirectPaths[
RedirectPathsEnum.LEARNER_DASHBOARD_POSTERS_INFOSCREEN
](),
],
});
*/

    return tabItems;
  };

  const items = getTabItems();
  const currentPath = location.pathname;
  const [activeIndex, setActiveIndex] = useState<number>(
    items.findIndex(({ data }: MenuItem) =>
      data.urls.some((path: string) => path === currentPath),
    ),
  );

  return (
    <StyledTabMenu
      model={items}
      activeIndex={activeIndex}
      onTabChange={(e) => setActiveIndex(e.index)}
    />
  );
};
