import { SurveyCategory } from '@/client/security-culture/types';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { AppButton } from '@/ui/buttons';
import { BarChart } from '@/ui/charts/bar-chart';
import { AppChip } from '@/ui/chip';
import { FlexContainer } from '@/ui/styled-ui';
import { parseFloatingNumber } from '@/utils/helpers/ui.helper';
import { isNull } from 'lodash';
import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledContainer = styled(FlexContainer)`
  padding-bottom: calc(2 * var(--default-padding));

  > div {
    position: absolute;
    bottom: calc(-1 * var(--xxsmall-padding));
  }
`;

const StyledChartContainer = styled(FlexContainer)`
  .canvas-wrapper {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      height: 1px;
      width: 100%;
      bottom: var(--xsmall-padding);
      display: block;
      background-color: var(--gray-dark);
    }
  }
`;

type SecurityCultureDashboardBarChartProps = {
  item: SurveyCategory;
  surveyId?: string;
  maxValue?: number;
};

export const SecurityCultureDashboardBarChart: React.FC<
  SecurityCultureDashboardBarChartProps
> = ({ item, surveyId, maxValue }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const change = item.result - item.previousResult;
  const changeString =
    change > 0
      ? `+ ${parseFloatingNumber(change)}`
      : `- ${parseFloatingNumber(Math.abs(change))}`;

  return (
    <StyledChartContainer
      direction="column"
      key={item.id}
      gap={10}
      className="relative"
    >
      <BarChart
        id={`sc-scores-${item.id}`}
        value={parseFloatingNumber(item.result)}
        secondaryValue={parseFloatingNumber(item.benchmarkResult)}
        displayXAxis={false}
        height="200px"
        width="200px"
        tooltip={
          !isNull(item.previousResult)
            ? t('security.culture.change.after.last.measurement', {
                change: changeString,
              })
            : undefined
        }
        defaultTooltip={t('security.culture.target.group')}
        benchmarkTooltip={t('security.culture.other.organisations')}
        maxValue={maxValue}
      />
      <p className="m-0">
        {t(`security.culture.category.${item.name}.title`)}
        <i
          id={`sc-scores-${item.id}-info`}
          className="pi pi-info-circle ml-1"
        />
      </p>
      <Tooltip
        target={`#sc-scores-${item.id}-info`}
        position="bottom"
        content={t(`security.culture.category.${item.name}.tooltip`)}
      />
      <StyledContainer direction="column" gap={20}>
        <AppButton
          type="outlined"
          label={t('button.view.more')}
          onClick={() =>
            surveyId &&
            navigate(
              RedirectPaths[RedirectPathsEnum.SECURITY_CULTURE_CATEGORY](
                surveyId,
                item.name,
              ),
            )
          }
        />
        {item.isLow && (
          <div className="flex justify-content-center">
            <AppChip label={t('generic.low')} type="error" />
          </div>
        )}
      </StyledContainer>
    </StyledChartContainer>
  );
};
