import { useAuth } from '@/hooks/auth';
import { ErrorPage, LoadingPage } from '@/pages';
import React from 'react';
import { useTranslation } from 'react-i18next';

type customWindowType = typeof window & { apiHandshakeToken: string };
const customWindow = window as customWindowType;

type AuthHandshakeProps = {
  children: React.ReactNode;
};

export const AuthHandshake: React.FC<AuthHandshakeProps> = ({ children }) => {
  const { isLoading, error, isDone } = useAuth(customWindow.apiHandshakeToken);
  const { t } = useTranslation();

  if (isLoading || !isDone) {
    return <LoadingPage message={t('auth.wait')} />;
  }

  if (error) {
    return <ErrorPage errorMessage={error.message} />;
  }

  return <>{children}</>;
};
