import { handleAxiosError, nested, notEqual } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { MaterialType } from '@/client/courses';
import { Actions, Subjects, SystemRoles, User, UserMeta } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { UserPropertiesForm } from '@/components/users/forms/UserPropertiesForm';
import { useUpdateUser, useUser, useUserCourses } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { cannotManageMultiAdmins, queryStateConverter } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { MenuItem } from 'primereact/menuitem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingPage } from '../LoadingPage';
import { UserTabs } from './UserTabs';

const getBreadcrumbs = (
  account: Account,
  user: User,
  t: TranslationFunctionType,
): MenuItem[] => {
  const items = [
    {
      label: account?.name,
      url: account.isSystem
        ? RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id)
        : RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('users'),
      url: RedirectPaths[RedirectPathsEnum.USERS](),
      template: AppBreadCrumbTemplate,
    },
    {
      label: user?.name,
      className: 'active',
      url: RedirectPaths[RedirectPathsEnum.USERS_EDIT](user?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('generic.properties'),
      url: RedirectPaths[RedirectPathsEnum.USERS_PROPERTIES](user.id),
      template: AppBreadCrumbTemplate,
    },
  ];

  return items;
};

export const UserPropertiesPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { cannot } = usePermission();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const navigate = useNavigate();
  const toast = useToast();
  const { user, isLoading } = useUser({
    userId: id,
    accountId: currentAccount?.id,
  });

  let initialValues: UserMeta = {};
  if (user && !isLoading) {
    const meta = user.meta;

    initialValues = {
      companyName: meta?.companyName,
      department: meta?.department,
      manager: meta?.manager,
      managerEmail: meta?.managerEmail,
      country: meta?.country,
      jobTitle: meta?.jobTitle,
      mobilePhone: meta?.mobilePhone,
      officeLocation: meta?.officeLocation,
    };
  }

  const updateUser = useUpdateUser();

  const handleUpdateUserProps = async (data: UserMeta) => {
    try {
      await updateUser.update({
        userId: id as string,
        accountId: (currentAccount as Account)?.id,
        updates: {
          companyName: data.companyName,
          department: data.department,
          manager: data.manager,
          managerEmail: data.managerEmail,
          country: data.country,
          jobTitle: data.jobTitle,
          mobilePhone: data.mobilePhone,
          officeLocation: data.officeLocation,
        },
      });
      toast?.success(t('toast.success'), t('user.updated'));
      navigate(RedirectPaths[RedirectPathsEnum.USERS]());
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const [hasDocuments, setHasDocuments] = useState(false);

  const { isLoading: userDocumentsLoading, userCourses: userDocuments } =
    useUserCourses({
      take: 1,
      accountId: currentAccount?.id,
      userId: id,
      filters: [nested('course', [notEqual('type', MaterialType.COURSE)])],
    });

  useEffect(() => {
    setHasDocuments(!!userDocuments?.result?.length);
  }, [userDocuments]);

  const isReadOnly =
    !!user?.account?.meta?.isSSO ||
    !!user?.account?.activeDirectoryIntegration?.active ||
    cannot(Actions.UPDATE, Subjects.USERS) ||
    (currentAccount?.isSystem &&
      currentUser?.role.code !== SystemRoles.DEVELOPER) ||
    cannotManageMultiAdmins(user, currentUser);

  if (!user || isLoading || userDocumentsLoading) {
    return <LoadingPage message={t('user.loading')} />;
  }

  return (
    <>
      {currentAccount && user && (
        <>
          <AppBreadCrumb model={getBreadcrumbs(currentAccount, user, t)} />
          <h1>{user.name ? user.name : user.email}</h1>
          <UserTabs user={user} hasDocuments={hasDocuments} />
        </>
      )}

      <UserPropertiesForm
        initialValues={initialValues}
        onSubmit={handleUpdateUserProps}
        state={queryStateConverter(updateUser)}
        isDisabled={isReadOnly}
      />
    </>
  );
};
