import { File } from '@/client/files';
import { t } from 'i18next';

export const downloadFileByUrl = async (file: File) => {
  try {
    // Fetch the file from the given URL
    const response = await fetch(file.signedUrl);

    if (!response.ok) {
      throw new Error(t('generic.file.download.error'));
    }

    // Convert the response into a Blob
    const blob = await response.blob();

    const link = document.createElement('a');
    const blobUrl = window.URL.createObjectURL(blob);
    link.href = blobUrl;
    link.download = file.fileName;

    document.body.appendChild(link);

    link.click();

    link?.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    throw new Error(`${t('generic.file.download.error')}: ${error}`);
  }
};
